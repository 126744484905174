import NotificationsModal from '../components/notification_modal';

const Notitications = () => {
    return (
        <div className="d-flex flex-column h-100">
            <h1 className="text-center my-4">Notifications</h1>
            <div className="d-flex">
                <NotificationsModal
                    mode='page'
                />
            </div>
        </div>
    )
}

export default Notitications;