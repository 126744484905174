const isDevelopment = process.env.REACT_APP_NODE_ENV === 'dev';

const logger = {
  log: (...args) => {
    if (isDevelopment) {
      console.log(...args);
    }
  },
  error: (...args) => {
    if (isDevelopment) {
      console.error(...args);
    }
  },
  // Add other methods as needed (warn, info, etc.)
};

export default logger;