import { faBarChart, faLineChart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as d3 from "d3";
import { useEffect, useRef } from "react";
import logger from '../util/logger';

const RadarChart = (props) => {
    const ref = useRef();
    useEffect(() => {

        let features = ["Descriptive", "Sentence", "Emotional", "Grammar", "Wordplay", "Professionalism"];
        if (['1','2','3','4'].includes(props.data[0]['criteria_id'].toString())){
            features = ["Helpful", "Friendly", "Clear", "Sentiment"];
        }

        let test = {}
        props.data.map((item, i) => {
            test[features[i]] = item
        });
        logger.log(test)
        
        let width = 300;
        let height = 300;
        let marginTop = 100;
        let marginLeft = 100;
        let container = d3.select(ref.current);
        container.selectAll('svg').remove();
        let svg = container.append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr('overflow', 'visible');
        
        let radialScale = d3.scaleLinear()
                    .domain([0, 5])
                    .range([0, 120]);
        let ticks = [1,2,3,4,5];
        
        svg.selectAll("circle")
            .data(ticks)
            .join(
                enter => enter.append("circle")
                    .attr("cx", width/ 2)
                    .attr("cy", height/ 2)
                    .attr("fill", "none")
                    .attr("stroke", "gray")
                    .attr("r", d => radialScale(d))
            );
        
        svg.selectAll(".ticklabel")
            .data(ticks)
            .join(
                enter => enter.append("text")
                    .attr("class", "ticklabel")
                    .attr("x", width / 2 + 5)
                    .attr("y", d => height / 2 - radialScale(d))
                    .text(d => d.toString())
                );
        
        function angleToCoordinate(angle, value){
            let x = Math.cos(angle) * radialScale(value);
            let y = Math.sin(angle) * radialScale(value);
            return {"x": width / 2 + x, "y": height / 2 - y};
        }
        
        let featureData = features.map((f, i) => {
            let angle = (Math.PI / 2) + (2 * Math.PI * i / features.length);
            logger.log(angle)
            if (angle < 4 && angle > 2) {
                return {
                    "name": f,
                    "angle": angle,
                    "line_coord": angleToCoordinate(angle, 5),
                    "label_coord": angleToCoordinate(angle, 7)
                };
            }
            return {
                "name": f,
                "angle": angle,
                "line_coord": angleToCoordinate(angle, 5),
                "label_coord": angleToCoordinate(angle, 5.5)
            };
        });
        
        // draw axis line
        svg.selectAll("line")
            .data(featureData)
            .join(
                enter => enter.append("line")
                    .attr("x1", width / 2)
                    .attr("y1", height / 2)
                    .attr("x2", d => d.line_coord.x)
                    .attr("y2", d => d.line_coord.y)
                    .attr("stroke","black")
            );
        
        // draw axis label
        svg.selectAll(".axislabel")
            .data(featureData)
            .join(
                enter => enter.append("text")
                    .attr("x", d => d.label_coord.x)
                    .attr("y", d => d.label_coord.y)
                    .text(d => d.name)
            );
        
        let line = d3.line()
                    .x(d => d.x)
                    .y(d => d.y);
        let colors = ["darkorange", "gray", "navy"];
        function getPathCoordinates(data_point){
            let coordinates = [];
            for (var i = 0; i < features.length; i++){
                let ft_name = features[i];
                let angle = (Math.PI / 2) + (2 * Math.PI * i / features.length);
                coordinates.push(angleToCoordinate(angle, data_point[ft_name]['grade']));
            }
            return coordinates;
        };
        
        function getCircleCoordinates(data_point){
            let coordinates = [];
            for (var i = 0; i < features.length; i++){
                let ft_name = features[i];
                let angle = (Math.PI / 2) + (2 * Math.PI * i / features.length);
                coordinates.push({
                    ...angleToCoordinate(angle, data_point[ft_name]['grade']),
                    criteria: data_point[ft_name]['criteria'],
                    explanation: data_point[ft_name]['explanation']
                });
            }
            return coordinates;
        };
        
        
        // draw the path element
        svg.selectAll("path")
            .data([test])
            .join(
                enter => enter.append("path")
                    .datum(d => getPathCoordinates(d))
                    .attr("d", line)
                    .attr("stroke-width", 3)
                    .attr("stroke", (_, i) => colors[i])
                    .attr("fill", (_, i) => colors[i])
                    .attr("stroke-opacity", 1)
                    .attr("opacity", 0.5)
            )
        
        // tooltip
        const tooltip = d3.select(".tooltipR")
        
        // draw circle 
        svg.append('g')
            .selectAll('circle')
            .data(getCircleCoordinates(test))
            .join(
                enter => enter.append('circle')
                    .attr('cx', d => d.x)
                    .attr('cy', d => d.y)
                    .attr('r', 8)
                    .attr('fill', 'green')
                    .attr('opacity', 0.8)
            )
            // .on("mouseover", (event, d) => {
            //     tooltip.style("display", "block")
            //       .style("left", (event.pageX + 15) + "px")
            //       .style("top", (event.pageY - 15) + "px")
            //       .html(`<strong>${d.criteria}</strong>:</br> ${d.explanation}`);
            //   })
            // .on("mouseout", () => {
            // tooltip.style("display", "none");
            // });
    }, [props.data]);
    return (
        <div class='canvasR' style={{width:'100%', height:'100%'}} ref={ref}>
          <div class='tooltipR'>
           
          </div>
        </div>
      )
};

export default RadarChart;