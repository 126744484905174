import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useWebSocket } from './websocket_context';
import apiClient from '../api/client';
import  { getAuthToken } from '../util/token';
import logger from '../util/logger';

const NotificationContext = createContext({
  notifications: [],
  setNotifications: () => {},
});

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const { messages, socket } = useWebSocket();
    const [notifications, setNotifications] = useState([]);
    
    const fetchNotifications = useCallback(() => {
        const token = getAuthToken();
        if (socket && token && token !== 'EXPIRED') {
            apiClient.get('/api/notifications')
            .then(({data}) => {
                logger.log(data);
                setNotifications(data);
            }).catch(error => {
                console.error('Failed to fetch notifications:', error);
            });
        };
    }, [socket]); 

    useEffect(() => {
        // logger.log(socket);
        fetchNotifications();
    }, [fetchNotifications]);

    useEffect(() => {
        setNotifications(prev => [...prev, messages]);
    }, [messages]);

  return (
    <NotificationContext.Provider value={{ notifications, setNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

