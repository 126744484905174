import { useState, useRef, useEffect, useMemo } from 'react';
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import NotiModal from '../components/modal';
import { GroupCartItem, RowItem } from "../components/cart-item";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faArrowUp, faArrowDown, faSort, faUpRightFromSquare, faCheckCircle, faTimesCircle, faAngleUp, faAngleDown, faGlobe
} from "@fortawesome/free-solid-svg-icons";
import { getAuthToken } from '../util/token';
import { useSearchParams } from "react-router-dom";
import Papa from 'papaparse';
import apiClient from '../api/client';
import logger from '../util/logger';

// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min.js');


function Cluster() {

    const [activeG, setActiveG] = useState('');
    const [activeDisplayName, setActiveDisplayName] = useState('');
    const [kwDetail, setKwDetail] = useState([]);
    const [kwGroup, setKwGroup] = useState([]);
    const [confirmKw, setConfirmKw] = useState([]);
    const [projectKw, setProjectKw] = useState([]);
    const [msg, setMsg] = useState('');
    const [mode, setMode] = useState('Type');
    const [filter, setFilter] = useState('');
    const [filterRange, setFilterRange] = useState(['','']);
    const [searchKey, setSearchKey] = useState('');
    const no_clusters = useRef();
    const range_from = useRef();
    const range_to = useRef();
    const [filePicked, setFilePicked] = useState(false);
    const [ nCluster, setNCluster] = useState(false);
    const [sortGroup, setSortGroup] = useState('');
    const [serpNoti, setSerpNoti] = useState('');

    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    //project info
    const topic = useRef('');
    const domain = useRef('');
    const description = useRef('');

    //save setup
    const [importStat, setImportStat] = useState('');
    const [ taskId, setTaskId] = useState('');
    const [autoSave, setAutoSave] = useState('');

    //table title sort
    const [sort, setSort] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    // get volume
    const [location, setLocation] = useState('VN');
    const [language, setLanguage] = useState('1040');


    useEffect(() => {
        if (searchParams.get('id')){
          const input = {
            id: searchParams.get('id')
          };
        //   logger.log(input);
          apiClient.post('/api/manage-tasks', input, {headers: {'X-User': userName}})
          .then (
              ({data}) =>{
                if (data === null){
                  return
                };
                logger.log(data);
                setProjectKw(data[0]['keywords']);
                setKwDetail(data[0]['keywords']);
                topic.current.value = (data[0]['topic']);
                domain.current.value = (data[0]['domain']);
                description.current.value = (data[0]['description']);
                // logger.log(data[0]['group']);
                if(data[0]['group']) setKwGroup(data[0]['group']);
        })}
      },[]);

    const tableSortHandler = (mode) => {
        if (mode === 'kw'){
            if (sort !== 'keyword_inc' && sort !== 'keyword_dec'){
              setSort('keyword_inc');
              if(typeof kwDetail[0] === 'object'){
                if (typeof kwDetail[0]['keywords'][0] === 'object'){
                    let new_arr = [];
                    new_arr = kwDetail.map((elem) => {
                        const child = elem.keywords.map((ele) => ({
                            group_name: ele.group_name,
                            keywords: [...ele.keywords.sort((a, b) => a.localeCompare(b))]
                        }));
                        return(
                            {group_name: elem.group_name,
                            keywords: child} // Sort keywords alphabetically
                        )
                    });
                    setKwDetail(new_arr);
                } else {
                    let new_arr = [];
                    new_arr = kwDetail.map((elem) => ({
                        group_name: elem.group_name,
                        keywords: [...elem.keywords.sort((a, b) => a.localeCompare(b))], // Sort keywords alphabetically
                    }));
                    setKwDetail(new_arr);
                };
              } else if (typeof kwDetail[0] === 'string'){
                kwDetail.sort((a,b) => (b > a) ? -1 : (b < a) ? 1 : 0);
              };
            } else if(sort === 'keyword_dec'){
                setSort('keyword_inc');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              keywords: [...ele.keywords.sort((a, b) => a.localeCompare(b))]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child} // Sort keywords alphabetically
                          )
                      });
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          keywords: [...elem.keywords.sort((a, b) => a.localeCompare(b))], // Sort keywords alphabetically
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                  kwDetail.sort((a,b) => (b > a) ? -1 : (b < a) ? 1 : 0);
                };
            } else if(sort === 'keyword_inc'){
              setSort('keyword_dec');
              if(typeof kwDetail[0] === 'object'){
                if (typeof kwDetail[0]['keywords'][0] === 'object'){
                    let new_arr = [];
                    new_arr = kwDetail.map((elem) => {
                        const child = elem.keywords.map((ele) => ({
                            group_name: ele.group_name,
                            keywords: [...ele.keywords.sort((a, b) => b.localeCompare(a))]
                        }));
                        return(
                            {group_name: elem.group_name,
                            keywords: child} // Sort keywords alphabetically
                        )
                    });
                    // logger.log(kwDetail);
                    setKwDetail(new_arr);
                } else {
                    let new_arr = [];
                    new_arr = kwDetail.map((elem) => ({
                        group_name: elem.group_name,
                        keywords: [...elem.keywords.sort((a, b) => b.localeCompare(a))], // Sort keywords alphabetically
                    }));
                    setKwDetail(new_arr);
                };
              } else if (typeof kwDetail[0] === 'string'){
                kwDetail.sort((a,b) => (b > a) ? 1 : (b < a) ? -1 : 0);
              };
            };
        } else if (mode === 'volume'){
            if (sort !== 'volume_inc' && sort !== 'volume_dec'){
                setSort('volume_inc');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[1]);
                                let vol1 = parseInt(a.split('<<>>')[1]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[1]);
                                let vol1 = parseInt(a.split('<<>>')[1]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                  kwDetail.sort((a,b) => {
                    let vol2 = parseInt(b.split('<<>>')[1]);
                    let vol1 = parseInt(a.split('<<>>')[1]);
                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                });
                };
              } else if(sort === 'volume_dec'){
                  setSort('volume_inc');
                  if(typeof kwDetail[0] === 'object'){
                    if (typeof kwDetail[0]['keywords'][0] === 'object'){
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => {
                            const child = elem.keywords.map((ele) => ({
                                group_name: ele.group_name,
                                keywords: [...ele.keywords.sort((a, b) => {
                                    let vol2 = parseInt(b.split('<<>>')[1]);
                                    let vol1 = parseInt(a.split('<<>>')[1]);
                                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                                })]
                            }));
                            return(
                                {group_name: elem.group_name,
                                keywords: child} // Sort keywords alphabetically
                            )
                        });
                        setKwDetail(new_arr);
                    } else {
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => ({
                            group_name: elem.group_name,
                            keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[1]);
                                let vol1 = parseInt(a.split('<<>>')[1]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                            })],
                        }));
                        setKwDetail(new_arr);
                    };
                  } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[1]);
                        let vol1 = parseInt(a.split('<<>>')[1]);
                        return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                    });
                  };
              } else if(sort === 'volume_inc'){
                setSort('volume_dec');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[1]);
                                let vol1 = parseInt(a.split('<<>>')[1]);
                                return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      // logger.log(kwDetail);
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                            let vol2 = parseInt(b.split('<<>>')[1]);
                            let vol1 = parseInt(a.split('<<>>')[1]);
                            return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[1]);
                        let vol1 = parseInt(a.split('<<>>')[1]);
                        return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                    });
                };
              };
        } else if (mode === 'kd'){
            if (sort !== 'kd_inc' && sort !== 'kd_dec'){
                setSort('kd_inc');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[2]);
                                let vol1 = parseInt(a.split('<<>>')[2]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[2]);
                                let vol1 = parseInt(a.split('<<>>')[2]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                  kwDetail.sort((a,b) => {
                    let vol2 = parseInt(b.split('<<>>')[2]);
                    let vol1 = parseInt(a.split('<<>>')[2]);
                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                });
                };
              } else if(sort === 'kd_dec'){
                  setSort('kd_inc');
                  if(typeof kwDetail[0] === 'object'){
                    if (typeof kwDetail[0]['keywords'][0] === 'object'){
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => {
                            const child = elem.keywords.map((ele) => ({
                                group_name: ele.group_name,
                                keywords: [...ele.keywords.sort((a, b) => {
                                    let vol2 = parseInt(b.split('<<>>')[2]);
                                    let vol1 = parseInt(a.split('<<>>')[2]);
                                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                                })]
                            }));
                            return(
                                {group_name: elem.group_name,
                                keywords: child} // Sort keywords alphabetically
                            )
                        });
                        setKwDetail(new_arr);
                    } else {
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => ({
                            group_name: elem.group_name,
                            keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[2]);
                                let vol1 = parseInt(a.split('<<>>')[2]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                            })],
                        }));
                        setKwDetail(new_arr);
                    };
                  } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[2]);
                        let vol1 = parseInt(a.split('<<>>')[2]);
                        return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                    });
                  };
              } else if(sort === 'kd_inc'){
                setSort('kd_dec');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[2]);
                                let vol1 = parseInt(a.split('<<>>')[2]);
                                return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      // logger.log(kwDetail);
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                            let vol2 = parseInt(b.split('<<>>')[2]);
                            let vol1 = parseInt(a.split('<<>>')[2]);
                            return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[2]);
                        let vol1 = parseInt(a.split('<<>>')[2]);
                        return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                    });
                };
              };
        };
    };

    const selectGroupHandler = (item) => {
        // logger.log(item);
        setKwDetail([item]);
        setActiveG(item.group_name);
        setActiveDisplayName(item.display_name);
        // logger.log(kwDetail);
        };

    const handleFileUpload = (e) => {
        setSortGroup('');
        const modal = document.getElementById('ModalCenter');
        const m = new bootstrap.Modal(modal);
        // if (mode === 'SERP' && (parseInt(no_clusters.current.value) > 5 || parseInt(no_clusters.current.value) < 3)){
        //     setConfirmKw([]);
        //     setMsg('Similar results should lie within 3 and 5!');
        // }
        setConfirmKw([]);
        setMsg('');
        
        const input_file = document.getElementById('formFile').files
        if (input_file.length === 0){
            return
        };
        logger.log(input_file);
        const file_size = input_file[0].size;
        const file = input_file[0];
        const data = new FormData();
        data.append("file", file);
        data.append('group',no_clusters.current.value);
        data.append('mode', mode);
        if (file_size < 50*1024*1024) {
            logger.log('less than 50MB');
            Papa.parse(input_file[0], {
                header: false,
                // worker: true,
                skipEmptyLines: true,
                complete: function (results) {
                    logger.log(results);
                    setConfirmKw(results.data.flat(1));
                },
              });
            // fetch(`${process.env.REACT_APP_HOST_IP}/api/uploadfile`, {
            //     method: 'POST',
            //     body: data,
            //     headers: {'Authorization': 'Bearer ' + token}
            // }).then(
            //     response => {
            //     dispatch(hideLoading());
            //     return response.json();
            // }).then(kw => {
            //     logger.log(kw);
            //     if (Array.isArray(kw)){
            //         setConfirmKw(current => {return [...current, ...kw]});
            //     }else{
            //         setMsg(kw.message);
            //     };
            // })
        } else {
            logger.log('greater than 50MB');
        };
        m.toggle();
    };
    const addKwHandler = (mode) => {
        if (mode === 'existing'){
            setProjectKw(c => [...new Set([...c,...confirmKw])]);
            setKwDetail(c => [...new Set([...c,...confirmKw])]);
        }else if (mode === 'new'){
            setTaskId('');
            topic.current.value = '';
            domain.current.value = '';
            description.current.value = '';
            setSearchParams('');
            setKwGroup([]);
            setProjectKw(confirmKw);
            setKwDetail(confirmKw);

        };
    };

    const groupKwHandler = () => {
        // logger.log(no_clusters.current.value);
        if (mode === 'SERP' && (no_clusters.current.value > 5 || no_clusters.current.value < 3)){
            setSerpNoti(true);
            setTimeout(() => {
                setSerpNoti(false);
            }, 3*1000);
            return
        };

        dispatch(showLoading());
        const input = {
            'kw_list': projectKw,
            'num_clusters': parseInt(no_clusters.current.value),
            'group_mode': mode,
            date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(),
        }
        
        apiClient.post(`/api/${mode === 'SERP' ? 'cluster-matrix-serp' : mode === 'Ada-2' ? 'cluster-ada' : 'cluster-kw'}`, input, {
            headers: {
            'X-User': userName
            }
        })
        .then(({data}) => {
            dispatch(hideLoading());
            logger.log(data);
            setKwGroup(current => { return data });
        });
    };

    const changeLocationHandler = (e, value) => {
        e.preventDefault();
        setMode(value);
        // logger.log(location);
      };

    const furtherSplitHandler = (item, num, mo) => {
        const input = {
            'kw_list': item.keywords,
            'num_clusters': num,
            'group_mode': mo,
            'parent': item.group_name,
            date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(),
        };
        logger.log(input);
        dispatch(showLoading());

        apiClient.post(`/api/${mo === 'SERP' ? 'cluster-matrix-serp' : mo === 'Ada-2' ? 'cluster-ada' : 'cluster-kw'}`, input, {
            headers: {
            'X-User': userName
            }
        }).then(({ data }) => {
            dispatch(hideLoading());
            logger.log(data);
            const new_group = kwGroup.map((it) => {
            if (it.group_name === item.group_name) {
                return { ...it, keywords: data };
            };
            return it;
            });
            logger.log(new_group);
            setKwGroup(new_group);
        });
    };

    function getStyle({ provided, style, isDragging }) {
        // If you don't want any spacing between your items
        // then you could just return this.
        // I do a little bit of magic to have some nice visual space
        // between the row items
        const combined = {
            ...style,
            ...provided.draggableProps.style,
            backgroundColor: isDragging ? '#fff3cd' : '',
            maxWidth: isDragging ? '20%' : '100%',
        };
        return combined;
    };

    const delKwHandler = (pa, ch, item) => {
        logger.log(pa,ch);
        if (pa === ch && pa === ''){
            setKwDetail(kwDetail.filter(kw => kw !== item));
            setProjectKw(projectKw.filter(kw => kw !== item));
        } else {
            if(ch === '' || pa === ch && pa.search(/\./i) === -1){
                if(!item){
                    setKwGroup(kwGroup.filter((it) => it.group_name !== pa));
                    setKwDetail([]);
                }else {
                    let newGroup = kwGroup.map((it) => {
                        if(it.group_name === pa){
                        it.keywords.splice(it.keywords.indexOf(item),1);
                        };
                        return it;
                    });
                    setKwGroup(newGroup);
                }
            } else if(pa.search(/\./i) !== -1){
                let newGroup = kwGroup.map((group) => {
                    if(group.group_name === ch){
                        if(!item){
                            logger.log('case');
                            let gr = group.keywords.filter((sub_group) => sub_group.group_name !== pa);
                            setKwDetail([]);
                            return {
                                group_name: ch,
                                keywords: gr
                            };
                        }else {
                            group.keywords.map((sub_group) => {
                                if(sub_group.group_name === pa){
                                    sub_group.keywords.splice(sub_group.keywords.indexOf(item),1);
                                };
                                return sub_group;
                            });
                        }    
                    }; 
                    return group;
                });
                setKwGroup(newGroup);
            }else {
                let newGroup = kwGroup.map((group) => {
                    if(group.group_name === pa){
                        if(!item){
                            logger.log('case');
                            let gr = group.keywords.filter((sub_group) => sub_group.group_name !== ch);
                            // setKwDetail([]);
                            return {
                                group_name: pa,
                                keywords: gr
                            };
                        } else {
                            group.keywords.map((sub_group) => {
                                if(sub_group.group_name === ch){
                                    sub_group.keywords.splice(sub_group.keywords.indexOf(item),1);
                                };
                                return sub_group;
                            });
                        }  
                    }; 
                    return group;
                });
                setKwGroup(newGroup);
                setKwDetail(newGroup.filter((group) => group.group_name === pa));
            };
        }
    };

    function Item({ provided, pa, ch, item, index, style, isDragging }) {
        // logger.log(provided.draggableProps);
        return (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle({ provided, style, isDragging })}
            className={`item ${isDragging ? "is-dragging" : ""}`}
          >
              <th scope="row">{index + 1}</th>
              <td>{item.split('<<>>')[0]}</td>
              <td>{item.split('<<>>')[1]}</td>
              <td>{item.split('<<>>')[2]}</td>
              <td>
                <button
                    type="button"
                    className="btn btn-outline-danger btn-sm  me-1"
                    onClick={event => delKwHandler(pa,ch,item)}
                >
                    <FontAwesomeIcon icon={faMinus} />
                </button>
              </td>
          </tr>
        );
      };


    function CloneItem({ provided, item, index, style, isDragging }) {
    // logger.log(provided.draggableProps);
        return (
            <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle({ provided, style, isDragging })}
            className={`item ${isDragging ? "is-dragging" : ""}`}
            >
                {/* <th scope="row">{index + 1}</th> */}
                <td>{item.split('<<>>')[0]}</td>
                {/* <td>Otto</td> */}
                {/* <td>@mdo</td> */}
            </tr>
        );
    };
      
    const onDragEndHandler = (result) => {
        if (!result.destination) return;
        if (result.source.droppableId === result.destination.droppableId) return;
        const parent_group = result.source.droppableId.replace('kd-','').replace(/-.*$/i,'');
        const source_child_group = result.source.droppableId.replace('kd-','').replace(/^.*-/i,'');
        const dest_child_group = result.destination.droppableId.replace('kd-','').replace(/^.*-/i,'');
        const dest_parent_group = result.destination.droppableId.replace('kd-','').replace(/-.*$/i,'');
        logger.log('source parent: ', parent_group, 'source child: ', source_child_group, 'dest child: ', dest_child_group, 'dest parent: ', dest_parent_group);
        // logger.log(result);
        const changed_kwDetail = Array.from(kwGroup);
        if (dest_child_group === '' && source_child_group === ''){
            if (typeof(changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].keywords[0]) === 'object') return;
            logger.log('source parent: ',parent_group, 'source child: ',source_child_group, 'dest parent: ', dest_parent_group);
            let movedItem = changed_kwDetail.filter((item) => item.group_name === parent_group)[0].keywords.splice(result.source.index,1)[0];
            changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].keywords.splice(1,0,movedItem);
            setKwGroup(changed_kwDetail);
            return;
        } else if (dest_child_group === '' && source_child_group !== ''){
            if (typeof(changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].keywords[0]) === 'object') return;
            logger.log(parent_group, source_child_group, dest_parent_group);
            let reorderedItem = changed_kwDetail.filter((item) => item.group_name === parent_group)[0].
                                                keywords.filter((item) => item.group_name === source_child_group)[0].
                                                keywords.splice(result.source.index, 1)[0];
            changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0]
                    .keywords.splice(1,0,reorderedItem);
            setKwGroup(changed_kwDetail);
            return;
        } else if (dest_child_group !== '' && source_child_group !== ''){
            logger.log('True');
            let reorderedItem = changed_kwDetail.filter((item) => item.group_name === parent_group)[0].
                                                keywords.filter((item) => item.group_name === source_child_group)[0].
                                                keywords.splice(result.source.index, 1)[0];
            logger.log(reorderedItem);
            changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].
                    keywords.filter((item) => item.group_name === dest_child_group)[0].
                    keywords.splice(1,0,reorderedItem);
            setKwGroup(changed_kwDetail);
            return;
        } else if(dest_child_group !== '' && source_child_group === '') {
            let movedItem = changed_kwDetail.filter((item) => item.group_name === parent_group)[0].keywords.splice(result.source.index,1)[0];
            changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].
                    keywords.filter((item) => item.group_name === dest_child_group)[0].
                    keywords.splice(1,0,movedItem);
            setKwGroup(changed_kwDetail);
            return;
        };
        // logger.log(result);
        // const items = Array.from(kwDetail);
        logger.log(parent_group, source_child_group, dest_child_group);
        const new_kwDetail = kwDetail.map((group) => {
            if (group.group_name === parent_group) {
                // logger.log(group);
                let reorderedItem = group.keywords.filter((item) => item.group_name === source_child_group)[0].keywords.splice(result.source.index, 1)[0];
                group.keywords.filter((item) => item.group_name === dest_child_group)[0].keywords.splice(result.destination.index, 0, reorderedItem);
                // logger.log(reorderedItem);
            return group;
        }});
        
        setKwDetail(new_kwDetail);
    };

    const filterHandler = (mode) => {
        setFilter(mode);
        if (mode === 'Keywords'){
            setFilterRange(['','']);
        }else if (mode === 'No. of Keywords'){
            setSearchKey('');
        }

    };

    const applyFilterHandler = (range, search, item) => {
        if (searchKey !== ''){
            if (typeof(item.keywords[0]) === 'object'){
                let kws = [];
                item.keywords.map((it) => {return(it.keywords)}).
                map((ite) => {ite.forEach((i) => {kws.push(i)})});
                return kws.includes(search);
            };
            return (
                item.keywords.includes(search)
            );
        }else if (range[0] !== '' || range[1] !== '' ){
            if (typeof(item.keywords[0]) === 'object'){
                let count = 0;
                item.keywords.map((it) => {
                    count += it.keywords.length;
                });
                  return ((range[0] !== '' ? (count >= range[0]) : true)
                  && (range[1] !== '' ? (count < range[1]) : true))
            }
            return ((range[0] !== '' ? (item.keywords.length >= range[0]) : true)
                    && (range[1] !== '' ? (item.keywords.length < range[1]) : true))
        };
        return true;
    };

    const saveClusterHandler = () => {
        setImportStat('');
        const m = new bootstrap.Modal(document.getElementById('ModalImport'));
        m.toggle();
      };

    const handleSaveCluster = () => {
        if (topic.current.value === ''){
            saveClusterHandler();
          }else{
            importCluster('save outside');
          }
    };

    const importCluster = (check) => {
        const input = {
          topic: topic.current.value,
          domain: domain.current.value,
          description: description.current.value,
          keywords: projectKw,
          group: kwGroup,
          date: new Date().getTime(),
          task: 'grouping',
          username: localStorage.getItem('username')
        }
        if (searchParams.get('id')){
          input['id'] = searchParams.get('id');
        } else if (taskId !== ''){
          input['id'] = taskId;
        }
        apiClient.post('/api/save-cluster', input, {headers: {'X-User': userName}})
        .then (
            ({data}) =>{
              if(data.acknowledged === true){
                setImportStat(true);
                if(check === "save outside") {
                    setAutoSave(true);
                    setTimeout(() => {
                        setAutoSave(false);
                    }, 3*1000)
                }
                if (taskId === ''){
                  setTaskId(data.inserted_id);
                }
              }else{
                setImportStat(false);
              }
              logger.log(data);
            }
        );
      };

    const exportFileHandler = (event, data) => {
        if(data.length === 0){
          return;
        } else{
          dispatch(showLoading());
          logger.log(data);
          apiClient.post('/api/download/kw-cluster', data, {headers: {'X-User': userName}})
          .then(
            response => response.data)
            .then(({blob}) => {
                dispatch(hideLoading());
                const url = window.URL.createObjectURL(blob);
                // logger.log(blob);
                const link = document.createElement("a");
                link.download = 'kw_cluster.csv';
                link.href = url;
                link.click();
            }).catch(error => {
              console.error(error);
          });
        };
      };

    // const myModal = new window.bootstrap.Modal(document.getElementById('#ModalCenter'), {
    //     keyboard: false,
    //     backdrop: true
    // });

    // myModal.show();
    const sortHandler = (e) => {
        e.preventDefault();
        sortGroup === '' ? setSortGroup(true) : setSortGroup(current => !current);
        if (!kwGroup[0].kw_count){
            const newKwGroup = kwGroup.map((item =>{
                if(typeof(item.keywords[0]) === 'object'){
                    let kw_count = 0;
                    item.keywords.map((it) =>{
                        kw_count += it.keywords.length;
                    });
    
                    return {...item, kw_count: kw_count}
                }else {
                    return {...item, kw_count: item.keywords.length}
                }
            }));
            sortGroup === true ? newKwGroup.sort((a,b) => a.kw_count - b.kw_count) : newKwGroup.sort((a,b) => b.kw_count - a.kw_count);
            setKwGroup(newKwGroup);
            return
        }
        sortGroup === true ? kwGroup.sort((a,b) => a.kw_count - b.kw_count) : kwGroup.sort((a,b) => b.kw_count - a.kw_count);
    };

    const getVolumeHandler = () => {
        const m = new bootstrap.Modal(document.getElementById('ModalVolume'));
        m.toggle();
    };

    const updateVolumeHandler = () => {
        logger.log(kwDetail);
        let kw = [];
        if (typeof kwDetail[0] !== 'string'){
            kw = kwDetail[0]['keywords'].map(item => {
                return item.split('<<>>')[0]
            });
        }else {
            kw = kwDetail.map(item => {
                return item.split('<<>>')[0]
            });
        }
        
        const input = {
            location: location,
            language: language,
            keyword_seed: kw
        };
        logger.log(input);
        
        apiClient.post('/api/generate-historical-metrics', input, {headers: {'X-User': userName}})
        .then (
            ({data}) =>{
                dispatch(showLoading());
                logger.log(data);
                let d = kwDetail;
                if (typeof kwDetail[0] !== 'string'){
                    logger.log(activeG);
                    d[0]['keywords'] = data.map(item => {
                        return `${item.Keyword}<<>>${item.Volume !== null ? item.Volume : 0}<<>>`
                    });
                    let g = kwGroup.map(item => {
                        if (item.group_name === activeG){
                            item.keywords = d[0]['keywords'];
                        };
                        return item
                    });
                    logger.log(g);
                    setKwGroup(g);
                }else {
                    logger.log(kwGroup);
                    d = data.map(item => {
                        return `${item.Keyword}<<>>${item.Volume !== null ? item.Volume : 0}<<>>`
                    });
                    let g = kwGroup.map(item => {
                        d.map(kw => {
                            let check = kw.split('<<>>')[0];
                            if (item.keywords.includes(check)){
                                let idx = item.keywords.indexOf(check);
                                item.keywords.splice(idx, 1);
                                item.keywords.push(kw);
                            }
                        });
                        return item 
                    });
                    logger.log(g);
                    setKwGroup(g);   
                }
                setKwDetail(d);
            })
    };

    const openOutlineModelHandler = () => {
        setImportStat('');
        const m = new bootstrap.Modal(document.getElementById('ModalOutline'));
        m.toggle();
    };

    const createOutlineHandler = () => {
        // let kws = kwDetail[0]['keywords'].map(item => {
        //     return item.split('<<>>')[0]
        // })
        const input = {
            topic: activeDisplayName,
            domain: domain.current.value,
            description: activeDisplayName,
            outline: '',
            keywords: kwDetail[0]['keywords'],
            date: new Date().getTime(),
            task: 'outline',
            username: localStorage.getItem('username')
          };
          logger.log(input);
          apiClient.post('/api/import-outline', input, {headers: {'X-User': userName}})
          .then (
              ({data}) =>{
                if(data.acknowledged === true){
                  setImportStat(true);
                }else{
                  setImportStat(false);
                }
                logger.log(data);
              }
          );
    }


    return (
        <DragDropContext onDragEnd={onDragEndHandler}>
            <NotiModal 
            kw_list = {confirmKw}
            msg = {msg}
            onGroupKw={addKwHandler}
            />
            {/* <NotiModal
            msg = 'Similar results should lie between 3-5'
            /> */}
            <div className="d-flex flex-column h-100">
                <h1 className="text-center my-4">Keyword cluster</h1>
                <div className="d-flex my-5 align-items-center">
                    <div className="d-flex input-group align-items-center w-50 me-auto">
                        {/* <label for="formFileSm" class="form-label">Upload keyword list</label> */}
                        <button class="btn btn-outline-secondary dropdown-toggle me" type="button" data-bs-toggle="dropdown" aria-expanded="false">{mode}</button>
                        <ul class="dropdown-menu">
                            <li onClick={event => changeLocationHandler(event, 'SERP')}>
                                <a class=" d-flex dropdown-item m-auto" href="#">
                                Search Intent
                                </a>
                            </li>
                            <li onClick={event => changeLocationHandler(event, 'Tf-Idf')}>
                                <a class=" d-flex dropdown-item m-auto" href="#">
                                Tf-Idf
                                </a>
                            </li>
                            <li onClick={event => changeLocationHandler(event, 'Ada-2')}>
                                <a class=" d-flex dropdown-item m-auto" href="#">
                                Semantic
                                </a>
                            </li>
                        </ul>
                        <input type="number" id='group_number' class="form-control" placeholder="Sub-group" aria-label="Sub-group" aria-describedby="button-addon2" style={{"max-width": "100px"}} ref={no_clusters} onChange={e => {e.target.value <= 0 && (no_clusters.current.value = 1); e.target.value !== '' ? setNCluster(true) : setNCluster(false)}}/>
                        <input class="form-control" id="formFile" type="file" onChange={e => {
                            if (e.target.files.length > 0) {
                                setFilePicked(true);
                                handleFileUpload();
                            }else{
                                setFilePicked(false);
                            }
                            }}
                            onClick={e => {e.target.value = null}}
                            />
                        
                        <button className={`btn btn-primary ${(!nCluster || projectKw.length === 0) && 'disabled'}`} type="button" aria-expanded="false" onClick = {groupKwHandler}>Start Clustering</button>
                    </div>
                    <button
                    type="button"
                    className={`btn btn-primary ${kwGroup.length === 0 && 'disabled'}`}
                    onClick={(event) => exportFileHandler(event, kwGroup)}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
                </div>
                {serpNoti &&
                    <div className='mb-4' style={{color: 'red'}}>
                    Shared SERP results must lie within 3 - 5.
                </div>}
            <div className="d-flex flex-column flex-grow-1 overflow-hidden">
                <div className="row flex-grow-1 overflow-hidden">
                    <div className="d-flex flex-column vh-100 col-md-4">
                        <h3 className="mb-2">Keyword Group</h3>
                        <div className="d-flex mb-2">
                            <div class="dropdown me-auto">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FontAwesomeIcon icon={faFilter} />
                                    <span className='ms-2'>Filter by</span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li onClick={e => {e.preventDefault(); filterHandler('No. of Keywords')}}><a class="dropdown-item" href="#">No. of keywords</a></li>
                                    <li onClick={e => {e.preventDefault(); filterHandler('Keywords')}}><a class="dropdown-item" href="#">Keywords</a></li>
                                </ul>
                            </div>
                            <div className="d-flex" style={{"width":"150px"}}>
                                <div class={`input-group flex-nowrap ${filter !== 'No. of Keywords' && 'd-none'}`}>
                                    {/* <span class="input-group-text" id="addon-wrapping">No. of Keywords</span> */}
                                    <input type="text" class="form-control" placeholder="From" aria-label="From" aria-describedby="addon-wrapping" ref={range_from} onChange={(e) => setFilterRange(current => [e.target.value,current[1]])}/>
                                    <input type="text" class="form-control" placeholder="To" aria-label="To" aria-describedby="addon-wrapping" ref={range_to} onChange={(e) => setFilterRange(current => [current[0],e.target.value])}/>
                                </div>
                                <div class={`input-group flex-nowrap ${filter !== 'Keywords' && 'd-none'}`}>
                                    <input type="text" class="form-control" placeholder="Keyword Search..." aria-label="Keyword Search..." aria-describedby="addon-wrapping" onChange={(e) => setSearchKey(e.target.value)}/>
                                </div>
                            </div>
                            
                            {kwGroup.length > 0 && <h5 className=" d-flex fs-6 align-self-end ms-2">Total groups: {kwGroup.filter((item) => applyFilterHandler(filterRange, searchKey, item)).length}</h5>}
                            {kwGroup.length > 0 &&
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm  mx-2"
                                    onClick={event => sortHandler(event)}
                                >
                                    {sortGroup === '' ? <FontAwesomeIcon icon={faSort} /> : sortGroup === true ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                                    
                                </button>
                            }
                        </div>
                        
                        <div className="col overflow-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-text">
                                        <ul class="list-group">
                                            {kwGroup.length === 0 && <h5 className="text-muted text-center fs-2" style={{"line-height": "300px", "border": "1px dashed #adb5bd"}}>Kw Group</h5>}
                                            {kwGroup.filter((item) => applyFilterHandler(filterRange, searchKey, item)
                                            ).map((item, index) => {
                                                return (
                                                <Droppable droppableId={`${item.group_name}-`}>
                                                    {(provided, snapshot) => (
                                                    <>
                                                        <GroupCartItem
                                                        key = {index}
                                                        item = {item}
                                                        activeG = {activeG}
                                                        onSelectGroup = {selectGroupHandler}
                                                        onFurtherSplit = {furtherSplitHandler}
                                                        provided={provided}
                                                        isDraggingOver = {snapshot.isDraggingOver}
                                                        />
                                                        {/* {provided.placeholder} */}
                                                    </>
                                                    )}
                                                
                                                </Droppable>
                                            )})}
                                        </ul>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" d-flex flex-column vh-100 col-md-8 overflow-hidden">
                        <div className='d-flex mb-2'>
                            <h3 className="">Keyword Detail</h3>
                            <a href="#" className="mx-4 align-self-center"
                            onClick={e => {e.preventDefault(); setKwDetail(projectKw)}}
                            >View all kws</a>
                            {autoSave === true &&
                            <div className={`d-flex me-2 align-items-center ms-2`} id="success-noti">
                                <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                <span className='ms-1'>Saved</span>
                            </div>
                            }
                            <button type="button" className={`btn btn-primary ms-auto ${activeG === '' && 'd-none'}`} onClick={openOutlineModelHandler}>Create outline</button>
                            <button type="button" className={`btn btn-primary ms-2 ${activeG === '' && 'd-none'}`} onClick={getVolumeHandler}>Check volume</button>
                            {/* Modal Save Outline*/}
                            <div class="modal fade" id="ModalOutline" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                        <div className="modal-header">
                                            <h5 class="modal-title me-auto" id="ModalLongTitle">Save Outline</h5>
                                            <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                                <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                                <span className='ms-1'>Success</span>
                                            </div>
                                            <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                                <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                                <span className='ms-1'>Failed. Pls Retry!</span>
                                            </div>
                                        </div>
                                        <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                            <div class="d-flex flex-column mb-4">
                                            <div class="input-group mb-3">
                                                <span class="input-group-text">Topic</span>
                                                <input type="text" class="form-control" placeholder="Topic" aria-label="Topic" aria-describedby="basic-addon1" 
                                                    defaultValue={activeDisplayName}
                                                />
                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text">Domain</span>
                                                <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1" defaultValue={domain.current.value}/>
                                            </div>
                                            <div class="form-floating">
                                                <textarea class="form-control" rows="2" placeholder="Leave a description" id="floatingTextarea2" style={{height: '100px'}} 
                                                    defaultValue={activeDisplayName}
                                                />
                                                <label for="floatingTextarea2">Description</label>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                                            <button type="button" className={`btn btn-primary`} id='import-button' onClick={createOutlineHandler}>Save</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            {/* Modal Save Outline*/}
                            {/* Modal Check Volume*/}
                            <div class="modal fade" id="ModalVolume" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                        <div className="modal-header">
                                            <h5 class="modal-title me-auto" id="ModalLongTitle">Check Keyword Volume</h5>
                                        </div>
                                        <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                            <div class="d-flex flex-column mb-4">
                                                <div class="input-group mb-3">
                                                    <span class="input-group-text">Country</span>
                                                    <select class="form-select" aria-label="Default select example" onClick={e => setLocation(e.target.value)}>
                                                        <option value="SG">Singapore</option>
                                                        <option selected value="VN">Vietnam</option>
                                                        <option value="US">US</option>
                                                    </select>
                                                </div>
                                                <div class="input-group mb-3">
                                                    <span class="input-group-text">Language</span>
                                                    <select class="form-select" aria-label="Default select example" onClick={e => setLanguage(e.target.value)}>
                                                        <option selected value="1040">VI</option>
                                                        <option value="1000">EN</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                            <button type="button" className={`btn btn-primary`} id='import-button' data-bs-dismiss="modal" onClick={updateVolumeHandler}>Check</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            {/* Modal Check Volume*/}
                            <div className={`btn-group ${activeG === '' ? 'ms-auto' : 'ms-2'}`}>
                                <button type="button" className="btn btn-primary" onClick={handleSaveCluster}>Save</button>
                                <button type="button" className="btn btn-primary" onClick={saveClusterHandler}>
                                <FontAwesomeIcon icon={faUpRightFromSquare}/>
                                </button>
                            </div>
                        </div>
                        {/* Modal Import*/}
                        <div class="modal fade" id="ModalImport" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                    <div className="modal-header">
                                        <h5 class="modal-title me-auto" id="ModalLongTitle">Save Cluster</h5>
                                        <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                            <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                            <span className='ms-1'>Success</span>
                                        </div>
                                        <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                            <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                            <span className='ms-1'>Failed. Pls Retry!</span>
                                        </div>
                                    </div>
                                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                        <div class="d-flex flex-column mb-4">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">Topic</span>
                                            <input type="text" class="form-control" placeholder="Topic" aria-label="Topic" aria-describedby="basic-addon1" ref={topic}/>
                                        </div>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">Domain</span>
                                            <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1" ref={domain}/>
                                        </div>
                                        <div class="form-floating">
                                            <textarea class="form-control" placeholder="Leave a description" id="floatingTextarea2" style={{height: '100px'}} ref={description}/>
                                            <label for="floatingTextarea2">Description</label>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                                        <button type="button" className={`btn btn-primary`} id='import-button' onClick={importCluster}>Save</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        {/* Modal Import*/}
                        <div className="d-flex flex-row pb-4 mb-4 overflow-auto">
                            
                                <table class="table table-striped">
                                    <thead className="sticky-top">
                                        <tr className='table-primary'>
                                            <th scope="col">#</th>
                                            <th scope="col">
                                                <button class="btn" type="button" aria-expanded="false" onClick={event => tableSortHandler('kw')}>
                                                    <span className='me-2 fw-bold'>Keyword </span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'keyword_inc' ? '#000' : '#6c757d'}`}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'keyword_dec' ? '#000' : '#6c757d'}`}}/>
                                                </button>
                                            </th>
                                            <th scope="col">
                                                <button class="btn" type="button" aria-expanded="false" onClick={event => tableSortHandler('volume')}>
                                                    <span className='me-2 fw-bold'>Volume </span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'volume_inc' ? '#000' : '#6c757d'}`}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'volume_dec' ? '#000' : '#6c757d'}`}}/>
                                                </button>
                                            </th>
                                            <th scope="col">
                                                <button class="btn" type="button" aria-expanded="false" onClick={event => tableSortHandler('kd')}>
                                                    <span className='me-2 fw-bold'>KD </span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'kd_inc' ? '#000' : '#6c757d'}`}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'kd_dec' ? '#000' : '#6c757d'}`}}/>
                                                </button>
                                            </th>
                                            <th scope="col"></th>
                                        </tr>
                                        {kwDetail.length === 0 && 
                                        <tr>
                                            <th colspan="5" style={{'border': "None"}}>
                                                <h5 className="text-muted text-center fs-2" style={{"line-height": "200px", "border": "1px dashed #adb5bd"}}>Group Detail</h5>
                                            </th>
                                        </tr>}
                                    </thead>
                                    {/* <tbody> */}
                                        {typeof kwDetail[0] !== 'string' ?
                                            kwDetail.map((group) => {
                                                // logger.log(group);
                                                let sub_group_id = 0;
                                                if (typeof(group.keywords[0]) !== 'object') {
                                                    let counter = 0;
                                                    let sorted_kws = group.keywords; //.sort() => eliminate sort()
                                                    sub_group_id += 1;
                                                    let group_id = '';
                                                    if(group.group_name.search(/\./i) === -1){
                                                        group_id = `kd-${group.group_name}-`
                                                    } else {
                                                        group_id = `kd-${group.group_name.replace(/\..*$/i,'')}-${group.group_name}`
                                                    }
                                                    return (
                                                        <Droppable
                                                            droppableId={group_id}
                                                            mode="virtual"
                                                            renderClone={(provided, snapshot, rubric) => (
                                                                <CloneItem
                                                                provided={provided}
                                                                isDragging={snapshot.isDragging}
                                                                item={group.keywords[rubric.source.index]}
                                                                index={rubric.source.index}
                                                                // style={{...provided.droppableProps}}
                                                                />
                                                                )}
                                                        >
                                                            {(provided) => (
                                                                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                                    <tr>
                                                                        <td colspan="4" class="table-primary">{group.display_name || group.group_name}</td>
                                                                        <td class="table-primary">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-danger btn-sm  me-1"
                                                                                onClick={event => delKwHandler(group.group_name,group.group_name.replace(/\..*$/i,''))}
                                                                            >
                                                                                <FontAwesomeIcon icon={faMinus} />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    {sorted_kws.map((kw, index) => {
                                                                        counter += 1;
                                                                        return (
                                                                            <Draggable key={kw} draggableId={kw} index={index} display='table'>
                                                                                {(provided) => (
                                                                                    <Item
                                                                                    provided={provided}
                                                                                    index={index}
                                                                                    pa={group.group_name}
                                                                                    ch={group.group_name.replace(/\..*$/i,'')}
                                                                                    item={kw}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    />
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                        
                                                                    })}
                                                                </tbody>)}
                                                        </Droppable>
                                                    )
                                                } else {
                                                        return(
                                                            <>
                                                            {group.keywords.map((embedded_group) => {
                                                                let counter = 0;
                                                                let sort_embedded = embedded_group.keywords; //eleminate sort()
                                                                sub_group_id += 1;
                                                                return (
                                                                    <Droppable
                                                                        droppableId={`kd-${group.group_name}-${embedded_group.group_name}`}
                                                                        mode="virtual"
                                                                        renderClone={(provided, snapshot, rubric) => (
                                                                            <CloneItem
                                                                            provided={provided}
                                                                            isDragging={snapshot.isDragging}
                                                                            item={embedded_group.keywords[rubric.source.index]}
                                                                            index={rubric.source.index}
                                                                            style={{...provided.droppableProps}}
                                                                            />
                                                                            )}
                                                                    >
                                                                        {(provided) => (
                                                                            <tbody {...provided.droppableProps} ref={provided.innerRef} >
                                                                                
                                                                                <tr>
                                                                                    <td colspan="4" class="table-primary" >{embedded_group.display_name || embedded_group.group_name}</td>
                                                                                    <td class="table-primary">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-outline-danger btn-sm  me-1"
                                                                                            onClick={event => delKwHandler(group.group_name,embedded_group.group_name)}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faMinus} />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                                {provided.placeholder}
                                                                                {sort_embedded.map((kw, index) => {
                                                                                    counter += 1;
                                                                                    return (
                                                                                        <Draggable key={kw} draggableId={kw} index={index} display='table'>
                                                                                            {(provided) => (
                                                                                                <Item
                                                                                                provided={provided}
                                                                                                pa={group.group_name}
                                                                                                ch={embedded_group.group_name}
                                                                                                index={index}
                                                                                                item={kw}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={provided.draggableProps.style}
                                                                                                />
                                                                                            )}   
                                                                                        </Draggable>
                                                                                    )
                                                                                })}
                                                                                {provided.placeholder}
                                                                            </tbody>
                                                                        )}
                                                                    </Droppable>
                                                                )})}
                                                            </>
                                                            )
                                                    };
                                            }) :
                                            <tbody>
                                            {kwDetail.map((item, index) => {
                                                return(
                                                        <tr key={index}>
                                                            <th scope="row">{index+1}</th>
                                                            <td >{item.split('<<>>')[0]}</td>
                                                            <td >{item.split('<<>>')[1] !== 'None' ? item.split('<<>>')[1] : 0}</td>
                                                            <td >{item.split('<<>>')[2] !== 'None' ? item.split('<<>>')[2] : ''}</td>
                                                            <td >
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-danger btn-sm  me-1"
                                                                    onClick={event => delKwHandler('','',item)}
                                                                >
                                                                    <FontAwesomeIcon icon={faMinus} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    
                                                )
                                            })}
                                            </tbody>
                                        }
                                </table>
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>  
        </DragDropContext>
    )
};

export default Cluster;