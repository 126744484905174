import { useState, Fragment, useRef, useEffect, useMemo } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";
import { OverlayTrigger, Popover } from 'react-bootstrap';

import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faDownload, faEye, faHeadset, faMinusCircle, faPaperPlane
} from "@fortawesome/free-solid-svg-icons";

import {
    ResponsiveContainer,
    Tooltip,
    PieChart, Pie, Cell,
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Area,
    BarChart,
    Bar,
    Line,
    ComposedChart
  } from "recharts";

import { getAuthToken } from "../util/token";
import { v4 as uuid4 } from 'uuid';
import apiClient from "../api/client";
import logger from "../util/logger";

const DataAnnotate = () => {
    const dispatch = useDispatch();
    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const [convData, setConvData] = useState([]);
    const [conv, setConv] = useState([]);
    const [curIdx, setCurIdx] = useState(0);
    const [extractedQuestions, setExtractedQuestions] = useState([]);
    const [conversationID, setConversationID] = useState('');

    useEffect(() => {
        dispatch(showLoading());
        apiClient.post('/api/extracted-questions', {}, {headers: {'X-User': userName}})
        .then (
            ({data}) =>{
                dispatch(hideLoading());
                logger.log(data);
                setConvData(data);
                setConv(data[curIdx]['conversation']);
                // logger.log(data[curIdx]['questions']);
                setExtractedQuestions(data[curIdx]['questions']);
                setConversationID(data[curIdx]['_id']);
        })
    },[]);

    useEffect(() => {
        if (convData.length > 0){
            setConv(convData[curIdx]['conversation']);
            setExtractedQuestions(convData[curIdx]['questions']);
            setConversationID(convData[curIdx]['_id']);
        }
    }, [curIdx])

    const chatBubble = (item, index) => {

        let k = 'Client';
        if( item['sender']['t'] === 's') {
            k = 'System'
        } else if (item['sender']['t'] === 'a'){
            k = item['sender']['n']
        }

        const v = item['msg'];
        // logger.log(v);
        return (
            <>
                <div key={index} className={`${k !== 'Client' && 'align-self-start border border-2'} ${k==='Client' && 'align-self-end bg-primary'} mt-3 p-3`} style={{'border-radius': '20px', 'max-width': '75%', 'min-width': '15%'}}>
                    <div className={`d-flex flex-column ${k==='Client' && 'text-white'}`} style={{wordBreak: 'break-word'}}>
                        {index === 1 ?
                        v.split('\n').map(inf => <span>{inf}</span>) :
                        v
                    }
                    </div>
                </div>
                <span className={`${k==='Client' ? 'align-self-end' : 'align-self-start'} badge text-secondary`}>{`${k !== 'Client' ? `${k} - ` : ''}${new Date(item['time']).toLocaleTimeString()}`}</span>
            </>
        )
    };

    const saveHandler = (q) => {
        logger.log(q);
        const d = document.querySelectorAll('.rephrase');
        const intentArr = document.querySelectorAll('.intent');
        const customIntent = document.querySelectorAll('.custom-intent');
        let result = {
            user: userName,
            _id: conversationID,
            curated: 'curated',
            questions: []
        }
        d.forEach((item, idx) => {
            let intent = intentArr[idx].value
            if (intent === 'manual'){
                intent = customIntent[idx].value
            }
            logger.log(idx, intent, item.value);
            result['questions'].push({
                question: item.value,
                intent: intent
            });
        });
        //set extracted questions
        setExtractedQuestions(result['questions']);
        logger.log(result);
        // update convData
        const idx = convData.findIndex(c => c._id === conversationID);
        if (idx !== -1){
            let d = [...convData];
            d[idx] = {
                ...result,
                conversation: d[idx]['conversation'],
                };
            setConvData(d);
        };
        // update questions and curated attr in db
        apiClient.post('/api/curate-question', result, {headers: {'X-User': userName}})
        .then (
              ({data}) =>{
                logger.log(data)
              }
            )
    };

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Tawkto Msg</h1>
            <div className='d-flex my-4'>
                <h5 className='me-4'>Total: {convData.length}</h5>
                <h5 className='me-2'>Curated: {convData.filter(item => item.curated === 'curated').length}</h5>
                <button type="button" class={`btn btn-secondary ${curIdx == 0 && 'disabled'} ms-auto`} onClick={e => {setCurIdx(c => c - 1)}}>PREV</button>
                <button type="button" class={`btn btn-primary ms-2 ${curIdx == convData.length && 'disabled'}`} onClick={e => {setCurIdx(c => c + 1)}}>NEXT</button>
            </div>
            <div className='d-flex my-4'>
                <div className='d-flex col-md-6 flex-column'>
                    <h5 className='me-auto'>Conversation</h5>
                    <div className="d-flex flex-column mb-3 pe-3" style={{'height':'500px', 'overflow': 'auto'}}>
                        {conv.map((item, index) => 
                            chatBubble(item, index)
                        )}
                    </div>
                </div>
                <div className='d-flex col-md-6 flex-column ms-2'>
                    <div className='d-flex align-items-center'>
                        <h5 className='me-4'>Extracted Questions</h5>
                        {   
                            useMemo(() => 
                                <span className={`d-flex me-auto badge ${convData.length > 0 && convData[curIdx].curated === 'curated' && 'bg-success'}`}>curated</span>
                            , [convData, curIdx]) 
                        }
                        <button type="button" class='btn btn-primary ms-auto' onClick={e => {setExtractedQuestions(c => [...c, {question: '', intent: ''}])}}>Insert</button>
                    </div>
                    <ul class="list-group list-group-flush mb-auto" style={{'height':'500px', 'overflow': 'auto'}}>
                        {   
                            useMemo(() =>
                                extractedQuestions.map((item, idx) => 
                                    item !== null && (
                                        <div key={curIdx + idx*10} >
                                            {idx !== 0 && <hr />}
                                            <div className='d-flex'>
                                                <li class="list-group-item w-100 border-0"><strong>{`${idx+1}. ${item.question}`}</strong></li>
                                                <button type="button" class='btn' onClick={e => {setExtractedQuestions(c => c.filter((_, index) => index !== idx))}}>
                                                    <FontAwesomeIcon icon={faMinusCircle} color='red'/>
                                                </button>
                                            </div>
                                            <div className='d-flex'>
                                                <select key={curIdx + idx*10} class="intent form-select w-25 mb-3 me-2" >
                                                    <option selected={item.intent === null} value=''>Classify intent</option>
                                                    <option selected={item.intent === 'info'} value="info">info</option>
                                                    <option selected={item.intent === 'service'} value="service">service</option>
                                                    <option selected={item.intent === 'policy'} value="policy">policy</option>
                                                    <option selected={item.intent === 'portal'} value="portal">portal</option>
                                                    <option selected={item.intent === 'out_of_scope'} value="out_of_scope">out_of_scope</option>
                                                    <option selected={item.intent === 'unrelated'} value="unrelated">unrelated</option>
                                                    <option  selected={!['', 'info', 'service', 'policy', 'portal', 'out_of_scope', 'unrelated'].includes(item.intent)} value="manual">manual</option>
                                                </select>
                                                <input key={curIdx + idx*10} type="text" class="custom-intent form-control w-auto mb-3" defaultValue={!['', 'info', 'service', 'policy', 'portal', 'out_of_scope', 'unrelated'].includes(item.intent) ? item.intent : ''}/>
                                            </div>
                                            <textarea key={curIdx + idx*10} class="rephrase form-control w-100 mb-2" defaultValue={item.question}/>
                                        </div>
                                    )
                                )
                            , [extractedQuestions])
                        }
                    </ul>
                    <button type="button" class={`btn btn-primary mt-4`} onClick={e => saveHandler(extractedQuestions)}>SAVE</button>
                    <div className='mt-2'>
                        <p><strong>Info</strong>: The user is asking for information that can be answered using details found in blog content or existing informational resources. These questions typically involve seeking general knowledge, explanations, or clarifications that do not directly pertain to our services, policies, or portal.
                        </p>
                        <p><strong>Service</strong>: The user is inquiring about specific services offered by our company. This includes questions about the nature of services, how they work, pricing, availability, and any other details directly related to the services we provide.
                        </p>
                        <p><strong>Policy</strong>: The user is asking about our company's policies, including those related to onboarding, payments, refunds, cancellations, or any other contractual or procedural guidelines that govern our relationship with clients.
                        </p>
                        <p><strong>Portal</strong>: The user is seeking guidance on how to use our online portal, including how to access it, navigate its features, troubleshoot issues, or complete tasks related to onboarding or managing their account through the portal.
                        </p>
                        <p><strong>Out_of_scope</strong>: The user is requesting services, information, or assistance that falls outside the range of what our company offers. This includes inquiries that cannot be fulfilled by our existing services or policies. It also includes extremely specific question requiring legal or professional consulting.
                        </p>
                        <p><strong>Unrelated</strong>: The user's question does not fit into any of the above categories and is unrelated to our company's services, policies, portal, or informational content. These may include personal inquiries, off-topic comments, or irrelevant questions.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DataAnnotate;