import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
// import * as bootstrap from 'bootstrap/dist/js/bootstrap'

import { useState, useRef, Fragment, useEffect, useMemo } from 'react';
// import { OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Collapse from 'react-bootstrap/Collapse';
import AuditIssue from "../components/audit_issue";
import {
    AreaChart,
    Area,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis, PieChart, Pie, Cell
  } from "recharts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faCalendar, faAngleUp, faAngleDown, faTimesCircle, faLineChart, faUpload, faGlobe, faPencil, faCheckCircle, faSearch, faCircleCheck, faArrowDown, faCaretDown, faSpinner, faFilePdf, faFileExcel, faHeartPulse, faEye, faWaveSquare, faFileWaveform, faCircle
} from "@fortawesome/free-solid-svg-icons";

import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import { getAuthToken } from '../util/token';
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { useSearchParams } from "react-router-dom";
import apiClient from '../api/client';
import logger from '../util/logger';

const Onpage = () => {
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const [projectData, setProjectData] = useState([]);
    const [stat, setStat] = useState([]);
    const [filteredStat, setFilteredStat] = useState([]);
    const [internalCrawl, setInternalCrawl] = useState({});
    const [externalCrawl, setExternalCrawl] = useState({});
    const [ criticalCount, setCriticalCount ] = useState(0);
    const [ warningCount, setWarningCount ] = useState(0);
    const [mode, setMode] = useState('internal');
    const [severance, setSeverance] = useState('All issues');
    const [open, setOpen] = useState([]);
    const [sortIssueMode, setSortIssueMode] = useState('default');
    const [auditMode, setAuditMode] = useState('');
    const [totalCrawled, setTotalCrawled] = useState(0);
    const [ crawlSpeed, setCrawlSpeed ] = useState(0);
    const [projectId, setProjectId] = useState('');
    const [confirmedInputURL, setConfirmInputURL] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const inputURL = useRef('');
    //Issue detail
    const [screenMode, setScreenMode] = useState('');
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [issue, setIssue] = useState('');
    const [desc, setDesc] = useState('');
    const [endPoint, setEndPoint] = useState('')
    const [importance, setImportance] = useState('');
    const [issueURL, setIssueURL] = useState([]);

    // pop up
    const [ mFreq, setMFreq ] = useState('Daily');
    const [ cFreq, setCFreq ] = useState();
    const [importStat, setImportStat] = useState('');
    const [ projectAuditMode, setProjectAuditMode] = useState('');
    const projectName = useRef('');
    const [projectNameStatic, setProjectNameStatic] = useState('');
    const [projectDescStatic, setProjectDescStatic] = useState('');
    const projectDesc = useRef('');
    const projectInputURL = useRef('');
    const [displayProjectName, setDisplayProjectName] = useState('');
    const [ activeProjectDate, setActiveProjectDate] = useState('');
    const [ activeUNIXTime, setActiveUNIXTime] = useState();

    //chart
    const [chart, setChart] = useState([]);

    useEffect(() => {
        if (searchParams.get('id')){
            const input = {
              id: searchParams.get('id'),
              type: 'audit'
            };
            apiClient.post('/api/get-audit-project', input, {headers: {'X-User': userName}})
            .then (
                ({data}) =>{
                    logger.log(data);
                    setProjectData(data[0]['outline']);

                    // process chart data
                    const d = []
                    data[0]['outline'].map(item =>{
                        const e = {}
                        e['date'] = item['crawlDate']
                        Object.keys(item['summary']).map(category => {
                            Object.keys(item['summary'][category]).map(issue => {
                                let key = `${category}${issue}`
                                e[key]= item['summary'][category][issue]['value']
                            })
                        })
                        d.push(e);
                    });
                    setChart(d);
                    logger.log(d);

                    setActiveUNIXTime(data[0]['outline'].slice(-1)[0]['crawlDate']);
                    const c = data[0]['outline'].slice(-1)[0]['summary'];
                    const internal = [];
                    const external = [];
                    Object.keys(c).map(category => {
                        if(category === 'Internal'){
                            Object.keys(c[category]).map(issue => {
                                if (issue !== 'all'){
                                    internal.push({
                                        'name': c[category][issue]['title'],
                                        'value': c[category][issue]['value'],
                                    });
                                };
                            })
                        };
                    });
                    Object.keys(c).map(category => {
                        if(category === 'External'){
                            Object.keys(c[category]).map(issue => {
                                if (issue !== 'all'){
                                    external.push({
                                        'name': c[category][issue]['title'],
                                        'value': c[category][issue]['value'],
                                    });
                                };
                            })
                        };
                    });
                    setStat(c);
                    setFilteredStat(c);
                    setInternalCrawl(internal);
                    setExternalCrawl(external);

                    let criticals = 0;
                    let warnings = 0
                        //count criticals & warnings:
                    Object.keys(c).map((category, idx) => {
                        Object.keys(c[category]).map(issue => {
                            if (parseInt(c[category][issue]['value']) > 0 && c[category][issue]['importance'] === 'Critical'){
                                criticals += 1;
                            } else if ( parseInt(c[category][issue]['value']) > 0 && c[category][issue]['importance'] === 'Warning' ) {
                                warnings += 1;
                            }
                        })
                    });
                    setCriticalCount(criticals);
                    setWarningCount(warnings);
                    setDisplayProjectName(data[0]['topic']);
                    setProjectId(data[0]['project_id']);
                    setConfirmInputURL(data[0]['domain']);
                    setProjectNameStatic(data[0]['topic']);
                    setProjectDescStatic(data[0]['description']);
                    setAuditMode(data[0]['audit_mode']);
                    setScreenMode('overview');
                    setActiveProjectDate(new Date(data[0]['outline'].slice(-1)[0]['crawlDate']).toDateString() + ' ' + new Date(data[0]['outline'].slice(-1)[0]['crawlDate']).toLocaleTimeString());
                })}
      }, []);
    useEffect(() => {
        let sev = '';
        let filtered = {}
        if(severance === 'Criticals') sev = 'Critical';
        if(severance === 'Warnings') sev = 'Warning';
        if(severance === 'All issues') sev = '';
        if (sev === ''){
            // setFilteredStat(stat);
            filtered = stat;
        }else {
            Object.keys(stat).map(category => {
                filtered[category] = {link: stat[category]['link']};
                Object.keys(stat[category]).map(issue => {
                    if (stat[category][issue]['importance'] === sev){
                        filtered[category][issue] = stat[category][issue];
                    };
                })
            });
        };
        // logger.log('filtered: ', filtered);
        let sorted = {};
        if (sortIssueMode === 'default'){
            //sort by key alphabe
            sorted = Object.keys(filtered).sort().reduce((acc, key) => {
                acc[key] = filtered[key];
                return acc
            },{});
            // logger.log(sorted);
        } else if (sortIssueMode === 'issues_count'){
            //sort by custom issue count function
            logger.log('issue count mode');
            sorted = Object.entries(filtered)
                            .sort(([a, ], [b, ]) => {
                                return countIssue(filtered, b) - countIssue(filtered, a)})
                            .reduce(
                                (r, [k, v]) => ({
                                ...r,
                                [k]: v
                                }),
                                {}
        )};
        // logger.log('sorted: ', sorted);
        setFilteredStat(sorted);
    },[severance]);

    useEffect(() => {
        if (sortIssueMode === 'default' && Object.keys(filteredStat).length > 0){
            const sorted = Object.keys(filteredStat).sort().reduce((acc, key) => {
                acc[key] = filteredStat[key];
                return acc
            },{});
            // logger.log(sorted);
            setFilteredStat(sorted);
        } else if (Object.keys(filteredStat).length > 0){
            const sorted = Object.entries(filteredStat)
                            .sort(([a, ], [b, ]) => countIssue(filteredStat, b) - countIssue(filteredStat, a))
                            .reduce(
                                (r, [k, v]) => ({
                                ...r,
                                [k]: v
                                }),
                                {}
                            )
            setFilteredStat(sorted);
        };
    },[sortIssueMode])

    const countIssue = (obj, category, type) => {
        let count = 0;
        if (type === 'pass'){
            Object.keys(obj[category]).map((issue, idx) => {
                if (issue !== 'link' && parseInt(obj[category][issue]['value']) === 0 && issue !== 'all'){
                    count += 1;
                }
            });
        } else {
            Object.keys(obj[category]).map((issue, idx) => {
                if (issue !== 'link' && parseInt(obj[category][issue]['value']) > 0 && issue !== 'all'){
                    count += 1;
                }
            });
        }
        return count
    };
    const auditDetailHandler = (category, issue, link, title, description, importance, displayCategory) => {
        // logger.log(issue, link);
        setEndPoint(link);
        setIssue(issue);
        setCategory(displayCategory);
        setTitle(title);
        setDesc(description);
        setImportance(importance);
        apiClient.post(`/api/${link}`, {
            issue: issue,
            category: category,
            url: confirmedInputURL,
            projectId: projectId,
            audit_type: auditMode,
            dateAdded: activeUNIXTime
        }, {headers: {'X-User': userName}})
        .then (
                ({data}) =>{
                    logger.log(data);
                    setIssueURL(data);
                    setScreenMode('detail');
                }
            )
    };
    const toggleDetail = () => {
        setScreenMode('overview');
    };
    const fetchData = async (projectId) => {
        let url = ''
        if (projectId !== ''){
            url = confirmedInputURL;
            setScreenMode('auditing');
            setTotalCrawled(0);
            setCrawlSpeed(0);
        } else {
            url = inputURL.current.value;
        }
        const input = {
            url: url,
            audit_type: auditMode,
            project_id: projectId
        }
        try {
            const response =  await fetch(`${process.env.REACT_APP_HOST_IP}/api/audit/technical`,{
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
            }
            });
            if (!response.ok || !response.body) {
                throw response.statusText;
                }
        
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const startTime = new Date().getTime();
            let concat_str = ''
            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    logger.log(new Date(new Date().getTime() - startTime).getSeconds());
                    const data = JSON.parse(concat_str)['data'];
                    const project_id = JSON.parse(concat_str)['project_id'];
                    const date = JSON.parse(concat_str)['dateAdded'];
                    setActiveUNIXTime(date);
                    // logger.log(decoder.decode(value));
                    const internal = [];
                    const external = [];
                    Object.keys(data).map(category => {
                        if(category === 'Internal'){
                            Object.keys(data[category]).map(issue => {
                                if (issue !== 'all'){
                                    internal.push({
                                        'name': data[category][issue]['title'],
                                        'value': data[category][issue]['value'],
                                    });
                                };
                            })
                        };
                    });
                    Object.keys(data).map(category => {
                        if(category === 'External'){
                            Object.keys(data[category]).map(issue => {
                                if (issue !== 'all'){
                                    external.push({
                                        'name': data[category][issue]['title'],
                                        'value': data[category][issue]['value'],
                                    });
                                };
                            })
                        };
                    });
                    logger.log(data);
                    setStat(data);
                    setProjectId(project_id);
                    setFilteredStat(data);
                    setInternalCrawl(internal);
                    setExternalCrawl(external);
                    setScreenMode('overview');

                    let criticals = 0;
                    let warnings = 0
                    //count criticals & warnings:
                    Object.keys(data).map((category, idx) => {
                        Object.keys(data[category]).map(issue => {
                            if (parseInt(data[category][issue]['value']) > 0 && data[category][issue]['importance'] === 'Critical'){
                                criticals += 1;
                            } else if ( parseInt(data[category][issue]['value']) > 0 && data[category][issue]['importance'] === 'Warning' ) {
                                warnings += 1;
                            }
                        })
                    });
                    setCriticalCount(criticals);
                    setWarningCount(warnings);
                    if (projectId !== ''){
                        setActiveProjectDate(new Date(date).toDateString() + ' ' + new Date(date).toLocaleTimeString());
                        setProjectData(c => [...c, {
                            crawlDate: date,
                            summary: data
                        }
                    ]
                    );
                    };
                    break;
                }
        
                const decodedChunk = decoder.decode(value, { stream: true });
                // logger.log(decodedChunk);
                if (decodedChunk.length < 100) {
                    // logger.log(decodedChunk)
                    const crawled = JSON.parse(decodedChunk)['data'];
                    // logger.log(crawled);
                    setTotalCrawled(crawled);
                    setCrawlSpeed(Math.round(parseInt(crawled)/((new Date().getTime() - startTime)/1000)));
                } else {
                    concat_str += decodedChunk;
                }
                
            }
        } catch (error){
            logger.log(error);
        }
    };
    const startAuditHandler = () => {
        setScreenMode('auditing');
        setConfirmInputURL(inputURL.current.value);
        logger.log({
            url: inputURL.current.value,
            audit_type: auditMode
        })
        fetchData('');
    };

    const exportFileHandler = (type, obj) => {
        let audit_type =''
        if (auditMode === 'complete'){
            audit_type = 'Full domain scan' 
        } else {
            audit_type = 'One page scan'
        }
        if (type === 'pdf'){
            apiClient.post('/api/download/technical-audit-report',
                {
                    url: confirmedInputURL,
                    audit_type: audit_type,
                    data: stat,
                },
                {headers: {'X-User': userName}}
            )
            .then (
                    response => {
                        return response.data;
                    }).then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        logger.log(blob);
                        const link = document.createElement("a");
                        link.download = 'technical_audit_report.pdf';
                        link.href = url;
                        link.click();
                    }).catch(error => {
                        console.error(error);
                    });
        } else if (type === 'excel') {
            const c_data = []
            Object.keys(obj).map (category => {
                Object.keys(obj[category]).map(issue => {
                    if (issue !== 'link') {
                        c_data.push({
                            'category': category,
                            'issue': obj[category][issue]['title'],
                            'description': obj[category][issue]['description'],
                            'importance': obj[category][issue]['importance'],
                            'no. of pages': obj[category][issue]['value']
                        })
                    }
                })
            })
            const csv = Papa.unparse({
            'data':c_data,
            });
            // logger.log(csv);
            const blob = new Blob([csv]);

            const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
            // logger.log(blob);
            const link = document.createElement("a");
            link.download = 'audit_technical_report.csv';
            link.href = url;
            link.click();
        } else {
            const csv = Papa.unparse({
            'data':obj,
            });
            // logger.log(csv);
            const blob = new Blob([csv]);

            const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
            // logger.log(blob);
            const link = document.createElement("a");
            link.download = 'audit_technical_report.csv';
            link.href = url;
            link.click();
        }
        return
    };

    const createProjectHandler = () => {
        let freq = '';
        if (['Daily', 'Weekly'].includes(mFreq)){
            freq = mFreq;
          }else {
            freq = cFreq
          };
        let input = {};
        if (projectData.length > 0 ){
            input = {
                project_id: projectId,
                topic: projectName.current.value,
                description: projectDesc.current.value,
                domain: projectInputURL.current.value,
                frequency: freq,
                type: 'update'
            }
        } else {
            input = {
                project_id: projectId,
                topic: projectName.current.value,
                description: projectDesc.current.value,
                domain: projectInputURL.current.value,
                outline: [{
                    crawlDate: activeUNIXTime,
                    summary: stat
                }],
                audit_mode: projectAuditMode,
                frequency: freq,
                date: activeUNIXTime,
                task: 'audit'
            };
        }
        logger.log(input);
        apiClient.post('/api/create-audit-project', input, {headers: {'X-User': userName}})
        .then (
          ({data}) =>{
            if (data === true) {
              setImportStat(true);
            }
        })

    };
    const reScanHandler = () => {
        logger.log({
            project_id: projectId,
            url: confirmedInputURL,
            audit_type: auditMode
        })
        fetchData(projectId);
    };

    const changeActiveTimeHandler = (t) => {
        setActiveUNIXTime(parseInt(t));
        projectData.map(item => {
            if (parseInt(t) === item['crawlDate']){
                setStat(item['summary']);
                setFilteredStat(item['summary']);
                const summary = item['summary'];

                let criticals = 0;
                let warnings = 0
                //count criticals & warnings:
                Object.keys(summary).map((category, idx) => {
                    Object.keys(summary[category]).map(issue => {
                        if (parseInt(summary[category][issue]['value']) > 0 && summary[category][issue]['importance'] === 'Critical'){
                            criticals += 1;
                        } else if ( parseInt(summary[category][issue]['value']) > 0 && summary[category][issue]['importance'] === 'Warning' ) {
                            warnings += 1;
                        }
                    })
                });
                // logger.log(criticals, warnings);
                setCriticalCount(criticals);
                setWarningCount(warnings);
                // logger.log(new Date(parseInt(t)).toDateString());
                setActiveProjectDate(new Date(parseInt(t)).toDateString() + ' ' + new Date(parseInt(t)).toLocaleTimeString());
                const internal = [];
                const external = [];
                Object.keys(summary).map(category => {
                    if(category === 'Internal'){
                        Object.keys(summary[category]).map(issue => {
                            if (issue !== 'all'){
                                internal.push({
                                    'name': summary[category][issue]['title'],
                                    'value': summary[category][issue]['value'],
                                });
                            };
                        })
                    };
                });
                Object.keys(summary).map(category => {
                    if(category === 'External'){
                        Object.keys(summary[category]).map(issue => {
                            if (issue !== 'all'){
                                external.push({
                                    'name': summary[category][issue]['title'],
                                    'value': summary[category][issue]['value'],
                                });
                            };
                        })
                    };
                });
                setInternalCrawl(internal);
                setExternalCrawl(external);
            }
        })
    };

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Technical Audit</h1>
            {projectId !== '' &&

                <div className='d-flex align-items-center'>
                    {
                        projectData.length > 0  &&
                        <>
                            <FontAwesomeIcon icon={faCircle} style={{'color':'green'}}/>
                            <span className='ms-2 fw-bold fs-6 me-auto'>{displayProjectName} - {activeProjectDate}</span>
                        </>
                    }
                    <div className='d-flex'>
                        { projectData.length > 0  &&
                            <select class="form-select" aria-label="Default select example"
                                onChange = {e => changeActiveTimeHandler(e.target.value)}
                            >
                                {
                                    projectData.map((item, index) => {
                                        // logger.log('active unixtime: ', activeUNIXTime, 'selected time: ', item['crawlDate']);
                                        return (
                                            <option value={item['crawlDate']} selected={activeUNIXTime === item['crawlDate']}>{new Date(item['crawlDate']).toDateString() + ' ' + new Date(item['crawlDate']).toLocaleTimeString()}</option>
                                        )
                                    })
                                }
                            </select>
                        }
                    </div>
                </div>
            }
            <div className='my-5'>
                {   
                screenMode === '' || screenMode === 'auditing' ?
                <>
                    <div className='d-flex justify-content-center'>
                        <div class="input-group me-3" style={{maxWidth: '75%'}}>
                            <div className='d-flex align-content-start flex-fill border border-light border-2' >
                                <FontAwesomeIcon icon={faSearch} className='fa-3 align-self-center me-2'/>
                                <input type="text" class="form-control" placeholder="Insert url" aria-label="Insert url" aria-describedby="button-addon2"
                                style={{border: 'none'}}
                                ref={inputURL}
                                />                        
                            </div>
                        </div>
                    </div>
                    {
                        screenMode !== 'auditing' ?
                        <>
                            <h5 className='my-4 text-center'>Choose an audit option:</h5>
                            <div className='d-flex justify-content-center mt-4'>
                                <div className={`d-flex flex-column border p-3 text-center me-4 ${auditMode === 'complete' && 'border-secondary'}`} style={{width:'200px', height:'100px'}} role='button'
                                onClick={e => setAuditMode('complete')}
                                >
                                    <input class="form-check-input" type="checkbox" checked={auditMode === 'complete'}id="complete-audit" value="" aria-label="..." />
                                    <h5>Complete site audit</h5>
                                </div>
                                <div className={`d-flex flex-column border p-3 text-center ${auditMode === 'onepage' && 'border-secondary'}`} style={{width:'200px', height:'100px'}} role='button'
                                onClick={e => setAuditMode('onepage')}
                                >
                                    <input class="form-check-input" type="checkbox" checked={auditMode === 'onepage'} id="onepage-audit" value="" aria-label="..." />
                                    <h5>One-page audit</h5>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center my-4'>
                                <button type="button" class="btn btn-primary" style={{width:'200px'}}
                                onClick={startAuditHandler}
                                >Start audit</button>
                            </div>
                        </> :
                        <div className='d-flex justify-content-center mt-4'>
                        <div className={`d-flex flex-column border p-3 text-center me-4`} style={{width:'200px', height:'100px'}} >
                            <p>
                                <FontAwesomeIcon icon={faSpinner} className='fas fa-spin me-2' />
                                Total URLs crawled
                            </p>
                            <h5>{totalCrawled}</h5>
                        </div>
                        <div className={`d-flex flex-column border p-3 text-center`} style={{width:'200px', height:'100px'}}>
                            <p>Crawl speed</p>
                            <h5>{crawlSpeed}<small> /s</small></h5>
                        </div>
                    </div>
                    }  
                </> :
                screenMode === 'overview' ?
                    <>
                        <div className="row my-5">
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b>Criticals</b>
                                <h2 className='align-self-center my-3'>
                                {criticalCount}
                                </h2>
                                <span style={{minHeight: '21px'}}>
                                
                                </span>
                            </div>
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b>Warnings</b>
                                <h2 className='align-self-center my-3'>
                                {warningCount}
                                </h2>
                                <span style={{minHeight: '21px'}}>
                                
                                </span>
                            </div>
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b>Total URL Crawled</b>
                                <h2 className='align-self-center my-3'>
                                {Object.keys(stat).length > 0 && stat['Summary']['total_urls_crawled']['value']}
                                </h2>
                                <span style={{minHeight: '21px'}}>
                                
                                </span>
                            </div>
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b>Blocked By Robots.txt</b>
                                <h2 className='align-self-center my-3'>
                                {Object.keys(stat).length > 0 && stat['Summary']['total_internal_blocked_by_robots']['value']}
                                </h2>
                                <span style={{minHeight: '21px'}}>
                                
                                </span>
                            </div>
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b>Int. Indexable URLs</b>
                                <h2 className='align-self-center my-3'>
                                {Object.keys(stat).length > 0 && stat['Summary']['total_internal_indexable_urls']['value']}
                                </h2>
                                <span style={{minHeight: '21px'}}>
                                
                                </span>
                            </div>
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <div className='d-flex'>
                                    <b className='me-auto'>Crawled Data</b>
                                    <select class="form-select form-select-sm" aria-label="Default select example" style={{width: '90px'}}
                                    onChange={e => setMode(e.target.value)}
                                    >
                                        <option selected value='internal'>Internal</option>
                                        <option value="external">External</option>
                                    </select>
                                </div>
                                <div className='d-flex align-items-center' style={{width: '200px', height: '80px'}}>
                                    {   internalCrawl.length > 0 &&
                                        <ResponsiveContainer width="100%" height="100%">
                                        <PieChart
                                            margin={{
                                                top: 0,
                                                right: 10,
                                                left: 10,
                                                bottom: -90,
                                            }}
                                        >
                                        <Pie
                                            dataKey="value"
                                            startAngle={180}
                                            endAngle={0}
                                            data={mode === 'internal' ? internalCrawl : externalCrawl}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={40}
                                            outerRadius={80}
                                            paddingAngle={5}
                                            fill="#8884d8"
                                        >
                                            {internalCrawl.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        </PieChart>
                                    </ResponsiveContainer>}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-3">
                            <h5 className='me-4 align-self-center'>Issue list</h5>
                            {
                                projectData.length > 0 &&
                                <button type="button" className="btn btn-primary"
                                    onClick={reScanHandler}
                                >
                                    <FontAwesomeIcon icon={faSearch} className='me-2' />
                                    Re-Scan
                                </button>
                            }
                            <button type="button" className="btn btn-primary me-2 ms-auto" data-bs-toggle="modal" data-bs-target="#ModalCenter"
                            onClick = {e => {
                                projectInputURL.current.value = confirmedInputURL;
                                setProjectAuditMode(auditMode);
                                setImportStat('');
                            }}
                            >
                                <FontAwesomeIcon icon={faFileWaveform} className='me-2' />
                                Monitor
                            </button>
                            {/* Modal Popup*/}
                            <div class="modal fade" id="ModalCenter" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="ModalLongTitle">Keyword detail</h5>
                                        <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                            <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                            <span className='ms-1'>Success</span>
                                        </div>
                                        <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                            <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                            <span className='ms-1'>Failed. Pls Retry!</span>
                                        </div>
                                    </div>
                                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                        <div class="d-flex flex-column mb-4">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="domain">*Project name</span>
                                            <input type="text" class="form-control" placeholder="Project name" aria-label="Project name" aria-describedby="basic-addon1" ref={projectName} defaultValue={projectNameStatic}/>
                                        </div>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="domain">*Project description</span>
                                            <input type="text" class="form-control" placeholder="Project description" aria-label="Project description" aria-describedby="basic-addon1" ref={projectDesc} defaultValue={projectDescStatic}/>
                                        </div>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="domain">*Input URL</span>
                                            <input type="text" class="form-control" placeholder="Input URL" aria-label="Input URL" aria-describedby="basic-addon1" ref={projectInputURL}/>
                                        </div>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">*Audit Mode</span>
                                            <div class="dropdown">
                                            <select class="form-select">
                                                <option value="complete" selected={projectAuditMode === 'complete'}>Full domain scan</option>
                                                <option value="onepage" selected={projectAuditMode === 'onepage'}>One page scan</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-3">
                                            <div class="input-group me-3">
                                            <span class="input-group-text">Frequency</span>
                                            <select class="form-select" id="freq" onChange={e => {setMFreq(e.target.value)}}>
                                                <option value="Daily">Daily</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Custom">Custom</option>
                                            </select>
                                            </div>
                                            {
                                            mFreq !== '' && mFreq !== 'Daily' && mFreq !== 'Weekly' &&
                                            <div class="input-group">
                                                <span class="input-group-text">Each per</span>
                                                <input type="text" class="form-control" placeholder="Days" aria-label="Days" aria-describedby="basic-addon1" onChange={e => {setCFreq(e.target.value)}}/>
                                                <span class="input-group-text">days</span>
                                            </div>
                                            }
                                        </div>                                       
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                        <button type="button" className={`btn btn-primary ${importStat === true && 'disabled'}`} onClick={createProjectHandler}>Add</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* Modal Popup */}
                            <button type="button" className="btn btn-outline-primary me-2" onClick={e => exportFileHandler('pdf')}>
                                <FontAwesomeIcon icon={faFilePdf} className='me-2 fa-xl' />
                                Download
                            </button>
                            <button type="button" className="btn btn-outline-primary" onClick={e => exportFileHandler('excel', stat)}>
                                <FontAwesomeIcon icon={faFileExcel} className='me-2 fa-xl' />
                                Export
                            </button>
                        </div>
                        <div className='d-flex my-2 w-100' style={{position: 'sticky', top: 0, zIndex: 1000}}>
                            <div className='p-3 bg-light w-100 d-flex'>
                                <button type="button" className={`btn ${severance === 'All issues' ? 'btn-dark' : 'btn-outline-secondary'} me-3`} onClick = {e => setSeverance('All issues')}>All issues</button>
                                <button type="button" className={`btn ${severance === 'Criticals' ? 'btn-dark' : 'btn-outline-secondary'} me-3`} onClick = {e => setSeverance('Criticals')}>Criticals</button>
                                <button type="button" className={`btn ${severance === 'Warnings' ? 'btn-dark' : 'btn-outline-secondary'} me-auto`} onClick = {e => setSeverance('Warnings')}>Warnings</button>
                                <div className='d-flex'>
                                    <div className='me-2 w-50 align-self-center'>
                                        <span>Sort by:</span>
                                    </div>
                                    <select className="form-select" aria-label="Default select example" onChange={e => {logger.log(e.target.value);setSortIssueMode(e.target.value)}}>
                                        <option selected value='default'>default</option>
                                        <option value="issues_count">issues count</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='d-flex flex-column col-md-9'>
                                <Accordion defaultActiveKey={Array.from({length: 20}, (v, i) => i)} alwaysOpen>
                                        {Object.keys(filteredStat).map((category, index) => {
                                            // logger.log(Array.from({length: Object.keys(data).length}, (v, i) => i));
                                            return(
                                                !['Summary', 'Internal', 'External'].includes(category) &&
                                                <Accordion.Item eventKey={index} id={`${category}-${index}`}>
                                                    <Accordion.Header><h5>{category}</h5><span className='ms-3'>{`(${countIssue(filteredStat, category)} issues)`}</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul className="list-group">
                                                            {Object.keys(filteredStat[category]).map((issue, idx) => 
                                                                // logger.log(data[category][issue])
                                                                parseInt(filteredStat[category][issue]['value']) > 0 && issue !== 'link' && issue !== 'all' &&
                                                                <button class="list-group-item list-group-item-action d-flex align-items-center" index={idx}
                                                                onClick={e => auditDetailHandler(category, issue, filteredStat[category]['link'], filteredStat[category][issue]['title'], filteredStat[category][issue]['description'], filteredStat[category][issue]['importance'], category) }
                                                                >
                                                                    <span className={`me-3 rounded ${filteredStat[category][issue]['importance'] === 'Critical' ? 'bg-danger' : filteredStat[category][issue]['importance'] === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>
                                                                    <span className='align-self-center me-auto'>{filteredStat[category][issue]['title']}: {filteredStat[category][issue]['value']} pages</span>
                                                                   { chart.length > 0 && <div style={{height: '50px', width: '100px', marginRight: '100px'}}
                                                                    >
                                                                        <ResponsiveContainer>
                                                                        <AreaChart
                                                                        width={80}
                                                                        height={50}
                                                                        data={chart}
                                                                        >
                                                                            <defs>
                                                                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                                                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                                                                </linearGradient>
                                                                            </defs>
                                                                            <Area type="monotone" isAnimationActive={false} dataKey={`${category}${issue}`} stroke="#82ca9d" fillOpacity={1} fill="url(#color1)"/>
                                                                        </AreaChart>
                                                                    </ResponsiveContainer>
                                                                    </div>}
                                                                    <button type="button" class="btn btn-primary">
                                                                        <FontAwesomeIcon icon={faSearch} className='me-2' />
                                                                        View issue
                                                                    </button>
                                                                </button>
                                                            )}
                                                        </ul>
                                                        
                                                        <div className='my-3'>
                                                            <FontAwesomeIcon icon={faCircleCheck} className='me-2'/>
                                                            <a href="#" role="button" aria-expanded={open} aria-controls={category}
                                                            onClick={(e) => {e.preventDefault();
                                                                if (!open.includes(category)){
                                                                    setOpen(c => [...c, category]);
                                                                } else{
                                                                    let d = open.filter(item => item !== category);
                                                                    setOpen(d);
                                                                }
                                                                
                                                            }}
                                                            >
                                                                Check passed: {countIssue(filteredStat, category, 'pass')}
                                                                <FontAwesomeIcon icon={faCaretDown} className='ms-2'/>
                                                            </a>
                                                        </div>
                                                        <Collapse in={open.includes(category) ? true : false}>
                                                            <ul className="list-group" id={category}>
                                                                {Object.keys(filteredStat[category]).map((issue, idx) => 
                                                                    // logger.log(data[category][issue])
                                                                    parseInt(filteredStat[category][issue]['value']) === 0 &&
                                                                    <button class="list-group-item list-group-item-action d-flex align-items-center" index={idx} >
                                                                        <span className={`me-3 rounded ${filteredStat[category][issue]['importance'] === 'Critical' ? 'bg-danger' : filteredStat[category][issue]['importance'] === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>
                                                                        <span className='align-self-center me-auto'>{filteredStat[category][issue]['title']}: {filteredStat[category][issue]['value']} pages</span>
                                                                    </button>
                                                                )}
                                                            </ul>
                                                        </Collapse>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                            
                                        })}
                                </Accordion>
                            </div>
                            <div className='d-flex flex-column col-md-3 ms-3 overflow-auto' style={{position: 'sticky', top: '80px', height: '90vh', zIndex: 999}}>
                                <h5 className='my-3'>
                                    Affected pages by category
                                </h5>
                                <ul className='list-group'>
                                    {Object.keys(filteredStat).map((item, index) => {
                                        return (
                                            !['Summary', 'Internal', 'External'].includes(item) &&
                                            <a className='list-group-item list-group-item-action d-flex flex-column mb-2 border-0' index={index} role='button'
                                            href={`#${item}-${index}`}
                                            >
                                                <div className='d-flex mb-2 w-100'>
                                                    {item}
                                                    <span className='ms-auto'>
                                                        {Math.round((countIssue(filteredStat, item)/Object.keys(stat[item]).length)*100)}%
                                                    </span>
                                                </div>
                                                <ProgressBar className='w-100'>
                                                    <ProgressBar variant="danger" now={Math.round((countIssue(filteredStat, item)/Object.keys(stat[item]).length)*100)} key={1} />
                                                    <ProgressBar variant="success" now={100 - Math.round((countIssue(filteredStat, item)/Object.keys(stat[item]).length)*100)} key={2} />
                                                </ProgressBar>
                                            </a>
                                        )
                                    })}
                                </ul>
                                
                            </div>
                        </div>
                    </> :
                    <AuditIssue
                    userName={userName}
                    issueURL={issueURL}
                    stat={stat}
                    toggleDetail={toggleDetail}
                    category={category}
                    title={title}
                    url={confirmedInputURL}
                    description={desc}
                    projectId={projectId}
                    audit_type={auditMode}
                    token={token}
                    issue={issue}
                    endPoint={endPoint}
                    importance={importance}
                    auditDetailHandler={auditDetailHandler}
                    exportFile={exportFileHandler}
                    projectData = {projectData}
                    crawlDate={activeUNIXTime}
                    />
                }
            </div>
        </div>
    )
};

export default Onpage;