import { useState, useRef, useEffect, useMemo } from 'react';
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import DatePicker from "../components/datepick";

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import { v4 as uuid4 } from 'uuid';
import apiClient from '../api/client';

import {
    ResponsiveContainer,
    Tooltip,
    PieChart, Pie, Cell, LabelList, Sector,
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Area,
    BarChart,
    Bar,
    Line,
    ComposedChart
  } from "recharts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faArrowUp, faArrowDown, faSort, faUpRightFromSquare, faCheckCircle, faTimesCircle, faAngleUp, faAngleDown, faGlobe, faLanguage, faPlus,
  faSquareCheck,
  faArrowCircleLeft,
  faEye,
  faFlag,
  faArrowUpRightFromSquare,
  faFolder,
  faBullseye,
  faCircle,
  faLineChart
} from "@fortawesome/free-solid-svg-icons";
import { getAuthToken } from '../util/token';
import { useSearchParams } from "react-router-dom";
import Papa from 'papaparse';
import moment from "moment";
import * as d3 from "d3";
import { scaleTime } from "d3-scale";
import logger from '../util/logger';


function Accounting() {

    const dispatch = useDispatch();
    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const COLORS  = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    
    const [dateRange, setDataRange] = useState('');
    const [domain, setDomain] = useState('bbcincorp.com');
    const [topRankedCountries, setTopRankedCountries] = useState([]);
    const [topRankedQueries, setTopRankedQueries] = useState([]);
    const [topRankedPages, setTopRankedPages] = useState([]);
    // ranking
    const [rankingDist, setRankingDist] = useState([]);
    const [ rankingProgress, setRankingProgress] = useState([]);
    // traffic
    const [totalTraffic, setTotalTraffic] = useState([]);
    const [trafficByCountry, setTrafficByCountry] = useState([]);
    const [trafficByFolder, setTrafficByFolder] = useState([]);
    const [currentFolderTraffic, setCurrentFolderTraffic] = useState([]);
    const [topVisitedCountries, setTopVisitedCountries] = useState([]);
    const [topVisitedPages, setTopVisitedPages] = useState([]);
    // performance
    const [topEngagedPages, setTopEngagedPages] = useState([]);
    const [worstEngagedPages, setWorstEngagedPages] = useState([]);
    const [topPerformedPages, setTopPerformedPages] = useState([]);
    const [worstPerformedPages, setWorstPerformedPages] = useState([]);
    const [topPerformedCountries, setTopPerformedCountries] = useState([]);

    const applyHandler = () => {
        const d = dateRange.split('-');
        const unix_start_time = new Date(Date.UTC(d[0], d[1]-1, d[2], 0,0,0)).getTime();
        const unix_end_time = new Date(Date.UTC(d[0], d[1], 0, 0,0,0)).getTime();
        logger.log(unix_start_time, unix_end_time)
        const dateObject = new Date(Date.UTC(d[0], d[1]-1, d[2], 0,0,0));
        const input = {
            date: dateObject,
            ranked_entity: 'page',
            domain: domain
        };
        logger.log(input);
        dispatch(showLoading());
        Promise.all([
            apiClient.post('/api/top-ranked', input,
                {
                    headers: {'X-User': userName}
                }
            ).then (
                ({data}) =>{
                    logger.log(data);
                    setTopRankedPages(data);
                }),
            apiClient.post('/api/top-ranked', {
                date: dateObject,
                ranked_entity: 'country',
                domain: domain
            }, { headers: {'X-User': userName} }).then (
                ({data}) =>{
                    logger.log(data);
                    setTopRankedCountries(data);
            }),
            apiClient.post('/api/top-ranked', {
                date: dateObject,
                ranked_entity: 'query',
                domain: domain
            }, { headers: {'X-User': userName} }).then (
                ({data}) =>{
                    logger.log(data);
                    setTopRankedQueries(data);
            }),
            apiClient.get('/api/ranking-distribution', {
                params: {
                    start: unix_start_time,
                    end: unix_end_time,
                    domain: domain
                },
                headers: {'X-User': userName}
            }).then (
                ({data}) =>{
                    data.sort((a,b) => a.date - b.date)
                    // logger.log('ranking dist', data)
                    setRankingDist(data);
                }
            ),
            apiClient.get('/api/ranking', {
                params: {
                    start: unix_start_time,
                    end: unix_end_time,
                    domain: domain
                },
                headers: {'X-User': userName}
            }).then (
                ({data}) =>{
                    data.sort((a,b) => a.date - b.date)
                    // logger.log('ranking progress: ', data);
                    setRankingProgress(data);
                    }
                ),
            apiClient.post('/api/traffic-progress', {
                date: dateObject,
                domain: domain
            }, { headers: {'X-User': userName} }).then (
                    ({data}) =>{
                        let traffic = [];
                        let folderTraffic = [];
                        let countryTraffic = [];
                        // logger.log('data: ', data);
                        setTopVisitedCountries(data['top_visited_countries'])
                        data['traffic_progress'].sort((a,b) => new Date(a.date) - new Date(b.date)).map(item => {
                            traffic.push({
                                date: item.date,
                                clicks: item.clicks,
                                impressions: item.impressions
                            });
                            if (domain === 'bbcincorp.com'){
                                folderTraffic.push({
                                    date: item.date,
                                    ...item.folder
                                });
                                countryTraffic.push({
                                    date: item.date,
                                    ...item.country
                                })
                            }
                        });
                        // logger.log(totalTraffic);
                        setTotalTraffic(traffic);

                        let d = []
                        if (domain === 'bbcincorp.com'){
                            setTrafficByFolder(folderTraffic);
                            setTrafficByCountry(countryTraffic);
                            logger.log('traffic by country: ', countryTraffic)
                            
                            Object.entries(folderTraffic.slice(-1)[0]).map(([k, v]) => {
                                if (k !== 'date'){
                                    d.push({'name': k, 'value': v})
                                }
                            });
                            setCurrentFolderTraffic(d);
                        }

                        d = []
                        let pages = [];
                        let dates = [];
                        data['traffic_progress'].map(item => {
                            dates.push(item.date);
                            Object.entries(item['pages']).map(([k, v]) => {
                                pages.push(k);
                            }
                        )});
                        let u_pages = [...new Set(pages)];
                        u_pages.map(item => {
                            let m = {
                                'url': item
                                };
                                data['traffic_progress'].map(it => {
                                    Object.entries(it['pages']).map(([k, v]) => {
                                        if (k === item){
                                            m[it.date] = v; 
                                        }
                                })
                            });
                            d.push(m);
                        });
                        setTopVisitedPages(d);
                        // logger.log('top visited counties: ', d);
                }),
            apiClient.post('/api/top-performance', {
                date: dateObject,
                domain: domain
            }, { headers: {'X-User': userName} }).then (
                ({data}) =>{
                    logger.log('top performance: ', data);
                    setTopEngagedPages(data['top_engaged_pages']);
                    setWorstEngagedPages(data['worst_engaged_pages']);
                    setTopPerformedPages(data['top_performed_pages']);
                    setWorstPerformedPages(data['worst_performed_pages']);
                    setTopPerformedCountries(data['top_performed_countries']);
            })
        ]).then(
            () => {return dispatch(hideLoading())}
          )
    };

    const get_total_kw = () => {
       if (rankingDist.length > 0) {
          let total = 0;
          Object.keys(rankingDist.slice(-1)[0]).map(item => {
            if (item !== 'date' && typeof rankingDist.slice(-1)[0][item] !== 'object'){
              total += rankingDist.slice(-1)[0][item];
            }
          });
          return total
        }
    };

    const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

    const getPercent = (value, total) => {
        const ratio = total > 0 ? value / total : 0;

        return toPercent(ratio, 1);
    };

    const renderTooltipContent = (o) => {
        const { payload = [], label } = o;
        if (payload !== null) {
            const total = payload.reduce(
                (result, entry) => result + entry.value,
                0
              );
            
              return (
                <div className="customized-tooltip-content">
                  <p className="total">{`${moment(label).format('YYYY-MM-DD')} (Total: ${total})`}</p>
                  <ul className="list">
                    {payload.map((entry, index) => (
                      <li key={`item-${index}`} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value.toLocaleString('en-us')} (${getPercent(entry.value, total)})`}
                      </li>
                    ))}
                  </ul>
                </div>
              );
        }
    };
    

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // logger.log(payload);
            return (
            <div className="custom-tooltip">
                <p className="label">{`Date: ${moment(label).format('YYYY-MM-DD')}`}</p>
                {
                    (payload.length === 1 && payload[0].name !== 'update_index') ?
                      <p className="desc">{`${payload[0].name}: ${payload[0].value === 101 ? 'Out top 100' : payload[0].value.toLocaleString('en-us')}`}</p> :
                    (payload.length === 1 && payload[0].name === 'update_index') ?
                      payload.map(item => {
                        return (
                          <>
                          {item.payload.google_updates.map(it => (
                            <p className="desc">{`${it.update_name}:`}<br />{`${it.duration}`}</p>
                          ))}
                          </>
                      )
                      })
                    : 
                        <>
                          <p className="desc">{`${payload.filter(item => item.name !== 'update_index')[0].name}: ${payload.filter(item => item.name !== 'update_index')[0].value}`}</p>
                          {
                            payload.filter(item => item.name === 'update_index').map(item => {
                              return item.payload.google_updates.map(it => (
                                <p className="desc">{`${it.update_name}:`}<br />{`${it.duration}`}</p>
                              ))
                            })
                          }
                        </>
                }
                
            </div>
            );
        }
    };

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        // logger.log(cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value);
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        if (payload.name !== 'Other'){
            return (
                <g>
                  <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                  <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                  <text x={ex -20 + (cos >= 0 ? 1 : -1) * 3} y={ey-22} textAnchor={textAnchor} fill="#333" fontSize={'12px'}>
                      {`${payload.name}`}
                  </text>
                  <text x={ex -20 + (cos >= 0 ? 1 : -1) * 3} y={ey-6} textAnchor={textAnchor} fill="#333" fontSize={'12px'}>
                      {`${value.toLocaleString('en-us')}`}
                  </text>
                </g>
              );
        }
    };

    const convertSecondsToHoursMinutes = (s) => {
        if (s < 3600){
            let minutes = Math.floor(s/60);
            let seconds = Math.floor(s%60);
            return minutes + 'm ' + seconds + 's'
        } else {
            let hours = Math.floor(s/3600);
            let minutes = Math.floor((s%3600)/60);
            return hours + 'h ' + minutes + 'm'
        }
    };

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Accounting Report</h1>
            <div className='d-flex my-4'>
                <p className='me-auto'>
                    <b>Domain:</b>
                    <FontAwesomeIcon icon={faCircle} className='mx-2' style={{color: 'green'}} />
                     {domain}
                </p>
                <div className='d-flex me-2'>
                    <select class="form-select" onChange={e => setDomain(e.target.value)}>
                        <option value='bbcincorp.com' selected>bbcincorp.com</option>
                        <option value='bbcincorp.sg'>bbcincorp.sg</option>
                    </select>
                </div>
                <div className='d-flex me-2'>
                    <select class="form-select" onChange={e => setDataRange(e.target.value)}>
                        <option selected>Date</option>
                        {
                            Array.from({ length: 12 }, (_, index) => index + 1).map(item => (
                                <option value={`2024-${item}-01`}>{item} - 2024</option>
                            ))
                        }
                    </select>
                </div>
                <button type="button" class="btn btn-primary" onClick={applyHandler}>Apply</button>
            </div>
            <h2 className='my-2'>Website ranking report</h2>
            <div className="d-flex my-5">
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                    <b>Average Position</b>
                    <h2 className='align-self-center my-3'>
                        {
                            rankingProgress.length > 0 ?
                            rankingProgress.slice(-1)[0]['rank']:
                            null
                        }
                    </h2>
                    <span style={{minHeight: '21px'}}>
                    </span>
                </div>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                    <b>Top 5</b>
                    <h2 className='align-self-center my-3'>
                        {
                            rankingDist.length > 0 ?
                            rankingDist.slice(-1)[0]['Top 5']:
                            null
                        }
                        <span style={{fontSize: '12px',}}> /
                            {get_total_kw()}
                        </span>
                    </h2>
                    <span style={{minHeight: '21px'}}>
                    </span>
                </div>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                    <b>Top 10</b>
                    <h2 className='align-self-center my-3'>
                        {
                            rankingDist.length > 0 ?
                            rankingDist.slice(-1)[0]['Top 10'] + rankingDist.slice(-1)[0]['Top 5']:
                            null
                        }
                        <span style={{fontSize: '12px',}}> /
                            {get_total_kw()}
                        </span>
                    </h2>
                    <span style={{minHeight: '21px'}}>
                    </span>
                </div>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                    <b>Top 20</b>
                    <h2 className='align-self-center my-3'>
                        {
                            rankingDist.length > 0 ?
                            rankingDist.slice(-1)[0]['Top 20'] + rankingDist.slice(-1)[0]['Top 10'] + rankingDist.slice(-1)[0]['Top 5']:
                            null
                        }
                        <span style={{fontSize: '12px',}}> /
                            {get_total_kw()}
                        </span>
                    </h2>
                    <span style={{minHeight: '21px'}}>
                    </span>
                </div>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                    <b>Top 50</b>
                    <h2 className='align-self-center my-3'>
                        {
                            rankingDist.length > 0 ?
                            rankingDist.slice(-1)[0]['Top 50'] + rankingDist.slice(-1)[0]['Top 20'] + rankingDist.slice(-1)[0]['Top 10'] + rankingDist.slice(-1)[0]['Top 5']:
                            null
                        }
                        <span style={{fontSize: '12px',}}> /
                            {get_total_kw()}
                        </span>
                    </h2>
                    <span style={{minHeight: '21px'}}>
                    </span>
                </div>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                    <b>Top 100</b>
                    <h2 className='align-self-center my-3'>
                        {
                            rankingDist.length > 0 ?
                            rankingDist.slice(-1)[0]['Top 100'] + rankingDist.slice(-1)[0]['Top 50'] + rankingDist.slice(-1)[0]['Top 20'] + rankingDist.slice(-1)[0]['Top 10'] + rankingDist.slice(-1)[0]['Top 5']:
                            null
                        }
                        <span style={{fontSize: '12px',}}> /
                            {get_total_kw()}
                        </span>
                    </h2>
                    <span style={{minHeight: '21px'}}>
                    </span>
                </div>
            </div>
            <div className={`d-flex flex-column ${rankingProgress.length === 0 && 'd-none'}`}>
                <div className="row h-100">
                    <div className="d-flex flex-column col-md-6" style={{'height': '400px'}}>
                        <h5 className="mb-4">Ranking Distribution</h5>
                        <ResponsiveContainer width="100%" height="80%">
                            <AreaChart
                            width={500}
                            height={300}
                            data={rankingDist}
                            stackOffset="expand"
                            syncId="ProgressDist"
                            syncMethod={(tick, datum) => {
                              let d = tick.filter(item => item.value === datum.activeLabel);
                              if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                return d[0].index
                              }
                            }}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                name = "date"
                                tickFormatter = {(unixTime) => moment(unixTime).format('YY-M-D')}

                            />
                            <YAxis 
                                tickFormatter={(decimal) => `${(decimal * 100).toFixed(0)}%`}
                            />
                            <Tooltip 
                                content={renderTooltipContent}
                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                            />
                            <Legend />
                            
                            <Area type="monotone" dataKey="Top 5" stackId="1" stroke="#8884d8" fill="#8884d8" dot={{ stroke: '#8884d8', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Top 10" stackId="1" stroke="#82ca9d" fill="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Top 20" stackId="1" stroke="#ffc658" fill="#ffc658" dot={{ stroke: '#ffc658', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Top 50" stackId="1" stroke="#d4d884" fill="#d4d884" dot={{ stroke: '#d4d884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Top 100" stackId="1" stroke="#d88884" fill="#d88884" dot={{ stroke: '#d88884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Out of 100" stackId="1" stroke="#c6504a" fill="#c6504a" dot={{ stroke: '#c6504a', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                                  
                            </AreaChart>
                        </ResponsiveContainer>
                        
                    </div>
                    <div className=" d-flex flex-column col-md-6" style={{'height': '400px'}}>
                        <h5 className="mb-4">Ranking Progress</h5>                              
                        <ResponsiveContainer width="100%" height="80%">
                            <AreaChart
                            width={500}
                            height={300}
                            data={rankingProgress}
                            syncId="ProgressDist"
                            syncMethod={(tick, datum) => {
                              let d = tick.filter(item => item.value === datum.activeLabel);
                              if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                return d[0].index
                              }
                            }}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                              <defs>
                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#ffc658" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#d4d884" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#d4d884" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="color5" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#d88884" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#d88884" stopOpacity={0}/>
                                </linearGradient>
                              </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                name = "date"
                                tickFormatter = {(unixTime) => moment(unixTime).format('YY-M-D')}

                            />
                            <YAxis 
                                domain={[0, 'auto']}
                                reversed
                            />
                            <Tooltip 
                                content={<CustomTooltip />}
                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                            />
                            <Legend legendType='none'/>
                            <Area type="monotone" dataKey="rank" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column my-4'>
                <h5 className='text-nowrap me-2'>Top highest ranked countries with traffic</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'45%'}}>Countries</th>
                                <th scope="col" style={{'width':'10%'}}>Impression</th>
                                <th scope="col" style={{'width':'10%'}}>Clicks</th>
                                <th scope="col" style={{'width':'10%'}}>CTR</th>
                                <th scope="col" style={{'width':'10%'}}>Position</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    topRankedCountries.map((item, idx) => (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.keys[0]}</td>
                                            <td>{item.impressions.toLocaleString('en-us')}</td>
                                            <td>{item.clicks.toLocaleString('en-us')}</td>
                                            <td>{`${(item.ctr*100).toFixed(1)}%`}</td>
                                            <td>{item.position.toFixed(1)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </div>
            </div>
            <div className='d-flex flex-column my-4'>
                <h5 className='text-nowrap me-2'>Top highest ranked queries</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'45%'}}>Queries</th>
                                <th scope="col" style={{'width':'10%'}}>Impression</th>
                                <th scope="col" style={{'width':'10%'}}>Clicks</th>
                                <th scope="col" style={{'width':'10%'}}>CTR</th>
                                <th scope="col" style={{'width':'10%'}}>Position</th>
                            </tr>
                        </thead>
                            <tbody>
                            {
                                    topRankedQueries.map((item, idx) => (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.keys[0]}</td>
                                            <td>{item.impressions.toLocaleString('en-us')}</td>
                                            <td>{item.clicks.toLocaleString('en-us')}</td>
                                            <td>{`${(item.ctr*100).toFixed(1)}%`}</td>
                                            <td>{item.position.toFixed(1)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </div>
            </div>
            <div className='d-flex flex-column my-4'>
                <h5 className='text-nowrap me-2'>Top highest ranked landing pages</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'45%'}}>Pages</th>
                                <th scope="col" style={{'width':'10%'}}>Impression</th>
                                <th scope="col" style={{'width':'10%'}}>Clicks</th>
                                <th scope="col" style={{'width':'10%'}}>CTR</th>
                                <th scope="col" style={{'width':'10%'}}>Position</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    topRankedPages.map((item, idx) => (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.keys[0]}</td>
                                            <td>{item.impressions.toLocaleString('en-us')}</td>
                                            <td>{item.clicks.toLocaleString('en-us')}</td>
                                            <td>{`${(item.ctr*100).toFixed(1)}%`}</td>
                                            <td>{item.position.toFixed(1)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </div>
            </div>
            <hr />
            <h2 className='my-2'>Website traffic report</h2>
            <div className='d-flex flex-column my-4' style={{height: '400px'}}>
                <h5 className='mb-4'>Traffic Progress</h5>
                <ResponsiveContainer width="100%" height="80%">
                    <AreaChart
                    width={500}
                    height={300}
                    data={totalTraffic}
                    syncId="ProgressDist"
                    syncMethod={(tick, datum) => {
                        let d = tick.filter(item => item.value === datum.activeLabel);
                        if (d.length > 0 && Object.keys(d[0]).includes('index')){
                        return d[0].index
                        }
                    }}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                        <defs>
                        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                        </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        name = "date"
                        tickFormatter = {(unixTime) => moment(unixTime).format('D-M-YY')}

                    />
                    <YAxis 
                        domain={[0, 'auto']}
                    />
                    <Tooltip 
                        content={<CustomTooltip />}
                        wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                    />
                    <Legend legendType='none'/>
                    <Area type="monotone" dataKey="clicks" stroke="#8884d8" fillOpacity={1} fill="url(#color1)">
                        <LabelList dataKey="clicks" position="bottom" stroke="black" fontSize='12px' formatter={value => value.toLocaleString('en-us')}/>
                    </Area>
                    </AreaChart>
                </ResponsiveContainer>
            </div>
            {
                domain !== 'bbcincorp.sg' &&
                <>
                    <div className='d-flex flex-column my-4'>
                        <h5 className='mb-4'>Traffic by Folder</h5>
                        <div className='d-flex' style={{height: '400px'}}>
                            <div className='d-flex col-md-4'>
                                <ResponsiveContainer width="100%" height="80%">
                                    <PieChart
                                        width={300}
                                        height={300}
                                        margin={{
                                            top: 0,
                                            right: 10,
                                            left: 10,
                                            bottom: 0,
                                        }}
                                    >
                                        <Pie
                                            dataKey="value"
                                            data={currentFolderTraffic.length > 0 && currentFolderTraffic}
                                            innerRadius={0}
                                            // outerRadius={80}
                                            // activeShape={renderActiveShape}
                                            label={renderActiveShape}
                                            paddingAngle={1}
                                        >
                                            {currentFolderTraffic.length > 0 && currentFolderTraffic.map((entry, index) => {
                                                return (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                                )
                                            }
                                            )}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                            <div className='d-flex col-md-8'>
                                <ResponsiveContainer width="100%" height="80%">
                                    <AreaChart
                                    width={300}
                                    height={300}
                                    data={trafficByFolder}
                                    syncId="ProgressDist"
                                    syncMethod={(tick, datum) => {
                                        let d = tick.filter(item => item.value === datum.activeLabel);
                                        if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                        return d[0].index
                                        }
                                    }}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 0,
                                    }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            name = "date"
                                            tickFormatter = {(unixTime) => moment(unixTime).format('YY-M-D')}

                                        />
                                        <YAxis 
                                            domain={[0, 'auto']}
                                        />
                                        <Tooltip 
                                            content={renderTooltipContent}
                                            wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                        />
                                        <Legend />
                                        
                                        <Area type="monotone" dataKey="Offshore" stackId="1" stroke="#8884d8" fill="#8884d8" dot={{ stroke: '#8884d8', strokeWidth: 1, r: 2,strokeDasharray:''}} />
                                        <Area type="monotone" dataKey="Hong Kong" stackId="1" stroke="#82ca9d" fill="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                                        <Area type="monotone" dataKey="Singapore" stackId="1" stroke="#ffc658" fill="#ffc658" dot={{ stroke: '#ffc658', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                                        <Area type="monotone" dataKey="Vietnam" stackId="1" stroke="#d4d884" fill="#d4d884" dot={{ stroke: '#d4d884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                                        <Area type="monotone" dataKey="Other" stackId="1" stroke="#d88884" fill="#d88884" dot={{ stroke: '#d88884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>                       
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                            <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                                <thead className='sticky-top table-primary'>
                                    <tr>
                                        <th scope="col" style={{'width':'5%'}}>#</th>
                                        <th scope="col" style={{'width':'45%'}}>Folder</th>
                                        {
                                            trafficByFolder.map(item => (
                                                <th scope="col" style={{'width':`${(50/trafficByFolder.length).toFixed(0)}%`}}>{`${new Date(item.date).getMonth()+1}-${new Date(item.date).getFullYear()}`}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                    <tbody>
                                        {
                                            trafficByFolder.map((item, idx) => (
                                                idx === 0 &&
                                                <>
                                                    <tr>
                                                        <th scope="row">{idx+1}</th>
                                                        <td>Offshore</td>
                                                        <td>{item.Offshore.toLocaleString('en-us')}</td>
                                                        {trafficByFolder.map((it, index) => {
                                                            if (index > 0){
                                                                return (
                                                                    <td>{it.Offshore.toLocaleString('en-us')}</td>
                                                                )
                                                            }
                                                        }) }
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{idx+2}</th>
                                                        <td>Hong Kong</td>
                                                        <td>{item['Hong Kong'].toLocaleString('en-us')}</td>
                                                        {trafficByFolder.map((it, index) => {
                                                            if (index > 0){
                                                                return (
                                                                    <td>{it['Hong Kong'].toLocaleString('en-us')}</td>
                                                                )
                                                            }
                                                        }) }
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{idx+3}</th>
                                                        <td>Singapore</td>
                                                        <td>{item['Singapore'].toLocaleString('en-us')}</td>
                                                        {trafficByFolder.map((it, index) => {
                                                            if (index > 0){
                                                                return (
                                                                    <td>{it.Singapore.toLocaleString('en-us')}</td>
                                                                )
                                                            }
                                                        }) }
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{idx+4}</th>
                                                        <td>Vietnam</td>
                                                        <td>{item['Vietnam'].toLocaleString('en-us')}</td>
                                                        {trafficByFolder.map((it, index) => {
                                                            if (index > 0){
                                                                return (
                                                                    <td>{it.Vietnam.toLocaleString('en-us')}</td>
                                                                )
                                                            }
                                                        }) }
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{idx+5}</th>
                                                        <td>Other</td>
                                                        <td>{item['Other'].toLocaleString('en-us')}</td>
                                                        {trafficByFolder.map((it, index) => {
                                                            if (index > 0){
                                                                return (
                                                                    <td>{it.Other.toLocaleString('en-us')}</td>
                                                                )
                                                            }
                                                        }) }
                                                    </tr>
                                                </>
                                            ))
                                        }
                                    </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='d-flex flex-column my-4'>
                        <h5 className='mb-4'>Traffic by Serviced Country</h5>
                        <div className='d-flex' style={{height: '400px'}}>
                            <ResponsiveContainer width="100%" height="80%">
                                <BarChart
                                width={300}
                                height={300}
                                data={function(){
                                    let d = []
                                    trafficByCountry.map(item => {
                                        let other = 0;
                                        let m = {
                                            date: item.date
                                        }
                                        Object.entries(item).map(([k, v]) => {
                                            if (k !== 'date' && v < 500){
                                                other += v;
                                            } else if (k !== 'date' && v > 500){
                                                m[k] = v
                                            };
                                        });
                                        m['Other'] = other;
                                        d.push(m)
                                    });
                                    return d
                                }()}
                                syncId="ProgressDist"
                                syncMethod={(tick, datum) => {
                                    let d = tick.filter(item => item.value === datum.activeLabel);
                                    if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                    return d[0].index
                                    }
                                }}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 0,
                                }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="date"
                                        name = "date"
                                        tickFormatter = {(unixTime) => moment(unixTime).format('YY-M-D')}

                                    />
                                    <YAxis 
                                        domain={[0, 'auto']}
                                    />
                                    <Tooltip 
                                        content={renderTooltipContent}
                                        wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                    />
                                    <Legend />
                                        {   trafficByCountry.length > 0 &&
                                            Object.entries(trafficByCountry.slice(-1)[0]).map(([k, v], index) => {
                                                let colorRange = d3.schemeSpectral[11];
                                                // logger.log(colorRange);
                                                if (k !== 'date' && k !== 'Other' && v > 500) {
                                                    // logger.log(k)
                                                    return (
                                                        <Bar type="monotone" dataKey={k} stackId="1" stroke={colorRange[index]} fill={colorRange[index]} dot={{ stroke: colorRange[index], strokeWidth: 1, r: 2,strokeDasharray:''}}>
                                                            <LabelList dataKey={k} position="center" stroke="#ccc" strokeWidth='0.5px' fontSize='10px' formatter={value => value.toLocaleString('en-us')}/>
                                                        </Bar>
                                                    )
                                                }
                                            })
                                        }
                                        {trafficByCountry.length > 0 && (function() {
                                                let colorRange = d3.schemeSpectral[11];
                                                return (
                                                    <Bar type="monotone" dataKey='Other' stackId="1" stroke={colorRange.slice(-1)[0]} fill={colorRange.slice(-1)[0]} dot={{ stroke: colorRange.slice(-1)[0], strokeWidth: 1, r: 2,strokeDasharray:''}}>
                                                        <LabelList dataKey='Other' position="center" stroke="#ccc" strokeWidth='0.5px' fontSize='10px' formatter={value => value.toLocaleString('en-us')}/>
                                                    </Bar>
                                                )
                                            })()}          
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                            <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                                <thead className='sticky-top table-primary'>
                                    <tr>
                                        <th scope="col" style={{'width':'5%'}}>#</th>
                                        <th scope="col" style={{'width':'45%'}}>Country</th>
                                        {
                                            trafficByCountry.map(item => (
                                                <th scope="col" style={{'width':`${(50/trafficByCountry.length).toFixed(0)}%`}}>{`${new Date(item.date).getMonth()+1}-${new Date(item.date).getFullYear()}`}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                    <tbody>
                                        {
                                            function(){
                                                let d = []
                                                let countries = [];
                                                let dates = [];
                                                trafficByCountry.map(item => {
                                                    Object.entries(item).map(([k, v]) => {
                                                        if (k !== 'date'){
                                                            countries.push(k);
                                                        }else{
                                                            dates.push(v)
                                                        }
                                                    })
                                                });
                                                let u_countries = [...new Set(countries)];
                                                u_countries.map(item => {
                                                    let m = {
                                                        'country': item
                                                        };
                                                    trafficByCountry.map(it => {
                                                        Object.entries(it).map(([k, v]) => {
                                                            if (k === item){
                                                            m[it.date] = v; 
                                                            }
                                                        })
                                                    });
                                                    d.push(m);
                                                });
                                                return d
                                            }().map((item, idx) => 
                                                <tr>
                                                    <th scope="row">{idx+1}</th>
                                                    <th scope="row">{item.country}</th>
                                                    {
                                                        Object.entries(item).map(([k, v]) => {
                                                            if (k !== 'country'){
                                                                return (
                                                                    <td>{v.toLocaleString('en-us')}</td>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tr>
                                            )
                                        }
                                    </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
            <div className='d-flex flex-column my-4'>
                <h5 className='text-nowrap me-2'>{`Top visitors' countries - ${dateRange}`}</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'45%'}}>Countries</th>
                                <th scope="col" style={{'width':'10%'}}>Impression</th>
                                <th scope="col" style={{'width':'10%'}}>Clicks</th>
                                <th scope="col" style={{'width':'10%'}}>CTR</th>
                                <th scope="col" style={{'width':'10%'}}>Position</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    topVisitedCountries.map((item, idx) => (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.keys[0]}</td>
                                            <td>{item.impressions.toLocaleString('en-us')}</td>
                                            <td>{item.clicks.toLocaleString('en-us')}</td>
                                            <td>{`${(item.ctr*100).toFixed(1)}%`}</td>
                                            <td>{item.position.toFixed(1)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </div>
            </div>
            <div className='d-flex flex-column my-4'>
                <h5 className='mb-4'>{`Top Visited Pages`}</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'45%'}}>Country</th>
                                {
                                    trafficByCountry.map(item => (
                                        <th scope="col" style={{'width':`${(50/trafficByCountry.length).toFixed(0)}%`}}>{`${new Date(item.date).getMonth()+1}-${new Date(item.date).getFullYear()}`}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    topVisitedPages.map((item, idx) => 
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.url}</td>
                                            {
                                                Object.entries(item).map(([k, v]) => {
                                                    if (k !== 'url'){
                                                        return (
                                                            <td>
                                                                <>
                                                                    {v.toLocaleString('en-us')}
                                                                    <div class="progress">
                                                                        <div class="progress-bar" role="progressbar" style={{width: `${v*100/2000}%`}} aria-valuenow={v} aria-valuemin="0" aria-valuemax="2000"></div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        )
                                                    }
                                                })
                                            }
                                        </tr>
                                    )
                                }
                            </tbody>
                    </table>
                </div>
            </div>
            <hr />
            <h2 className='my-2'>Website engagement report</h2>
            <div className='d-flex flex-column my-4'>
                <h5 className='text-nowrap me-2'>{`Top engaged pages - ${dateRange}`}</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'45%'}}>URL</th>
                                <th scope="col" style={{'width':'6%'}}>Impression</th>
                                <th scope="col" style={{'width':'6%'}}>Clicks</th>
                                <th scope="col" style={{'width':'6%'}}>CTR</th>
                                <th scope="col" style={{'width':'6%'}}>Position</th>
                                <th scope="col" style={{'width':'6%'}}>Users</th>
                                <th scope="col" style={{'width':'6%'}}>Engaged sessions</th>
                                <th scope="col" style={{'width':'6%'}}>Engaged rate</th>
                                <th scope="col" style={{'width':'6%'}}>Avg. time</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    topEngagedPages.map((item, idx) => (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.url}</td>
                                            <td>{item.impressions.toLocaleString('en-us')}</td>
                                            <td>{item.clicks.toLocaleString('en-us')}</td>
                                            <td>{`${(item.ctr*100).toFixed(1)}%`}</td>
                                            <td>{item.position.toFixed(1)}</td>
                                            <td>{item.users.toLocaleString('en-us')}</td>
                                            <td>{item.engaged_sessions.toLocaleString('en-us')}</td>
                                            <td>{`${(item.engagement_rate*100).toFixed(1)}%`}</td>
                                            <td>{convertSecondsToHoursMinutes(parseInt(item.avg_engagement_time/item.users))}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </div>
            </div>
            <div className='d-flex flex-column my-4'>
                <h5 className='text-nowrap me-2'>{`Worst engaged pages - ${dateRange}`}</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'45%'}}>URL</th>
                                <th scope="col" style={{'width':'6%'}}>Impression</th>
                                <th scope="col" style={{'width':'6%'}}>Clicks</th>
                                <th scope="col" style={{'width':'6%'}}>CTR</th>
                                <th scope="col" style={{'width':'6%'}}>Position</th>
                                <th scope="col" style={{'width':'6%'}}>Users</th>
                                <th scope="col" style={{'width':'6%'}}>Engaged sessions</th>
                                <th scope="col" style={{'width':'6%'}}>Engaged rate</th>
                                <th scope="col" style={{'width':'6%'}}>Avg. time</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    worstEngagedPages.map((item, idx) => (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.url}</td>
                                            <td>{item.impressions.toLocaleString('en-us')}</td>
                                            <td>{item.clicks.toLocaleString('en-us')}</td>
                                            <td>{`${(item.ctr*100).toFixed(1)}%`}</td>
                                            <td>{item.position.toFixed(1)}</td>
                                            <td>{item.users.toLocaleString('en-us')}</td>
                                            <td>{item.engaged_sessions.toLocaleString('en-us')}</td>
                                            <td>{`${(item.engagement_rate*100).toFixed(1)}%`}</td>
                                            <td>{convertSecondsToHoursMinutes(parseInt(item.avg_engagement_time/item.users))}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </div>
            </div>
            <hr />
            <h2 className='my-2'>Website performance report</h2>
            <div className='d-flex flex-column my-4'>
                <h5 className='text-nowrap me-2'>{`Top performed countries - ${dateRange}`}</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'35%'}}>Country</th>
                                <th scope="col" style={{'width':'10%'}}>Impression</th>
                                <th scope="col" style={{'width':'10%'}}>Clicks</th>
                                <th scope="col" style={{'width':'10%'}}>CTR</th>
                                <th scope="col" style={{'width':'10%'}}>Position</th>
                                <th scope="col" style={{'width':'10%'}}>Users</th>
                                <th scope="col" style={{'width':'10%'}}>Conv.</th>
                                <th scope="col" style={{'width':'10%'}}>Conv. rate</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    topPerformedCountries.map((item, idx) => (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.url}</td>
                                            <td>{item.impressions.toLocaleString('en-us')}</td>
                                            <td>{item.clicks.toLocaleString('en-us')}</td>
                                            <td>{`${(item.ctr*100).toFixed(1)}%`}</td>
                                            <td>{item.position.toFixed(1)}</td>
                                            <td>{item.users.toLocaleString('en-us')}</td>
                                            <td>{item.key_events.toLocaleString('en-us')}</td>
                                            <td>{`${((item.key_events/item.users)*100).toFixed(1)}%`}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </div>
            </div>
            <div className='d-flex flex-column my-4'>
                <h5 className='text-nowrap me-2'>{`Top performed pages - ${dateRange}`}</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'35%'}}>URL</th>
                                <th scope="col" style={{'width':'10%'}}>Impression</th>
                                <th scope="col" style={{'width':'10%'}}>Clicks</th>
                                <th scope="col" style={{'width':'10%'}}>CTR</th>
                                <th scope="col" style={{'width':'10%'}}>Position</th>
                                <th scope="col" style={{'width':'10%'}}>Users</th>
                                <th scope="col" style={{'width':'10%'}}>Conv.</th>
                                <th scope="col" style={{'width':'10%'}}>Conv. rate</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    topPerformedPages.map((item, idx) => (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.url}</td>
                                            <td>{item.impressions.toLocaleString('en-us')}</td>
                                            <td>{item.clicks.toLocaleString('en-us')}</td>
                                            <td>{`${(item.ctr*100).toFixed(1)}%`}</td>
                                            <td>{item.position.toFixed(1)}</td>
                                            <td>{item.users.toLocaleString('en-us')}</td>
                                            <td>{item.key_events.toLocaleString('en-us')}</td>
                                            <td>{`${((item.key_events/item.users)*100).toFixed(1)}%`}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </div>
            </div>
            <div className='d-flex flex-column my-4'>
                <h5 className='text-nowrap me-2'>{`Worst performed pages - ${dateRange}`}</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'35%'}}>URL</th>
                                <th scope="col" style={{'width':'10%'}}>Impression</th>
                                <th scope="col" style={{'width':'10%'}}>Clicks</th>
                                <th scope="col" style={{'width':'10%'}}>CTR</th>
                                <th scope="col" style={{'width':'10%'}}>Position</th>
                                <th scope="col" style={{'width':'10%'}}>Users</th>
                                <th scope="col" style={{'width':'10%'}}>Conv.</th>
                                <th scope="col" style={{'width':'10%'}}>Conv. rate</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    worstPerformedPages.map((item, idx) => (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.url}</td>
                                            <td>{item.impressions.toLocaleString('en-us')}</td>
                                            <td>{item.clicks.toLocaleString('en-us')}</td>
                                            <td>{`${(item.ctr*100).toFixed(1)}%`}</td>
                                            <td>{item.position.toFixed(1)}</td>
                                            <td>{item.users.toLocaleString('en-us')}</td>
                                            <td>{item.key_events.toLocaleString('en-us')}</td>
                                            <td>{`${((item.key_events/item.users)*100).toFixed(1)}%`}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default Accounting;