import React, { useState } from 'react';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  Form, 
  Button, 
  Badge 
} from 'react-bootstrap';
import { 
  Search, 
  Tag, 
  Edit, 
  RefreshCw, 
  Plus, 
  X 
} from 'lucide-react';
import logger from '../util/logger';

const EmbeddingManager = () => {
  const [rawText, setRawText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedChunk, setSelectedChunk] = useState(null);
  const [chunks, setChunks] = useState([]);
  const [retrievedResults, setRetrievedResults] = useState([]);

  // Mock data for demonstration
  const sampleChunks = [
    {
      id: 1,
      text: "This is a sample chunk of text that would be stored in the embedding database.",
      tags: ["documentation", "sample", "intro"],
      embedding: [] // This would contain the actual embedding vector
    }
  ];

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    logger.log("Searching for:", searchQuery);
  };

  const handleUpdateText = async (text) => {
    setRawText(text);
  };

  return (
    <Container className="py-4">
      {/* Search Section */}
      <Card className="mb-4">
        <Card.Header>
          <Card.Title>Search Embeddings</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSearchSubmit}>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Enter your query..."
                />
              </Col>
              <Col xs="auto">
                <Button 
                  type="submit" 
                  variant="primary" 
                  className="d-flex align-items-center gap-2"
                >
                  <Search size={16} />
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {/* Main Content Area */}
      <Row className="g-4">
        {/* Text Editor Section */}
        <Col md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Raw Text Editor</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Control
                as="textarea"
                value={rawText}
                onChange={(e) => handleUpdateText(e.target.value)}
                className="mb-3"
                style={{ height: '250px' }}
                placeholder="Enter or edit raw text here..."
              />
              <Button 
                variant="success" 
                className="d-flex align-items-center gap-2"
              >
                <RefreshCw size={16} />
                Recreate Embedding
              </Button>
            </Card.Body>
          </Card>
        </Col>

        {/* Chunks and Tags Section */}
        <Col md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Chunks & Tags</Card.Title>
            </Card.Header>
            <Card.Body>
              {sampleChunks.map((chunk) => (
                <div
                  key={chunk.id}
                  className="border rounded p-3 mb-3 cursor-pointer"
                  onClick={() => setSelectedChunk(chunk)}
                  style={{ cursor: 'pointer' }}
                >
                  <p className="small mb-2">{chunk.text}</p>
                  <div className="d-flex flex-wrap gap-2">
                    {chunk.tags.map((tag) => (
                      <Badge 
                        key={tag}
                        bg="primary"
                        className="d-flex align-items-center gap-1 p-2"
                      >
                        <Tag size={12} />
                        {tag}
                        <Button
                          variant="link"
                          size="sm"
                          className="p-0 text-white"
                        >
                          <X size={12} />
                        </Button>
                      </Badge>
                    ))}
                    <Button
                      variant="light"
                      size="sm"
                      className="d-flex align-items-center gap-1"
                    >
                      <Plus size={12} />
                      Add Tag
                    </Button>
                  </div>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Results Section */}
      {retrievedResults.length > 0 && (
        <Card className="mt-4">
          <Card.Header>
            <Card.Title>Search Results</Card.Title>
          </Card.Header>
          <Card.Body>
            {retrievedResults.map((result) => (
              <div 
                key={result.id} 
                className="border rounded p-3 mb-3"
              >
                <p className="small">{result.text}</p>
                <div className="d-flex flex-wrap gap-2 mt-2">
                  {result.tags.map((tag) => (
                    <Badge
                      key={tag}
                      bg="primary"
                      className="d-flex align-items-center gap-1 p-2"
                    >
                      <Tag size={12} />
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>
            ))}
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default EmbeddingManager;