import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { WebSocketProvider } from './websocket_context';
import { NotificationProvider } from './notification_context';

export const CombinedProvider = ({ children }) => {
  return (
    <WebSocketProvider>
        <NotificationProvider>
            {children}
        </NotificationProvider>
    </WebSocketProvider>
  );
};
