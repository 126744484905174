import React, {useState, useRef, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faArrowUp, faCopy, faPlus, faAngleDown, faListCheck, faLineChart, faKey, faLink, faMinus } from "@fortawesome/free-solid-svg-icons";
import autosize from 'autosize';
import logger from '../util/logger';

const ADDCartItem = (props) => {

    const heading = props.items.content_type;
    const body = props.items.content_body.split('<br>');

    const headingRef = useRef();
    const bodyRef = useRef(); 

    const onDragStart = (e, heading) => {
      props.onDragStart(e, heading);
    };

    const insertContentHandler = (e, heading, body) => {
      props.onInsertContent(e, heading, body);
    };

    const llmSummarizeHandler = (body) => {
      props.onLLMSummarize(body);
    };
    

    return (
        <>
          {/* <li className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center w-auto ${
                              props.items.content_type === "H2" && "ms-2"
                            } ${props.detail === false && props.items.content_type === "P" && "d-none"} ${props.items.content_type === "H3" && "ms-3"} ${props.items.content_type === "H4" && "ms-4"} ${props.items.content_type === "P" && "ms-5"} ${props.disabled.includes(props.items.id) && 'disabled bg-light'}`}> */}
            <span className="badge bg-secondary me-2" ref={headingRef}>{heading}</span>
            <p draggable='true' className='d-flex flex-column m-0 me-auto' style={{'word-break':'break-word'}} ref={bodyRef} onDragStart={event => onDragStart(event, heading)}>
              { body.filter(item => item !== '').length > 1 ?
                body.map(item => 
                  <>
                    <span>{item}</span>
                    <br />
                  </>) :
                  body[0]
              }
            </p>
            {heading === 'P' &&
              <button type="button" className='btn btn-warning btn-sm ms-2' onClick={e => llmSummarizeHandler(body)}><FontAwesomeIcon icon={faListCheck} /></button>
              }
            <button type="button" className='btn btn-primary btn-sm ms-2' onClick={e => insertContentHandler(e, heading, body)}>+</button>
          {/* </li> */}
        </>
    )
};

const ADDQuestionItem = (props) => {

  const body = props.items.title;
  const url = props.items.URL;

  const bodyRef = useRef(); 


  const onDragStart = (e, heading) => {
    props.onDragStart(e, heading);
  };

  const insertContentHandler = (e, heading, body) => {
    props.onInsertContent(e, heading, body);
  };
  

  return (
      <li className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center w-auto ms-5 ${
        props.disabled.includes(props.items._id) && 'disabled bg-light'}`}
        >
        <span className="badge bg-secondary me-2" >P</span>
        <a href={url} target="_blank" className='m-0 me-auto' style={{'word-break':'break-word'}} ref={bodyRef} onDragStart={event => onDragStart(event, 'P')}>{body}</a>
        <button type="button" className='btn btn-primary btn-sm ms-2' onClick={e => insertContentHandler(e, 'P', body)}>+</button>
      </li>
  )
};

const DELCartItem = (props) => {

  const heading = props.items.content_type;
  const body = props.items.content_body;
  const id = props.items.id;
  const headingRef = useRef();
  const bodyRef = useRef(null);

  const delItemHandler = (event) => {
    const heading_item = {
      id: id,
      content_type: headingRef.current.outerText,
      content_body: bodyRef.current.outerText
    }
    props.onDelItem(heading_item);
  };

  const copyItemHandler = (event) => {
    const current_item = {
      id: id,
      content_type: headingRef.current.outerText,
      content_body: bodyRef.current.value
    };
    // logger.log(props.items);
    const copy_item = {
      id: String(Math.random()),
      content_type: headingRef.current.outerText,
      content_body: bodyRef.current.value
    }
    props.onCopyItem(props.items, copy_item);
  };

  const updateChangeHandler = () => {
    const heading_item = {
      id: id,
      content_type: headingRef.current.value,
      content_body: bodyRef.current.value
    };
    props.onUpdateChanges(heading_item);
    autosize(bodyRef.current);
  };




  //contenteditable={props.editable === props.items.id ? 'true' : 'false'}

  return (
      <>
          <span className="badge bg-secondary me-2" ref={headingRef}>{heading}</span>
          <textarea className='m-0 me-auto w-100'  ref={bodyRef} defaultValue={props.items.content_body} onBlur ={updateChangeHandler}/>
          <button type="button" className='btn btn-info btn-sm ms-2' onClick={copyItemHandler}>
            <FontAwesomeIcon icon={faCopy} />
          </button>
          <button type="button" className='btn btn-danger btn-sm ms-2' onClick={delItemHandler}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
      </>
  )
};


const GROUPCartItem = (props) => {

  const [mode, setMode] = useState('Type');
  const [ desc, setDesc] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const no_clusters = useRef();
  const cluster_mode = useRef();
  const group_number_item = useRef();

  const selectGroupHandler = (item) => {
    // logger.log(item.group_name);
    props.onSelectGroup(item);
  };

  const changeLocationHandler = (e, value) => {
    e.preventDefault();
    setMode(value);
    // logger.log(location);
  };

  const furtherSplitHandler = (item) => {
    props.onFurtherSplit(item, parseInt(no_clusters.current.value), mode);
  };

  const onOffHandler = () => {
    setDesc(current => !current);
  };

  const collapseHandler = () => {
    setCollapse(current => !current);
  };

  const countItemHandler = (group) => {
    let count = 0;
    group.map((item) => {
      count += item.keywords.length;
    });
    return count;
  };

  const countVolHandler = (group) => {
    if (typeof group[0] === 'string'){
      let count = 0;
      group.map((item) => {
        count += parseInt(item.split('<<>>')[1]);
      })
      return count.toLocaleString()
    } else if(typeof group[0] === 'object'){
      let count = 0;
        group.map((item) => {
          item.keywords.map((key) => {
            count += parseInt(key.split('<<>>')[1]);
          })
        });
        return count.toLocaleString()
    }
  };

  const saveTxtHandler = (pa,ch,content) => {
    if (ch === ''){
      props.item.display_name = content;
    }else {
      props.item.keywords.map((it) => {
        if(it.group_name === ch){
          it.display_name = content;
        }
      })
    };
    // logger.log(pa,ch,content);
  };

  const GroupValidateHandler = (e) => {
    e.target.value <= 0 && (no_clusters.current.value = 1);
    let total_number = 0;
    typeof(props.item.keywords[0]) !== 'object' ?
    total_number = props.item.keywords.length :
    total_number = countItemHandler(props.item.keywords)

    if (e.target.value > total_number) {
      e.target.value = total_number;
    };
  };

  return (
    <>
      <li className={`list-group-item list-group-item-action d-flex flex-column w-auto p-0 ms-1 mb-2`}
      onClick = {event => selectGroupHandler(props.item)}
      ref={props.provided.innerRef}
      {...props.provided.droppableProps}
      >
        <div className={`d-flex flex-column card justify-content-start ${desc && 'mb-2'} ${props.activeG === props.item.group_name && 'bg-info'}`}
        style={{'backgroundColor': `${props.isDraggingOver ? '#ffc107' : ''}`, 'border-top':'1px solid orange'}}
        >
          <div className='d-flex card-header'>
            {/* <span className='me-auto'>{props.item.group_name}</span> */}
            <input type="text" class="form-control" placeholder={props.item.group_name} onBlur={event => saveTxtHandler(props.item.group_name,'',event.target.value)} defaultValue={props.item.display_name}/>
          </div>
          {/* <textarea className={`m-0 me-auto w-100 ${!desc && 'd-none'}`} style={{'height':'30px'}} onBlur={event => saveTxtHandler(props.item.group_name,'',event.target.value)}/> */}
          <div className='d-flex card-body p-2 justify-content-start'>
            <div className='me-auto align-self-center'>
              <div className="badge bg-primary rounded-pill align-self-center ms-2">
                  <FontAwesomeIcon icon={faKey} className='me-2'/>
                  {typeof(props.item.keywords[0]) !== 'object' ?
                  props.item.keywords.length :
                  countItemHandler(props.item.keywords)
                  }
              </div>
              <div className="badge bg-primary rounded-pill align-self-center ms-2">
                <FontAwesomeIcon icon={faLineChart} className='me-2'/>
                {countVolHandler(props.item.keywords)}
              </div>
            </div>
            <button
              type="button"
              className={`btn btn-light btn-sm me-2 ${typeof(props.item.keywords[0]) !== 'object' && 'd-none'}`}
              onClick={collapseHandler}
            >
              <FontAwesomeIcon icon={faAngleDown} />
            </button>
            <input className="ms-auto me-2" type="number" class="form-control" placeholder="Sub" aria-label="Sub" aria-describedby="button-addon2" style={{"width": "60px"}} ref={no_clusters} onChange={e => GroupValidateHandler(e)}/>
            <div className="btn-group">
              <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                {/* <span class={`${mode !== 'Type' ? 'me-2' : 'visually-hidden'}`}>{mode}</span> */}
                <span>{mode}</span>
              </button>
              <button type="button" className="btn btn-outline-secondary btn-sm" onClick = {event => furtherSplitHandler(props.item)}>Split</button>
              <ul className="dropdown-menu">
                <li onClick={event => changeLocationHandler(event, 'SERP')}><a class="d-flex m-auto dropdown-item" href="#">SERP</a></li>
                <li onClick={event => changeLocationHandler(event, 'Tf-Idf')}><a class="d-flex m-auto dropdown-item" href="#">Tf-Idf</a></li>
                <li onClick={event => changeLocationHandler(event, 'Ada-2')}><a class="d-flex m-auto dropdown-item" href="#">Ada-2</a></li>
              </ul>
            </div>
            {/* <button
              type="button"
              className="btn btn-light btn-sm  me-1"
              onClick={onOffHandler}
            >
              <FontAwesomeIcon icon={faPlus} /> */}
            {/* </button> */}
          </div>
        </div>
      </li>
      {typeof(props.item.keywords[0]) === 'object' && 
        // <ul className="ps-2">
        <>
          {props.item.keywords.map((item, index) => {
            return (
              <Droppable droppableId={`${props.item.group_name}-${item.group_name}`}>
                {(provided, snapshot) => (
                  <>
                    <li className={`list-group-item list-group-item-action d-flex flex-column w-auto ms-3 ${props.activeG === item.group_name && 'bg-light'} ${collapse && 'd-none'}`}
                    onClick = {event => selectGroupHandler(item)}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver = {snapshot.isDraggingOver}
                    style={{'backgroundColor': `${snapshot.isDraggingOver ? '#ffc107' : ''}`, 'margin-bottom': `${index === props.item.keywords.length - 1 ? '10px' : ''}`}}
                    >
                      <div className={`d-flex justify-content-start align-items-center ${desc && 'mb-2'}`}>
                        {/* <span className='me-auto'>{item.group_name}</span> */}
                        <input type="text" class="form-control" placeholder={item.group_name} onBlur={event => saveTxtHandler(props.item.group_name,item.group_name,event.target.value)} defaultValue={item.display_name}/>
                        {/* <button
                          type="button"
                          className="btn btn-light btn-sm  me-1"
                          onClick={onOffHandler}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button> */}
                        <div className="badge bg-secondary rounded-pill align-self-center ms-2">
                            <FontAwesomeIcon icon={faKey} className='me-2'/>
                            {typeof(item.keywords[0]) !== 'object' ?
                            item.keywords.length :
                            countItemHandler(item.keywords)
                            }
                        </div>
                        <div className="badge bg-secondary rounded-pill align-self-center ms-2">
                          <FontAwesomeIcon icon={faLineChart} className='me-2'/>
                          {countVolHandler(item.keywords)}
                        </div>
                      </div>
                      {/* <textarea className={`m-0 me-auto w-100 ${!desc && 'd-none'}`} style={{'height':'30px'}} onBlur={event => saveTxtHandler(props.item.group_name,item.group_name,event.target.value)}/> */}
                    </li>
                    {/* {provided.placeholder} */}
                  </>
                )}
              </Droppable>
            )
          })}
        </>
        // </ul>
      }
    </>
  )
};

const GroupAudit = (props) => {

  const [mode, setMode] = useState('Type');
  const [ desc, setDesc] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const no_clusters = useRef();
  const cluster_mode = useRef();
  const group_number_item = useRef();

  useEffect(() => {
    setCollapse(props.collapseA);
  },[props.collapseA]);

  const selectGroupHandler = (item) => {
    // logger.log(item.group_name);
    props.onSelectGroup(item);
  };

  const changeLocationHandler = (e, value) => {
    e.preventDefault();
    setMode(value);
    // logger.log(location);
  };

  const furtherSplitHandler = (item) => {
    props.onFurtherSplit(item, parseInt(no_clusters.current.value), mode);
  };

  const onOffHandler = () => {
    setDesc(current => !current);
  };

  const collapseHandler = () => {
    setCollapse(current => !current);
  };

  const countItemHandler = (group) => {
    let count = 0;
    group.map((item) => {
      count += item.keywords.length;
    });
    return count;
  };

  const countVolHandler = (group) => {
    if (typeof group[0] === 'string'){
      let count = 0;
      group.map((item) => {
        count += parseInt(item.split('<<>>')[1]);
      })
      return count.toLocaleString()
    } else if(typeof group[0] === 'object'){
      let count = 0;
        group.map((item) => {
          item.keywords.map((key) => {
            count += parseInt(key.split('<<>>')[1]);
          })
        });
        return count.toLocaleString()
    }
  };

  const saveTxtHandler = (pa,ch,content) => {
    if (ch === ''){
      props.item.display_name = content;
    }else {
      props.item.keywords.map((it) => {
        if(it.group_name === ch){
          it.display_name = content;
        }
      })
    };
    // logger.log(pa,ch,content);
  };
  const deleteGroupHandler = (group_name) => {
    props.onDeleteGroup(group_name);
  };

  return (
    <>
      <li className={`list-group-item list-group-item-action d-flex flex-column w-auto p-0 ms-1 mb-2`}
      onClick = {event => selectGroupHandler(props.item)}
      ref={props.provided.innerRef}
      {...props.provided.droppableProps}
      >
        <div className={`d-flex flex-column card justify-content-start ${desc && 'mb-2'} ${props.activeG === props.item.group_name && 'bg-info'}`}
        style={{'backgroundColor': `${props.isDraggingOver ? '#ffc107' : ''}`, 'border-top':'1px solid orange'}}
        >
          <div className='d-flex card-header'>
            {/* <span className='me-auto'>{props.item.group_name}</span> */}
            <input type="text" class="form-control" placeholder={props.item.group_name} onBlur={event => saveTxtHandler(props.item.group_name,'',event.target.value)} defaultValue={props.item.display_name}/>
          </div>
          {/* <textarea className={`m-0 me-auto w-100 ${!desc && 'd-none'}`} style={{'height':'30px'}} onBlur={event => saveTxtHandler(props.item.group_name,'',event.target.value)}/> */}
          <div className='d-flex card-body p-2 justify-content-start'>
            <div className='me-auto align-self-center'>
              <div className="badge bg-primary rounded-pill align-self-center ms-2">
                  <FontAwesomeIcon icon={faLink} className='me-2'/>
                  {props.item.keywords.length}
              </div>
              <div className="badge bg-primary rounded-pill align-self-center ms-2">
                  <FontAwesomeIcon icon={faKey} className='me-2'/>
                  {typeof(props.item.keywords[0]) !== 'object' ?
                  props.item.keywords.length :
                  countItemHandler(props.item.keywords)
                  }
              </div>
              <div className="badge bg-primary rounded-pill align-self-center ms-2">
                <FontAwesomeIcon icon={faLineChart} className='me-2'/>
                {countVolHandler(props.item.keywords)}
              </div>
            </div>
            <button
              type="button"
              className='btn btn-danger btn-sm me-2'
              onClick={e => deleteGroupHandler(props.item.group_name)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <button
              type="button"
              className={`btn btn-light btn-sm me-2 ${typeof(props.item.keywords[0]) !== 'object' && 'd-none'}`}
              onClick={collapseHandler}
            >
              <FontAwesomeIcon icon={(props.collapseA || collapse) ? faAngleDown : faMinus} />
            </button>
          </div>
        </div>
      </li>
      {typeof(props.item.keywords[0]) === 'object' && 
        // <ul className="ps-2">
        <>
          {props.item.keywords.map((item, index) => {
            return (
              <Droppable droppableId={`${props.item.group_name}-${item.group_name}`}>
                {(provided, snapshot) => (
                  <>
                    <li className={`list-group-item list-group-item-action d-flex flex-column w-auto ms-3 ${props.activeG === item.group_name && 'bg-light'} ${(props.collapseA || collapse) ? 'd-none' : 'd-block'}`}
                    onClick = {event => selectGroupHandler(item)}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver = {snapshot.isDraggingOver}
                    style={{'backgroundColor': `${snapshot.isDraggingOver ? '#ffc107' : ''}`, 'margin-bottom': `${index === props.item.keywords.length - 1 ? '10px' : ''}`}}
                    >
                      <div className={`d-flex flex-column ${desc && 'mb-2'}`}>
                        <div className='d-flex'>
                          <input type="text" key={item.display_name} class="form-control" placeholder={item.group_name} onBlur={event => saveTxtHandler(props.item.group_name,item.group_name,event.target.value)} defaultValue={item.display_name}
                            />
                          <div className="badge bg-secondary rounded-pill align-self-center ms-2">
                              <FontAwesomeIcon icon={faKey} className='me-2'/>
                              {typeof(item.keywords[0]) !== 'object' ?
                              item.keywords.length :
                              countItemHandler(item.keywords)
                              }
                          </div>
                          <div className="badge bg-secondary rounded-pill align-self-center ms-2">
                            <FontAwesomeIcon icon={faLineChart} className='me-2'/>
                            {countVolHandler(item.keywords)}
                          </div>
                        </div>
                        <div className='mt-2 ms-2'>
                          <a href={`https://gleads.vn${item.url}`} target='_blank'>{item.url}</a>
                        </div>
                      </div>
                      {/* <textarea className={`m-0 me-auto w-100 ${!desc && 'd-none'}`} style={{'height':'30px'}} onBlur={event => saveTxtHandler(props.item.group_name,item.group_name,event.target.value)}/> */}
                    </li>
                    {/* {provided.placeholder} */}
                  </>
                )}
              </Droppable>
            )
          })}
        </>
        // </ul>
      }
    </>
  )
};

const ROWItem = (props) => {
  return (
    <>
      <tr>
        <th scope="row">{props.counter}</th>
        <td>{props.item}</td>
        <td>Otto</td>
        <td>@mdo</td>
      </tr>
    </>
  )
};


export const DelCartItem = DELCartItem;
export const AddCartItem =  ADDCartItem;
export const AddQuestionItem = ADDQuestionItem;
export const GroupCartItem = GROUPCartItem;
export const RowItem = ROWItem;
export const AuditGroup = GroupAudit;