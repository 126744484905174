import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { getAuthToken } from "../util/token";
import { wsService } from "../util/websocketService";

const WebSocketContext = createContext({
  socket: null,
  messages: [],
  closeSocket: () => {},
  connectSocket: () => {},
});

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState({});

  const connectSocket = useCallback(() => {
    const newSocket = wsService.connect();
    if (newSocket) {
      setSocket(newSocket);
    }
  }, []);

  // Handle message updates
  useEffect(() => {
    const handleMessage = (message) => {
      setMessages(message);
    };

    // Register message handler
    wsService.addMessageHandler(handleMessage);

    // Connect when provider mounts
    connectSocket();

    // Cleanup
    return () => {
      wsService.removeMessageHandler(handleMessage);
      wsService.disconnect();
    };
  }, [connectSocket]);

  const closeSocket = useCallback(() => {
    wsService.disconnect();
    setSocket(null);
    setMessages([]);
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket, messages, closeSocket, connectSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

