import { useState, useMemo, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch, useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload, faGlobe, faLanguage, faSearch, faFilter, faAngleUp, faAngleDown, faPencil,
    faCheckCircle, faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

import { Form, redirect, useActionData, useNavigation } from "react-router-dom";
import {
    CartesianGrid,
    Legend,
    Line,
    AreaChart,
    Area,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
  } from "recharts";
  import moment from "moment";
  import { scaleTime } from "d3-scale";
import { getAuthToken } from "../util/token";
import apiClient from "../api/client";
import logger from "../util/logger";

const dummy_data = [
    ['thiết kế logo', 60500, 8360, 1698, 8493, 'LOW', 32],
    ['thiết kế online', 60500, 8360, 1698, 8493, 'LOW', 32],
]

function KwResearch() {

    const dispatch = useDispatch();

    const [searchKw, setSearchKw] = useState([]);
    const [alert, setAlert] = useState('');
    const [filter, setFilter] = useState([]);
    const [selectedCompetition, setSelectedCompetition] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [ filterRange, setFilterRange] = useState([]);
    const [ volFilterRange, setVolFilterRange] = useState([]);
    const [rankDetail, setRankDetail] = useState([]);
    const [filteredRank, setFilteredRank] = useState([]);
    const [selectKwList, setSelectKwList] = useState([]);

    const [location, setLocation] = useState([]);
    const [locationName, setLocationName] = useState('Global');
    const [language, setLanguage] = useState('');
    const [isValidURL, setIsValidURL] = useState(true);
    const urlSeed = useRef();

    //table pagination
    const [sort,setSort] = useState('');
    const [showResult, setShowResult] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [currentPageRange, setCurrentPageRange] = useState(1);

    //popover chart data
    const [popChartData, setPopChartData] = useState([]);

    //bulk edit
    const group = useRef();
    const domain = useRef();
    const description = useRef();
    const category = useRef();
    const multiFreq = useRef();
    const multiDays = useRef();
    const [country, setCountry] = useState();
    const [bulkMode, setBulkMode] = useState();
    const [importStat, setImportStat] = useState('');
    const [pendingTasks, setPendingTasks] = useState([]);
    const [taskId, setTaskId] = useState('');

    //country geo target
    const [countryList, setCountryList] = useState([]);
    const searchStr = useRef('');
    const [filteredList, setFilteredList] = useState([]);

    const keySearch = useRef();
    const lowerBound = useRef(['','']);
    const upperBound = useRef(['','']);
    const vollowerBound = useRef(['','']);
    const volupperBound = useRef(['','']);

    //get auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    useEffect(() => {
        const input = {}
        Promise.all([
            apiClient.post('/api/manage-tasks', input, {headers: {'X-User': userName}})
            .then (
            ({data}) =>{
                const task = data.filter(item => item.task === 'grouping');
                setPendingTasks(task);
            }),
            apiClient.get('/api/country-geo-target', {headers: {'X-User': userName}})
            .then(
                ({data}) => {
                    setCountryList(data);
                    setFilteredList(data);
                }
            )
        ])
    },[]);

    const locationFilterHandler = (val) => {

        const search = countryList.filter(item => {
            return (
                (val !== '' ? item.Name.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
            )
        });
        setFilteredList(search);
    };

    const isValidHttpUrl = (str) =>{
        try {
          const url = new URL(str);
          if (!['https:','https:'].includes(url.protocol)) return false
          return true;
        } catch (err) {
          return false;
        }
      }

    const keywordResearchHandler = () => {
        // logger.log(isValidHttpUrl(urlSeed.current.value))

        if ((((urlSeed.current.value !== '' && !isValidHttpUrl(urlSeed.current.value)) || urlSeed.current.value === '') && searchKw.length === 0) || language === '') {
            if (urlSeed.current.value !== '' && !isValidHttpUrl(urlSeed.current.value)) {
                setIsValidURL(false);
                setTimeout(() =>{
                    setIsValidURL(true)
                },3*1000);
            };
            // logger.log('invalid')
            return;
        };
        dispatch(showLoading());
        let url = ''
        if(isValidHttpUrl(urlSeed.current.value)) url = urlSeed.current.value;
        const input = {
            location: location,
            language: language,
            keyword_seed: searchKw,
            url_seed: url
        }
        logger.log(input);
        apiClient('/api/generate-keyword-ideas', input, {headers: {'X-User': userName}})
        .then (
          ({data}) =>{
            dispatch(hideLoading());
            if(data.length === 0){
                const m = new bootstrap.Modal(document.getElementById('ModalAlert'));
                m.toggle();
                return
            };
            setRankDetail(data);
            setFilteredRank(data);
            let newPagination = [];
            for (let i = 1; i <= Math.ceil(data.length/showResult); i++){
            // logger.log(i);
            newPagination.push(
                <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                setSelectedPage(i);
                const el = document.querySelectorAll('li[class*=active]')[0];
                el.className = 'page-item';
                document.getElementById(`page-${i}`).className = 'page-item active';
                }}>{i}
                </a>
            )
            };
            setPagination(newPagination);
          }
      )
    };

    useEffect(() => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        let newPagination = [];
        const dummy_var = rankDetail.filter((item, index) => applyFilterHandler(item));
        setFilteredRank(dummy_var);
        // logger.log(dummy_var.length);
        for (let i = 1; i <= Math.ceil(dummy_var.length/showResult); i++){
          newPagination.push(
            <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
              setSelectedPage(i);
              const el = document.querySelectorAll('li[class*=active]')[0];
              el.className = 'page-item';
              document.getElementById(`page-${i}`).className = 'page-item active';
              }}>{i}
            </a>
          )
        };
        setPagination(newPagination);
      },[filterRange, searchKey, volFilterRange, selectedCompetition]);
    
      useEffect(() => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        let newPagination = [];
        for (let i = 1; i <= Math.ceil(rankDetail.length/showResult); i++){
          newPagination.push(
            <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
              setSelectedPage(i);
              const el = document.querySelectorAll('li[class*=active]')[0];
              el.className = 'page-item';
              document.getElementById(`page-${i}`).className = 'page-item active';
              }}>{i}
            </a>
          )
        };
        setPagination(newPagination);
      },[showResult]);
    
    const handleInsertKw = (e) => {
        if(e.key === 'Enter'){
            const kw = e.target.value;
            if (searchKw.length === 10){
                setAlert(true);
                setTimeout(() =>{
                    setAlert(false);
                },3*1000);
                return
            }
            if(searchKw.includes(kw)){
                e.target.value = '';
                return
            };
            setSearchKw( c => [...c, kw]);
            e.target.value = '';
        }
    };
    const clearKwHandler = (kw) => {
        setSearchKw(searchKw.filter(item => item !== kw));
    };
    const clearAllKwsHandler = (e) => {
        e.preventDefault();
        setSearchKw([]);
    };

    const filterHandler = (e, mode) => {
        e.preventDefault();
        setFilter(current => [...current, mode]);
    };
    const filterModeHandler = () => {
        if(filter.includes('high') === true) setFilterRange(current => [lowerBound.current.value,upperBound.current.value]);
        if(filter.includes('low') === true) setFilterRange(current => [lowerBound.current.value,upperBound.current.value]);
        if(filter.includes('volume') === true) setVolFilterRange(current => [vollowerBound.current.value,volupperBound.current.value]);
        if(filter.includes('keyword') === true) {
            const search_key_mode = document.getElementById("kw-search-mode").innerHTML;
            let search_key = keySearch.current.value;
            if (search_key_mode === 'E') {
                search_key = `^${keySearch.current.value}$`;
            };
            setSearchKey(c => search_key)
            };
    };
    const clearFilterHandler = (e, mode) => {
        const newFilter = filter.filter(item => {
            if(mode === 'high'){
                return item !== 'high' && item !== 'low'
            } else {
                return item !== mode
            } 
        });
        setFilter(newFilter);
        logger.log(filter);
        if(mode === 'competition'){
        setSelectedCompetition('');
        }else if (mode === 'high' || mode === 'low'){
        if (filterRange[0] === '' && filterRange[1] === '') return;
        setFilterRange(['','']);
        }else if (mode === 'keyword'){
        setSearchKey('');
        } else if (mode === 'volume'){
        setVolFilterRange(['','']);
        }
    };
    const changeSelectedKwHandler = (e, kw) => {
        if (e.target.checked) {
            if (kw === undefined) {
              const d = document.getElementsByClassName('row-checkbox');
              for ( let i = 0; i < d.length; i++) d[i].checked = true;
              setSelectKwList(filteredRank);
            } else {
              setSelectKwList(c => [...c, kw]);
            }
          } else {
            if (kw === undefined){
              const d = document.getElementsByClassName('row-checkbox');
              for ( let i = 0; i < d.length; i++) d[i].checked = false;
              setSelectKwList([]);
            } else {
              const new_list = selectKwList.filter(item => !(item[0] === kw[0]));
              setSelectKwList(new_list);
            }
          };
        //   logger.log(selectKwList);
    };
    const sortHandler = (mode) => {
        if (mode === 'kw'){
            if (sort !== 'keyword_inc' && sort !== 'keyword_dec'){
              setSort('keyword_inc');
              filteredRank.sort((a,b) => (b[0] > a[0]) ? -1 : (b[0] < a[0]) ? 1 : 0);
            } else if(sort === 'keyword_dec'){
              setSort('keyword_inc');
              filteredRank.sort((a,b) => (b[0] > a[0]) ? -1 : (b[0] < a[0]) ? 1 : 0);
            } else if(sort === 'keyword_inc'){
              setSort('keyword_dec');
              filteredRank.sort((a,b) => (b[0] > a[0]) ? 1 : (b[0] < a[0]) ? -1 : 0);
            };
        } else if (mode === 'competition'){
            if (sort !== 'competition_inc' && sort !== 'competition_dec'){
                setSort('competition_inc');
                filteredRank.sort((a,b) => (b[5] > a[5]) ? -1 : (b[5] < a[5]) ? 1 : 0);
              } else if(sort === 'competition_dec'){
                setSort('competition_inc');
                filteredRank.sort((a,b) => (b[5] > a[5]) ? -1 : (b[5] < a[5]) ? 1 : 0);
              } else if(sort === 'competition_inc'){
                setSort('competition_dec');
                filteredRank.sort((a,b) => (b[5] > a[5]) ? 1 : (b[5] < a[5]) ? -1 : 0);
              };
        } else if (mode === 'vol') {
            if (sort !== 'vol_inc' && sort !== 'vol_dec'){
              setSort('vol_inc');
              filteredRank.sort((a,b) => {
                if(a[1] === null) return 1;
                if(b[1] === null) return -1;
                return (b[1] > a[1]) ? -1 : (b[1] < a[1]) ? 1 : 0
              });
            } else if(sort === 'vol_inc'){
              setSort('vol_dec');
              filteredRank.sort((a,b) => {
                if(a[1] === null) return -1;
                if(b[1] === null) return 1;
                return (b[1] > a[1]) ? 1 : (b[1] < a[1]) ? -1 : 0
              });
            } else if(sort === 'vol_dec'){
              setSort('vol_inc');
              filteredRank.sort((a,b) => {
                if(a[1] === null) return 1;
                if(b[1] === null) return -1;
                return (b[1] > a[1]) ? -1 : (b[1] < a[1]) ? 1 : 0
              });
            };
        } else if (mode === 'low') {
            if (sort !== 'low_inc' && sort !== 'low_dec'){
              setSort('low_inc');
              filteredRank.sort((a,b) => {
                if(a[3] === null) return 1;
                if(b[3] === null) return -1;
                return (b[3] > a[3]) ? -1 : (b[3] < a[3]) ? 1 : 0
              });
            } else if(sort === 'low_inc'){
              setSort('low_dec');
              filteredRank.sort((a,b) => {
                if(a[3] === null) return -1;
                if(b[3] === null) return 1;
                return (b[3] > a[3]) ? 1 : (b[3] < a[3]) ? -1 : 0
              });
            } else if(sort === 'low_dec'){
              setSort('low_inc');
              filteredRank.sort((a,b) => {
                if(a[3] === null) return 1;
                if(b[3] === null) return -1;
                return (b[3] > a[3]) ? -1 : (b[3] < a[3]) ? 1 : 0
              });
            };
        }else if (mode === 'high') {
            if (sort !== 'high_inc' && sort !== 'high_dec'){
              setSort('high_inc');
              filteredRank.sort((a,b) => {
                if(a[4] === null) return 1;
                if(b[4] === null) return -1;
                return (b[4] > a[4]) ? -1 : (b[4] < a[4]) ? 1 : 0
              });
            } else if(sort === 'high_inc'){
              setSort('high_dec');
              filteredRank.sort((a,b) => {
                if(a[4] === null) return -1;
                if(b[4] === null) return 1;
                return (b[4] > a[4]) ? 1 : (b[4] < a[4]) ? -1 : 0
              });
            } else if(sort === 'high_dec'){
              setSort('high_inc');
              filteredRank.sort((a,b) => {
                if(a[4] === null) return 1;
                if(b[4] === null) return -1;
                return (b[4] > a[4]) ? -1 : (b[4] < a[4]) ? 1 : 0
              });
            };
          }
    };
    const changeShowResultHandler= (result) => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        setShowResult(result);
    };
    const applyFilterHandler = (item) => {
        logger.log('re-evaluate');
        return(
          (selectedCompetition !== '' && selectedCompetition !== 'Competition' ? item[5] === selectedCompetition : true) &&
          (searchKey !== '' ? item[0].search(new RegExp(searchKey, 'i')) !== -1 : true) &&
          ((volFilterRange[0] !== '' && item[1] !== null) ? (item[1] >= volFilterRange[0]) : (volFilterRange[0] !== '' && item[1] === null) ? false : true) &&
          ((volFilterRange[1] !== '' && item[1] !== null) ? (item[1] <= volFilterRange[1]) : (volFilterRange[1] !== '' && item[1] === null) ? false : true) &&
          ((filterRange[0] !== '' && filter.includes('low') && item[3] !== null) ? item[3] >= filterRange[0] : (filterRange[0] !== '' && filter.includes('low') && item[3] === null) ? false : true) &&
          ((filterRange[1] !== '' && filter.includes('low') && item[3] !== null) ? item[3] <= filterRange[1] : (filterRange[1] !== '' && filter.includes('low') && item[3] === null) ? false : true) &&
          ((filterRange[0] !== '' && filter.includes('high') && item[4] !== null) ? item[4] >= filterRange[0] : (filterRange[0] !== '' && filter.includes('high') && item[4] === null) ? false : true) &&
          ((filterRange[1] !== '' && filter.includes('high') && item[4] !== null) ? item[4] <= filterRange[1] : (filterRange[1] !== '' && filter.includes('high') && item[4] === null) ? false : true)
        )
      };
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const renderLineTooltipContent = (o) => {
        const { payload = [], label } = o;      
        return (
          <div className="customized-tooltip-content">
            <p className="total">{`${moment(label).format('YYYY-MM-DD')}`}</p>
            <ul className="list">
              {payload !== null &&
              payload.map((entry, index) => (
                <li key={`item-${index}`} style={{ color: entry.color }}>
                  {`${entry.name}: ${entry.value}`}
                </li>
              ))}
            </ul>
          </div>
        );
      };
    const popoverChart = (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"width":"300px", 'height': '180px'}} className="d-flex">
          <ResponsiveContainer width="100%" height="110%">
              <AreaChart
              data={popChartData}
              margin={{
                  top: 10,
                  right: 10,
                  left: -10,
                  bottom: 10,
              }}
              >
                <defs>
                  <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="date"
                    name = "date"
                    tickFormatter = {(unixTime) => moment(unixTime*1000).format('YY-MM')}
                    // {...getXAxisArgsForTimeBasedGraph(popChartData.map((item) => item.date*1000))}
    
                />
                <YAxis 
                    domain={[0, 'auto']}
                    tickFormatter={ num => new Intl.NumberFormat("en").format(num)}
                />
                <Tooltip 
                    content={renderLineTooltipContent}
                    wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                />
                <Area type="monotone" isAnimationActive={false} dataKey="monthly_searches" stroke="#82ca9d" fillOpacity={1} fill="url(#color1)"/>
              </AreaChart>
          </ResponsiveContainer>
        </Popover>
        );
    
    const createTaskHandler = () => {
        if (selectKwList.length === 0) return;
        let li = [];
        if (bulkMode === 'rank'){
            let freq = '';
            freq = multiFreq.current.value;
            if (!['Daily', 'Weekly'].includes(freq)){
                freq = multiDays.current.value;
            };
            li = selectKwList.map((item) => {
                return {
                Keyword: item[0],
                Domain: domain.current.value,
                Country: country,
                Freq: freq,
                Category: category.current.value,
                URL: '',
                dateCreated: new Date().getTime(),
                };
            });
            // logger.log(li);
            apiClient('/api/keyword-update', li, {headers: {'X-User': userName}})
            .then (
                ({data}) =>{
                if (data.acknowledged === true) {
                    setImportStat(true);
                } else {
                    setImportStat(false);
                };
                }
            );
        } else if (bulkMode === 'group'){
            const kw = selectKwList.map(item => {
                return item[0]
            })
            li = {
                topic: group.current.value,
                domain: domain.current.value,
                description: description.current.value,
                keywords: kw,
                task: 'grouping',
                date: new Date().getTime(),
                };
            if(taskId !== '') li['_id'] = taskId;
            // logger.log(li);
            apiClient('/api/create-task', li, {headers: {'X-User': userName}})
            .then (
                ({data}) =>{
                if (data === true) {
                    setImportStat(true);
                } else {
                    setImportStat(false);
                };
                }
            );
        };
    };

    const downloadReportHandler = () => {
        if(filteredRank.length === 0){
          return;
        } else{
            const result = filteredRank.map(item => {
                let monthly_searches = {}
                item[7].map(it => {
                    monthly_searches[new Date([it.date] * 1000).toLocaleDateString()] =  it.monthly_searches;
                });
                let output = {
                    'Keyword': item[0],
                    'Avg. monthly': item[1],
                    'Avg. CPC': item[2],
                    'Low bid': item[3],
                    'High bid': item[4],
                    'Competition level': item[5],
                    'Competition Index': item[6],
                    ...monthly_searches
                };
                return output
            });
            // logger.log(result);
          dispatch(showLoading());
          // logger.log(outline);
          apiClient('/api/download/kw-research-report', result, {headers: {'X-User': userName}})
          .then(
            response => {
              dispatch(hideLoading());
              return response.data;
            }).then((blob) => {
              const url = window.URL.createObjectURL(blob);
              // logger.log(blob);
              const link = document.createElement("a");
              link.download = 'kw_research_report.xlsx';
              link.href = url;
              link.click();
            }).catch(error => {
              console.error(error);
          });
        };
      };

      const bulkEditHandler = (mode) => {
        setBulkMode(mode);
      };

      const countVolume = () => {
        let count = 0;
        filteredRank.map(item => count += parseInt(item[1]));
        logger.log(count);
        return count;
    };

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Keyword Research</h1>
            <div class="input-group mb-3" style={{maxWidth: '67%'}}>
                <span class="input-group-text" id="basic-addon1">Start with an url</span>
                <div className='d-flex align-content-start flex-fill border border-light border-2' >
                    {/* <FontAwesomeIcon icon={faSearch} className='fa-3 align-self-center me-2'/> */}
                    <input type="text" class="form-control" placeholder="Insert a fully qualified url (starts with http or https)" aria-describedby="button-addon2"
                    style={{border: 'none'}}
                    ref={urlSeed}
                    />                        
                </div>
            </div>
            {   !isValidURL ?
                <div className={`mb-3`} style={{color: 'red'}}>
                    Invalid URL
                </div> : null
            }
            <div class="d-flex mb-3">
                <div class="input-group me-3" style={{maxWidth: '75%'}}>
                    <span class="input-group-text" id="basic-addon1">AND/OR Start with keywords</span>
                    <div className='d-flex align-content-start flex-fill border border-light border-2' >
                        {/* <FontAwesomeIcon icon={faSearch} className='fa-3 align-self-center me-2'/> */}
                        <input type="text" class="form-control" placeholder="Use 'Enter' to insert keywords. Max 10 allowed." aria-describedby="button-addon2"
                        style={{border: 'none'}}
                        onKeyDown={e => handleInsertKw(e)}
                        />                        
                    </div>
                    <button class="btn btn-primary" type="button" id="button-addon2"
                    onClick={keywordResearchHandler}
                    >Search</button>
                </div>
                <div class="dropdown me-3 align-self-center">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faGlobe} className='me-2' />
                        {locationName}
                    </button>
                    <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'300px', 'overflow': 'auto', width: '250px'}}>
                        <input type="text" class="form-control" placeholder="Search country..." aria-label="Search country..." aria-describedby="basic-addon1"
                        onChange = {e => locationFilterHandler(e.target.value)}
                        ref={searchStr}
                        />
                        {filteredList.map((item, index) => {
                            return (
                                <li key={index} onClick={e => {setLocationName(item.Name); setLocation([item['Criteria ID']])}}><a class="dropdown-item" href="#">{item.Name}</a></li>
                            )
                        })}
                        {/* <li><a class="dropdown-item" href="#"
                        onClick={e => {e.preventDefault(); setLocation(['2704'])}}
                        >Vietnam</a></li> */}
                        {/* <li><a class="dropdown-item" href="#"
                        onClick={e => {e.preventDefault(); setLocation(['2704'])}}
                        >US</a></li> */}
                    </ul>
                </div>
                <div class="dropdown align-self-center">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="language" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faLanguage} className='me-2' />
                        {language === '' ? 'Language' : language === '1040' ? 'VI' : language === '1000' ? 'EN' : language}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#"
                        onClick={e => {e.preventDefault(); setLanguage('1040')}}
                        >VI</a></li>
                        <li><a class="dropdown-item" href="#"
                        onClick={e => {e.preventDefault(); setLanguage('1000')}}
                        >EN</a></li>
                    </ul>
                </div>
            </div>
            {searchKw.length > 0 &&
            <div class="d-flex flex-wrap mb-3" style={{maxWidth: '75%'}}>
                {
                    searchKw.map((item, index) => {
                        return (
                            <div key={index} className='badge bg-primary py-1 rounded-pill me-2 mb-2 align-self-center'>
                                <span className='me-3 ms-1'>{item}</span>
                                <dutton className='btn me-2 text-light fw-bold p-0'
                                onClick = {e => clearKwHandler(item)}
                                >
                                    X
                                </dutton>
                            </div>
                        )
                    })
                }
                {
                    searchKw.length >= 2 &&
                    <a className='align-self-center' href="#" onClick={e => clearAllKwsHandler(e)}>Clear all</a>
                }  
            </div>
            }
            <div className={`mb-3 ${!alert && 'd-none'}`} style={{color: 'red'}}>
                Max 10 keywords allowed!
            </div>
            {/* Modal Import*/}
            <div class="modal fade" id="ModalAlert" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title me-auto" id="ModalLongTitle">Resources Exhausted</h5>
                    </div>
                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                        <p>The system is busy. Please try again in 30 seconds.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Modal Import*/}
            <div className='my-5'>
                <div className='d-flex mb-2'>
                    <div class="dropdown me-3">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="filter" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faFilter} />
                            <span className='ms-2'>Filter by</span>
                        </button>
                        <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                            <li onClick={e => filterHandler(e, 'keyword')}><a class="dropdown-item" href="#">Keyword</a></li>
                            <li onClick={e => filterHandler(e, 'volume')}><a class="dropdown-item" href="#">Volume</a></li>
                            <li onClick={e => filterHandler(e, 'low')}><a class="dropdown-item" href="#">Low bid</a></li>
                            <li onClick={e => filterHandler(e, 'high')}><a class="dropdown-item" href="#">High bid</a></li>
                            <li onClick={e => filterHandler(e, 'competition')}><a class="dropdown-item" href="#">Competition</a></li>
                        </ul>
                    </div>
                    <div className={`dropdown me-3 ${selectKwList.length === 0 && 'd-none'}`}>
                        <button class="btn btn-secondary dropdown-toggle" type="button"  aria-expanded="false" data-bs-toggle='dropdown'
                        onClick = {e => {setImportStat(''); setBulkMode('')}}
                        >
                            <span className='ms-2'>Bulk Edit</span>
                        </button>
                        <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                            <li onClick={e => bulkEditHandler('group')} data-bs-toggle="modal" data-bs-target="#ModalMultiSelect"><a class="dropdown-item" href="#">Group keywords</a></li>
                            <li onClick={e => bulkEditHandler('rank')} data-bs-toggle="modal" data-bs-target="#ModalMultiSelect"><a class="dropdown-item" href="#">Track rankings</a></li>
                        </ul>
                    </div>
                    {/* Modal Update Multiple Items */}
                    <div class="modal fade" id="ModalMultiSelect" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="ModalLongTitle">Create Task</h5>
                                <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                    <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                    <span className='ms-1'>Success</span>
                                </div>
                                <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                    <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                    <span className='ms-1'>Failed. Pls Retry!</span>
                                </div>
                            </div>
                            <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                <div class="d-flex flex-column mb-4">
                                    {
                                        bulkMode === 'rank' &&
                                    <>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">Country</span>
                                            <select class="form-select" id="country" value={country} onChange={e => setCountry(e.target.value)}>
                                                <option value="US">US</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Vietnam">Vietnam</option>
                                            </select>
                                        </div>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">Category</span>
                                            <input type="text" class="form-control" placeholder="Category" aria-label="Category" aria-describedby="basic-addon1"
                                            ref={category}
                                            />
                                        </div>
                                        <div className="d-flex mb-3">
                                            <div class="input-group me-3">
                                            <span class="input-group-text">Frequency</span>
                                            <select class="form-select" id="multiFreq" ref={multiFreq} onChange={e => {e.target.value === 'Custom' ? document.getElementById('custom-freq').style.display = 'flex' : document.getElementById('custom-freq').style.display = 'none'}}>
                                                <option value="Daily">Daily</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Custom">Custom</option>
                                            </select>
                                            </div>
                                            <div class="input-group" id="custom-freq" style={{'display': 'none'}}>
                                                <span class="input-group-text">Each per</span>
                                                <input type="text" class="form-control" placeholder="Days" aria-label="Days" aria-describedby="basic-addon1" ref={multiDays}/>
                                                <span class="input-group-text">days</span>
                                            </div>
                                        </div>
                                    </>}
                                    {
                                        bulkMode === 'group' &&
                                        <>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text">Add to Pending Task</span>
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="taskID" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Pending task
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    { pendingTasks.map((item, index) => {
                                                            return (
                                                                <li key={index}><a class="dropdown-item" href="#" onClick={e => {e.preventDefault();
                                                                    setTaskId(item._id);
                                                                    document.getElementById('taskID').innerHTML = item.topic;
                                                                    group.current.value = item.topic;
                                                                    domain.current.value = item.domain;
                                                                    description.current.value = item.description;
                                                                }}
                                                                >{item.topic}</a></li>
                                                            )
                                                    })
                                                    }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text">Task name</span>
                                                <input type="text" class="form-control" placeholder="Task name" aria-label="Task name" aria-describedby="basic-addon1"
                                                ref={group}
                                                />
                                            </div>
                                        </>
                                    }
                                    <div class="input-group mb-3">
                                        <span class="input-group-text">Domain</span>
                                        <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1"
                                        ref={domain}
                                        />
                                    </div>
                                    {
                                        bulkMode === 'group' &&
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">Description</span>
                                            <textarea class="form-control" placeholder="Task description" aria-label="Task description" aria-describedby="basic-addon1"
                                            ref={description}
                                            />
                                        </div>
                                    } 
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className={`btn btn-primary ${importStat === true && 'disabled'}`}
                                onClick = {createTaskHandler}
                                >Create</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal Update Multiple Items */}
                    <div className={`d-flex ${filter.length === 0 && 'd-none'}`}>
                        <div class={`input-group flex-nowrap me-2 ${filter.includes('keyword') ===false && 'd-none'}`}>
                            <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'keyword')}>X</button>
                            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="kw-search-mode" data-bs-toggle="dropdown" aria-expanded="false">M</button>
                            <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("kw-search-mode").innerHTML = "E"}}>E</a></li>
                            <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("kw-search-mode").innerHTML = 'B'}}>B</a></li>
                            </ul>
                            <input type="text" class="form-control" placeholder="Keyword Search..." aria-label="Keyword Search..." aria-describedby="addon-wrapping" ref={keySearch}/>
                        </div>

                        <div class={`input-group flex-nowrap me-2 w-auto ${filter.includes('competition') ===false && 'd-none'}`}>
                            <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'competition')}>X</button>
                            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className='ms-2'>{`${selectedCompetition === '' ? 'Competition' : selectedCompetition}`}</span>
                            </button>
                            <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" href="#"
                                onClick={e => {e.preventDefault(); setSelectedCompetition('LOW')}}>Low</a></li>
                                <li><a class="dropdown-item" href="#"
                                onClick={e => {e.preventDefault(); setSelectedCompetition('MEDIUM')}}>Medium</a></li>
                                <li><a class="dropdown-item" href="#"
                                onClick={e => {e.preventDefault(); setSelectedCompetition('HIGH')}}>High</a></li>
                            </ul>
                        </div>
                        <div class={`input-group flex-nowrap me-2 ${filter.includes('high') === false && filter.includes('low') ===false && 'd-none'}`}>
                            {/* <span class="input-group-text" id="addon-wrapping">X</span> */}
                            <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'high')}>X</button>
                            <input type="text" class="form-control" placeholder="From" aria-label="From" aria-describedby="addon-wrapping" ref={lowerBound}/>
                            <input type="text" class="form-control" placeholder="To" aria-label="To" aria-describedby="addon-wrapping" ref={upperBound}/>
                        </div>
                        <div class={`input-group flex-nowrap me-2 ${filter.includes('volume') === false && 'd-none'}`}>
                            <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="volume" type="button" onClick={e => clearFilterHandler(e, 'volume')}>X</button>
                            <input type="text" class="form-control" placeholder="From" aria-label="From" aria-describedby="addon-wrapping" ref={vollowerBound}/>
                            <input type="text" class="form-control" placeholder="To" aria-label="To" aria-describedby="addon-wrapping" ref={volupperBound}/>
                        </div>
                        <button class="btn btn-primary ms-2" type="button" aria-expanded="false"
                        onClick={filterModeHandler}
                        >
                        Apply
                        </button>
                    </div>
                    <button class="btn btn-primary ms-auto" type="button" aria-expanded="false" onClick={downloadReportHandler}>
                        <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                        Download
                    </button>
                </div>
                <div className="overflow-auto mb-3" style={{"height":"500px"}}>
                    <table class="table table-striped" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className="table-primary sticky-top" style={{'z-index':'9'}}>
                            <tr>
                            <th scope="col" style={{'width':'5%'}}>
                                <div>
                                    <input class="form-check-input" type="checkbox" id='master-checkbox' aria-label="..." onChange={e => {changeSelectedKwHandler(e)}}/>
                                </div>
                            </th>
                            <th scope="col" style={{'width':'5%'}}>#</th>
                            <th scope="col" style={{'width':'25%'}}>
                                <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('kw')}>
                                    <span className='me-2 fw-bold'>Keyword </span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'keyword_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'keyword_dec' ? '#000' : '#6c757d'}`}}/>
                                </button>
                            </th>
                            <th scope="col" style={{'width':'10%'}}>
                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('vol')}>
                                    <span className='me-2 fw-bold'>Avg. Search</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'vol_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'vol_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                </button>
                            </th>
                            <th scope="col" style={{'width':'25%'}} >Search Trend</th>
                            <th scope="col" style={{'width':'12%'}}>
                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('competition')}>
                                    <span className='me-2 fw-bold'>Competition</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'competition_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'competition_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                </button>
                            </th>
                            <th scope="col" style={{'width':'8%'}}>
                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('low')}>
                                    <span className='me-2 fw-bold'>Low Bid</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'low_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'low_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                </button>
                            </th>
                            <th scope="col" style={{'width':'10%'}}>
                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('high')}>
                                    <span className='me-2 fw-bold'>High Bid</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'high_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'high_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                </button>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {useMemo(() =>
                            filteredRank.slice(showResult*(selectedPage-1),showResult*selectedPage).map((item, index) =>{
                                return (
                                    <tr key={index}>
                                        <th><div>
                                                <input class="form-check-input row-checkbox" type="checkbox" value="" aria-label="..." 
                                                onChange={e => {changeSelectedKwHandler(e, item)}}
                                                />
                                            </div>
                                        </th>
                                        <th scope="row">{(index+1)+(selectedPage-1)*showResult}</th>
                                        <td>{item[0]}</td>
                                        <td>{numberWithCommas(item[1])}</td>
                                        <td>
                                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={popoverChart}>
                                            <div style={{height: '50px'}}
                                            onMouseEnter={e =>setPopChartData(item[7])}
                                            >
                                                <ResponsiveContainer>
                                                <AreaChart
                                                width={80}
                                                height={50}
                                                data={item[7]}
                                                >
                                                    <defs>
                                                        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <Area type="monotone" isAnimationActive={false} dataKey="monthly_searches" stroke="#82ca9d" fillOpacity={1} fill="url(#color1)"/>
                                                </AreaChart>
                                            </ResponsiveContainer>
                                            </div>
                                        </OverlayTrigger>
                                        </td>
                                        <td>{item[5]}</td>
                                        <td>{numberWithCommas(item[3])}</td>
                                        <td>{numberWithCommas(item[4])}</td>
                                    </tr>
                                )
                            }),[filteredRank, sort, selectedPage, showResult, selectKwList, popChartData])
                            }
                        </tbody>
                    </table>
                    <h4 className={`text-muted text-center ${rankDetail.length > 0 && 'd-none'}`}>No Records founded in selected period!</h4>
                </div>
                <div className="d-flex">
                    <div>
                        <span className='me-4'>Total keywords: <b>{numberWithCommas(filteredRank.length)}</b></span>
                        <span>Total volume: <b>{numberWithCommas(countVolume())}</b></span>
                    </div>
                    <div class="input-group mb-3 me-3 ms-auto w-25">
                    <label class="input-group-text" for="inputGroupSelect01">Results per page</label>
                    <select class="form-select" id="inputGroupSelect01" onChange={e => changeShowResultHandler(e.target.value)}>
                        <option selected value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                    </div>
                    <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class={`page-item ${currentPageRange === 1 && 'disabled'}`}
                        onClick = {e => {
                        e.preventDefault();
                        setCurrentPageRange(current => {
                            if(current > 1){
                            return current -1
                            }
                            return current;
                            })}}
                        ><a class="page-link" href="#">Previous</a></li>
                        {pagination.map((item, index) => {return(
                        <li  key={index+1} id={`page-${index+1}`} className={`page-item ${index+1 === selectedPage && 'active'} ${(index+1 > 10*currentPageRange || index + 1 <= 10*(currentPageRange-1)) && 'd-none'}`}>
                            {item}
                        </li>
                        )})
                        }
                        <li
                        class={`page-item ${(pagination.length <= 10 || currentPageRange + 1 > Math.ceil(pagination.length/10)) && 'disabled'}`}
                        onClick = {e => {
                        e.preventDefault();
                        setCurrentPageRange(current => {
                            if(current + 1 <= Math.ceil(pagination.length/10)) {
                            return (current +1);
                            }
                            return current;
                        })}
                        }
                        ><a class="page-link" href="#">Next</a></li>
                    </ul>
                    </nav>
                
              </div>
            </div>
        </div>
    )
}

export default KwResearch