import { useState, useRef, Fragment, useEffect } from 'react';
import { useDispatch } from "react-redux";
import {showLoading, hideLoading } from 'react-redux-loading-bar';

import Menu from "../components/menu";
import Head from "../components/loading-bar";
import DatePicker from "../components/datepick";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { OverlayTrigger, Popover } from 'react-bootstrap';



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faArrowLeft, faArrowRight, faFilter
} from "@fortawesome/free-solid-svg-icons";
import { getAuthToken } from '../util/token';
import apiClient from '../api/client';
import logger from '../util/logger';

function KPIReport() {

    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [ team, setTeam] = useState("Team")
    // const startEnd = useRef(`${start.current.value} - ${end.current.value}`)
    const dispatch = useDispatch();

    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const applyDateRangeHandler = (start, end) => {
        logger.log(start, end,"kpi report");
        setStart(c => start);
        setEnd(c => end);
        logger.log(document.getElementById("startEnd"));
        document.getElementById("startEnd").value = `${start} - ${end}`;
    };

    const datePicker = (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"1000px", 'border': 'none'}}>
            <DatePicker 
                start={start}
                end={end}
                applyDateRangeHandler = {applyDateRangeHandler}
            />
        </Popover>
    );

    const exportFileHandler = async (e, start, end, team) => {
        e.preventDefault();
        if (team === "Team" || ((start === "" || end === "") && team ==="MD") ) {
            return;
        };
        const date_obj = {
            start: ((new Date(start)).toLocaleDateString('en-US')).toString().split("/"), // m/d/yyyy
            end: ((new Date(end)).toLocaleDateString('en-US')).toString().split("/"), // m/d/yyyy
            team: team
        };
        logger.log(date_obj);

        dispatch(showLoading());
        try {
            const response = await apiClient.post('/api/download/kpi-report', date_obj, {
                headers: {
                    'X-User': userName,
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                },
                responseType: 'blob' // Tell Axios to expect binary data
            });
            
            const blob = new Blob([response.data], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = URL.createObjectURL(blob);
            dispatch(hideLoading());
            // Create temporary link and trigger download
            const link = document.createElement('a');
            link.href = url;
            link.download = 'kpi-report.xlsx'; // Or appropriate file extension
            document.body.appendChild(link);
            link.click();
            
            // Cleanup
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          } catch (error) {
            console.error('Error downloading report:', error);
          };

    };

   return (
        <div className="d-flex flex-column h-100">
            <h1 className="text-center my-4">KPI Export Template</h1>
            <div className="d-flex">
                    <div class="dropdown pe-2 ms-auto">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faFilter} />
                            <span className='ms-2'>{team}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li onClick={e => {e.preventDefault(); setTeam('MD')}}><a class="dropdown-item" href="#">MD</a></li>
                            <li onClick={e => {e.preventDefault(); setTeam('Acc.')}}><a class="dropdown-item" href="#">Acc.</a></li>
                            <li onClick={e => {e.preventDefault(); setTeam('SEO')}}><a class="dropdown-item" href="#">SEO</a></li>
                        </ul>
                    </div>
                    <div class="input-group pe-3 w-auto">
                        <span class="input-group-text" id="basic-addon1">Date range</span>
                        <OverlayTrigger trigger={["click"]} placement="bottom" overlay={datePicker} rootClose container={this} containerPadding={20}>
                            <input type="text" className="form-control" placeholder="from - to" id="startEnd"/>
                        </OverlayTrigger>
                    </div>
                    <button class="btn btn-primary" type="button" aria-expanded="false" onClick={event => exportFileHandler(event,start,end, team)}>
                        <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                        Download
                    </button>

            </div>
        </div>
   );
};

export default KPIReport;