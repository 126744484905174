import { useState, useRef, Fragment, useEffect, useMemo } from 'react';
import Menu from "../components/menu";

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import SERPDatePicker from "../components/serpdatepick";

import { OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faCalendar, faAngleUp, faAngleDown, faTimesCircle, faLineChart, faEye, faCheck, faArrowUp, faArrowDown, faCircle
} from "@fortawesome/free-solid-svg-icons";

import {showLoading, hideLoading } from 'react-redux-loading-bar';
import Head from "../components/loading-bar";
import { useDispatch } from "react-redux";

import {
    CartesianGrid,
    Legend,
    Bar,
    BarChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Cell
  } from "recharts";
import moment from "moment";
import Papa from 'papaparse';
import { getAuthToken } from '../util/token';
import apiClient from '../api/client';
import logger from '../util/logger';
  
function SerpVolatility() {
    const [filter, setFilter] = useState([]);
    const [ date, setDate] = useState(['msg','']);
    const [filterRange, setFilterRange] = useState(['','']);
    const [searchKey, setSearchKey] = useState('');
    const [sort,setSort] = useState('');
    const [showResult, setShowResult] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [currentPageRange, setCurrentPageRange] = useState(1);
    const [ appliedCategory, setAppliedCategory] = useState('');
    const [ searchURL, setSearchURL] = useState('');

    // date picker
    const [startDate, setStartDate] = useState((new Date()).getTime());
    const [endDate, setEndDate] = useState((new Date()).getTime());
    const [startDateComp, setStartDateComp] = useState('');
    const [endDateComp, setEndDateComp] = useState('');
    const [ comparisonMode, setComparisonMode] = useState(false);
    const [ activePeriod, setActivePeriod] = useState('today');
    const [ trackingDomains, setTrackingDomains] = useState([]);

    const urlSearch = useRef('');
    const [ domain, setDomain] = useState('gleads.vn');
    const [ country, setCountry ] = useState('Country');
    const [ appliedCountry, setAppliedCountry ] = useState('Country');

    //Volatility Secion
    const[winLose, setWinLose] = useState([]);
    const [ selectedCategory, setSelectedCategory] = useState('');
    const [category,setCategory] = useState([]);
    const [mode, setMode] = useState('Type');
    const [kSearch, setKSearch] = useState('');
    const [uSearch, setUSearch] = useState('');
    const [ selectedBar, setSelectedBar] = useState(0);
    const [filteredRank, setFilteredRank] = useState([]);

    // get auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const dispatch = useDispatch();


    const applyDateRangeHandler = (start, end, startcomp, endcomp, domain, compstat, category, keyword, url, domain_cat_list, filter_mode) => {
      // logger.log(filter_mode);
      dispatch(showLoading());
      setSelectedBar(0);
      setComparisonMode(compstat);
      setDomain(domain);
      setCategory(domain_cat_list);
      setSelectedCategory(category);
      setMode(filter_mode);
      setKSearch(keyword);
      setUSearch(url);
      let unix_start_time = (new Date(start)).getTime();
      let unix_end_time = (new Date(end)).getTime() + 86400*1000;

      let unix_start_comp = (new Date(startcomp)).getTime();
      let unix_end_comp = (new Date(endcomp)).getTime() + 86400*1000;
      logger.log('unix_end_comp: ',unix_end_comp)

      if (startcomp === '' || endcomp === ''){
        unix_start_comp = '';
        unix_end_comp = '';
      };
      

      setStartDate(c => unix_start_time);
      setEndDate(c => unix_end_time);
      setStartDateComp( c => unix_start_comp);
      setEndDateComp( c => unix_end_comp);
      // logger.log(document.getElementById("startEnd"));
      document.getElementById("startEnd").value = `${start} - ${end}`;

      if(isNaN(unix_start_time) && isNaN(unix_end_time)) {
        unix_start_time = '';
        unix_end_time = '';
      }else if(isNaN(unix_end_time)){
        unix_end_time = '';
      } else if(isNaN(unix_start_time)){
        unix_start_time = '';
      };
      logger.log(unix_start_time, unix_end_time, unix_start_comp, unix_end_comp);
      
      const input = {
        domain: domain,
        start: unix_start_time,
        end: unix_end_time,
        mode: filter_mode
      };

      if (filter_mode === 'Category'){
        input.category = category;
      } else if (filter_mode === 'Keywords'){
        input.keyword = keyword;
      } else if (filter_mode === 'URL'){
        input.url = url;
      };
      logger.log(input);

      apiClient.post('/api/volatility-index', input, {headers: {'X-USer': userName}})
      .then (
              ({data}) =>{
                logger.log(data);
                data.sort((a,b) => a.date - b.date);
                // logger.log(data[0]['serp']);
                setWinLose(data);
                dispatch(hideLoading());
              }
          );
    };


    const datePicker = useMemo(() => {
      logger.log('re-render date picker');
      return (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"1000px", 'border': 'none'}}>
          <SERPDatePicker 
              applyDateRangeHandler = {applyDateRangeHandler}
              start = {startDate}
              end = {endDate}
              startComp = {startDateComp}
              endComp = {endDateComp}
              domain={domain}
              comparisonMode = {comparisonMode}
              domain_cat={category}
              selected_category = {selectedCategory}
              filter_mode = {mode}
              kw = {kSearch}
              url = {uSearch}
              activePeriod = {activePeriod}
              trackingDomains = {trackingDomains}
          />
      </Popover>
      )
    },[startDate, endDate, startDateComp, endDateComp, domain, mode, kSearch, uSearch, activePeriod, selectedCategory, trackingDomains]);

    useEffect(() => {

        let c_date = new Date();

        let c_day = c_date.getDate();
        let c_month = c_date.toLocaleString('en-US', {month: 'short'});
        let c_year = c_date.getFullYear();

        let c = `${c_month} ${c_day}, ${c_year}`;
        document.getElementById("startEnd").value = `${c} - ${c}`;

        apiClient.get('/api/tracking-domains', {headers: {'X-User': userName}})
        .then (
                ({data}) =>{
                setTrackingDomains(data);
                }
            );

        const input = {
          domain: domain,
          start: startDate,
          end: endDate,
          mode: '',
        };

        apiClient.post('/api/volatility-index', input, {headers: {'X-USer': userName}})
        .then (
                ({data}) =>{
                  data.sort((a,b) => a.date - b.date);
                  logger.log(data);
                  setWinLose(data);
                }
            ).catch(error => {
              console.error(error);
          });
        
    },[]);


    useEffect(() => {
      setFilteredRank([]);
      setCurrentPageRange(1);
      setSelectedPage(1);
      let newPagination = [];
      // let filtered_range = rankDetail.filter((item, index) => applyFilterHandler(item));
      let filtered_range = [];
      if (winLose.length > 0 && Array.isArray(winLose[0]['win_win_change'])) {
        filtered_range = (winLose[selectedBar]['serp']).filter((item, index) => applyFilterHandler(item));
        setFilteredRank(filtered_range);
      };
      for (let i = 1; i <= Math.ceil(filtered_range.length/showResult); i++){
        newPagination.push(
          <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
            setSelectedPage(i);
            const el = document.querySelectorAll('li[class*=active]')[0];
            el.className = 'page-item';
            document.getElementById(`page-${i}`).className = 'page-item active';
            }}>{i}
          </a>
        )
      };
      setPagination(newPagination);
    },[searchURL, showResult, selectedCategory, selectedBar, winLose]);



    const BarCustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
          return (
          <div className="custom-tooltip">
              <p className="label">{`Date: ${moment(label).format('YYYY-MM-DD')}`}</p>
              <p className="desc">{`Index: ${payload[0].value}`}</p>
          </div>
          );
          }
      };

    const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

    const getPercent = (value, total) => {
        const ratio = total > 0 ? value / total : 0;

        return toPercent(ratio, 2);
    };

    const filterHandler = (e, mode) => {
      e.preventDefault();
      setFilter(current => [...current, mode]);
      logger.log(filterRange, searchKey);
      // if (mode === 'Keywords'){
      //     setFilterRange(['','']);
      // }else if (mode === 'No. of Keywords'){
      //     setSearchKey('');
      // }
    };

    const sortHandler = (mode) => {
      if (mode === 'pos') {
        if (sort !== 'pos_inc' && sort !== 'pos_dec'){
          setSort('pos_inc');
          filteredRank.sort((a,b) => {
            if(a[1] === null) return 1;
            if(b[1] === null) return -1;
            return (parseInt(b[1]) > parseInt(a[1])) ? -1 : (parseInt(b[1]) < parseInt(a[1])) ? 1 : 0
          });
        } else if(sort === 'pos_inc'){
          setSort('pos_dec');
          filteredRank.sort((a,b) => {
            if(a[1] === null) return -1;
            if(b[1] === null) return 1;
            return (parseInt(b[1]) > parseInt(a[1])) ? 1 : (parseInt(b[1]) < parseInt(a[1])) ? -1 : 0
          });
        } else if(sort === 'pos_dec'){
          setSort('pos_inc');
          filteredRank.sort((a,b) => {
            if(a[1] === null) return 1;
            if(b[1] === null) return -1;
            return (parseInt(b[1]) > parseInt(a[1])) ? -1 : (parseInt(b[1]) < parseInt(a[1])) ? 1 : 0
          });
        };
      } else if (mode === 'prev_pos') {
        if (sort !== 'prev_pos_inc' && sort !== 'prev_pos_dec'){
          setSort('prev_pos_inc');
          filteredRank.sort((a,b) => {
            if(a[2] === null) return 1;
            if(b[2] === null) return -1;
            return (parseInt(b[2]) > parseInt(a[2])) ? -1 : (parseInt(b[2]) < parseInt(a[2])) ? 1 : 0
          });
        } else if(sort === 'prev_pos_inc'){
          setSort('prev_pos_dec');
          filteredRank.sort((a,b) => {
            if(a[2] === null) return -1;
            if(b[2] === null) return 1;
            return (parseInt(b[2]) > parseInt(a[2])) ? 1 : (parseInt(b[2]) < parseInt(a[2])) ? -1 : 0
          });
        } else if(sort === 'prev_pos_dec'){
          setSort('prev_pos_inc');
          filteredRank.sort((a,b) => {
            if(a[1] === null) return 1;
            if(b[1] === null) return -1;
            return (parseInt(b[2]) > parseInt(a[2])) ? -1 : (parseInt(b[2]) < parseInt(a[2])) ? 1 : 0
          });
        };
      } else if (mode === 'change') {
        if (sort !== 'change_inc' && sort !== 'change_dec'){
          setSort('change_inc');
          filteredRank.sort((a,b) => {
            if(a[3] === null) return 1;
            if(b[3] === null) return -1;
            return (parseInt(b[3]) > parseInt(a[3])) ? -1 : (parseInt(b[3]) < parseInt(a[3])) ? 1 : 0
          });
        } else if(sort === 'change_inc'){
          setSort('change_dec');
          filteredRank.sort((a,b) => {
            if(a[2] === null) return -1;
            if(b[2] === null) return 1;
            return (parseInt(b[3]) > parseInt(a[3])) ? 1 : (parseInt(b[3]) < parseInt(a[3])) ? -1 : 0
          });
        } else if(sort === 'change_dec'){
          setSort('change_inc');
          filteredRank.sort((a,b) => {
            if(a[1] === null) return 1;
            if(b[1] === null) return -1;
            return (parseInt(b[3]) > parseInt(a[3])) ? -1 : (parseInt(b[3]) < parseInt(a[3])) ? 1 : 0
          });
        };
      };
  };

  const clearFilterHandler = (e, mode) =>{
    const newFilter = filter.filter(item => item !== mode);
    setFilter(newFilter);
    if (mode === 'URL'){
      setSearchURL('');
  };
};

  const changeShowResultHandler= (result) => {
    setCurrentPageRange(1);
    setSelectedPage(1);
    setShowResult(result);
  };



  const filterModeHandler = () => {
    if(filter.includes('URL') === true) {
      const search_url_mode = document.getElementById("url-search-mode").innerHTML;
      let search_url = urlSearch.current.value;
      if (search_url_mode === 'E') {
        search_url = `^${search_url}$`;
      };
      logger.log(search_url);
      setSearchURL(c => search_url);
    };
  };


  const applyFilterHandler = (item) => {
    logger.log('re render');
    return(
      (searchURL !== '' ? item[0].search(new RegExp(searchURL, 'i')) !== -1 : true));
  };

  const updateRankingHandler = (start, end) => {
    logger.log(start, end);
    if(isNaN(start) && isNaN(end)) {
      start = '';
      end = '';
    }else if(isNaN(end)){
      end = '';
    } else if(isNaN(start)){
      start = '';
    }


  };
  
    const KwRankHandler = (kw, url) => {
      // let start = Math.floor(new Date(startDate.current.value).getTime());
      // let end = Math.floor(new Date(endDate.current.value).getTime())
      let start = startDate;
      let end = endDate;

      logger.log(appliedCountry, appliedCategory);
      if(isNaN(start) && isNaN(end)) {
        start = '';
        end = '';
      }else if(isNaN(end)){
        end = '';
      } else if(isNaN(start)){
        start = '';
      }
      const input = {
        'start': start,
        'end': end,
        'keyword': kw,
        'url': url
      }
    };

    const downloadRankReportHandler = (rank) => {
      if(rank.length === 0){
        return;
      } else{
        dispatch(showLoading());

        const fields = ['URL','Current Rank', 'Prev Rank', 'Change'];
        const csv = Papa.unparse({
          'data':rank,
          'fields': fields
        });
        // logger.log(csv);
        const blob = new Blob([csv]);

        const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
        // logger.log(blob);
        const link = document.createElement("a");
        link.download = 'serp.csv';
        link.href = url;
        link.click();

      };
    };

    const handleBarClick = (data, index) => {
      setSelectedBar(index);
      // logger.log(index);
      // data.setState({
      //   activeIndex: index,
      // })
    };


    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">SERP Volatility</h1>
            <div class="d-flex dropdown mb-4 align-self-end">
                <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faCircle} style={{'color':'green'}}/>
                    <span className='ms-2 fw-bold fs-6'>{domain}</span>
                </div>
                <div class="input-group ps-3 w-auto">
                  <span class="input-group-text" id="basic-addon1">Date range</span>
                  <OverlayTrigger trigger={["click"]} placement="bottom" overlay={datePicker} rootClose container={this} containerPadding={20}>
                    <input type="text" className="form-control" placeholder="from - to" id="startEnd"/>
                  </OverlayTrigger>
                </div>
            </div>
            <div className='d-flex flex_column mb-4'>
              <div className='d-flex flex-column col-md-6'>
                <h5 className="mb-4">Volatility Index</h5>

                  <ResponsiveContainer width="100%" height="80%">
                    <BarChart
                    width={500}
                    height={300}
                    data={winLose}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                          dataKey="date"
                          name = "date"
                          tickFormatter = {(unixTime) => moment(unixTime).format('YY-MM-DD')}
                          // {...getXAxisArgsForTimeBasedGraph(data.map((item) => item.date))}

                      />
                      <YAxis 
                          domain={[0, 10]}
                      />
                      <Tooltip 
                          content={<BarCustomTooltip />}
                          wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                      />
                      {/* <Legend legendType='none'/> */}
                      <Bar dataKey="index" onClick = {handleBarClick}>
                        {winLose.map((entry, index) => (
                          <Cell cursor="pointer" fill={index === selectedBar ? '#82ca9d' : '#8884d8'} key={`cell-${index}`} />
                        ))}
                      </Bar>
                    </BarChart>
                </ResponsiveContainer>
              </div>
              <div className='d-flex flex-column col-md-6' style={{'height': '400px'}}>
              <h5 className="mb-4">Top Winners/Losers</h5>
                <div className='d-flex overflow-auto'>
                  <table class="table table-striped" style={{'table-layout':'fixed', 'word-wrap': 'break-word', 'font-size': '13px'}}>
                    <thead className="table-primary sticky-top">
                      {mode !== 'Keywords' ?
                        <tr>
                          <th scope="col" style={{'width':'5%'}}>#</th>
                          <th scope="col" style={{'width':'30%'}}>Dimension</th>
                          <th scope="col" style={{'width':'8%'}}>UP</th>
                          <th scope="col" style={{'width':'8%'}}>Pct UP</th>
                          <th scope="col" style={{'width':'8%'}}>DOWN</th>
                          <th scope="col" style={{'width':'10%'}}>Pct DOWN</th>
                        </tr> : 
                        <tr>
                          <th scope="col" style={{'width':'5%'}}>#</th>
                          <th scope="col" style={{'width':'50%'}}>Dimension</th>
                          <th scope="col" style={{'width':'15%'}}>Current</th>
                          <th scope="col" style={{'width':'15%'}}>Previous</th>
                          <th scope="col" style={{'width':'15%'}}>Change</th>
                        </tr>
                      }
                    </thead>
                    <tbody>
                        <tr>
                          <td colspan='6' className="table-success fw-bold">Top 5 Winners</td>
                        </tr>
                        {
                          winLose.length > 0 &&  mode !== 'Keywords' &&
                          Object.keys(winLose[selectedBar]['win_win_count']).map((item, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{index+1}</th>
                                <td scope="col">{item}</td>
                                <td scope="col" style={{'color':'green'}}>{winLose[selectedBar]['win_win_count'][item]}</td>
                                <td scope="col" style={{'color':'green'}}>{winLose[selectedBar]['win_win_change'][item]} <FontAwesomeIcon icon={faArrowUp} /></td>
                                <td scope="col" style={{'color':'red'}}>{winLose[selectedBar]['win_lose_count'][item]}</td>
                                <td scope="col" style={{'color':'red'}}>{winLose[selectedBar]['win_lose_change'][item]} <FontAwesomeIcon icon={faArrowDown} /></td>
                              </tr>
                            )
                          })}
                          { winLose.length > 0 && Array.isArray(winLose[0]['win_win_change']) &&
                          winLose[selectedBar]['win_win_change'].map((item, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{index+1}</th>
                                <td scope="col">{item[0]}</td>
                                <td scope="col">{item[1]}</td>
                                <td scope="col">{item[2]}</td>
                                <td scope="col" style={{'color':'green'}}>{item[3]} <FontAwesomeIcon icon={faArrowUp} /></td>
                              </tr>
                            )
                          })
                        }
                        <tr>
                          <td colspan='6' className="table-danger fw-bold">Top 5 Losers</td>
                        </tr>
                        {
                        winLose.length > 0 && mode !== 'Keywords' &&
                        Object.keys(winLose[selectedBar]['lose_lose_count']).map((item, index) => {
                          // logger.log('selected bar', selectedBar);
                          return (
                            <tr key={index}>
                              <th scope="row">{index+1}</th>
                              <td scope="col">{item}</td>
                              <td scope="col" style={{'color':'green'}}>{winLose[selectedBar]['lose_win_count'][item]}</td>
                              <td scope="col" style={{'color':'green'}}>{winLose[selectedBar]['lose_win_change'][item]} <FontAwesomeIcon icon={faArrowUp} /></td>
                              <td scope="col" style={{'color':'red'}}>{winLose[selectedBar]['lose_lose_count'][item]}</td>
                              <td scope="col" style={{'color':'red'}}>{winLose[selectedBar]['lose_lose_change'][item]} <FontAwesomeIcon icon={faArrowDown} /></td>
                            </tr>
                          )
                        })}
                        {winLose.length > 0 && Array.isArray(winLose[0]['win_win_change']) &&
                        winLose[selectedBar]['lose_lose_change'].map((item, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">{index+1}</th>
                              <td scope="col">{item[0]}</td>
                              <td scope="col">{item[1]}</td>
                              <td scope="col">{item[2]}</td>
                              <td scope="col" style={{'color':'red'}}>{item[3]} <FontAwesomeIcon icon={faArrowDown} /></td>
                            </tr>
                          )
                        })
                        }
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
            <hr className='my-4'/>
            <div className='my-5'>
              <div className="d-flex mb-2">
                <div class="dropdown me-3">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faFilter} />
                        <span className='ms-2'>Filter by</span>
                    </button>
                    <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                        <li onClick={e => filterHandler(e, 'URL')}><a class="dropdown-item" href="#">URL</a></li>
                    </ul>
                </div>
                <div className={`d-flex ${filter.length === 0 && 'd-none'}`}>
                    {/* <button class="btn btn-sm btn-close btn-outline-danger p-2 align-self-center me-2" type="button" aria-expanded="false" onClick={clearFilterHandler}/> */}
                    <div class={`input-group flex-nowrap ${filter.includes('URL')===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'URL')}>X</button>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="url-search-mode" data-bs-toggle="dropdown" aria-expanded="false">M</button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("url-search-mode").innerHTML = "E"}}>E</a></li>
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("url-search-mode").innerHTML = 'B'}}>B</a></li>
                        </ul>
                        <input type="text" class="form-control" placeholder="URL Search..." aria-label="URL Search..." aria-describedby="addon-wrapping" ref={urlSearch}/>
                    </div>
                    <button class="btn btn-primary ms-2" type="button" aria-expanded="false"
                    onClick={filterModeHandler}
                    >
                      Apply
                    </button>
                </div>
                <button class="btn btn-primary ms-auto" type="button" aria-expanded="false" onClick={event => downloadRankReportHandler(winLose[selectedBar]['serp'])}>
                    <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                    Download
                </button>
              </div>
              <div className="overflow-auto mb-3" style={{"height":"500px"}}>
                  <table class="table table-striped" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                      <thead className="table-primary sticky-top" style={{'z-index':'9'}}>
                          <tr>
                          <th scope="col" style={{'width':'5%'}}>#</th>
                          <th scope="col" style={{'width':'50%'}} className="px-0">
                              <span className='me-2 fw-bold'>URL</span>
                          </th>
                          <th scope="col" style={{'width':'15%'}} className="px-0">
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('pos')}>
                                <span className='me-2 fw-bold'>Current Rank</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'pos_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'pos_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'15%'}} className="px-0">
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('prev_pos')}>
                                <span className='me-2 fw-bold'>Prev Rank </span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'prev_pos_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'prev_pos_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'15%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('change')}>
                                <span className='me-2 fw-bold'>Change </span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'change_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'change_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          </tr>
                      </thead>
                      <tbody>
                            {useMemo(() =>
                              // winLose.length > 0 && Array.isArray(winLose[0]['serp']) &&
                              filteredRank.slice(showResult*(selectedPage-1),showResult*selectedPage).map((item, index) =>{
                                return (
                                    <tr key={index}>
                                      <th scope="row">{(index+1)+(selectedPage-1)*showResult}</th>
                                      <td>{item[0]}</td>
                                      <td>{item[1]}</td>
                                      <td>{item[2]}</td>
                                      <td style={{'color':`${item[3] > 0 ? 'green' : item[3] === 0 ? "orange" : 'red'}`}}>
                                        {Math.abs(item[3]) === 0 ? '-' : Math.abs(item[3])}
                                        {
                                          item[3] > 0 ?
                                          <FontAwesomeIcon icon={faArrowUp} className='ms-1' style={{'color':'green'}}/> :
                                          item[3] < 0 ?
                                          <FontAwesomeIcon icon={faArrowDown} className='ms-1' style={{'color':'red'}}/> :
                                          null
                                        }
                                        </td>
                                    </tr>
                                )
                            }),[filteredRank, sort, selectedPage, showResult, kSearch])
                          }
                      </tbody>
                  </table>
                  <h4 className={`text-muted text-center ${filteredRank.length > 0 &&  'd-none'}`}>No Records found in selected period!</h4>
              </div>
              <div className="d-flex">
                <div class="input-group mb-3 me-3 ms-auto w-25">
                  <label class="input-group-text" for="inputGroupSelect01">Results per page</label>
                  <select class="form-select" id="inputGroupSelect01" onChange={e => changeShowResultHandler(e.target.value)}>
                    <option selected value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class={`page-item ${currentPageRange === 1 && 'disabled'}`}
                    onClick = {e => {
                      e.preventDefault();
                      setCurrentPageRange(current => {
                        if(current > 1){
                          return current -1
                        }
                        return current;
                        })}}
                    ><a class="page-link" href="#">Previous</a></li>
                    {pagination.map((item, index) => {return(
                      <li  key={index+1} id={`page-${index+1}`} className={`page-item ${index+1 === selectedPage && 'active'} ${(index+1 > 10*currentPageRange || index + 1 <= 10*(currentPageRange-1)) && 'd-none'}`}>
                        {item}
                      </li>
                      )})
                    }
                    <li
                    class={`page-item ${(pagination.length <= 10 || currentPageRange + 1 > Math.ceil(pagination.length/10)) && 'disabled'}`}
                    onClick = {e => {
                      e.preventDefault();
                      setCurrentPageRange(current => {
                        if(current + 1 <= Math.ceil(pagination.length/10)) {
                          return (current +1);
                        }
                        return current;
                      })}
                      }
                    ><a class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
                
              </div>
            </div>
        </div>
    )
};

export default SerpVolatility;