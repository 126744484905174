import React, { useState, Fragment, useRef, useEffect, useMemo, useCallback} from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";
import { OverlayTrigger, Popover } from 'react-bootstrap';

import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faDownload, faEye, faHeadset, faPaperPlane, faAngleUp, faAngleDown
} from "@fortawesome/free-solid-svg-icons";

import {
    ResponsiveContainer,
    Tooltip,
    PieChart, Pie, Cell,
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Area,
    BarChart,
    Bar,
    Line,
    ComposedChart,
    Treemap
  } from "recharts";

import { getAuthToken } from "../util/token";
import DatePicker from "../components/datepick";
import RadarChart from '../components/radar_chart';
import moment from "moment";
import { scaleLinear } from 'd3-scale';
import apiClient from '../api/client';
import logger from "../util/logger";

const listGrowth = [
    {
        "date": "1709226000000",
        "sent": 106
    },
    {
        "date": "1711904400000",
        "sent": 144
    },
    {
        "date": "1714496400000",
        "sent": 127
    },
    {
        "date": "1717174800000",
        "sent": 89
    }
];

const TawkTo = () => {
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const dispatch = useDispatch();
    // const COLORS  = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#4CAF50', '#FF6347', '#FFD700', '#1E90FF', '#FF4500', '#32CD32'];
    const COLORS = ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'];
    const [agentList, setAgentList] = useState([
        'All',
        'Alex Pei',
        'Alva Ng',
        'Amber',
        'Cecilia Ng',
        'Emma',
        'Evelyn Miller',
        'Ginny',
        'Grace Chen',
        'Jane Huang',
        'Jennifer Joan',
        'Kuntal Sahu',
        'Laura Huang',
        'Linda',
        'Lucy Lee',
        'Milly Ng',
        'Naomi Ng',
        'Rachel',
        'Sky',
        'Stella Wo',
        'Suri Allen',
        'Tiana Fan',
        
    ]);
    const [filteredAgentList, setFilteredAgentList] = useState([
        'All',
        'Alex Pei',
        'Alva Ng',
        'Amber',
        'Cecilia Ng',
        'Emma',
        'Evelyn Miller',
        'Ginny',
        'Grace Chen',
        'Jane Huang',
        'Jennifer Joan',
        'Kuntal Sahu',
        'Laura Huang',
        'Linda',
        'Lucy Lee',
        'Milly Ng',
        'Naomi Ng',
        'Rachel',
        'Sky',
        'Stella Wo',
        'Suri Allen',
        'Tiana Fan',
    ]);
    const [criteriaList, setCriteriaList] = useState([
        {name: 'Helpfulness', value: 1},
        {name: 'Friendly & polite', value: 2},
        {name: 'Clear & detailed', value: 3},
        {name: 'Sentiment', value: 4},
        {name: 'Next-step action', value: 5},
        {name: 'Conversation intent', value: 6},
    ]);
    const [criterion, setCriterion] = useState('');
    const [conversationType, setConversationType] = useState('Informational');
    const [agentName, setAgentName] = useState('Agent Name');
    const [startDate, setStartDate] = useState((new Date()).getTime());
    const [endDate, setEndDate] = useState((new Date()).getTime());
    const [operation, setOperation] = useState('');
    const operationValue = useRef('');
    const [tawkChat, setTawkChat] = useState([]);
    const [conv, setConv] = useState([]);
    const [evalData, setEvalData] = useState([]);
    const [consultingQuality, setConsultingQuality] = useState([]);
    const [overallConsultingQuality, setOverallConsultingQuality] = useState([]);

    // Statistical metrics
    const [totalChat, setTotalChat] = useState('');
    const [totalTimeSpend, setTotalTimeSpend] = useState('');
    const [avgResponseTime, setAvgResponseTime] = useState('');
    const [nextStepRate, setNextStepRate] = useState('');
    const [viewDate, setViewDate] = useState(null);

    // chart
    const [totalChatSeries, setTotalChatSeries] = useState([]);
    const [totalTimeSpendSeries, setTotalTimeSpendSeries] = useState([]);
    const [avgResponseTimeSeries, setAvgResponseTimeSeries] = useState([]);
    const [nextStepRateSeries, setNextStepRateSeries] = useState([]);
    const [countryTimeSeries, setCountryTimeSeries] = useState([]);
    const [intentHistory, setIntentHistory] = useState([]);
    const[ intentPerformance, setIntentPerformance] = useState([]);
    // weekly & monthly data
    const [weeklyChatSeries, setWeeklyChatSeries] = useState([]);
    const [weeklyTimeSpendSeries, setWeeklyTimeSpendSeries] = useState([]);
    const [weeklyResponseTimeSeries, setWeeklyResponseTimeSeries] = useState([]);
    const [weeklyNextStepRateSeries, setweeklyNextStepRateSeries] = useState([]);
    const [weeklyCountryTimeSeries, setWeeklyCountryTimeSeries] = useState([]);
    const [weeklyIntentHistory, setWeeklyIntentHistory] = useState([]);
    const [monthlyChatSeries, setMonthlyChatSeries] = useState([]);
    const [monthlyTimeSpendSeries, setMonthlyTimeSpendSeries] = useState([]);
    const [monthlyResponseTimeSeries, setMonthlyResponseTimeSeries] = useState([]);
    const [monthlyNextStepRateSeries, setMonthlyNextStepRateSeries] = useState([]);
    const [monthlyCountryTimeSeries, setMonthlyCountryTimeSeries] = useState([]);
    const [monthlyIntentHistory, setMonthlyIntentHistory] = useState([]);
    const [selectedViewValue, setSelectedViewValue] = useState('Daily');
    // failed-to-convert reasons
    const [failedToConvert, setFailedToConvert] = useState([]);
    //peer-to-peer evaluation
    const [peerEval, setPeerEval] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    const filterAgentHandler = (val) => {
        const search = agentList.filter(item => {
            return (
                (val !== '' ? item.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
            )
        });
        setFilteredAgentList(search);
    };

    const applyDateRangeHandler = (start, end) => {
        let unix_start_time = (new Date(start)).getTime();
        let unix_end_time = (new Date(end)).getTime() + 86400*1000;

        setStartDate(c => unix_start_time);
        setEndDate(c => unix_end_time);
        document.getElementById("startEnd").value = `${start} - ${end}`;
        if(isNaN(unix_start_time) && isNaN(unix_end_time)) {
            unix_start_time = '';
            unix_end_time = '';
        }else if(isNaN(unix_end_time)){
        unix_end_time = '';
        } else if(isNaN(unix_start_time)){
        unix_start_time = '';
        };
    };
    const datePicker = (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"1000px", 'border': 'none'}}>
            <DatePicker 
                applyDateRangeHandler = {applyDateRangeHandler}
                start = {startDate}
                end = {endDate}
                hideControl=''
            />
        </Popover>
    );

    const convertSecondsToHoursMinutes = (s) => {
        if (s < 3600){
            let minutes = Math.floor(s/60);
            let seconds = Math.floor(s%60);
            return minutes + 'm ' + seconds + 's'
        } else {
            let hours = Math.floor(s/3600);
            let minutes = Math.floor((s%3600)/60);
            return hours + 'h ' + minutes + 'm'
        }
    };

    const groupByWeekAndMonth = (data, label, isRate = false) => {
        const weekData = {};
        const monthData = {};
        const weekCounts = {};  // To track the count of data points per week for average calculation
        const monthCounts = {}; // To track the count of data points per month for average calculation
    
        // Helper function to get the start of the week (Monday)
        const getStartOfWeek = (date) => {
            const dayOfWeek = date.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
            const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // If it's Sunday (0), subtract 6 days, otherwise subtract days to reach Monday
            const monday = new Date(date);
            monday.setDate(monday.getDate() + diff); // Set date to the start of the week (Monday)
            monday.setHours(0, 0, 0, 0); // Reset the time to midnight
            return monday;
        };
    
        // Helper function to get the start of the month
        const getStartOfMonth = (date) => {
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            firstDay.setHours(0, 0, 0, 0); // Reset the time to midnight
            return firstDay;
        };
        
        if (['countriesTrending', 'intentHistory'].includes(label)){
            data.forEach(entry => {
                const timestamp = parseInt(entry.date);
                const currentDate = new Date(timestamp);
        
                // Get start of the week as Unix timestamp
                const startOfWeek = getStartOfWeek(currentDate).getTime(); // Convert to seconds
            
                if (!weekData[startOfWeek]) {
                    weekData[startOfWeek] = { date: startOfWeek };
                };
                Object.keys(entry).forEach(key => {
                  if (key !== 'date') {
                    if (!weekData[startOfWeek][key]) {
                        weekData[startOfWeek][key] = 0;
                    }
                    weekData[startOfWeek][key] += entry[key];
                  }
                });
                // Get start of the month as Unix timestamp
                const startOfMonth = getStartOfMonth(currentDate).getTime(); // Convert to seconds
                if (!monthData[startOfMonth]) {
                    monthData[startOfMonth] = { date: startOfMonth };
                };
                Object.keys(entry).forEach(key => {
                  if (key !== 'date') {
                    if (!monthData[startOfMonth][key]) {
                        monthData[startOfMonth][key] = 0;
                    }
                    monthData[startOfMonth][key] += entry[key];
                  }
                });
              });
            
              return {
                weekData: Object.values(weekData),
                monthData: Object.values(monthData)
            };
        } else {
            data.forEach(({ date, [label]: value }) => {
                const timestamp = parseInt(date);
                const currentDate = new Date(timestamp);
        
                // Get start of the week as Unix timestamp
                const startOfWeek = getStartOfWeek(currentDate).getTime(); // Convert to seconds
                if (!weekData[startOfWeek]) {
                    weekData[startOfWeek] = 0;
                    weekCounts[startOfWeek] = 0;
                }
                weekData[startOfWeek] += value;
                weekCounts[startOfWeek] += 1;
        
                // Get start of the month as Unix timestamp
                const startOfMonth = getStartOfMonth(currentDate).getTime(); // Convert to seconds
                if (!monthData[startOfMonth]) {
                    monthData[startOfMonth] = 0;
                    monthCounts[startOfMonth] = 0;
                }
                monthData[startOfMonth] += value;
                monthCounts[startOfMonth] += 1;
            });
        
            // Format the result, apply average calculation if isRate is true
            const formatData = (data, counts) => Object.entries(data).map(([timestamp, value]) => ({
                date: parseInt(timestamp), // Unix timestamp as an integer
                [label]: isRate ? value / counts[timestamp] : value // Calculate average for rate, sum otherwise
            }));
        
            return {
                weekData: formatData(weekData, weekCounts),
                monthData: formatData(monthData, monthCounts)
            };
        };  
    };

    // Define the mapping function
    const getLegendLabel = (label, type) => {
        const labelMap = {
        "service_scope_mismatch": {label: "Service Scope Mismatch", description: "Requested services fall outside company's scope of services"},
        "onboarding_policy_restrictions": {label: "Onboarding Policy Restrictions", description: "Client's business activities or nationalities not supported under company's onboarding policy"},
        "regulatory_restrictions": {label: "Regulatory Restrictions", description: "External regulatory or legal restrictions preventing engagement (including trade sanctions)"},
        "premature_conversation_end": {label: "Premature Conversation End", description: "Client ended conversation abruptly or became unresponsive"},
        "decision_pending": {label: "Decision Pending", description: "Client needs more time for consideration or awaiting approvals"},
        "budget_mismatch": {label: "Budget Mismatch", description: "Client's budget does not align with service pricing"},
        "documentation_issues": {label: "Documentation Issues", description: 'Missing or incomplete required documentation from client'},
        "stakeholder_unavailability": {label: "Stakeholder Unavailability", description: "Key decision-makers or required parties unavailable"},
        "communication_barriers": {label: "Communication Barriers", description: "Communication challenges including technical issues, language barriers, or cultural differences"},
        "value_proposition_issues": {label: "Value Proposition Issues", description: "Client unclear about or unsatisfied with the proposed solution's value"},
        "information_quality": {label: "Information Quality", description: 'Issues with information quality or completeness from either party'},
        "internal_company_issues": {label: "Internal Company Issues", description: "Internal delays or misalignment within company's service teams"},
        "competitive_loss": {label: "Competitive Loss", description: "Client preferred competitor's offering"},
        "timing_mismatch": {label: "Timing Mismatch", description: "Client's required timeline does not align with service delivery capabilities"},
        "existing_contract_obligations": {label: "Existing Contract Obligations", description: "Client bound by existing contracts or agreements with other providers"},
        "seasonal_factors": {label: "Seasonal Factors", description: "Client postponing decision due to business seasonality or fiscal year considerations"},
        "risk_assessment_failure": {label: "Risk Assessment Failure", description: 'Client meets basic eligibility criteria but fails to pass subsequent internal risk assessment criteria'},
        "market_conditions": {label: "Market Conditions", description: "External market conditions or economic factors affecting client's decision"}
        };

        const invertedLabelMap = Object.fromEntries(Object.entries(labelMap).map(([key, value]) => [value.label, value.description]));
        if (type === 'label') {
            return labelMap[label] || {label, description: "No description available"};
        }
        return invertedLabelMap[label] || "No description available";
    };

    const applyFilterHandler = () => {
        let input = {
            start_date: startDate,
            end_date: endDate,
            agent_name: agentName,
            criteria: criterion
        };
        if (['1','2','3','4'].includes(criterion)){
            input['operation_type'] = operation;
            input['operation_value'] = operationValue.current.value
        } else if (criterion === '6'){
            input['conversation_type'] = conversationType
        }
        logger.log(input);
        dispatch(showLoading());

        apiClient.post('/api/tawkto-message', input, {
            headers: {'X-User': userName}
        }).then (
            ({data}) =>{
                dispatch(hideLoading());
                logger.log(data);
                setTawkChat(data['data']);
                setTotalChat(data['total_chat']);
                setTotalTimeSpend(convertSecondsToHoursMinutes(data['total_time_spent']));
                setAvgResponseTime(convertSecondsToHoursMinutes(data['avg_response_time']));
                setNextStepRate(`${(data['next_action_rate']*100).toFixed(2)}%`);
                setOverallConsultingQuality(data['overall_consulting_quality']);
                // intent history
                let h = Object.entries(data['intent']).map(([k, v]) => {
                    return {
                        date: parseInt(k),
                        ...v
                    }
                });
                h.sort((a,b) => a.date - b.date);
                setIntentHistory(h);
                const { weekData: weeklyIntent, monthData: monthlyIntent } = groupByWeekAndMonth(h, 'intentHistory');
                setWeeklyIntentHistory(weeklyIntent);
                setMonthlyIntentHistory(monthlyIntent);
                logger.log('intent history: ', h);
                // intent performance
                let p = Object.entries(data['intent_performance']).map(([k, v]) => {
                    return {
                        'intent': k,
                         ...v
                    }
                });
                setIntentPerformance(p);
                logger.log('intent performance', p);
                // failed to convert
                p = Object.entries(data['fail_reasons']).map(([k, v], index) => {
                    return {    name: [getLegendLabel(k, 'label').label],
                                value: v,
                                fil: COLORS[index % COLORS.length]
                            }
                }).sort((a,b) => {
                    return Object.values(b)[0] - Object.values(a)[0];
                });
                logger.log('failed to convert', p);
                setFailedToConvert(p)
                // chat detail
                let d = Object.entries(data['total_chat_series']).map(([k, v]) => {
                    return {
                        date: k,
                        'Total chats': v
                    }
                })
                d.sort((a,b) => a.date - b.date);
                setTotalChatSeries(d);
                const { weekData: weeklyChat, monthData: monthlyChat } = groupByWeekAndMonth(d, 'Total chats');
                setWeeklyChatSeries(weeklyChat);
                setMonthlyChatSeries(monthlyChat);
                d = Object.entries(data['total_time_spent_series']).map(([k, v]) => {
                    return {
                        date: k,
                        'Time spent': v
                    }
                })
                d.sort((a,b) => a.date - b.date);
                setTotalTimeSpendSeries(d);
                const { weekData: weeklyTimeSpend, monthData: monthlyTimeSpend } = groupByWeekAndMonth(d, 'Time spent');
                setWeeklyTimeSpendSeries(weeklyTimeSpend);
                setMonthlyTimeSpendSeries(monthlyTimeSpend);
                d = Object.entries(data['avg_response_time_series']).map(([k, v]) => {
                    return {
                        date: k,
                        'Response time': v
                    }
                })
                d.sort((a,b) => a.date - b.date);
                setAvgResponseTimeSeries(d);
                const { weekData: weeklyResponseTime, monthData: monthlyResponseTime } = groupByWeekAndMonth(d, 'Response time', true);
                setWeeklyResponseTimeSeries(weeklyResponseTime);
                setMonthlyResponseTimeSeries(monthlyResponseTime);
                d = Object.entries(data['next_action_rate_series']).map(([k, v]) => {
                    return {
                        date: k,
                        'Next-step rate': v
                    }
                })
                d.sort((a,b) => a.date - b.date);
                setNextStepRateSeries(d);
                const { weekData: weeklyRate, monthData: monthlyRate } = groupByWeekAndMonth(d, 'Next-step rate', true);
                setweeklyNextStepRateSeries(weeklyRate);
                setMonthlyNextStepRateSeries(monthlyRate);

                d = [...data['country_trending']];
                d.sort((a,b) => a.date - b.date);
                setCountryTimeSeries(d);
                const { weekData: weeklyTrending, monthData: monthlyTrending } = groupByWeekAndMonth(d, 'countriesTrending', false);
                setWeeklyCountryTimeSeries(weeklyTrending);
                setMonthlyCountryTimeSeries(monthlyTrending);

                setPeerEval(data['peer_to_peer_metrics']);
            }
        )
    };

    const chatBubble = (item, index) => {

        let k = 'Client';
        if( item['sender']['t'] === 's') {
            k = 'System'
        } else if (item['sender']['t'] === 'a'){
            k = item['sender']['n']
        }

        const v = item['msg'];
        // logger.log(v);
        return (
            <>
                <div key={index} className={`${k !== 'Client' && 'align-self-start border border-2'} ${k==='Client' && 'align-self-end bg-primary'} mt-3 p-3`} style={{'border-radius': '20px', 'max-width': '75%', 'min-width': '15%'}}>
                    <div className={`${k==='Client' && 'text-white'}`} style={{wordBreak: 'break-word'}}>
                        {v}
                    </div>
                </div>
                <span className={`${k==='Client' ? 'align-self-end' : 'align-self-start'} badge text-secondary`}>{`${k !== 'Client' ? `${k} - ` : ''}${new Date(item['time']).toLocaleTimeString()}`}</span>
            </>
        )
    }

    const openChatDetailHandler = (item) => {
        const d = item['evaluation'].filter(it => ['1','2','3','4'].includes(it.criteria_id.toString()));
        setConsultingQuality(d);
        setEvalData(item['evaluation']);
        setConv(item['conversation']);
        const m = new bootstrap.Modal(document.getElementById('ModalChatDetail'), {focus: false});
        m.toggle();
    };

    const CustomTooltip = useCallback(({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // logger.log(payload, label)
            // setViewDate(moment(parseInt(label)).format('DD-MM'));
            return (
            <div className="custom-tooltip p-2">
                <p className="label">{`Date: ${moment(parseInt(label)).format('D-M-YY')}`}</p>
                <ul className='ps-0' style={{listStyleType: 'none'}}>
                    {
                        payload.map(item => {
                            if (item.dataKey === 'open rate' || item.dataKey === 'click rate'){
                                return <li className="">{`${item.dataKey}: ${item.value}%`}</li>
                            }
                            return <li className="">{`${item.dataKey}: ${item.dataKey === 'Total chats' ? item.value.toLocaleString('en-us') :
                                item.dataKey === 'Time spent' || item.dataKey === 'Response time' ? convertSecondsToHoursMinutes(item.value) :
                                `${parseInt(item.value*100)}%`
                            }`}</li>
                        })
                    }
                </ul>
            </div>
            );
        }
    },[]);

    const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;
    const getPercent = (value, total) => {
        const ratio = total > 0 ? value / total : 0;

        return toPercent(ratio, 0);
    };

    const renderTooltipContent = (o) => {
        const { payload = [], label } = o;
        if (payload !== null){
            const total = payload.reduce(
            (result, entry) => result + entry.value,
            0
            );
        
            return (
            <div className="customized-tooltip-content">
                <p className="total">{`${moment(label).format('D-M-YY')} (Total: ${total})`}</p>
                <ul className="list">
                {payload.map((entry, index) => {
                    if (entry.value > 0){
                        return (
                            <li key={`item-${index}`} style={{ color: entry.color }}>
                                {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
                            </li>
                        )
                    }
                    })}
                </ul>
            </div>
            );
        }
    };

    const checkIntentHandler = (arr) => {
        let intent = '';
        arr.map(item => {
            // logger.log(item.criteria_id.toString() == '6' && new RegExp('Informational').test(item.grade.toString()))
            if (item.criteria_id.toString() === '6' && new RegExp('Informational').test(item.grade.toString())){
                intent = 'Informational';
            } else if (item.criteria_id.toString() === '6' && new RegExp('Commercial').test(item.grade.toString())){
                intent = 'Commercial'
            } else if (item.criteria_id.toString() === '6' && new RegExp(/Transactional/).test(item.grade.toString())){
                intent = 'Transactional'
            } else if (item.criteria_id.toString() === '6' && new RegExp(/Support/).test(item.grade.toString())){
                intent = 'Support'
            } else if (item.criteria_id.toString() === '6' && new RegExp(/Account/).test(item.grade.toString())){
                intent = 'Account mgmt'
            } else if (item.criteria_id.toString() === '6' && new RegExp(/Feedback/).test(item.grade.toString())){
                intent = 'Feedback'
            } else if (item.criteria_id.toString() === '6') {
                intent = 'Other'
            }
        });
        return intent;
    };

    const checkNextStepHandler = (arr) => {
        let intent = '';
        arr.map(item => {
            if (item.criteria_id.toString() === '5' && new RegExp('yes').test(item.grade.toString())){
                intent = 'yes';
            }
        });
        return intent;
    };

    const CountryHeatmap = ({ 
        data = [], 
        height = 500,
        cellSize = 30,
        className = ""
      }) => {
        if (!data || data.length === 0) {
          return (
            <div className="p-4">
              <p className="text-gray-500">No data available to display the heatmap.</p>
            </div>
          );
        }
        const allValues = []
        const uniqueCountries = new Set();
            data.forEach(dayData => {
            if (dayData) {
                Object.entries(dayData).map(([key, v]) => {
                if (key !== 'date') {
                    uniqueCountries.add(key);
                    allValues.push(v);
                };
                });
            }
        });
        
        const minValue = Math.min(...allValues);
        const maxValue = Math.max(...allValues);
        
        // Create a color scale using d3-scale
        const colorScale = scaleLinear()
            .domain([minValue, maxValue])
            .range(['#ebedf0', '#216e39']); // Adjust the color range as needed
        
        const getColor = (value) => {
            return colorScale(value);
        };
      
        const countriesArray = Array.from(uniqueCountries);
        const countryTotal = {}
        countriesArray.sort((a, b) => {
        //   const maxA = Math.max(...data.map(d => (d && d[a]) || 0));
        //   const maxB = Math.max(...data.map(d => (d && d[b]) || 0));
            const sumA = data.reduce((acc, d) => acc + ((d && d[a]) || 0), 0);
            !countryTotal[a] && (countryTotal[a] = sumA);
            const sumB = data.reduce((acc, d) => acc + ((d && d[b]) || 0), 0);
            !countryTotal[b] && (countryTotal[b] = sumB);
          return sumB - sumA;
        });
      
        const processedData = data.map(dayData => {
          if (!dayData || !dayData.date) return null;
          const date = new Date(dayData.date);
          const values = {};
          countriesArray.forEach(country => {
            values[country] = dayData[country] || 0;
          });
          return { date, values };
        }).filter(Boolean);
        const margin = { 
          top: 60,
          right: 20,
          bottom: 20,
          left: 200
        };
        
        // const getColor = (value) => {
        //   if (value === 0) return '#ebedf0';
        //   if (value <= 1) return '#9be9a8';
        //   if (value <= 3) return '#40c463';
        //   if (value <= 5) return '#30a14e';
        //   return '#216e39';
        // };
      
        const contentWidth = processedData.length * cellSize;
        const contentHeight = countriesArray.length * cellSize;
        
        const formatDate = (date) => {
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            // year: '2-digit'
          }).replace(/\//g, '-');
        };
      
        return (
          <div className='d-flex flex-column col-md-8 p-4'>
            {/* Legend */}
            <div className='d-flex'>
                <h5>Countries trending</h5>
                <div className="d-flex ms-auto items-center p-2 border-t border-gray-200">
                    <span className="text-xs text-gray-500 mr-2">Less</span>
                    {[0, 1, 3, 5, 7].map((value, i) => (
                    <div key={`legend-${i}`} className="mx-1">
                    <svg width={20} height={10}>
                    <rect width={20} height={10} fill={getColor(value)} />
                    </svg>
                    </div>
                    ))}
                    <span className="text-xs text-gray-500 ml-2">More</span>
                </div>
            </div>
            <div className="border border-gray-200 rounded bg-white" style={{ height: `${height}px`, width: '100%' }}>
              {/* Main scrollable container */}
                <div className="relative" style={{ height: '100%', overflow: 'auto' }}>
                    {/* Content wrapper with minimum width */}
                    <div style={{ minWidth: contentWidth + margin.left + margin.right }}>
                    {/* Fixed header for dates */}
                        <div className="sticky top-0 bg-white z-10" style={{ height: margin.top, marginLeft: margin.left }}>
                            <svg width={contentWidth} height={margin.top}>
                            {processedData.map((day, i) => (
                            <text
                            key={`date-${i}`}
                            x={cellSize / 2 + i * cellSize}
                            y={margin.top - 10}
                            textAnchor="start"
                            className="text-xs fill-gray-600"
                            transform={`rotate(-45 ${cellSize / 2 + i * cellSize},${margin.top - 10})`}
                            style={{
                                fontWeight: formatDate(day.date) === viewDate ? "bold" : ''
                                }}
                            >
                            {formatDate(day.date)}
                            </text>
                            ))}
                            </svg>
                        </div>
                    
                        {/* Main content area */}
                        <div className="d-flex">
                            {/* Fixed left column for country names */}
                            <div className="d-flex left-0 bg-white z-10" style={{ width: margin.left }}>
                                <svg width={margin.left} height={contentHeight}>
                                    {countriesArray.map((country, i) => (
                                    <text
                                        key={`country-${i}`}
                                        x={margin.left - 10}
                                        y={i * cellSize + cellSize / 2}
                                        textAnchor="end"
                                        dominantBaseline="middle"
                                        className="text-xs fill-gray-600"
                                    >
                                        {`${country} (${countryTotal[country]})`}
                                    </text>
                                    ))}
                                </svg>
                            </div>
                    
                            {/* Heatmap grid */}
                            <div className='d-flex'>
                                <svg width={contentWidth} height={contentHeight}>
                                    {countriesArray.map((country, i) =>
                                    processedData.map((day, j) => (
                                        <rect
                                            key={`cell-${i}-${j}`}
                                            x={j * cellSize}
                                            y={i * cellSize}
                                            width={cellSize - 1}
                                            height={cellSize - 1}
                                            fill={getColor(day.values[country])}
                                            rx={2}
                                        >
                                        <title>{`${country} on ${formatDate(day.date)}: ${day.values[country]} appearances`}</title>
                                        </rect>
                                    ))
                                    )}
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        );
    };

    const handleSwitchChange = (event) => {
        setSelectedViewValue(event.target.value);
    };

    const sortedData = useMemo(() => {
        let sortableData = [...peerEval];
        if (sortConfig.key !== null) {
          sortableData.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          });
        }
        setPeerEval(sortableData);
      }, [sortConfig]);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const TawkDetail = React.memo(({ tawkChat }) => {
        logger.log('render');
        const memoizedChatRows = useMemo(() => 
          tawkChat.map((item, idx) => (
            <tr key={idx}>
              <th scope="col">{idx + 1}</th>
              <td scope="col">{item['_id']}</td>
              <td scope="col">{item.agent_name.join(', ')}</td>
              <td scope="col">{new Date(item.start_time).toLocaleDateString()}</td>
              <td scope="col">{convertSecondsToHoursMinutes(item.chat_duration)}</td>
              <td scope="col">{item.message_count}</td>
              <td scope="col">{checkIntentHandler(item.evaluation)}</td>
              <td scope="col">{checkNextStepHandler(item.evaluation)}</td>
              <td scope="col">
                <button type="button" className="btn btn-light" onClick={e => openChatDetailHandler(item)}>
                  <FontAwesomeIcon icon={faEye} />
                </button>
              </td>
            </tr>
          )), [tawkChat]
        );
        return (
            <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                    <thead className='sticky-top table-primary'>
                        <tr>
                            <th scope="col" style={{'width':'5%'}}>#</th>
                            <th scope="col" style={{'width':'18%'}}>Conversation Id</th>
                            <th scope="col" style={{'width':'18%'}}>Agent name</th>
                            <th scope="col" style={{'width':'18%'}}>Start</th>
                            <th scope="col" style={{'width':'18%'}}>Duration</th>
                            <th scope="col" style={{'width':'18%'}}>Message Count</th>
                            <th scope="col" style={{'width':'18%'}}>Intent</th>
                            <th scope="col" style={{'width':'18%'}}>Next-step</th>
                            <th scope="col" style={{'width':'5%'}}>Action</th>
                        </tr>
                    </thead>
                        <tbody>
                            {memoizedChatRows}
                        </tbody>
                </table>
            </div>
          );
    });

    // Custom cell renderer for Treemap
    const CustomizedContent = ({ depth, x, y, width, height, index, colors, name }) => {
        return (
        <g>
            <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
                fill: colors[index % colors.length],
                stroke: '#fff',
                strokeWidth: 2,
            }}
            />
            {width > 100 ? (
            <text
                x={x + width / 2}
                y={y + height / 2}
                textAnchor="middle"
                fill="#fff"
                fontSize={11}
            >
                {name}
            </text>
            ) : null}
        </g>
        );
    };

    // Custom Tooltip Component
    const CustomTreeTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
        const { name, value } = payload[0].payload;
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <p className="label">{`${name}: ${value}`}</p>
                <small>{getLegendLabel(name, 'description')}</small>
            </div>
        );
        }
        return null;
    };

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Tawkto Evaluation</h1>
            <div className='d-flex my-4'>
                <div class="input-group ps-3 w-auto me-3">
                    <span class="input-group-text" id="basic-addon1">Date range</span>
                    <OverlayTrigger trigger={["click"]} placement="bottom" overlay={datePicker} rootClose container={this} containerPadding={20}>
                        <input type="text" className="form-control" placeholder="from - to" id="startEnd"/>
                    </OverlayTrigger>
                </div>
                <div class="dropdown me-3 align-self-center">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faHeadset} className='me-2' />
                        {agentName}
                    </button>
                    <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'300px', 'overflow': 'auto', width: '250px', 'z-index': '9999'}}>
                        <input type="text" class="form-control sticky-top" placeholder="Search country..." aria-label="Search country..." aria-describedby="basic-addon1"
                        onChange = {e => filterAgentHandler(e.target.value)}
                        />
                        {filteredAgentList.map((item, index) => {
                            return (
                                <li key={index} onClick={e => setAgentName(item)}><a class="dropdown-item" href="#">{item}</a></li>
                            )
                        })}
                    </ul>
                </div>
                <div className='d-flex me-2'>
                    <select class="form-select" onChange={e => setCriterion(e.target.value)}>
                        <option selected value=''>Criteria</option>
                        {
                            criteriaList.map((item, index) => 
                                <option value={item.value}>{item.name}</option>
                            )
                        }
                    </select>
                </div>
                {
                    ['1','2','3','4'].includes(criterion) ?
                    <>
                        <div className='d-flex me-2'>
                            <select class="form-select" onChange={e => setOperation(e.target.value)} >
                                <option selected value={`>=`}>{`>=`}</option>
                                <option value={`<=`}>{`<=`}</option>
                                <option value={`=`}>{`=`}</option>
                            </select>
                        </div>
                        <div className='d-flex'>
                            <input type="text" className="form-control me-2" placeholder="value" ref={operationValue}/>
                        </div>
                    </> :
                    criterion === '6' ?
                    <div className='d-flex me-2'>
                        <select class="form-select" onChange={e => setConversationType(e.target.value)}>
                            <option selected>Informational</option>
                            <option >Commercial</option>
                            <option >Transactional</option>
                            <option >Support</option>
                            <option >Account management</option>
                            <option >Feedback</option>
                            <option >Other</option>
                        </select>
                    </div> : null
                }
                <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>
                    Apply filter
                </button>
            </div>
            <div className='d-flex my-4 pt-4 align-items-center'>
                <div className='d-flex flex-column col-md-6 align-self-start'>
                    {/* switch button */}
                    <div className="btn-group custom-switch w-25" role="group" aria-label="Basic radio toggle button group">
                        <input 
                            type="radio" 
                            className="btn-check" 
                            name="btnradio" 
                            id="btnradio1" 
                            value="Daily" 
                            checked={selectedViewValue === 'Daily'} 
                            onChange={handleSwitchChange} 
                        />
                        <label className="btn btn-outline-primary" htmlFor="btnradio1">Daily</label>

                        <input 
                            type="radio" 
                            className="btn-check" 
                            name="btnradio" 
                            id="btnradio2" 
                            value="Weekly" 
                            checked={selectedViewValue === 'Weekly'} 
                            onChange={handleSwitchChange} 
                        />
                        <label className="btn btn-outline-primary" htmlFor="btnradio2">Weekly</label>

                        <input 
                            type="radio" 
                            className="btn-check" 
                            name="btnradio" 
                            id="btnradio3" 
                            value="Monthly" 
                            checked={selectedViewValue === 'Monthly'} 
                            onChange={handleSwitchChange} 
                        />
                        <label className="btn btn-outline-primary" htmlFor="btnradio3">Monthly</label>
                    </div>
                    <div className='d-flex my-3'>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                            <b>Total Chat</b>
                            <div className='d-flex'>
                                <div className='d-flex col-md-4 justify-content-center'>
                                    <h4 className='align-self-center my-3'>
                                        {totalChat}
                                    </h4>
                                </div>
                                <div className='d-flex col-md-8' style={{maxHeight: '100px'}}>
                                    <ResponsiveContainer width='100%' height='100%'>
                                        <AreaChart
                                        width={500}
                                        height={300}
                                        data={selectedViewValue === 'Daily' ? totalChatSeries : selectedViewValue === 'Weekly' ? weeklyChatSeries : monthlyChatSeries}
                                        syncId="syncChart"
                                        syncMethod={(tick, datum) => {
                                            let d = tick.filter(item => item.value === datum.activeLabel);
                                            if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                                return d[0].index
                                            }
                                        }}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 10,
                                            bottom: 5,
                                        }}
                                        >
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis
                                                dataKey="date"
                                                name = "date"
                                                hide= 'true'
                                                tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                                            />
                                            <YAxis 
                                                domain={[0, 'auto']}
                                                hide= 'true'
                                            />
                                            <Tooltip 
                                                content={<CustomTooltip />}
                                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                                offset={20}
                                                allowEscapeViewBox={{x:true}}
                                            />
                                            {/* <Legend legendType='none'/> */}
                                            <Area type="monotone" dataKey="Total chats" name="list growth" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                            <b>Total Time Spent</b>
                            <div className='d-flex'>
                                <div className='d-flex col-md-4 justify-content-center'>
                                    <h4 className='align-self-center my-3'>
                                        {totalTimeSpend}
                                    </h4>
                                </div>
                                <div className='d-flex col-md-8' style={{maxHeight: '100px'}}>
                                    <ResponsiveContainer width='100%' height='100%'>
                                        <AreaChart
                                        width={500}
                                        height={300}
                                        data={selectedViewValue === 'Daily' ? totalTimeSpendSeries : selectedViewValue === 'Weekly' ? weeklyTimeSpendSeries : monthlyTimeSpendSeries}
                                        syncId="syncChart"
                                        syncMethod={(tick, datum) => {
                                            let d = tick.filter(item => item.value === datum.activeLabel);
                                            if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                                return d[0].index
                                            }
                                        }}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 10,
                                            bottom: 5,
                                        }}
                                        >
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis
                                                dataKey="date"
                                                name = "date"
                                                hide= 'true'
                                                tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                                            />
                                            <YAxis 
                                                domain={[0, 'auto']}
                                                hide= 'true'
                                            />
                                            <Tooltip 
                                                content={<CustomTooltip />}
                                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                                offset={20}
                                                allowEscapeViewBox={{x:true}}
                                            />
                                            {/* <Legend legendType='none'/> */}
                                            <Area type="monotone" dataKey="Time spent" name="list growth" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                            <b>Avg. Response Time</b>
                            <div className='d-flex'>
                                <div className='d-flex col-md-4 justify-content-center'>
                                    <h4 className='align-self-center my-3'>
                                        {avgResponseTime}
                                    </h4>
                                </div>
                                <div className='d-flex col-md-8' style={{maxHeight: '100px'}}>
                                    <ResponsiveContainer width='100%' height='100%'>
                                        <AreaChart
                                        width={500}
                                        height={300}
                                        data={selectedViewValue === 'Daily' ? avgResponseTimeSeries : selectedViewValue === 'Weekly' ? weeklyResponseTimeSeries : monthlyResponseTimeSeries}
                                        syncId="syncChart"
                                        syncMethod={(tick, datum) => {
                                            let d = tick.filter(item => item.value === datum.activeLabel);
                                            if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                                return d[0].index
                                            }
                                        }}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 10,
                                            bottom: 5,
                                        }}
                                        >
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis
                                                dataKey="date"
                                                name = "date"
                                                hide= 'true'
                                                tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                                            />
                                            <YAxis 
                                                domain={[0, 'auto']}
                                                hide= 'true'
                                            />
                                            <Tooltip 
                                                content={<CustomTooltip />}
                                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                                offset={20}
                                                allowEscapeViewBox={{x:true}}
                                            />
                                            {/* <Legend legendType='none'/> */}
                                            <Area type="monotone" dataKey="Response time" name="list growth" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                            <b>Next-action Rate</b>
                            <div className='d-flex'>
                                <div className='d-flex col-md-4 justify-content-center'>
                                    <h4 className='align-self-center my-3'>
                                        {nextStepRate}
                                    </h4>
                                </div>
                                <div className='d-flex col-md-8' style={{maxHeight: '100px'}}>
                                    <ResponsiveContainer width='100%' height='100%'>
                                        <AreaChart
                                        width={500}
                                        height={300}
                                        data={selectedViewValue === 'Daily' ? nextStepRateSeries : selectedViewValue === 'Weekly' ? weeklyNextStepRateSeries : monthlyNextStepRateSeries}
                                        syncId="syncChart"
                                        syncMethod={(tick, datum) => {
                                            let d = tick.filter(item => item.value === datum.activeLabel);
                                            if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                                return d[0].index
                                            }
                                        }}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 10,
                                            bottom: 5,
                                        }}
                                        >
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis
                                                dataKey="date"
                                                name = "date"
                                                hide= 'true'
                                                tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                                            />
                                            <YAxis 
                                                domain={[0, 'auto']}
                                                hide= 'true'
                                            />
                                            <Tooltip 
                                                content={<CustomTooltip />}
                                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                                offset={20}
                                                allowEscapeViewBox={{x:true}}
                                            />
                                            {/* <Legend legendType='none'/> */}
                                            <Area type="monotone" dataKey="Next-step rate" name="list growth" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column col-md-6' style={{'height': '400px'}}>
                    <h5 className="mb-4">Chat Intent</h5>
                    <ResponsiveContainer width="100%" height="80%">
                        <BarChart
                        width={500}
                        height={300}
                        data={selectedViewValue === 'Daily' ? intentHistory : selectedViewValue === 'Weekly' ? weeklyIntentHistory : monthlyIntentHistory}
                        // stackOffset="expand"
                        syncId="syncChart"
                        // syncMethod={(tick, datum) => {
                        //     let d = tick.filter(item => item.value === datum.activeLabel);
                        //     if (d.length > 0 && Object.keys(d[0]).includes('index')){
                        //         return d[0].index
                        //     }
                        // }}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            name = "date"
                            tickFormatter = {(unixTime) => moment(unixTime).format('YY-MM-D')}

                        />
                        <YAxis 
                            // tickFormatter={(decimal) => `${(decimal * 100).toFixed(0)}%`}
                            domain={[0, 'auto']}
                        />
                        <Tooltip 
                            content={renderTooltipContent}
                            wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                        />
                        <Legend />
                        
                        <Bar type="monotone" dataKey="Informational" stackId="1" stroke="#8884d8" fill="#8884d8" dot={{ stroke: '#8884d8', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Commercial" stackId="1" stroke="#82ca9d" fill="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Transactional" stackId="1" stroke="#ffc658" fill="#ffc658" dot={{ stroke: '#ffc658', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Support" stackId="1" stroke="#d4d884" fill="#d4d884" dot={{ stroke: '#d4d884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Account management" stackId="1" stroke="#d88884" fill="#d88884" dot={{ stroke: '#d88884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Feedback" stackId="1" stroke="#c6504a" fill="#c6504a" dot={{ stroke: '#c6504a', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Other" stackId="1" stroke="#c6504a" fill="#c6504a" dot={{ stroke: '#c6504a', strokeWidth: 1, r: 2,strokeDasharray:''}}/>      
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className='d-flex my-4'>
                {
                    countryTimeSeries.length > 0 &&
                    <CountryHeatmap data={selectedViewValue === 'Daily' ? countryTimeSeries : selectedViewValue === 'Weekly' ? weeklyCountryTimeSeries : monthlyCountryTimeSeries}/>
                }
                <div className='d-flex col-md-4 flex-column py-4 h-100'>
                    <h5 className='mb-2'>Fail-to-convert reasons</h5>
                        {
                            failedToConvert.length > 0 &&
                            <div className='d-flex' style={{height: '500px'}}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <Treemap
                                        dataKey="value"
                                        nameKey="name"
                                        data={failedToConvert}
                                        stroke="#fff"
                                        content ={<CustomizedContent colors={COLORS}/>}
                                        margin={{
                                            top: 0,
                                            right: 10,
                                            left: 10,
                                            bottom: -10,
                                        }}
                                        animationDuration={600}                       
                                    >
                                        <Tooltip content={<CustomTreeTooltip />}/>
                                    </Treemap>
                                </ResponsiveContainer>
                            </div>
                        }
                </div>
            </div>
            <div className='d-flex my-4'>
                <div className="d-flex flex-column col-md-4">
                    <h5 className='me-auto'>Avg. Consulting Quality</h5>
                    <div classNa='d-flex'>
                        {
                            overallConsultingQuality.length > 0 &&
                            <RadarChart 
                                data={overallConsultingQuality}
                            />
                        }
                    </div>
                </div>
                <div className='d-flex flex-column col-md-8'>
                    <h5 className="mb-4">Evaluation by Chat Intent</h5>
                    <div className='d-flex overflow-auto my-2' style={{maxHeight: '300px'}}>
                        <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                            <thead className='sticky-top table-primary'>
                                <tr>
                                    <th scope="col" style={{'width':'5%'}}>#</th>
                                    <th scope="col" style={{'width':'18%'}}>Intent</th>
                                    <th scope="col" style={{'width':'18%'}}>Total</th>
                                    <th scope="col" style={{'width':'18%'}}>Helpfulness</th>
                                    <th scope="col" style={{'width':'18%'}}>Friendly</th>
                                    <th scope="col" style={{'width':'18%'}}>Clear</th>
                                    <th scope="col" style={{'width':'18%'}}>Sentiment</th>
                                    <th scope="col" style={{'width':'18%'}}>Conv. rate</th>
                                </tr>
                            </thead>
                                <tbody>
                                    {
                                        intentPerformance.map((item, idx) => {
                                            return (
                                                <tr>
                                                    <th scope="col">{idx+1}</th>
                                                    <td scope="col">
                                                        <>
                                                            <FontAwesomeIcon icon={faCircle} color={`${item['intent'] === 'Informational' ? '#8884d8' :
                                                                                                        item['intent'] === 'Commercial' ? '#82ca9d' :
                                                                                                        item['intent'] === 'Transactional' ? '#ffc658' :
                                                                                                        item['intent'] === 'Support' ? '#d4d884' :
                                                                                                        item['intent'] === 'Account management' ? '#d88884' :
                                                                                                        item['intent'] === 'Feedback' ? '#c6504a' :
                                                                                                        item['intent'] === 'Other' ? '#c6504a' : null
                                                            }`} size='sm' className='me-2'/>
                                                            {item['intent']}
                                                        </>
                                                    </td>
                                                    <td scope="col">
                                                        {item['count'] > 0 ? item['count'] : null}
                                                    </td>
                                                    <td scope="col" style={{backgroundColor: `${parseInt(item['1']) === 5 ? "#31a354"  :
                                                                                                parseInt(item['1']) === 4 ? "#78c679"  :
                                                                                                parseInt(item['1']) === 3 ? "#fecc5c"  :
                                                                                                parseInt(item['1']) === 2 ? "#fd8d3c"  :
                                                                                                parseInt(item['1']) === 1 ? "#f03b20"  : 
                                                                                                parseInt(item['1']) === 0 ? "#666666"  : null
                                                    }`}}>
                                                        {item['1'] === 0 ? '' : item['1'].toFixed(1)}
                                                    </td>
                                                    <td scope="col" style={{backgroundColor: `${parseInt(item['2']) === 5 ? "#31a354"  :
                                                                                                parseInt(item['2']) === 4 ? "#78c679"  :
                                                                                                parseInt(item['2']) === 3 ? "#fecc5c"  :
                                                                                                parseInt(item['2']) === 2 ? "#fd8d3c"  :
                                                                                                parseInt(item['2']) === 1 ? "#f03b20"  :
                                                                                                parseInt(item['2']) === 0 ? "#666666"  : null
                                                    }`}}>
                                                        {item['2'] === 0 ? '' : item['2'].toFixed(1)}
                                                    </td>
                                                    <td scope="col" style={{backgroundColor: `${parseInt(item['3']) === 5 ? "#31a354"  :
                                                                                                parseInt(item['3']) === 4 ? "#78c679"  :
                                                                                                parseInt(item['3']) === 3 ? "#fecc5c"  :
                                                                                                parseInt(item['3']) === 2 ? "#fd8d3c"  :
                                                                                                parseInt(item['3']) === 1 ? "#f03b20"  :
                                                                                                parseInt(item['3']) === 0 ? "#666666"  : null
                                                    }`}}>
                                                        {item['3'] === 0 ? '' : item['3'].toFixed(1)}
                                                    </td>
                                                    <td scope="col" style={{backgroundColor: `${parseInt(item['4']) === 5 ? "#31a354"  :
                                                                                                parseInt(item['4']) === 4 ? "#78c679"  :
                                                                                                parseInt(item['4']) === 3 ? "#fecc5c"  :
                                                                                                parseInt(item['4']) === 2 ? "#fd8d3c"  :
                                                                                                parseInt(item['4']) === 1 ? "#f03b20"  :
                                                                                                parseInt(item['4']) === 0 ? "#666666"  : null
                                                    }`}}>
                                                        {item['4'] === 0 ? '' : item['4'].toFixed(1)}
                                                    </td>
                                                    <th scope="col">{item['5'] > 0 ? `${item['5'].toFixed(1)}%` : null}</th>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column mb-4'>
                <h5 className="mb-4">Peer-to-Peer comparison</h5>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'3%'}} rowSpan="2">#</th>
                                <th scope="col" style={{'width':'12%'}} rowSpan="2">
                                    <button class="btn" type="button" aria-expanded="false" onClick={() => requestSort('agent_name')}>
                                        <span className='me-2 fw-bold'>Agent Name</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sortConfig.key === 'agent_name' && sortConfig.direction === 'ascending' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sortConfig.key === 'agent_name' && sortConfig.direction === 'descending' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                                <th scope="col" colSpan="2">Workload</th>
                                <th scope="col" colSpan="6">Performance</th>
                            </tr>
                            <tr>
                                <th scope="col" style={{'width':'10%'}}>
                                    <button class="btn" type="button" aria-expanded="false" onClick={() => requestSort('total_chat')}>
                                        <span className='me-2 fw-bold'>Total Chat</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sortConfig.key === 'total_chat' && sortConfig.direction === 'ascending' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sortConfig.key === 'total_chat' && sortConfig.direction === 'descending' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'10%'}}>
                                    <button class="btn" type="button" aria-expanded="false" onClick={() => requestSort('chat_duration')}>
                                        <span className='me-2 fw-bold'>Total Time Spent</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sortConfig.key === 'chat_duration' && sortConfig.direction === 'ascending' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sortConfig.key === 'chat_duration' && sortConfig.direction === 'descending' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'10%'}}>
                                    <button class="btn" type="button" aria-expanded="false" onClick={() => requestSort('avg_response_time')}>
                                        <span className='me-2 fw-bold'>Avg. Resp Time</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sortConfig.key === 'avg_response_time' && sortConfig.direction === 'ascending' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sortConfig.key === 'avg_response_time' && sortConfig.direction === 'descending' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'10%'}} onClick={() => requestSort('1')}>
                                    <button class="btn" type="button" aria-expanded="false" onClick={() => requestSort('1')}>
                                        <span className='me-2 fw-bold'>Helpfulness</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sortConfig.key === '1' && sortConfig.direction === 'ascending' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sortConfig.key === '1' && sortConfig.direction === 'descending' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'10%'}}>
                                    <button class="btn" type="button" aria-expanded="false" onClick={() => requestSort('2')}>
                                        <span className='me-2 fw-bold'>Friendly</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sortConfig.key === '2' && sortConfig.direction === 'ascending' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sortConfig.key === '2' && sortConfig.direction === 'descending' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'10%'}} onClick={() => requestSort('3')}>
                                    <button class="btn" type="button" aria-expanded="false" onClick={() => requestSort('3')}>
                                        <span className='me-2 fw-bold'>Clear</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sortConfig.key === '3' && sortConfig.direction === 'ascending' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sortConfig.key === '3' && sortConfig.direction === 'descending' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'10%'}} onClick={() => requestSort('4')}>
                                    <button class="btn" type="button" aria-expanded="false" onClick={() => requestSort('4')}>
                                        <span className='me-2 fw-bold'>Sentiment</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sortConfig.key === '4' && sortConfig.direction === 'ascending' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sortConfig.key === '4' && sortConfig.direction === 'descending' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'10%'}}>
                                    <button class="btn" type="button" aria-expanded="false" onClick={() => requestSort('5')}>
                                        <span className='me-2 fw-bold'>Conv. rate</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sortConfig.key === '5' && sortConfig.direction === 'ascending' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sortConfig.key === '5' && sortConfig.direction === 'descending' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                peerEval.map((item, idx) => {
                                    return (
                                        <tr>
                                            <th scope="col">{idx+1}</th>
                                            <td scope="col">{item['agent_name']}</td>
                                            <td scope="col">{item['total_chat']}</td>
                                            <td scope="col">{convertSecondsToHoursMinutes(item['chat_duration'])}</td>
                                            <td scope="col">{convertSecondsToHoursMinutes(item['avg_response_time'])}</td>
                                            <td scope="col">{item['1'].toFixed(1)}</td>
                                            <td scope="col">{item['2'].toFixed(1)}</td>
                                            <td scope="col">{item['3'].toFixed(1)}</td>
                                            <td scope="col">{item['4'].toFixed(1)}</td>
                                            <td scope="col">{`${(item['5']*100).toFixed(1)}%`}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='d-flex my-4 flex-column'>
                <h5 className="mb-4">Evaluation Detail</h5>
                {/* Modal Bounce*/}
                <div class="modal fade" id="ModalChatDetail" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div class="modal-content">
                            <div className="modal-header">
                                <h5 class="modal-title me-2" id="ModalLongTitle">Message Content & Evaluation</h5>
                            </div>
                            <div class="d-flex flex-row modal-body">
                                <div className='d-flex col-md-6'>
                                    <div className="d-flex flex-column mb-3 pe-3" style={{'height':'500px', 'overflow': 'auto'}}>
                                        {conv.map((item, index) => 
                                            chatBubble(item, index)
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex col-md-6 flex-column'>
                                    <div className='d-flex mb-4 align-self-center'>
                                        {
                                            consultingQuality.length > 0 &&
                                            <RadarChart 
                                                data={consultingQuality}
                                            />
                                        }
                                    </div>
                                    <div className='d-flex mb-4' style={{'height':'200px', 'overflow': 'auto'}}>
                                        <ul list-group list-group-flush>
                                            {
                                                consultingQuality.map((item, idx) => {
                                                    let criteria_name = '';
                                                    if (item.criteria_id.toString() === '1'){
                                                        criteria_name = 'Helpfulness'
                                                    } else if (item.criteria_id.toString() === '2'){
                                                        criteria_name = 'Friendly'
                                                    } else if (item.criteria_id.toString() === '3'){
                                                        criteria_name = 'Clear'
                                                    } else if (item.criteria_id.toString() === '4'){
                                                        criteria_name = 'Sentiment'
                                                    };
                                                    let colorCode = '#31a354'
                                                    if (item.grade === 4){
                                                        colorCode = '#78c679'
                                                    } else if (item.grade === 3){
                                                        colorCode = '#fecc5c'
                                                    } else if (item.grade === 2) {
                                                        colorCode = '#fd8d3c'
                                                    } else if (item.grade === 1){
                                                        colorCode = '#f03b20'
                                                    }
                                                     return <li className='list-group-item border-0'>
                                                                <span className='badge' style={{backgroundColor: colorCode}}>
                                                                    {`${criteria_name}: ${item.grade}`}
                                                                </span>
                                                                <br />
                                                                <span>
                                                                    {`${item?.explaination ||item?.explanation}`}
                                                                </span>
                                                            </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Bounce*/}
                <TawkDetail tawkChat={tawkChat} />
            </div>
        </div>
    )
};

export default  TawkTo;