import { useState, useRef, Fragment, useEffect } from 'react';
import { useDispatch } from "react-redux";
import {showLoading, hideLoading } from 'react-redux-loading-bar';

import Menu from "../components/menu";
import Head from "../components/loading-bar";
import DatePicker from "../components/datepick";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Papa from 'papaparse';
import { v4 as uuid4 } from 'uuid';



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faArrowLeft, faArrowRight, faFilter, faGlobe, faAngleUp, faAngleDown, faEye, faUpRightFromSquare, faTimesCircle, faCheckCircle, faRefresh
} from "@fortawesome/free-solid-svg-icons";
import { getAuthToken } from '../util/token';
import apiClient from '../api/client';
import logger from '../util/logger';

function KWSuggestion() {
    //get auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const dispatch = useDispatch();

    const [data, setData] = useState({});

    // platform
    const [platform, setPlatform] = useState("Google");
    const [platformList, setPlatformList] = useState(["Google", "Youtube", "Bing"]);
    const [filteredPlatform, setFilteredPlatform] = useState(["Google", "Youtube", "Bing"]);
    // country
    const [country, setCountry] = useState("Viet Nam");
    const [countryList, setCountryList] = useState([]);
    const [filteredCountry, setFilteredCountry] = useState([]);
    // language
    const [language, setLanguage] = useState("Vietnamese");
    const [languageList, setLanguageList] = useState([]);
    const [filteredLanguage, setFilteredLanguage] = useState([]);

    const [advanced, setAdvanced] = useState(false);
    const [sort, setSort] = useState('');
    const [additions, setAdditions] = useState([]);
    const seed = useRef('');
    const customText = useRef('');
    const [pos, setPos] = useState('');
    const [depth, setDepth] = useState(3);
    const incl = useRef('');
    const excl = useRef('');

    const [group, setGroup] = useState({});
    const [groupDetail, setGroupDetail] = useState([]);
    const [groupText, setGroupText] = useState('');
    const [groupDetailText, setGroupDetailText] = useState('');
    const [activeTab, setActiveTab] = useState('');
    const [ activeLetter, setActiveLetter ] = useState('');

    //streaming response
    const [totalQueue, setTotalQueue] = useState(0);
    const [totalDiscovered, setTotalDiscovered] = useState(0);
    const [screenMode, setScreenMode ] = useState('start');
    const [selectKwList, setSelectKwList] = useState([]);
    const [suggestionId, setSuggestionId] = useState('');

    const [importStat, setImportStat] = useState('');
    const [taskId, setTaskId] = useState('');
    const [pendingTasks, setPendingTasks] = useState([]);
    const domain = useRef('');
    const description = useRef('');
    const taskName = useRef('');
    const [textValid, setTextValid] = useState();

    useEffect(() => {
        Promise.all([
            apiClient.get('/api/iso-lang-reg-code', {headers: {'X-User': userName}})
            .then (({data}) => {
            logger.log(data);
            const lang_code = data['iso_lang_code'];
            const reg_code = data['iso_reg_code'];
            setCountryList(reg_code);
            setFilteredCountry(reg_code);
            setLanguageList(lang_code);
            setFilteredLanguage(lang_code);
        }
        ),
        apiClient.post('/api/manage-tasks', {}, {headers: {'X-User': userName}})
        .then (
            ({data}) =>{
                const task = data.filter(item => item.task === 'grouping');
                setPendingTasks(task);
            })])
    },[]);

    useEffect(() => {
        if (activeTab !== '' && groupText !== null){
            const d = Object.fromEntries(
                Object.entries(data['stats'][[activeTab]]).filter(([k,v]) => {
                    return k.search(new RegExp(groupText, 'i')) !== -1
                }
                )
            );
            setGroup(d);
        }
    }, [groupText]);

    useEffect(() => {
        if (activeTab !== '' && groupDetailText !== null){
            // logger.log(activeTab, activeLetter);
            let d = [];
            if (activeLetter !== '') {
                d = data['stats'][[activeTab]][[activeLetter]]['contains'].filter(k => {
                        if (Array.isArray(k)){
                            return k[0].search(new RegExp(groupDetailText, 'i')) !== -1
                        } else {
                            return k.search(new RegExp(groupDetailText, 'i')) !== -1
                        }
                    }
                );
                // logger.log(d);
            } else {
                let check = []
                d = Object.entries(data['stats'][[activeTab]]).reduce((a, [k, v]) => {
                    // logger.log(k, activeLetter, v);
                    let c = v['contains'].filter(k => {
                        if (Array.isArray(k)){
                            if (!check.includes(k[0])){
                                check.push(k[0]);
                                return k[0].search(new RegExp(groupDetailText, 'i')) !== -1
                            };
                            return false
                        } else {
                            return k.search(new RegExp(groupDetailText, 'i')) !== -1
                        }
                    });
                    return [...a, ...c]
                }, []);
                // logger.log(d);
            }
            
            setGroupDetail(d);
        }
    }, [groupDetailText])

    const filterHandler = (type, val) => {

        let l = [];
        if(type === 'platform'){
            l = platformList.filter(item => {
                return (
                    (val !== '' ? item.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
                )
            });
            setFilteredPlatform(l);
        } else if (type === 'country'){
            l = Object.fromEntries(Object.entries(countryList).filter(([item, code]) => {
                return (
                    (val !== '' ? item.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
                )
            }));
            setFilteredCountry(l);
        } else if (type === 'language'){
            l = Object.fromEntries(Object.entries(languageList).filter(([item, code]) => {
                return (
                    (val !== '' ? item.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
                )
            }));
            setFilteredLanguage(l);
        }
        
    };

    const tableSortHandler = (type) => {
        const d = document.getElementsByClassName('row-checkbox');
        for ( let i = 0; i < d.length; i++) d[i].checked = false;
        setSelectKwList([]);
        if(type === 'kw'){
            if (sort !== `keyword_inc` && sort !== `keyword_dec`){
                setSort(`keyword_inc`);
                groupDetail.sort((a,b) => {
                    let aa = a
                    Array.isArray(a) ? aa = a[0] : aa = a;
                    let bb = b
                    Array.isArray(b) ? bb = b[0] : bb = b;
                    return aa.localeCompare(bb)
                    })
                } else if(sort === `keyword_dec`){
                setSort('keyword_inc');
                groupDetail.sort((a,b) => {
                    let aa = a
                    Array.isArray(a) ? aa = a[0] : aa = a;
                    let bb = b
                    Array.isArray(b) ? bb = b[0] : bb = b;
                    return aa.localeCompare(bb);
                    });
                } else if(sort === `keyword_inc`){
                setSort(`keyword_dec`);
                groupDetail.sort((a,b) => {
                    let aa = a
                    Array.isArray(a) ? aa = a[0] : aa = a;
                    let bb = b
                    Array.isArray(b) ? bb = b[0] : bb = b;
                    return bb.localeCompare(aa);
                    });
                };
        } else {
            if(type === 'volume'){
                if (sort !== `volume_inc` && sort !== `volume_dec`){
                    setSort(`volume_inc`);
                    groupDetail.sort((a,b) => {
                        let aa = a
                        Array.isArray(a) ? aa = a[1] : aa = 0;
                        let bb = b
                        Array.isArray(b) ? bb = b[1] : bb = 0;
                        return (bb > aa) ? -1 : (bb < aa) ? 1 : 0
                        })
                    } else if(sort === `volume_dec`){
                    setSort('volume_inc');
                    groupDetail.sort((a,b) => {
                        let aa = a
                        Array.isArray(a) ? aa = a[1] : aa = 0;
                        let bb = b
                        Array.isArray(b) ? bb = b[1] : bb = 0;
                        return (bb > aa) ? -1 : (bb < aa) ? 1 : 0
                        });
                    } else if(sort === `volume_inc`){
                    setSort(`volume_dec`);
                    groupDetail.sort((a,b) => {
                        let aa = a
                        Array.isArray(a) ? aa = a[1] : aa = 0;
                        let bb = b
                        Array.isArray(b) ? bb = b[1] : bb = 0;
                        return (aa > bb) ? -1 : (aa < bb) ? 1 : 0
                        });
                    };
        }}
    };

    const fetchData = async (input) => {
        
        try {
            const response =  await fetch(`${process.env.REACT_APP_HOST_IP}/api/kw-suggest`,{
                method: 'POST',
                body: JSON.stringify(input),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'X-Device-ID': localStorage.getItem('device_id'),
                    'X-User': userName
                }
                });
            if (!response.ok || !response.body) {
                throw response.statusText;
                }
        
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const startTime = new Date().getTime();
            let concat_str = ''
            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    logger.log(new Date(new Date().getTime() - startTime).getSeconds());
                    const data = JSON.parse(concat_str)['data'];
                    logger.log(data);
                    setScreenMode('finish');  
                    setData(data);
                    if (additions.length > 0){
                        setGroupDetail(data[[data['additions'][0]]]);
                        setGroup(data['stats'][[data['additions'][0]]]);
                    } else {
                        setGroupDetail(data['Default']);
                        setGroup(data['stats']['Default']);
                    }
                    
                    // logger.log(data['stats'][data['additions'][0]]);
                    break;
                }
        
                const decodedChunk = decoder.decode(value, { stream: true });
                // logger.log(decodedChunk);
                if (decodedChunk.length < 100) {
                    logger.log(decodedChunk)
                    const crawled = JSON.parse(decodedChunk)['data'];
                    // logger.log(crawled);
                    setTotalQueue(crawled['total_queue']);
                    setTotalDiscovered(crawled['total_discovered']);
                } else {
                    concat_str += decodedChunk;
                }
                
            }
        } catch (error){
            logger.log(error);
        }
    };


    const suggestKwHandler = () => {
        if (seed.current.value === '') return;
        if (customText.current.value !== '' && pos === '') return;
        setGroup({});
        setGroupDetail([]);
        let d = []
        if (customText.current.value !== ''){
            d = [...additions, 'Custom'];
            setAdditions(d);
        }else {
            d = [...additions];
        }
        const id = uuid4();
        setSuggestionId(id);
        const input = {
            id_: id,
            query: seed.current.value.split('\n'),
            country: countryList[country],
            language: languageList[language],
            platform: platform,
            additions: d,
            custom_text: customText.current.value.split('\n'),
            position: pos,
            scan_depth: depth,
            include: incl.current.value.split('\n'),
            exclude: excl.current.value.split('\n')
        };
        logger.log(input);
        if (additions.length > 0){
            setActiveTab(additions[0]);
        } else {
            setActiveTab('Default');
        }

        
        setAdvanced(false);
        setScreenMode('running');
        fetchData(input);
    };

    const stopSuggestingHandler = () => {
        apiClient.post('/api/stop-kw-suggest', {id: suggestionId}, {headers: {'X-User': userName}})
        .then (
                    ({data}) =>{
                        logger.log(data)
                }).catch(e =>
                    logger.log(e)
                )
    };
    
    const groupDetailHandler = (e, l, w) => {
        // const d = data['Alphabet'].filter(item => {
        //     return item.search(new RegExp(w, 'i')) !== -1
        // });
        const d = document.getElementsByClassName('row-checkbox');
        for ( let i = 0; i < d.length; i++) d[i].checked = false;
        setSelectKwList([]);
        e.preventDefault();
        setActiveLetter(l);
        setGroupDetail(w);
    };
    const changeTabHandler = (tab) => {
        setActiveTab(tab);
        setActiveLetter('');
        let d = [];
        let check = [];
        Object.entries(data['stats'][[tab]]).map(([k, v]) => {
            v['contains'].map(item => {
                if (Array.isArray(item)){
                    if (!check.includes(item[0])){
                        d.push(item);
                        check.push(item[0]);
                    }
                }else{
                    d.push(item)
                }
            })
        });
        d = [...new Set(d)]
        setGroupDetail(d);
        setGroup(data['stats'][[tab]]);
    };


    const changeSelectedKwHandler = (e, kw) => {

        if (e.target.checked) {
            if (kw === undefined) {
              const d = document.getElementsByClassName('row-checkbox');
              for ( let i = 0; i < d.length; i++) d[i].checked = true;
              setSelectKwList(groupDetail);
            } else {
              setSelectKwList(c => [...c, kw]);
            }
          } else {
            if (kw === undefined){
              const d = document.getElementsByClassName('row-checkbox');
              for ( let i = 0; i < d.length; i++) d[i].checked = false;
              setSelectKwList([]);
            } else {
              const new_list = selectKwList.filter(item => !(item === kw));
              setSelectKwList(new_list);
            }
          };
        // logger.log(selectKwList);
    };

    const createTaskHandler = () => {
        if (selectKwList.length === 0) return;
        let li = {
            topic: taskName.current.value,
            domain: domain.current.value,
            description: description.current.value,
            keywords: selectKwList,
            task: 'grouping',
            date: new Date().getTime(),
            };
        if(taskId !== '') li['_id'] = taskId;
        // logger.log(li);
        apiClient.post('/api/create-task', li, {headers: {'X-User': userName}})
        .then (
            ({data}) =>{
            if (data === true) {
                setImportStat(true);
            } else {
                setImportStat(false);
            };
            }
        );
    };

    const updateVolumeHandler = () => {
        dispatch(showLoading());
        if (selectKwList.length === 0 || selectKwList.length > 2000) return;
        let s = selectKwList.filter(item => !Array.isArray(item));
        let lang = ''
        if (languageList[language] === 'vi'){
            lang = '1040'
        } else if (languageList[language] === 'en'){
            lang = '1000'
        }
        const input = {
            location: countryList[country],
            language: lang,
            keyword_seed: s
        };
        logger.log(input);
        apiClient.post('/api/generate-historical-metrics', input, {headers: {'X-User': userName}})
        .then (
          ({response}) =>{
            dispatch(hideLoading());
            logger.log(response);
            let g = groupDetail.map(kw => {
                let c = kw
                response.map(it => {
                    if (kw === it['Keyword']){
                        if (it['Volume'] !== null){
                            c = [kw, it['Volume']]  
                        } else {
                            c = [kw, 0]
                        }
                        
                    }
                });
                return c
            });
            setGroupDetail(g);
            logger.log(g);
            logger.log(data['stats'][[activeTab]]);
            logger.log(activeTab);
            let e = 
                Object.entries(data['stats'][[activeTab]]).reduce((a, [k, v]) => {
                    // logger.log(k, activeLetter, v);
                    let c = v['contains'];
                    response.map(it => {
                        if (v['contains'].includes(it['Keyword'])){
                            let volume = 0
                            if (it['Volume'] !== null){
                                volume = it['Volume'];
                            }
                            let idx = c.indexOf(it['Keyword']);
                            c.splice(idx, 1);
                            c = [...c, [it['Keyword'], volume]];
                        }
                    });
                    return {...a,
                        [k]: {
                            'value': v['value'],
                            'contains': c
                        }
                    }
                }, {});
            
            // logger.log(e);
            let d = data;
            // logger.log(d);
            let tab = 'Default'
            if (activeTab !== ''){
                tab = activeTab
            }
            d['stats'][[tab]] = {...e}
            setData(d);
            setGroup(d['stats'][[tab]]);
          })
    }

    const getRefreshTaskHandler = () => {
        apiClient.post('/api/manage-tasks', {}, {headers: {'X-User': userName}}).then (
            ({data}) =>{
                const task = data.filter(item => item.task === 'grouping');
                setPendingTasks(task);
            })
    };

    const downloadFileHandler = () => {
        const d = groupDetail.map(item => {
            return [item]
        });
        const csv = Papa.unparse({
            "fields": ["Keyword"],
            "data": d
        });
        // logger.log(csv);
        const blob = new Blob([csv]);

        const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
        // logger.log(blob);
        const link = document.createElement("a");
        link.download = 'keyword_suggestion.csv';
        link.href = url;
        link.click();
    };

    const viewAllKwHandler = () => {
        const e = document.getElementsByClassName('row-checkbox');
        for ( let i = 0; i < e.length; i++) e[i].checked = false;
        setSelectKwList([]);
        setActiveLetter('');
        let d = [];
        if (additions.length > 0){
            let check = [];
            additions.map(tab => {
                Object.entries(data['stats'][[tab]]).map(([k, v]) => {
                    v['contains'].map(item => {
                        if (Array.isArray(item)){
                            if (!check.includes(item[0])){
                                d.push(item);
                                check.push(item[0]);
                            }
                        }else{
                            d.push(item)
                        }
                    })
                });
            });
            d = [...new Set(d)]
            setGroupDetail(d);
        } else {
            let check = [];
            Object.entries(data['stats']['Default']).map(([k, v]) => {
                v['contains'].map(item => {
                    if (Array.isArray(item)){
                        if (!check.includes(item[0])){
                            d.push(item);
                            check.push(item[0]);
                        }
                    }else{
                        d.push(item)
                    }
                })
            });
            d = [...new Set(d)]
            logger.log(check);
            setGroupDetail(d);
        }
        
    };
    const validateSeedHandler = (text) => {
        let arr = text.split('\n')
        arr = arr.filter(item => item !== '')
        if(arr.length > 5){
            setTextValid('exceed');
        }else if(arr.length === 0){
            setTextValid('empty');
        }else{
            setTextValid('pass');
        }
    }

   return (
        <div className="d-flex flex-column">
            <h1 className="text-center my-4">Keyword Suggestion</h1>
            <div className="d-flex mb-4">
                <div className='d-flex col-md-9 flex-column pe-3'>
                    <textarea rows="3" maxRows="5" className="form-control me-4" ref={seed} placeholder="Enter seed keyword. Each separated by newline.&#10;Max 5 keywords allowed" aria-label="Enter seed keyword" aria-describedby="basic-addon2" 
                        onChange={e => validateSeedHandler(e.target.value)}
                    />
                    <p className={`${[undefined, 'empty', 'pass'].includes(textValid) && 'd-none'} mt-2`} style={{color:'red'}}>Max 5 keywords allowed</p>
                    <div className='d-flex my-3'>
                        {/* Platform filter */}
                        <div class="dropdown me-3 align-self-center me-3">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faGlobe} className='me-2' />
                                {platform}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'300px', 'overflow': 'auto', width: '250px'}}>
                                {filteredPlatform.map((item, index) => {
                                    return (
                                        <li key={index} onClick={e => {setPlatform(item)}}><a class="dropdown-item" href="#">{item}</a></li>
                                    )
                                })}
                            </ul>
                        </div>
                        {/* Country filter */}
                        <div class="dropdown me-3 align-self-center me-3">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faGlobe} className='me-2' />
                                {country}
                            </button>
                            <ul class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'300px', 'overflow': 'auto', width: '250px', zIndex: 9999}}>
                                <input type="text" class="form-control sticky-top" placeholder="Search country..." aria-label="Search country..." aria-describedby="basic-addon1"
                                onChange = {e => filterHandler('country', e.target.value)}
                                />
                                {Object.keys(filteredCountry).length > 0 &&
                                Object.keys(filteredCountry).map((item, index) => {
                                    return (
                                        <li key={index} onClick={e => {setCountry(item)}}><a class="dropdown-item" href="#">{item}</a></li>
                                    )
                                })}
                            </ul>
                        </div>
                        {/* Language filter */}
                        <div class="dropdown me-3 align-self-center me-auto">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faGlobe} className='me-2' />
                                {language}
                            </button>
                            <ul class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'300px', 'overflow': 'auto', width: '250px', zIndex: 9999}}>
                                <input type="text" class="form-control sticky-top" placeholder="Search country..." aria-label="Search country..." aria-describedby="basic-addon1"
                                onChange = {e => filterHandler('language', e.target.value)}
                                />
                                {Object.keys(filteredLanguage).length > 0 &&
                                Object.keys(filteredLanguage).map((item, index) => {
                                    if (['Vietnamese', 'English'].includes(item)){
                                        return (
                                            <li key={index} onClick={e => {setLanguage(item)}}><a class="dropdown-item" href="#">{item}</a></li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className={`d-flex mt-4 flex-column ${!advanced && 'd-none'}`}>
                        <h5 className='mb-3'>Additions</h5>
                        <div className='d-flex mb-3'>
                            <div className='d-flex col-md-6 flex-column justify-content-around'>
                                {   ["Alphabet", "Numbers", "Questions", "Prepositions", "Comparison"].map(item =>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" disabled={language === 'Vietnamese' && ['Questions', 'Prepositions'].includes(item)} value={item} id={item} onChange={e => {!additions.includes(item) ? setAdditions(c => [...c, item]) : setAdditions(c => {const d = c.filter(val => val !== item); return d})}}/>
                                            <label class="form-check-label" for={item}>
                                                {item}
                                            </label>
                                        </div>
                                )}
                            </div>
                            <div className='d-flex col-md-6'>
                                <div className='d-flex flex-column border border-2 w-100' style={{height: '150px'}}>
                                    {
                                        additions.includes("Alphabet") &&
                                        <div className={`flex-fill border-top ps-2`}>A B C D E F G H I J K L M O P Q R S T U V W X Y Z</div>
                                    }
                                    {
                                        additions.includes("Numbers") &&
                                        <div className={`flex-fill border-top ps-2`}>1 2 3 4 5 6 7 8 9 0</div>
                                    }
                                    {
                                        additions.includes("Questions") &&
                                        <div className={`flex-fill border-top ps-2`}>Questions</div>
                                    }
                                    {
                                        additions.includes("Prepositions") &&
                                        <div className={`flex-fill border-top ps-2`}>Prepositions</div>
                                    } 
                                    {
                                        additions.includes("Comparison") &&
                                        <div className={`flex-fill border-top ps-2`}>Comparison</div>
                                    }    
                                </div>
                            </div>
                        </div> 
                        <h5 className='mb-3 mt-4'>Custom text</h5>
                        <textarea rows="5" ref={customText} className="form-control mb-3" placeholder="Enter custom text" aria-label="Enter custom text" aria-describedby="basic-addon2" />
                        <h5 className='mb-3 mt-4'>Position of custom texts</h5>
                        <div className='d-flex flex-column justify-content-around'>
                            {   ["Before", "After"].map(item =>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id={item} onChange={e => setPos(item)}/>
                                        <label class="form-check-label" for={item}>
                                            {item}
                                        </label>
                                  </div>
                            )}
                        </div>
                        <h5 className='mb-3 mt-4'>Scan depth</h5>
                        <select className="form-select w-25" aria-label="Default select example" onChange={e => setDepth(e.target.value)}>
                            {
                                [0,1,2,3,4,5].map(item => 
                                    <option selected={item === 3} value={item}>{item}</option>
                                )
                            }
                        </select>
                        <h5 className='mb-3 mt-4'>Include</h5>
                        <textarea ref={incl} rows="3" className="form-control mb-3" />
                        <h5 className='mb-3 mt-4'>Exclude</h5>
                        <textarea ref={excl} rows="3" className="form-control mb-3" />
                    </div>
                </div>
                <div className='d-flex col-md-3 flex-column'>
                    <button type="button" class="btn btn-primary text-nowrap mb-3"
                        disabled={textValid === 'pass' ? false : true}
                        onClick = {suggestKwHandler}
                    >Suggest Keywords</button>
                    <button type="button" class="btn btn-outline-primary text-nowrap"
                        onClick = {e => setAdvanced(c => !c)}
                    >Advanced Options</button>
                </div>
                
            </div>
            {/* running */}
            {
                screenMode === 'running' &&
                <>
                    <div className = 'd-flex my-4 align-self-center w-75'>
                        <div className='d-flex col-md-6 align-items-center flex-column '>
                            <h5>Queued</h5>
                            <div className='d-flex border border-2 justify-content-center align-items-center' style={{width: '200px', 'height': '100px'}}>
                                <h5>{totalQueue}</h5>
                            </div>
                        </div>
                        <div className='d-flex col-md-6 align-items-center flex-column'>
                            <h5>Discovered</h5>
                            <div className='d-flex border border-2 justify-content-center align-items-center' style={{width: '200px', 'height': '100px'}}>
                            <h5>{totalDiscovered}</h5>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-warning text-nowrap w-25 align-self-center"
                        onClick = {stopSuggestingHandler}
                    >Stop Suggesting</button>
                </>
            }
            { 
                screenMode === 'finish' &&
                <div className="row flex-grow-1 overflow-hidden my-4">
                        <div className="d-flex flex-column h-100 col-md-4">
                            <h3 className="mb-2">Group</h3>
                            <div className="d-flex mb-2">
                                <input type="text" class="form-control" placeholder="Search Group..." aria-label="Search Group..." aria-describedby="addon-wrapping" onChange={e => setGroupText(e.target.value)}/>
                            </div>
                            <ul class="d-flex justify-content-start nav nav-tabs" id="myTab" role="tablist">
                                {   additions.length > 0 ?
                                    data['additions'].map(item => 
                                        <li class="nav-item" role="presentation">
                                            <button class={`nav-link ${activeTab === item && 'active'}`} id={item}
                                                onClick={e => changeTabHandler(item)}
                                            >{item}</button>
                                        </li>
                                        ) :
                                        <>
                                            <li class="nav-item" role="presentation">
                                                <button class={`nav-link ${activeTab === "Default" && 'active'}`} id="Default"
                                                    onClick={e => changeTabHandler("Default")}
                                                >Default</button>
                                            </li>
                                        {   
                                        data['additions'].filter(item => item === 'Custom').map(item => 
                                            <li class="nav-item" role="presentation">
                                                <button class={`nav-link ${activeTab === item && 'active'}`} id={item}
                                                    onClick={e => changeTabHandler(item)}
                                                >{item}</button>
                                            </li>
                                            )
                                        }
                                        </>      
                                }
                            </ul>
                            <div className="col overflow-auto" style={{maxHeight: '800px'}}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-text">
                                            <div class="list-group">
                                                { Object.keys(group).length > 0 &&
                                                Object.entries(group).map(([key, value]) => 
                                                    Object.entries(group[[key]]).map(([letter, val]) => 
                                                    letter === 'value' &&
                                                    <button type="button" className="d-flex align-items-center list-group-item list-group-item-action"
                                                        onClick={e => groupDetailHandler(e, key, group[[key]]['contains'])}
                                                    >
                                                            {key}
                                                            {   val > 0 &&
                                                                <span className="ms-auto badge bg-primary rounded-pill">{val}</span>
                                                            }
                                                    </button>
                                                    )
                                                )
                                                }
                                                
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" d-flex flex-column h-100 col-md-8 overflow-hidden">
                            <div className='d-flex mb-2 align-items-center'>
                                <h3 className="text-nowrap">Keyword Detail</h3>
                                <a href='#' className='ms-2' onClick={viewAllKwHandler}>View all keywords</a>
                                <div className='d-flex ms-auto'>
                                    {selectKwList.length > 0 &&
                                        <span class={`badge ${selectKwList.length > 2000 ? 'bg-danger' : 'bg-secondary'} align-self-center me-2`}>{selectKwList.length}</span>
                                    }
                                    {
                                        platform !== 'Youtube' &&
                                        <button type="button" className="btn btn-primary me-2" onClick={updateVolumeHandler}>
                                            Update Volume
                                        </button>
                                    }
                                    <button type="button" className="btn btn-primary me-2" onClick={downloadFileHandler}>
                                        <FontAwesomeIcon icon={faDownload} />
                                    </button>
                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#ModalMultiSelect"
                                        onClick = {e => setImportStat('')}
                                    >+ Add Task</button>
                                </div>
                            </div>
                            {/* Modal Update Multiple Items */}
                            <div class="modal fade" id="ModalMultiSelect" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="ModalLongTitle">Create Task</h5>
                                        <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                            <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                            <span className='ms-1'>Success</span>
                                        </div>
                                        <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                            <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                            <span className='ms-1'>Failed. Pls Retry!</span>
                                        </div>
                                    </div>
                                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                        <div class="d-flex flex-column mb-4">
                                            <div class="input-group mb-3 d-flex align-items-center">
                                                <span class="input-group-text">Add to Pending Task</span>
                                                <div class="dropdown me-4">
                                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="taskID" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Pending task
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    { pendingTasks.map((item, index) => {
                                                            return (
                                                                <li key={index}><a class="dropdown-item" href="#" onClick={e => {e.preventDefault();
                                                                    setTaskId(item._id);
                                                                    document.getElementById('taskID').innerHTML = item.topic;
                                                                    taskName.current.value = item.topic;
                                                                    domain.current.value = item.domain;
                                                                    description.current.value = item.description;
                                                                }}
                                                                >{item.topic}</a></li>
                                                            )
                                                    })
                                                    }
                                                    </ul>
                                                </div>
                                                <button className='btn' onClick={getRefreshTaskHandler}>
                                                    <FontAwesomeIcon icon={faRefresh} />
                                                </button>
                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text">Task name</span>
                                                <input type="text" class="form-control" placeholder="Task name" aria-label="Task name" aria-describedby="basic-addon1"
                                                ref={taskName}
                                                />
                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text">Domain</span>
                                                <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1"
                                                ref={domain}
                                                />
                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text">Description</span>
                                                <textarea class="form-control" placeholder="Task description" aria-label="Task description" aria-describedby="basic-addon1"
                                                ref={description}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                        <button type="button" className={`btn btn-primary ${importStat === true && 'disabled'}`}
                                        onClick = {createTaskHandler}
                                        >Create</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* Modal Update Multiple Items */}
                            <div className="d-flex flex-row pb-4 mb-4 overflow-auto" style={{maxHeight: '800px'}}>
                                    <table class="table table-striped">
                                        <thead className="sticky-top">
                                            <tr className='table-primary'>
                                                <th scope="col">
                                                    <div>
                                                        <input class="form-check-input row-checkbox" type="checkbox" id='master-checkbox' aria-label="..." onChange={e => {changeSelectedKwHandler(e)}}/>
                                                    </div>
                                                </th>
                                                <th scope="col">#</th>
                                                <th scope="col">
                                                    <div className='d-flex'>
                                                        <button class="btn" type="button" aria-expanded="false" onClick={event => tableSortHandler('kw')}>
                                                            <span className='me-2 fw-bold'>Keyword </span>
                                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'keyword_inc' ? '#000' : '#6c757d'}`}}/>
                                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'keyword_dec' ? '#000' : '#6c757d'}`}}/>
                                                        </button>
                                                        <input type="text" className="form-control ms-4 w-50" placeholder="Search keyword..." 
                                                        onChange={e => setGroupDetailText(e.target.value)}
                                                        />
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <button class="btn" type="button" aria-expanded="false" onClick={event => tableSortHandler('volume')}>
                                                        <span className='me-2 fw-bold'>Volume </span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'volume_inc' ? '#000' : '#6c757d'}`}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'volume_dec' ? '#000' : '#6c757d'}`}}/>
                                                    </button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {   groupDetail.length > 0 &&
                                                groupDetail.map((item, index) => 
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <input class="form-check-input row-checkbox" type="checkbox" value="" aria-label="..." 
                                                                onChange={e => {changeSelectedKwHandler(e, item)}}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>{index+1}</td>
                                                        <td>{Array.isArray(item) ? item[0] : item}</td>
                                                        <td>{Array.isArray(item) ? item[1].toLocaleString() : null}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                
                            </div>
                        </div>
                    </div>
            }
        </div>
   );
};

export default KWSuggestion;