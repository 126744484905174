import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { getAuthToken } from "../util/token";
import {useEffect, useState, useRef} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import {
    CartesianGrid,
    Legend,
    Line,
    AreaChart,
    Area,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
  } from "recharts";
import moment from "moment";
import { scaleTime } from "d3-scale";
import { redirect, useLocation, useSearchParams } from "react-router-dom";
import apiClient from '../api/client';
import logger from "../util/logger";


function UserProfile() {
    const userName = localStorage.getItem('username');

    // authorize google api resources:
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showPw, setShowPw] = useState(false);
    const [showCPw, setShowCPw] = useState(false);
    const [showGem, setShowGem] = useState(false);
    const token = getAuthToken();
    const firstName = useRef();
    const lastName = useRef();
    const email = useRef();
    const newPw = useRef();
    const confirmPw = useRef();
    const gem = useRef();
    const [isChangingPw, setIsChangingPw] = useState(false);
    const [displayPeriod, setDisplayPeriod] = useState('Daily');
    const [freqChart, setFreqChart] = useState([]);
    const [kwUseFreqChart, setKwUseFreqChart] = useState([]);
    const [backlinkUseFreqChart, setBacklinkUseFreqChart] = useState([]);
    const [data, setData] = useState([]);
    const [kwUse, setKwUse] = useState([]);
    const [backlinkUse, setBacklinkUse] = useState([]);
    const [validatePwChange, setValidatePwChange] = useState();
    const [kwUseMode, setKwUseMode] = useState('number_of_tasks');
    const [backlinkUseMode, setBacklinkUseMode] = useState('number_of_tasks');

    const [tab, setTab] = useState('usage');
    const [hasGemini, setHasGemini] = useState(false);
    const [updateState, setUpdateState] = useState(false);
    const [authorizedSC, setAuthorizedSC] = useState(false);
    const [authorizedGA4, setAuthorizedGA4] = useState(false);

    useEffect(() => {
        apiClient.get('/api/user-profile', {headers: {'X-User': userName}})
        .then(({data}) => {
        firstName.current.value = data['info'][0]['first_name'];
        lastName.current.value = data['info'][0]['last_name'];
        email.current.value = data['info'][0]['email'];
        setData(data['usage']);
        setKwUse(data['kw_usage']);
        setBacklinkUse(data['backlink_usage']);
        logger.log(data);
        if (data['info'][0]['has_gemini_token']){
          setHasGemini(true);
        };
        if (data['info'][0]['authorized_search_console']){
          setAuthorizedSC(true);
        };
        if (data['info'][0]['authorized_ga4']){
          setAuthorizedGA4(true);
        };
      })
    }, []);

    const togglePwHandler = (e, type) => {
        e.preventDefault();
        if (type === 'new'){
            setShowPw(c => !c);
        } else if (type === 'confirm') {
            setShowCPw(c => !c);
        } else if (type === 'gemini'){
          setShowGem( c => !c);
        }
        
    };

    const renderLineTooltipContent = (o) => {
        const { payload = [], label } = o;      
        return (
          <div className="customized-tooltip-content">
            <p className="total">{`${moment(label).format('YYYY-MM-DD')}`}</p>
            <ul className="list">
              {payload !== null &&
              payload.map((entry, index) => (
                <li key={`item-${index}`} style={{ color: entry.color }}>
                  {`${entry.name}: ${entry.name === 'total_cost' ? entry.value.toFixed(2) : entry.value}`}
                </li>
              ))}
            </ul>
          </div>
        );
      };
    
    const getXAxisArgsForTimeBasedGraph = (numericValues) => {
    const maxValue = Math.max(...numericValues);
    const minValue = Math.min(...numericValues);
    const timeScale = scaleTime().domain([minValue, maxValue]).nice(5);
    
    return {
        scale: timeScale,
        type: "number",
        domain: timeScale.domain(),
        tickFormatter: (unixTime) => moment(unixTime).format('YYYY-MM-DD'),
        ticks: timeScale.ticks(5)
    };
    };

    const changeDisplayFreqHandler = (freq) => {
        const end = new Date();
        const endUnix = end.getTime()
        const startUnix = new Date(end.getFullYear(), end.getMonth() - 12, 1).getTime();
        setDisplayPeriod(freq);
      if (freq === 'Weekly') {
        const msInWeek = 1000*60*60*24*7;
        const curYear = new Date().getFullYear();
        const first_week = Math.ceil((startUnix - new Date(curYear, 0, 1).getTime())/msInWeek);
        const total_week = Math.round(Math.abs(startUnix - endUnix)/msInWeek);
        let weekly_detail = [];
        let kw_weekly_detail = [];
        let backlink_weekly_detail = [];
        for (let i = 0 ; i <=total_week;  i++){
          // token
          let week_object = {
            // ...t,
            date: new Date(curYear, 0, 1).getTime() + (first_week + i)*msInWeek
          };
          data.filter(item => (
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek ) >= (first_week + i)) && 
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek) < (first_week + i+1)) )).map((item, index) => {
            // logger.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (!(it in Object.keys(week_object))) {
                  week_object[it] = [item[it]];
                } else {
                  week_object[it].push(item[it]);
                };
              }
            });
          });
          weekly_detail.push(week_object);
          // kw research
          let kw_week_object = {
            // ...t,
            date: new Date(curYear, 0, 1).getTime() + (first_week + i)*msInWeek
          };
          kwUse.filter(item => (
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek ) >= (first_week + i)) && 
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek) < (first_week + i+1)) )).map((item, index) => {
            // logger.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (!(it in Object.keys(kw_week_object))) {
                  kw_week_object[it] = [item[it]];
                } else {
                  kw_week_object[it].push(item[it]);
                };
              }
            });
          });
          kw_weekly_detail.push(kw_week_object);

          // kw research
          let backlink_week_object = {
            // ...t,
            date: new Date(curYear, 0, 1).getTime() + (first_week + i)*msInWeek
          };
          backlinkUse.filter(item => (
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek ) >= (first_week + i)) && 
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek) < (first_week + i+1)) )).map((item, index) => {
            // logger.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (!(it in Object.keys(backlink_week_object))) {
                  backlink_week_object[it] = [item[it]];
                } else {
                  backlink_week_object[it].push(item[it]);
                };
              }
            });
          });
          backlink_weekly_detail.push(backlink_week_object);
        }

        weekly_detail = weekly_detail.filter(item => Object.keys(item).length > 1);
        weekly_detail.map(item => {
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0);
              }
            })
        });

        kw_weekly_detail = kw_weekly_detail.filter(item => Object.keys(item).length > 1);
        kw_weekly_detail.map(item => {
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0);
              }
            })
        });
        backlink_weekly_detail = backlink_weekly_detail.filter(item => Object.keys(item).length > 1);
        backlink_weekly_detail.map(item => {
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0);
              }
            })
        });
        setFreqChart(weekly_detail);
        setKwUseFreqChart(kw_weekly_detail);
        setBacklinkUseFreqChart(backlink_weekly_detail);
        // logger.log(kw_weekly_detail);
      } else if(freq === 'Monthly') {
        const startMonth = new Date(startUnix).getMonth();
        const startYear = new Date(startUnix).getFullYear();
        const endMonth = new Date(endUnix).getMonth();
        const endYear = new Date(endUnix).getFullYear();
        const totalMonth = endMonth - startMonth + 12*(endYear - startYear);
        // logger.log(
        // endMonth - startMonth + 12*(endYear - startYear)
        // );
        let monthly_detail = [];
        let kw_monthly_detail = [];
        let backlink_monthly_detail = [];
        for (let i = 0 ; i <=totalMonth;  i++){
          //token
          let monthly_object = {
            // ...t,
            date: new Date(startYear, startMonth + i, 1).getTime()
          };
          data.filter(item => (
            (item.date >= new Date(startYear, startMonth + i, 1).getTime()) && 
            (item.date < new Date(startYear, startMonth + i + 1, 1).getTime()) )).map((item, index) => {
            // logger.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (!(it in Object.keys(monthly_object))) {
                  monthly_object[it] = [item[it]];
                } else {
                  monthly_object[it].push(item[it]);
                };
              }
            });
          });
          // logger.log(week_object);
          monthly_detail.push(monthly_object);

          // kw research
          let kw_monthly_object = {
            // ...t,
            date: new Date(startYear, startMonth + i, 1).getTime()
          };
          kwUse.filter(item => (
            (item.date >= new Date(startYear, startMonth + i, 1).getTime()) && 
            (item.date < new Date(startYear, startMonth + i + 1, 1).getTime()) )).map((item, index) => {
            // logger.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date' && it !=='username'){
                if (!(Object.keys(kw_monthly_object).includes(it))) {
                  kw_monthly_object[it] = [item[it]];
                } else {
                  kw_monthly_object[it].push(item[it]);
                };
              }
            });
          });
          // logger.log(kw_monthly_object);
          kw_monthly_detail.push(kw_monthly_object);

          // backlink research
          let backlink_monthly_object = {
            // ...t,
            date: new Date(startYear, startMonth + i, 1).getTime()
          };
          backlinkUse.filter(item => (
            (item.date >= new Date(startYear, startMonth + i, 1).getTime()) && 
            (item.date < new Date(startYear, startMonth + i + 1, 1).getTime()) )).map((item, index) => {
            // logger.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date' && it !=='username'){
                if (!(Object.keys(backlink_monthly_object).includes(it))) {
                  backlink_monthly_object[it] = [item[it]];
                } else {
                  backlink_monthly_object[it].push(item[it]);
                };
              }
            });
          });
          backlink_monthly_detail.push(backlink_monthly_object);
        };

        
        // logger.log(kw_monthly_detail);
        monthly_detail = monthly_detail.filter(item => Object.keys(item).length > 1);
        monthly_detail.map(item => {
          Object.keys(item).map(it => {
            if (it !== 'date'){
              if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0);
            }
          });
        });
        kw_monthly_detail = kw_monthly_detail.filter(item => Object.keys(item).length > 1);
        kw_monthly_detail.map(item => {
          Object.keys(item).map(it => {
            if (it !== 'date'){
              if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0);
            }
          });
        });
        backlink_monthly_detail = backlink_monthly_detail.filter(item => Object.keys(item).length > 1);
        backlink_monthly_detail.map(item => {
          Object.keys(item).map(it => {
            if (it !== 'date'){
              if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0);
            }
          });
        });
        setFreqChart(monthly_detail);
        setKwUseFreqChart(kw_monthly_detail);
        setBacklinkUseFreqChart(backlink_monthly_detail);
        // logger.log(kw_monthly_detail);
      }
    };

    const changePwHandler = () => {
        if ((newPw.current.value !== confirmPw.current.value) || newPw.current.value === ''){
            setValidatePwChange(false);
            return
        } else {
            setValidatePwChange(true);
        }
        apiClient.put('/api/update-password', {pw : confirmPw.current.value}, {headers: {'X-User': userName}})
        .then (
              ({data}) => {
                if (data){
                    setTimeout(() => {
                        setIsChangingPw(false);
                    },3000)
                }
              }
          )
    };

    const saveGeminiHandler = (type) => {
      if (gem.current.value === '' && type === 'update') return;
      apiClient.put('/api/llm/save-gemini', {type: type, gemini_token: gem.current.value}, {headers: {'X-User': userName}})
      .then (
              ({data}) => {
                if (data){
                  logger.log(data)
                  setUpdateState(true);
                  setTimeout(() =>{
                    setUpdateState(false);
                  }
                  ,3000)
                  if (type === 'update'){
                    setHasGemini(true)
                  } else if (type ==='delete'){
                    setHasGemini(false)
                  }
                }
              }
          )
    };

    const authorizeSCHandler = () => {
      window.location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_AUTHORIZE_URI}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fwebmasters.readonly&access_type=offline`
    };

    const authorizedGA4Handler = () => {
      window.location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_AUTHORIZE_URI}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&access_type=offline`
    }

    useEffect(() => {
      if (searchParams.get('code')){
          logger.log(searchParams.get('code'));
          let c = searchParams.get('code');
          let scope = searchParams.get('scope');
          let auth_type = 'authorize_sc';
          if (new RegExp('analytics').test(scope)){
            auth_type = 'authorize_ga4'
          }
          apiClient.get('/api/callback/auth', {params: {code: c, auth_type: auth_type}, headers: {'X-User': userName}})
          .then (
              ({data}) =>{ 
                  const token = data.token;
                  logger.log(data);
                  window.location.href = `${process.env.REACT_APP_HOST_IP}:3000/user-profile`
              })
          }
  }, [location]);

  const revokeSCHandler = (resource_name) => {
    apiClient.post('/api/revoke-gg-resources', {revoke_resource: resource_name}, {headers: {'X-User': userName}})
    .then (
        ({data}) =>{
          logger.log(data);
          if (data && resource_name === 'search_console'){
            setAuthorizedSC(false);
          } else if (data && resource_name === 'ga_4'){
            setAuthorizedGA4(false);
          }
         })
  };


    return (
        <div className="d-flex flex-column h-100">
            <h1 className="text-center my-4">User Profile</h1>
            <div className="d-flex mt-4">
                <div className='col-md-4 d-flex justify-content-center align-self-start'>
                    <img src="gleads-logo.png" class="rounded-circle img-fluid" width="100" height="100" alt="..." />
                </div>
                <div className='d-flex flex-column col-md-8'>
                    <div className="d-flex">
                        <div class="col-md-4 me-3">
                            <label for="firstName" class="form-label">First name</label>
                            <input type="text" class="form-control" id="firstName" ref={firstName}/>
                        </div>
                        <div class="col-md-4">
                            <label for="lastName" class="form-label">Last name</label>
                            <input type="text" class="form-control" id="lastName" ref={lastName}/>
                        </div>
                    </div>
                    <div className="d-flex mt-2">
                        <div class="col-md-4 me-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="text" class="form-control" id="email" ref={email}/>
                        </div>
                        <div class="col-md-4">
                            {
                                isChangingPw ?
                                <div className='d-flex flex-column'>
                                    <div className="">
                                        <label for="password" class="form-label">New Password</label>
                                        <div className="input-group">
                                            <input type={`${showPw ? 'text' : 'password'}`} class="form-control" id="password" ref={newPw}/>
                                            <label class="input-group-text" for="password">
                                                <a href="#" className="link-secondary" onClick = {e => togglePwHandler(e, 'new')}>
                                                    {
                                                        showPw ?
                                                        <FontAwesomeIcon icon={faEyeSlash} /> :
                                                        <FontAwesomeIcon icon={faEye} />
                                                    }
                                                    
                                                </a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <label for="password" class="form-label">Confirm Password</label>
                                        <div className="input-group">
                                            <input type={`${showCPw ? 'text' : 'password'}`} class="form-control" id="password" ref={confirmPw}/>
                                            <label class="input-group-text" for="password">
                                                <a href="#" className="link-secondary" onClick = {e => togglePwHandler(e, 'confirm')}>
                                                    {
                                                        showCPw ?
                                                        <FontAwesomeIcon icon={faEyeSlash} /> :
                                                        <FontAwesomeIcon icon={faEye} />
                                                    }
                                                    
                                                </a>
                                            </label>
                                        </div>
                                    </div>
                                    {validatePwChange === false ?
                                    <div style={{color: 'red'}}>
                                        Confirm password doesn't match new password.
                                    </div> :
                                    validatePwChange === true ?
                                    <div style={{color: 'green'}}>
                                        Password changed successfully.
                                    </div> :
                                    null
                                    }
                                    
                                    <button type="button" className="mt-3 btn btn-primary" onClick={changePwHandler}>Change password</button>
                                </div> :
                                <div className='d-flex'>
                                    <button className='btn btn-light p-2 mt-4 align-self-end' onClick={e => {setValidatePwChange(''); setIsChangingPw(c => !c)}}>
                                        Change Password
                                    </button>
                                </div>
                            }
 
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex mt-4'>
              <div className='col-md-4 d-flex justify-content-center align-self-start my-5'>
                <ul class="list-group list-group-flush w-100 p-4">
                  <button type="button" class={`list-group-item list-group-item-action ${tab === 'usage' && 'active'}`} onClick={e => setTab('usage')}>Historical Usage</button>
                  <button type="button" class={`list-group-item list-group-item-action ${tab === 'integration' && 'active'}`} onClick={e => setTab('integration')}>Integration</button>
                </ul>
              </div>
              <div className='d-flex flex-column col-md-8'>
                <hr className="my-5"/>
                {
                  tab === 'usage' ?
                  <>
                    <h5>LLM Model</h5>
                    <div className='d-flex ms-auto mt-2'>
                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" class="btn-check" name="btnradio" id="btnradio1" value='Daily' autocomplete="off" onChange={e => changeDisplayFreqHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="btnradio1">Daily</label>

                            <input type="radio" class="btn-check" name="btnradio" value='Weekly' id="btnradio2" autocomplete="off" onChange={e => changeDisplayFreqHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="btnradio2">Weekly</label>

                            <input type="radio" class="btn-check" name="btnradio" value='Monthly' id="btnradio3" autocomplete="off" onChange={e => changeDisplayFreqHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="btnradio3">Monthly</label>
                        </div>
                    </div>
                    <div className='d-flex mt-5' style={{'height': '400px'}}>
                        <ResponsiveContainer width="100%" height="80%">
                            <AreaChart
                            width={500}
                            height={300}
                            data={displayPeriod === 'Daily' ? data : freqChart}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                                <defs>
                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                                </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                name = "date"
                                // tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm YYYY-MM-DD')}
                                {...getXAxisArgsForTimeBasedGraph(data.map((item) => item.date))}

                            />
                            <YAxis 
                                domain={[0, 'auto']}
                            />
                            <Tooltip 
                                content={renderLineTooltipContent}
                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                            />
                            <Legend legendType='none'/>
                                <Area type="monotone" dataKey="token" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <hr className="my-5"/>
                    <h5>Keyword Volume</h5>
                    <select className="form-select ms-auto d-flex w-25" aria-label="Default select example" onChange={e => setKwUseMode(e.target.value)}>
                      <option selected value="number_of_tasks">Reports</option>
                      <option value="number_of_keywords">Keywords</option>
                      <option value="total_cost">Cost</option>
                    </select>
                    <div className='d-flex mt-5' style={{'height': '400px'}}>
                        <ResponsiveContainer width="100%" height="80%">
                            <AreaChart
                            width={500}
                            height={300}
                            data={displayPeriod === 'Daily' ? kwUse : kwUseFreqChart}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                                <defs>
                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                                </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                name = "date"
                                // tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm YYYY-MM-DD')}
                                {...getXAxisArgsForTimeBasedGraph(data.map((item) => item.date))}

                            />
                            <YAxis 
                                domain={[0, 'auto']}
                            />
                            <Tooltip 
                                content={renderLineTooltipContent}
                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                            />
                            <Legend legendType='none'/>
                                <Area type="monotone" dataKey={kwUseMode} stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <hr className="my-5"/>
                    <h5>Backlink</h5>
                    <select className="form-select ms-auto d-flex w-25" aria-label="Default select example" onChange={e => setBacklinkUseMode(e.target.value)}>
                      <option selected value="number_of_tasks">Reports</option>
                      <option value="total_cost">Cost</option>
                    </select>
                    <div className='d-flex mt-5' style={{'height': '400px'}}>
                        <ResponsiveContainer width="100%" height="80%">
                            <AreaChart
                            width={500}
                            height={300}
                            data={displayPeriod === 'Daily' ? backlinkUse : backlinkUseFreqChart}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                                <defs>
                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                                </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                name = "date"
                                // tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm YYYY-MM-DD')}
                                {...getXAxisArgsForTimeBasedGraph(data.map((item) => item.date))}

                            />
                            <YAxis 
                                domain={[0, 'auto']}
                            />
                            <Tooltip 
                                content={renderLineTooltipContent}
                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                            />
                            <Legend legendType='none'/>
                                <Area type="monotone" dataKey={backlinkUseMode} stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                  </> :
                  <div className='d-flex flex-column'>
                    <div class="d-flex mb-3 w-75 my-4">
                      <label for="gemini-pro" className="form-label text-nowrap align-self-center m-0"><h5>Gemini Pro</h5></label>
                      <div className="input-group mx-3 w-100">
                          <input type={`${showGem ? 'text' : 'password'}`} placeholder={hasGemini ? '******************' : ''} class="form-control" id="gemini-pro" ref={gem}/>
                          <label class="input-group-text" for="password">
                              <a href="#" className="link-secondary" onClick = {e => togglePwHandler(e, 'gemini')}>
                                  {
                                      showGem ?
                                      <FontAwesomeIcon icon={faEyeSlash} /> :
                                      <FontAwesomeIcon icon={faEye} />
                                  }
                                  
                              </a>
                          </label>
                      </div>
                      
                      <button class="btn btn-outline-primary me-2" onClick={e => saveGeminiHandler('update')}>Save</button>
                      <button class="btn btn-outline-danger me-2" onClick={e => saveGeminiHandler('delete')}>Remove</button>
                      <FontAwesomeIcon className={`align-self-center ${!updateState && 'd-none'}`} icon={faCircleCheck} style={{color:'green'}}/>
                    </div>
                    <div className='d-flex align-items-center my-4'>
                      <h5 className='me-3'>Google Search Console</h5>
                      {
                        authorizedSC ?
                        <>
                          <div className='d-flex me-3 align-items-center'>
                            <FontAwesomeIcon className='me-2' icon={faCircleCheck} style={{color: 'green'}}/>
                            <span>Connected</span>
                          </div>
                          <button type="button" class="btn btn-primary" onClick={e => revokeSCHandler('search_console')}>Revoke</button>
                        </> :
                        <button type="button" class="btn btn-primary" onClick={authorizeSCHandler}>Authorize</button>
                      }
                    </div>
                    <div className='d-flex align-items-center my-4'>
                      <h5 className='me-3'>Google Analytics 4</h5>
                      {
                        authorizedGA4 ?
                        <>
                          <div className='d-flex me-3 align-items-center'>
                            <FontAwesomeIcon className='me-2' icon={faCircleCheck} style={{color: 'green'}}/>
                            <span>Connected</span>
                          </div>
                          <button type="button" class="btn btn-primary" onClick={e => revokeSCHandler('ga_4')}>Revoke</button>
                        </> :
                        <button type="button" class="btn btn-primary" onClick={authorizedGA4Handler}>Authorize</button>
                      }
                    </div>
                  </div>
                  
                }
              </div>
            </div>
        </div>
    )
};

export default UserProfile;