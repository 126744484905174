import React, { useEffect, useRef } from 'react';
import WordCloud from 'wordcloud';
import logger from './logger';

const WordCloudComponent = ({ words }) => {
  const wordCloudRef = useRef(null);

  useEffect(() => {
    // logger.log(wordCloudRef.current);
    const handleMouseOut = () => {
        const tooltip = document.getElementById('tooltip');
        tooltip.style.display = 'none';
    };
    if (wordCloudRef.current && words.length > 0) {
      WordCloud(wordCloudRef.current, {
        list: words,
        gridSize: 5,
        weightFactor: 1,
        fontFamily: 'Arial',
        color: 'random-dark',
        rotateRatio: 0,
        backgroundColor: '#ffffff',
        minSize: 0,
        hover: (item, dimension, event) => {
            const tooltip = document.getElementById('tooltip');
            if(item){
                tooltip.innerHTML = `<div><p>Anchor: ${item[0]}</p><p>Backlinks: ${item[1]}</p></div>`;
                tooltip.style.left = `${event.pageX}px`;
                tooltip.style.top = `${event.pageY}px`;
                tooltip.style.display = 'block';
            } else {
                tooltip.style.display = 'none';
            }
          },
        // mouseout: () => {
        // const tooltip = document.getElementById('tooltip');
        // tooltip.style.display = 'none';
        // }
      });
      // Attach mouseout event listener to the canvas element
      const canvas = wordCloudRef.current.querySelector('canvas');
      if(canvas){
        canvas.addEventListener('mouseout', handleMouseOut);

      // Cleanup function to remove event listener
        return () => {
            canvas.removeEventListener('mouseout', handleMouseOut);
        };
      }
    }
  }, [words]);

  return (
    <>
        <div ref={wordCloudRef} style={{ width: '600px', height: '200px' }} />
        <div id="tooltip" style={{ position: 'absolute', display: 'none', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: '#fff', padding: '5px', borderRadius: '3px' }} />
    </>
  )
};

export default WordCloudComponent;