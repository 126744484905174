import { useState, useMemo, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch, useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';

import WordCloudComponent from "../util/word-cloud";
import Papa from 'papaparse';
import { v4 as uuid4 } from 'uuid';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload, faGlobe, faLanguage, faSearch, faFilter, faAngleUp, faAngleDown, faPencil,
    faCheckCircle, faTimesCircle, faTrash, faRefresh
} from "@fortawesome/free-solid-svg-icons";

import { Form, redirect, useActionData, useNavigation } from "react-router-dom";
import {
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    BarChart,
    Bar,
    PieChart,
    Pie,
    Cell
  } from "recharts";
  import moment from "moment";
  import { scaleTime } from "d3-scale";
import { getAuthToken } from "../util/token";
import apiClient from "../api/client";
import logger from "../util/logger";

const Backlink = () => {

    const dispatch = useDispatch();
    //get auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');
    
    const [data, setData] = useState({});
    const inputURL = useRef('');

    //table pagination
    const [sort,setSort] = useState('');
    const [showResult, setShowResult] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [currentPageRange, setCurrentPageRange] = useState(1);
    
    const [rankDetail, setRankDetail] = useState([]);
    const [filteredRank, setFilteredRank] = useState([]);

    //metrics
    const [spamScore, setSpamScore] = useState([]);
    const [domainRank, setDomainRank] = useState([]);
    const [tld, setTld] = useState([]);
    const [country, setCountry] = useState([]);
    const [backlinkTypes, setBacklinkTypes] = useState([]);
    const [linkAttrs, setLinkAttrs] = useState([]);
    const [anchors, setAnchors] = useState([]);
    const [domain, setDomain] = useState([]);
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const [backlinkMode, setBacklinkMode] = useState('one_per_domain');

    const [filterArr, setFilterArr] = useState([{
      id: uuid4(),heading: 'url_from', operation: 'contains', value: ''
    }]);

    const f = {
      url_from: 'URL from',
      domain_from: 'Domain from',
      url_to: 'URL to',
      backlink_spam_score: 'Backlink spam score',
      rank: 'Rank',
      domain_from_ip: 'Domain from IP',
      page_from_external_links: 'Page from external links',
      first_seen: 'First seen',
      last_seen: 'Last seen',
      item_type: 'Backlink type',
      dofollow: 'DoFollow',
      alt: 'Alt',
      image_url: 'Image URL',
      anchor: 'Anchor'
    }


    const keySearch = useRef();

    const checkBacklinkHandler = (refresh) => {
      if (inputURL.current.value === '') return;
      // logger.log(backlinkMode);
        dispatch(showLoading());
        apiClient.post('/backlink-audit', {
          target: inputURL.current.value,
          refresh: refresh,
          mode: backlinkMode
        }, {headers: {'X-User': userName}}).then (
        ({data}) =>{
        dispatch(hideLoading());
        logger.log(data);
        if (typeof data === 'string'){
            const m = new bootstrap.Modal(document.getElementById('ModalAlert'));
            m.toggle();
            return;
        }
        setData(data);
        setDomain(data['domain']);
        setRankDetail(data['items']);
        setFilteredRank(data['items']);
        let d = Object.entries(data['spam_score'])
                    .reduce((r, [k, v])  => ([
                    ...r,
                    {'name': k,
                    'value': v}
                    ]),
                    [])
        setSpamScore(d);
        d = Object.entries(data['domain_rank'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v}
                        ]),
                        [])
        setDomainRank(d);
        let totalTLD = Object.entries(data['tld']).reduce((r, [k,v]) => r + parseInt(v), 0);
        d = Object.entries(data['tld'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v,
                        'pct': `${Math.round(parseInt(v)/totalTLD*100)} %`
                    }
                        ]),
                        []);
        // logger.log(d);
        setTld(d);
        let totalCountries = Object.entries(data['country']).reduce((r, [k,v]) => r + parseInt(v), 0);
        // logger.log('total countries: ', totalCountries)
        d = Object.entries(data['country'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v,
                        'pct': `${Math.round(parseInt(v)/totalCountries*100)} %`
                    }
                        ]),
                        [])
        // logger.log(d)
        setCountry(d);
        let backlinkType = Object.entries(data['backlink_types']).reduce((r, [k,v]) => r + parseInt(v), 0);
        d = Object.entries(data['backlink_types'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v,
                        'pct': `${Math.round(parseInt(v)/backlinkType*100)}`
                    }
                        ]),
                        [])
        setBacklinkTypes(d);
        let totalAttrs = Object.entries(data['link_attr']).reduce((r, [k,v]) => r + parseInt(v), 0);
        d = Object.entries(data['link_attr'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v,
                        'pct': `${Math.round(parseInt(v)/totalAttrs*100)}`
                    }
                        ]),
                        [])
        setLinkAttrs(d);
        d = Object.entries(data['anchor'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        [k, v]
                        ]),
                        []);
        // logger.log(d);
        setAnchors(d);
        })
    //   logger.log(data);
    //   setDomain(data['domain']);
    //   setRankDetail(data['items']);
    //   setFilteredRank(data['items']);
      
    };

    useEffect(() => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        let newPagination = [];
        for (let i = 1; i <= Math.ceil(filteredRank.length/showResult); i++){
          newPagination.push(
            <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
              setSelectedPage(i);
              const el = document.querySelectorAll('li[class*=active]')[0];
              el.className = 'page-item';
              document.getElementById(`page-${i}`).className = 'page-item active';
              }}>{i}
            </a>
          )
        };
        setPagination(newPagination);
      },[filteredRank, showResult]);

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    
    const sortHandler = (title, heading, data) => {
        if(Array.isArray(data)){
          if(title==='detail'){
            if (['first_seen', 'last_seen'].includes(heading)) {
                if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
                    setSort(`${heading}_inc`);
                    data.sort((a,b) => new Date(b[heading]).getTime() - new Date(a[heading]).getTime());
                } else if(sort === `${heading}_dec`){
                    setSort(`${heading}_inc`);
                    data.sort((a,b) => new Date(b[heading]).getTime() - new Date(a[heading]).getTime());
                } else if(sort === `${heading}_inc`){
                    setSort(`${heading}_dec`);
                    data.sort((a,b) => new Date(a[heading]).getTime() - new Date(b[heading]).getTime());
                };
            } else {
                if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
                    setSort(`${heading}_inc`);
                    data.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
                    } else if(sort === `${heading}_dec`){
                    setSort(`${heading}_inc`);
                    data.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
                    } else if(sort === `${heading}_inc`){
                    setSort(`${heading}_dec`);
                    data.sort((a,b) => (b[heading] > a[heading]) ? 1 : (b[heading] < a[heading]) ? -1 : 0);
                    };
            }
          } else if (title==='country'){
            if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
              setSort(`${heading}_inc`);
              let d = country.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
              setCountry(d);
              } else if(sort === `${heading}_dec`){
              setSort(`${heading}_inc`);
              let d = country.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
              setCountry(d);
              } else if(sort === `${heading}_inc`){
              setSort(`${heading}_dec`);
              let d = country.sort((a,b) => (b[heading] > a[heading]) ? 1 : (b[heading] < a[heading]) ? -1 : 0);
              setCountry(d);
              };
            }
        } else {
          if (heading ==='domain'){
            if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
              setSort(`${heading}_inc`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => k1.localeCompare(k2))));
              } else if(sort === `${heading}_dec`){
              setSort(`${heading}_inc`);
             setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => k1.localeCompare(k2))));
              } else if(sort === `${heading}_inc`){
              setSort(`${heading}_dec`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => k2.localeCompare(k1))));
              };
          } else {
            if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
              setSort(`${heading}_inc`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => (v2[heading] > v1[heading]) ? -1 : (v2[heading] < v1[heading]) ? 1 : 0)));
              } else if(sort === `${heading}_dec`){
              setSort(`${heading}_inc`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => (v2[heading] > v1[heading]) ? -1 : (v2[heading] < v1[heading]) ? 1 : 0)));
              } else if(sort === `${heading}_inc`){
              setSort(`${heading}_dec`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => (v1[heading] > v2[heading]) ? -1 : (v1[heading] < v2[heading]) ? 1 : 0)));
              };
          }
        };
        };


    const changeShowResultHandler= (result) => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        setShowResult(result);
    };
    
    const downloadReportHandler = () => {

        if(filteredRank.length === 0){
          return;
        } else{
          let d = filteredRank.map(item => 
              [
                item.url_from,
                item.domain_from,
                item.url_to,
                item.backlink_spam_score,
                item.rank,
                item.domain_from_ip,
                item.page_from_external_links,
                item.first_seen,
                item.last_seen,
                item.item_type,
                item.dofollow,
                item.alt,
                item.image_url,
                item.anchor
              ]
            )
          const csv = Papa.unparse({
            "fields": ['URL from', "Domain from", 'URL to', 'Backlink spam score', 'Rank', 'Domain from IP', 'Page from external links', 'First seen', 'Last seen', 'Backlink type', 'DoFollow', 'Alt', 'Image URL', 'Anchor'],
            "data": d
        });
          // logger.log(csv);
          const blob = new Blob([csv]);

          const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
          // logger.log(blob);
          const link = document.createElement("a");
          link.download = 'backlinks.csv';
          link.href = url;
          link.click();
        };
      };

      const incFilterHandler = (filterID) => {
        if(filterArr.length === 3 ) return;
        setFilterArr( c => {
            const d = [...c];
            d.push({id: filterID ,heading: 'url_from', operation: 'contains', value: ''});
            return d
        });
        logger.log(filterArr);
    };
    const updateFilterHandler = (filterID, type, value) => {
        const newArr = filterArr.filter(item => {
            if(item.id === filterID){
                const d = item;
                item[type] = value;
                return d
            }else {
                return item
            }
        });
        setFilterArr(newArr);
        // logger.log(filterArr);
    };

    const applyFilterHandler = () => {
        //filterArr[{id, heading, operation, value}]
        logger.log('re render');
        const arr = rankDetail.filter(item => {
            let count = 0;
            filterArr.map(condition => {
                if (condition.operation === 'contains'){
                  logger.log(condition.heading)
                  // logger.log()
                //   logger.log(`"${item[condition.heading]}".includes("${condition.value}")`)
                    count += eval(`"${item[condition.heading]}".includes("${condition.value}")`)
                }else if (condition.operation === 'does not contain'){
                    count += eval(`!"${item[condition.heading]}".includes("${condition.value}")`)
                }else if (condition.operation === 'is'){
                    // logger.log(`"${item[condition.heading]}" === "${condition.value}"`);
                    count += eval(`"${item[condition.heading]}" === "${condition.value}"`)
                }else if (condition.operation === 'is not'){
                    // logger.log(`"${item[condition.heading]}" !== "${condition.value}"`);
                    count += eval(`"${item[condition.heading]}" !== "${condition.value}"`)
                }else if(condition.operation === 'greater than') {
                    count += eval(`"${item[condition.heading]}" > "${condition.value}"`)
                }else if (condition.operation === 'greater than or equal'){
                    count += eval(`"${item[condition.heading]}" >= "${condition.value}"`)
                }else if (condition.operation === 'less than'){
                    count += eval(`"${item[condition.heading]}" < "${condition.value}"`)
                }else if (condition.operation === 'less than or equal'){
                    count += eval(`"${item[condition.heading]}" <= "${condition.value}"`)
                } 
            })
            return count === filterArr.length
        });
        setFilteredRank(arr);
        logger.log(arr);
        logger.log(filterArr)
      };
    const deleteFilterHandler = (filterID) => {
        const arr = filterArr.filter(item => item.id !== filterID);
        document.getElementById(filterID).value = '';
        if (arr.length === 0){
          setFilterArr([{id: uuid4() ,heading: 'url_from', operation: 'contains', value: ''}]);
        } else {
            setFilterArr(arr);
        }
        logger.log(filterArr);
    }
    
    const urlFilter = (
            <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"600px", 'border': 'none'}}>
                <div className='d-flex flex-column p-4 bg-white shadow'>
                    <p>Filter URLs</p>
                    {
                        filterArr.map(item => {
                            // logger.log(item);
                            return (
                                <div className='d-flex align-items-center' key={item}>
                                    where
                                    <select class="form-select form-select-sm" aria-label="Default select example" className='ms-3 me-2'
                                        onChange={e => updateFilterHandler(item.id, 'heading', e.target.value)}
                                    >
                                        <option value='url_from' selected={item.heading === '' ? true : item.heading === 'url_from' ? true : false}>URL from</option>
                                        { 
                                            Object.entries(f).map(([heading, title]) =>{
                                                if (heading !== 'url_from') {
                                                    return (<option value={heading} selected={item['heading'] === heading}>{title}</option>)
                                                } 
                                            })
                                        }
                                    </select>
                                    <select class="form-select form-select-sm" aria-label="Default select example" className='me-2'
                                        onChange={e => updateFilterHandler(item.id, 'operation', e.target.value)}
                                    >
                                        <option value='contains' selected={item.operation === '' ? true : item.operation === 'contains' ? true : false}>contains</option>
                                        <option value="does not contain" selected={item.operation === 'does not contain'}>does not contain</option>
                                        <option value="is" selected={item.operation === 'is'}>is</option>
                                        <option value="is not" selected={item.operation === 'is not'}>is not</option>
                                        <option value="greater than" selected={item.operation === 'greater than'}>greater than</option>
                                        <option value="greater than or equal" selected={item.operation === 'greater than or equal'}>greater than or equal</option>
                                        <option value="less than" selected={item.operation === 'less than'}>less than</option>
                                        <option value="less than or equal" selected={item.operation === 'less than or equal'}>less than or equal</option>
                                    </select>

                                    <input type="text" id={item.id} className="form-control me-2" style={{width: '120px'}} placeholder="Enter a value" aria-label="Enter a value" aria-describedby="basic-addon2" 
                                        defaultValue={item.value}
                                        onChange={e => updateFilterHandler(item.id, 'value', e.target.value)}
                                    />
                                    <button className='btn'
                                        onClick={e => {return}}
                                    ><FontAwesomeIcon icon={faTrash} onClick={e => deleteFilterHandler(item.id)}/></button>
                                </div>
                            )
                        })
                    }
                    <div className='d-flex mt-4'>
                        <button type="button" class={`btn btn-light me-auto ${filterArr.length === 3 && 'disabled'}`} onClick={e => incFilterHandler(uuid4())}>+ Add condition</button>
                        <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>Apply</button>
                    </div>
                    
                </div>
            </Popover>
    );

    const changeBacklinkModeHandler = (showAll) => {
      if (showAll){
        // logger.log('as_is');
        setBacklinkMode('as_is')
      } else {
        // logger.log('one_per_domain');
        setBacklinkMode('one_per_domain');
      };
    };

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Backlink Audit</h1>
            <div class="input-group flex-nowrap mt-4">
              <span class="input-group-text" id="addon-wrapping">Input URL</span>
              <input type="text" class="form-control" placeholder="URL" aria-label="Username" aria-describedby="addon-wrapping" ref={inputURL}/>
              <button type="button" class="btn btn-primary w-25" onClick={e => checkBacklinkHandler('')}>Check</button>
            </div>
            <div class="form-check form-switch mt-2">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={e => changeBacklinkModeHandler(e.target.checked)}/>
              <label class="form-check-label" for="flexSwitchCheckChecked">Show all backlinks</label>
            </div>
            <span>Note: a domain or a subdomain should be specified without https:// and www.</span>
            <span>a page should be specified with absolute URL (including https:// or https://)</span>
            {
                Object.keys(data).length > 0 && data['date_checked'] !== '' &&
                <div className='d-flex align-self-end align-items-center'>
                    <a href='#' className='me-2' onClick={e => checkBacklinkHandler('refresh')}>
                        <FontAwesomeIcon icon={faRefresh} className='me-2'/>
                        Refresh
                    </a>
                    <span className='d-flex'>{`Last checked: ${new Date(data['date_checked']).toLocaleDateString()}`}</span>
                </div>
            }
            {/* Modal Import*/}
            <div class="modal fade" id="ModalAlert" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title me-auto" id="ModalLongTitle">Resources Exhausted</h5>
                    </div>
                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                        <p>Backlink usage exceeded 100 reports/day</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                    </div>
                    </div>
                </div>
            </div>
        {/* Modal Import*/}
            <div className='my-5'>
            <div className='d-flex'>
              <div className='d-flex col-md-6'>
                <div className='d-flex flex-column w-100'>
                  <div className='d-flex mb-3 h-50'>
                    <div class="d-flex flex-grow flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                      <b>Backlinks</b>
                      <h2 className='align-self-center my-3'>
                        {(Object.keys(data).length > 0 && backlinkMode === 'one_per_domain') ? Object.keys(data['domain']).length : (Object.keys(data).length > 0 && backlinkMode === 'as_is') ? numberWithCommas(filteredRank.length) : ''}
                      </h2>
                    </div>
                    <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                      <b>Ref. Domains</b>
                      <h2 className='align-self-center my-3'>
                      {Object.keys(data).length > 0 &&  data['ref_domains'].length}
                    </h2>
                    </div>
                  </div>
                  <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 w-50 h-50">
                    <b>Ref. IPs</b>
                    <h2 className='align-self-center my-3'>
                    { Object.keys(data).length > 0 > 0 && data['ref_ips'].length}
                    </h2>
                  </div>
                </div>
              </div>
              <div className='d-flex col-md-6'>
              <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Top Anchors</b>
                  <hr />
                  <div style={{height: '200px'}}>
                    <WordCloudComponent words={anchors} />
                  </div>
                </div>
              </div>
            </div>
            
            <hr />
            {/* spam score and domain rank */}
            <div className='d-flex mb-4 justify-content-center'>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Spam score</b>
                  <hr />
                  <div style={{height: '300px'}}>
                    <ResponsiveContainer width="100%" height="80%">
                      <BarChart
                      width={500}
                      height={300}
                      data={spamScore}
                      layout="vertical"
                      margin={{
                          top: 30,
                          right: 30,
                          left: 20,
                          bottom:-30,
                      }}
                      >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        type="number" 
                        domain={[0, 'auto']}
                      />
                      <YAxis 
                        dataKey="name"
                        type="category"
                      />
                      <Tooltip 
                          wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                      />
                      <Legend />
                      <Bar dataKey="value" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Domain rank</b>
                  <hr />
                  <div style={{height: '300px'}}>
                    <ResponsiveContainer width="100%" height="80%">
                      <BarChart
                      width={500}
                      height={300}
                      data={domainRank}
                      layout="vertical"
                      margin={{
                          top: 30,
                          right: 30,
                          left: 20,
                          bottom:-30,
                      }}
                      >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        type="number" 
                        domain={[0, 'auto']}
                      />
                      <YAxis 
                        dataKey="name"
                        type="category"
                      />
                      <Tooltip 
                          wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                      />
                      <Legend />
                      <Bar dataKey="value" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            <hr className='my-5'/>
            {/* country and tld */}
            <div className='d-flex mb-4 justify-content-center'>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 w-100">
                  <b>Country</b>
                  <hr />
                  <div className='d-flex overflow-auto' style={{height: '300px'}}>
                    <table class="table">
                      <thead className='sticky-top table-light'>
                        <tr>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('country','name', country)}>
                              <span className='me-2 fw-bold'>Country</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'name_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'name_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('country', 'value', country)}>
                              <span className='me-2 fw-bold'>Ref. Domains</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'value_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'value_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('country', 'pct', country)}>
                              <span className='me-2 fw-bold'>Pct</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'pct_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'pct_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          country.map((item) => 
                            (
                              <tr>
                                <td>{item.name !== 'nil' ? item.name : 'Other'}</td>
                                <td>{item.value}</td>
                                <td>{item.pct}</td>
                              </tr>
                            )
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>TLD</b>
                  <hr />
                  <div className='d-flex' style={{height: '300px'}}>
                    <div className='d-flex col-md-6 h-100'>
                      <ResponsiveContainer width="100%" height="80%">
                        <PieChart
                        width={500}
                        height={300}
                        margin={{
                            top: 30,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip 
                              wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                          />
                            <Pie
                              dataKey="value"
                            //   startAngle={180}
                              // endAngle={0}
                              data={tld.sort((a,b) => b.value - a.value).slice(0,10)}
                              cx="50%"
                              cy="50%"
                              innerRadius={40}
                              outerRadius={80}
                              paddingAngle={5}
                              fill="#8884d8"
                          >
                              {tld.sort((a,b) => b.value - a.value).slice(0,10).map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    <div className='d-flex col-md-6 overflow-auto' style={{height: '250px'}}>
                    <table class="table">
                        <thead className='sticky-top table-light'>
                          <tr>
                            <th scope="col">TLD</th>
                            <th scope="col">Ref. Domains</th>
                            <th scope="col">Pct</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            tld.map((item) => 
                              (
                                <tr>
                                  <td>{item.name}</td>
                                  <td>{item.value}</td>
                                  <td>{item.pct}</td>
                                </tr>
                              )
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* backlink types & link attributes */}
            <div className='d-flex mb-4 justify-content-center'>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 w-100">
                  <b>Ref. Domains</b>
                  <hr />
                  <div className='d-flex overflow-auto' style={{height: '300px'}}>
                    <table class="table">
                      <thead className='sticky-top table-light'>
                        <tr>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('', 'domain', data['domain'])}>
                                <span className='me-2 fw-bold'>Domain</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'domain_inc' ? '#000' : '#6c757d'}`}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'domain_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('', 'backlinks', data['domain'])}>
                              <span className='me-2 fw-bold'>Backlinks</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'backlink_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'backlink_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('', 'country', data['domain'])}>
                              <span className='me-2 fw-bold'>Country</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'country_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'country_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('', 'ip', data['domain'])}>
                              <span className='me-2 fw-bold'>IP</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'ip_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'ip_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          Object.entries(domain).map(([domain, value]) => 
                              (
                                <tr>
                                  <td>{domain}</td>
                                  <td>{value.backlinks}</td>
                                  <td>{value.country }</td>
                                  <td>{value.ip}</td>
                                </tr>
                              )
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Backlink types</b>
                  <hr />
                  <div style={{height: '150px'}}>
                  {
                      backlinkTypes.map(item => 
                        <div className='d-flex align-items-center mb-3'>
                          <div className='m-0 me-2' style={{width: '70px'}}>{item.name}</div>
                          <div className='' style={{width: '400px'}}>
                            <ProgressBar now={item.pct} />
                          </div>
                          <div className='mx-2 text-end' style={{width: '40px'}}>{item.value}</div>
                          <div className='text-end' style={{width: '40px'}}>{`${item.pct} %`}</div>
                        </div>
                        )
                    }
                  </div>
                </div>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Link Attributes</b>
                  <hr />
                  <div className='d-flex flex-column' style={{height: '150px'}}>
                    {
                      linkAttrs.map(item => 
                        <div className='d-flex align-items-center mb-3'>
                          <div className='m-0 me-2' style={{width: '70px'}}>{item.name}</div>
                          <div className='' style={{width: '400px'}}>
                            <ProgressBar now={item.pct} />
                          </div>
                          <div className='mx-2 text-end' style={{width: '40px'}}>{item.value}</div>
                          <div className='text-end' style={{width: '40px'}}>{`${item.pct} %`}</div>
                        </div>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex mb-2'>
                <div className='d-flex'>
                  <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
                      <button type="button" class="btn btn-light">
                          <FontAwesomeIcon className='me-2' icon={faFilter} />
                          Filter URLs
                      </button>
                  </OverlayTrigger>
                </div>
                <button class="btn btn-primary ms-auto" type="button" aria-expanded="false" onClick={downloadReportHandler}>
                    <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                    Download
                </button>
            </div>
            <div className="overflow-auto mb-3" style={{"height":"500px"}}>
                <table class="table table-striped" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                    <thead className="table-primary sticky-top" style={{'z-index':'9'}}>
                        <tr>
                        <th scope="col" style={{'width':'5%'}}>#</th>
                        <th scope="col" style={{'width':'25%'}}>
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'url_from', filteredRank)}>
                                <span className='me-2 fw-bold'>URL from </span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'url_from_inc' ? '#000' : '#6c757d'}`}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'url_from_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'domain_from', filteredRank)}>
                                <span className='me-2 fw-bold'>Domain from</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'domain_from_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'domain_from_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'12%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'url_to', filteredRank)}>
                                <span className='me-2 fw-bold'>URL to</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'url_to_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'url_to_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'8%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'backlink_spam_score', filteredRank)}>
                                <span className='me-2 fw-bold'>Backlink spam score</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'backlink_spam_score_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'backlink_spam_score_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'rank', filteredRank)}>
                                <span className='me-2 fw-bold'>Rank</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'rank_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'rank_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'domain_from_ip', filteredRank)}>
                                <span className='me-2 fw-bold'>Domain from IP</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'domain_from_ip_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'domain_from_ip_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'page_from_external_links', filteredRank)}>
                                <span className='me-2 fw-bold'>Page from external link</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'page_from_external_links_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'page_from_external_links_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'first_seen', filteredRank)}>
                                <span className='me-2 fw-bold'>First seen</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'first_seen_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'first_seen_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'last_seen', filteredRank)}>
                                <span className='me-2 fw-bold'>Last seen</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'last_seen_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'last_seen_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'item_type', filteredRank)}>
                                <span className='me-2 fw-bold'>Link type</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'item_type_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'item_type_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'dofollow', filteredRank)}>
                                <span className='me-2 fw-bold'>Dofollow</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'dofollow_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'dofollow_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'alt', filteredRank)}>
                                <span className='me-2 fw-bold'>Alt</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'alt_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'alt_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'image_url', filteredRank)}>
                                <span className='me-2 fw-bold'>Image url</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'image_url_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'image_url_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'anchor', filteredRank)}>
                                <span className='me-2 fw-bold'>Anchor</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'anchor_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'anchor_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredRank.slice(showResult*(selectedPage-1),showResult*selectedPage).map((item, index) => 
                                    <tr key={index + 1}>
                                        <td>{(index+1)+(selectedPage-1)*showResult}</td>
                                        <td>{item.url_from}</td>
                                        <td>{item.domain_from}</td>
                                        <td>{item.url_to}</td>
                                        <td>{item.backlink_spam_score}</td>
                                        <td>{item.rank}</td>
                                        <td>{item.domain_from_ip}</td>
                                        <td>{item.page_from_external_links}</td>
                                        <td>{new Date(item.first_seen).toLocaleDateString()}</td>
                                        <td>{new Date(item.last_seen).toLocaleDateString()}</td>
                                        <td>{item.item_type}</td>
                                        <td>{item.dofollow ? 'True' : ''}</td>
                                        <td>{item.alt}</td>
                                        <td>{item.image_url}</td>
                                        <td>{item.anchor}</td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
                <h4 className={`text-muted text-center ${rankDetail.length > 0 && 'd-none'}`}>No Records founded in selected period!</h4>
            </div>
            <div className="d-flex">
                <div>
                    <span className='me-4'>Total backlinks: <b>{numberWithCommas(filteredRank.length)}</b></span>
                </div>
                <div class="input-group mb-3 me-3 ms-auto w-25">
                <label class="input-group-text" for="inputGroupSelect01">Results per page</label>
                <select class="form-select" id="inputGroupSelect01" onChange={e => changeShowResultHandler(e.target.value)}>
                    <option selected value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                </select>
                </div>
                <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class={`page-item ${currentPageRange === 1 && 'disabled'}`}
                    onClick = {e => {
                    e.preventDefault();
                    setCurrentPageRange(current => {
                        if(current > 1){
                        return current -1
                        }
                        return current;
                        })}}
                    ><a class="page-link" href="#">Previous</a></li>
                    {pagination.map((item, index) => {return(
                    <li  key={index+1} id={`page-${index+1}`} className={`page-item ${index+1 === selectedPage && 'active'} ${(index+1 > 10*currentPageRange || index + 1 <= 10*(currentPageRange-1)) && 'd-none'}`}>
                        {item}
                    </li>
                    )})
                    }
                    <li
                    class={`page-item ${(pagination.length <= 10 || currentPageRange + 1 > Math.ceil(pagination.length/10)) && 'disabled'}`}
                    onClick = {e => {
                    e.preventDefault();
                    setCurrentPageRange(current => {
                        if(current + 1 <= Math.ceil(pagination.length/10)) {
                        return (current +1);
                        }
                        return current;
                    })}
                    }
                    ><a class="page-link" href="#">Next</a></li>
                </ul>
                </nav>
            
            </div>
            </div>
        </div>
    )
}

export default Backlink