import { redirect } from "react-router-dom";
import apiClient from "../api/client";

export function action(){
    apiClient.post('/api/logout')
    .then(res => {
        if(res.status === 200){
            localStorage.removeItem('expiration');
            localStorage.removeItem('username');
            localStorage.removeItem('token');
            localStorage.removeItem('device_id');
            window.location.href = '/login';
            // redirect('/login');
        }
    }).catch(error => {
        localStorage.removeItem('expiration');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('device_id');
        console.error('Logout failed:', error);
        window.location.href = '/login';
    });
    return redirect('/login');
}