import React, { useEffect, useState, useCallback } from 'react';
import { Tab, Tabs, Badge, ListGroup, Button, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../styles/NotificationsModal.css';  // Import CSS for styling
import apiClient from '../api/client';
import { useNotification } from '../context';
import logger from '../util/logger';

const RenderNotifications = ({items, mode, markRead}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 5;
  if (!Array.isArray(items)) {
    return <div className="text-center p-4">No new notifications</div>;
  }

  let displayedItems = [];
  if (mode === 'modal') {
    displayedItems = items.slice(0, 5);
  } else {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    displayedItems = items.slice(indexOfFirstItem, indexOfLastItem);
  }

  return (
    <ListGroup as="ul" variant="flush">
      {items.length > 0 ? (
        <>
          {displayedItems.map((notification) => (
            <ListGroup.Item
              as="li"
              key={notification.id}
              action
              onClick={() => markRead(notification)}
              className={!notification.isRead ? 'fw-bold' : ''}
            >
              <div>{notification.header}</div>
              <small>{mode === 'modal' ? `${notification.content.slice(0, 50)}...` : notification.content}</small>
            </ListGroup.Item>
          ))}
          {mode === 'modal' ? (
            <ListGroup.Item as="li" className="text-center" action>
              <Button variant="link" onClick={() => navigate('/notifications')}>
                View All
              </Button>
            </ListGroup.Item>
          ) : (
            <Pagination style={{'margin-top': '20px'}}>
              <Pagination.Prev
                onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
              />
              <Pagination.Item active>{currentPage}</Pagination.Item>
              <Pagination.Next
                onClick={() =>
                  currentPage < Math.ceil(items.length / itemsPerPage) &&
                  setCurrentPage(currentPage + 1)
                }
              />
            </Pagination>
          )}
        </>
      ) : (
        <div className="text-center p-4">No new notifications</div>
      )}
    </ListGroup>
  );
};

const NotificationsModal = ({mode}) => {
    const [activeTab, setActiveTab] = useState('notifications');
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [notificationsList, setNotificationsList] = useState([]);
    const [transactionsList, setTransactionsList] = useState([]);
    const [notificationStatusTracking, setNotificationStatusTracking] = useState([]);
    const { notifications, setNotifications} = useNotification();

    useEffect(() => {
      setNotificationsList(notifications.filter(n => n.type === 'notification'));
      setTransactionsList(notifications.filter(n => n.type === 'transaction'));
    }, [notifications]);

    // Debounce function
    function debounce(func, delay) {
      let timeout;
      return (...args) => {
          clearTimeout(timeout);
          timeout = setTimeout(() => func(...args), delay);
      };
    }

    // Debounced function to update expiration
    const updateNotification = useCallback(
      debounce((trackingData) => {
        if (trackingData.length === 0) return;

        apiClient.post('/api/update-notifications', trackingData)
        .then(({data}) => {
          logger.log('Batch update successful', data);
          // Reset notification status tracking
          setNotificationStatusTracking([]);
        })
        .catch ((error) => {
          console.error('Batch update failed', error);
        });
      }, 5000), // 5000ms debounce
      [] // Empty dependencies array
    );
  
    const markUnread = (id) => {

      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification._id === id ? { ...notification, isRead: false } : notification
        )
      );
      
      // Use functional update to ensure having the latest state
      setNotificationStatusTracking(prevTracking => {
        const index = prevTracking.findIndex(item => item._id === id);
        let newTracking;
        
        if (index !== -1) {
          newTracking = prevTracking.map((item, i) => 
            i === index ? { ...item, isRead: false } : item
          );
        } else {
          const changedItem = notifications.find(item => item._id === id);
          newTracking = changedItem.isRead 
            ? [...prevTracking, { ...changedItem, isRead: false }]
            : prevTracking;
        };
        // Schedule update with latest tracking data
        updateNotification(newTracking);
        return newTracking;
      });
    };

    const markRead = (node) => {

      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification._id === node._id ? { ...notification, isRead: true } : notification
        )
      );
      setSelectedNotification(node);
      // Update notification status changes tracking
      let trackingChanges = [];
      const index = notificationStatusTracking.findIndex((item) => item._id === node._id);
      if (index !== -1) {
        trackingChanges = notificationStatusTracking.map((item, i) => (i === index ? { ...item, isRead: true } : item));
      } else {
        const changedItem = notifications.find((item) => item._id === node._id);
        if (!changedItem.isRead){
          trackingChanges = [...notificationStatusTracking, { ...changedItem, isRead: true }];
        };
      };
      if (trackingChanges.length > 0) {
        setNotificationStatusTracking(trackingChanges);
        // Schedule backend update
        updateNotification(trackingChanges);
      }
    };

    return (
      <div className={`notifications-center ${mode === 'modal' ? 'w-100' : 'd-flex flex-column justify-content-center w-75'}`}>
        {selectedNotification ? (
          <div className="notification-detail">
            <h5>{selectedNotification.header}</h5>
            <p className='text-wrap'>{selectedNotification.content}</p>
            <small>
              Sent by {selectedNotification.sender} at {selectedNotification.timestamp}
            </small>
            <div className='d-flex'>
              <Button variant="link" onClick={() => setSelectedNotification(null)}>
                Return to list
              </Button>
              <Button variant="link" onClick={() => markUnread(selectedNotification._id)}>
                Mark as Unread
              </Button>
            </div>
          </div>
        ) :
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
          <Tab eventKey="notifications" title={<span>Notifications
                {
                  notificationsList.filter((n) => !n.isRead).length > 0 &&
                  <Badge bg="primary" className='ms-2'>
                      {notificationsList.filter((n) => !n.isRead).length}
                  </Badge>
                }
            </span>
          }
        >
            <RenderNotifications
              items={notificationsList}
              mode={mode}
              markRead={markRead}
            />
          </Tab>
          <Tab eventKey="transactions" title={
            <span>Transactions
                {
                  transactionsList.filter((n) => !n.isRead).length > 0 &&
                  <Badge bg="primary" className='ms-2'>
                      {transactionsList.filter((n) => !n.isRead).length}
                  </Badge>
                }
            </span>
          }>
            <RenderNotifications
              items={transactionsList}
              mode={mode}
              markRead={markRead}
            />
          </Tab>
        </Tabs>
        }
      </div>
    );
  };
  
  export default NotificationsModal;
