import { useState, Fragment, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";
import Head from "../components/loading-bar";
import Menu from "../components/menu";

import autosize from 'autosize';

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
} from "@fortawesome/free-solid-svg-icons";

import MatchedItem from "../components/matched-item";
import { getAuthToken } from "../util/token";
import apiClient from "../api/client";
import { getDeviceId } from "../util/deviceManager";
import logger from "../util/logger";

function CheckPlagiarism() {

    const [matched, setMatched] = useState([]);
    const [hypertext, setHypertext] = useState([]);
    const [parcount, setParCount] = useState();
    const [wordcount, setWordCount] = useState();
    const [sentcount, setSentCount] = useState();
    const [matchpct, setMatchPct] = useState(0);
    const [uniqpct, setUniqPct] = useState();
    const [parlength, setParLength] = useState();
    const [sentlength, setSentLength] = useState();
    const [flesch, setFlesch] = useState();
    const [resultId, setResultId] = useState('');
    const [resultReady, setResultReady] = useState(false);
    const [highlightItem, setHighlightItem] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const text_raw = useRef();
    const exclURL = useRef('');
    const dispatch = useDispatch();

    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    // useEffect(() => {
    //     // prevInputValue.current = inputValue
    //     autosize(text_raw.current);
    // },[]);

    const fetchData = async (input) => {
        const deviceId = localStorage.getItem('device_id');
        try {
            const response =  await fetch(`${process.env.REACT_APP_HOST_IP}/api/content`,{
                method: 'POST',
                body: JSON.stringify(input),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'X-DEVICE-ID': deviceId || getDeviceId(),
                    'X-User': userName
                }
                });
            if (!response.ok || !response.body) {
                throw response.statusText;
                }
        
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const startTime = new Date().getTime();
            let concat_str = ''
            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    logger.log(new Date(new Date().getTime() - startTime).getSeconds());
                    // logger.log(concat_str);
                    const data = JSON.parse(concat_str)['data'];
                    // logger.log(data);
                    // setData(data);
                    setResultReady(true);
                    setMatched(data[0].matched_results);
                    setHypertext(data[0].transform_txt);
                    setParCount(data[0].par_count);
                    setWordCount(data[0].word_count);
                    setSentCount(data[0].sent_count);
                    setMatchPct(data[0].match_pct);
                    setUniqPct(data[0].unique_pct);
                    setParLength(data[0].par_avg_length);
                    setSentLength(data[0].sent_avg_length);
                    setFlesch(data[0].flesch_scrore);
                    setResultId(data[0]._id);
                    setIsLoading(current => !current);
                    
                    break;
                }
        
                const decodedChunk = decoder.decode(value, { stream: true });
                // logger.log(decodedChunk);
                // logger.log(decodedChunk.length);
                if (decodedChunk.length < 50) {
                    // logger.log(decodedChunk)
                    const crawled = JSON.parse(decodedChunk)['data'];
                    // logger.log(crawled);
                    setProgress(crawled['progress_pct']);
                    // setTotalDiscovered(crawled['total_discovered']);
                } else {
                    concat_str += decodedChunk;
                }
                
            }
        } catch (error){
            logger.log(error);
        }
    };

    const checkContentHandler =(event, text_raw) => {
        event.preventDefault();
        if(text_raw.current.value.trim() === ''){
            return
        };
        const text_obj = {
            raw_text: text_raw.current.value,
            excluded_url: exclURL.current.value
        };
        setIsLoading(current => !current);
        fetchData(text_obj);
        // logger.log(text_obj);
        // dispatch(showLoading());
        // fetch(`${process.env.REACT_APP_HOST_IP}/api/content`,{
        //     method: 'POST',
        //     body: JSON.stringify(text_obj),
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + token
        // }
        // }).then(
        // response => {
        //     dispatch(hideLoading());
        //     setResultReady(true);
        //     return response.json();
        // }
        // ).then((data) =>{
        //     setMatched(data[0].matched_results);
        //     setHypertext(data[0].transform_txt);
        //     setParCount(data[0].par_count);
        //     setWordCount(data[0].word_count);
        //     setSentCount(data[0].sent_count);
        //     setMatchPct(data[0].match_pct);
        //     setUniqPct(data[0].unique_pct);
        //     setParLength(data[0].par_avg_length);
        //     setSentLength(data[0].sent_avg_length);
        //     setFlesch(data[0].flesch_scrore);
        //     setResultId(data[0]._id);
        //     setIsLoading(current => !current);
        // }
        // );
    };

    const exportFileHandler = (e, text, id) => {
        e.preventDefault();
        const text_obj = {
            raw_text: text_raw.current.value,
            resultId: resultId
        };

        dispatch(showLoading());
        apiClient.post('/api/download/pdf-report', text_obj, {headers: {'X-User': userName}})
        .then(
            response => {
              dispatch(hideLoading());
              return response.data;
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            logger.log(blob);
            const link = document.createElement("a");
            link.download = 'plagiarism_report.pdf';
            link.href = url;
            link.click();
        }).catch(error => {
            console.error(error);
        });

    };

    const highlightHandler = (event) => {
        if (event.target.getAttribute('href')){
            // logger.log(event.target.getAttribute('href').replace("#",""));
            setHighlightItem(event.target.getAttribute('href').replace("#",""));
        }
    };

    return (
        <div className="d-flex flex-column h-100">
            <h1 className="text-center my-4">Content Quality Check</h1>
            <div className={`container ${resultReady && 'd-none'} mt-5`}>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">Excluded URL</span>
                    <input type="text" class="form-control" ref={exclURL} aria-describedby="basic-addon1" />
                </div>
                <div class="form-floating" >
                    <textarea class="form-control" style={{'height': '400px'}} placeholder="Leave a comment here" id="floatingTextarea" ref={text_raw}></textarea>
                    <label for="floatingTextarea">Enter content here</label>
                </div>
                <div class="d-grid gap-2 col-3 ms-auto">
                    <button type="submit" class={`btn btn-primary my-3 justify-content-center btn-lg ${isLoading && 'disabled'}`} onClick={event => checkContentHandler(event,text_raw )}>
                        
                        {
                            isLoading ?
                            <>
                                <div class="spinner-border spinner-border-sm text-light me-2" role="status"></div>
                                {`Loading ${progress === 0 ? '...' : `${parseInt(progress*100)}%`}`}
                            </> :
                            `Submit`
                        }
                    </button>
                </div>
            </div>
            <div className={`d-flex my-5 justify-content-center ${!resultReady && 'd-none'}`}>
                <div className="d-flex" style={{"width":"80%"}}>
                    <div className="d-flex flex-column col-md-4 justify-content-center p-4 border border-light border-4">
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style={{"width": matchpct}} aria-valuenow={matchpct} aria-valuemin="0" aria-valuemax="100" >
                                <span data-bs-toggle="tooltip" data-bs-placement="top" title={matchpct}>{matchpct}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-around my-4">
                            <div className="d-flex flex-column m-2 text-center bg-light justify-content-center" style={{"width":"150px", "height":"80px"}}>
                                <h4>Plagiarism</h4>
                                <span className="fs-3" style={{"color":'red'}}>{matchpct}</span>
                            </div>
                            <div className="d-flex flex-column m-2 text-center bg-light justify-content-center" style={{"width":"150px", "height":"80px"}}>
                                <h4>Unique</h4>
                                <span className="fs-3" style={{"color":'green'}}>{uniqpct}</span>
                            </div>
                        </div>
                    </div>
                    <div className=" d-flex flex-column col-md-8 ms-3 p-4 border border-light border-4">
                        <h3 className="mb-4 text-center">Content statistics</h3>
                        <div className="row">
                            <div className="d-flex flex-column col-md-6">
                                <div class="d-flex w-100 justify-content-between mb-3 pb-2 border-bottom">
                                    <h5 class="mb-1">No. of paragraph</h5>
                                    <span class="badge bg-danger rounded-pill">{parcount}</span>
                                </div>
                                <div class="d-flex w-100 justify-content-between mb-3 mb-3 pb-2 border-bottom">
                                    <h5 class="mb-1">Avg. paragraph length</h5>
                                    <span class="badge bg-danger rounded-pill">{parlength}</span>
                                </div>
                                <div class="d-flex w-100 justify-content-between mb-3 mb-3 pb-2 border-bottom">
                                    <h5 class="mb-1">No. of sentences</h5>
                                    <span class="badge bg-danger rounded-pill">{sentcount}</span>
                                </div>
                                <div class="d-flex w-100 justify-content-between mb-3 mb-3 pb-2 border-bottom">
                                    <h5 class="mb-1">Avg. sentence length</h5>
                                    <span class="badge bg-danger rounded-pill">{sentlength}</span>
                                </div>
                            </div>
                            <div className="d-flex flex-column col-md-6">
                                <div class="d-flex w-100 justify-content-between mb-3 pb-2 border-bottom">
                                    <h5 class="mb-1">Total word count</h5>
                                    <span class="badge bg-danger rounded-pill">{wordcount}</span>
                                </div>
                                <div class="d-flex w-100 justify-content-between mb-3 mb-3 pb-2 border-bottom">
                                    <h5 class="mb-1">Flesch score</h5>
                                    <span class="badge bg-danger rounded-pill">{flesch}</span>
                                </div>
                                <div class="d-flex w-100 justify-content-between mb-3 mb-3 pb-2 border-bottom">
                                    <h5 class="mb-1">Duplicated content pct.</h5>
                                    <span class="badge bg-danger rounded-pill">{matchpct}</span>
                                </div>
                                <div class="d-flex w-100 justify-content-between mb-3 mb-3 pb-2 border-bottom">
                                    <h5 class="mb-1">Unique content pct.</h5>
                                    <span class="badge bg-danger rounded-pill">{uniqpct}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`d-flex flex-column flex-grow-1 mt-4 ${!resultReady && 'd-none'}`}>
                <div className="row flex-grow-1">
                    <div className="d-flex flex-column h-100 col-md-8">
                        <div className="d-flex mb-2 pb-4">
                            <h3 className="d-inline-block me-auto">Content</h3>
                            <button
                                type="button"
                                className="btn btn-primary btn-md"
                                onClick={(event) => exportFileHandler(event, text_raw, resultId)}
                            >
                                <FontAwesomeIcon icon={faDownload} />
                            </button>
                        </div>
                        <div class="form-floating me-4">
                            {hypertext.map((item) => (
                                <p dangerouslySetInnerHTML={
                                    {__html: item}} onClick={(event) => highlightHandler(event)}/>
                            ))}
                        </div>
                    </div>
                    <div className=" d-flex flex-column h-100 col-md-4 sticky-top">
                        <h3 className="mb-4">Matched results</h3>
                        <div className="row g-4 overflow-auto" style={{"height":"600px"}}>
                            {parseFloat(matchpct) === 0 ?
                            <div>
                                <img src='/unique-illustration.svg' />
                                <h5 className='mt-3'>Congratulations!</h5>
                                <h5>No Content Plagiarism Found.</h5>
                            </div> :
                            <div class="list-group">
                                {matched.map((item) => (
                                    <MatchedItem
                                    item = {item}
                                    highlight = {highlightItem}
                                    />
                                ))}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CheckPlagiarism;