import { useState, useRef, useEffect, useMemo } from 'react';
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { AuditGroup } from "../components/cart-item";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DatePicker from "../components/datepick";

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import { v4 as uuid4 } from 'uuid';

import {
    ResponsiveContainer,
    Tooltip,
    PieChart, Pie, Cell,
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Area,
    BarChart,
    Bar,
    Line,
    ComposedChart
  } from "recharts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faArrowUp, faArrowDown, faSort, faUpRightFromSquare, faCheckCircle, faTimesCircle, faAngleUp, faAngleDown, faGlobe, faLanguage, faPlus,
  faSquareCheck,
  faArrowCircleLeft
} from "@fortawesome/free-solid-svg-icons";
import { getAuthToken } from '../util/token';
import { useSearchParams } from "react-router-dom";
import Papa from 'papaparse';
import moment from "moment";
import * as d3 from "d3";
import apiClient from '../api/client';
import logger from '../util/logger';

function MailChimp() {

    const dispatch = useDispatch();
    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const COLORS  = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    // logger.log(COLORS);
    const [bounceReasons, setBounceReasons] = useState([]);
    const [rawPerformance, setRawPerformance] = useState([]);
    const [filteredRawPerformance, setFilteredRawPerformance] = useState([]);
    const [unsub, setUnsub] = useState([]);
    const [performance, setPerformance] = useState([]);
    const [listGrowth, setListGrowth] = useState([]);

    const [totalCampaign, setTotalCampaign] = useState([]);
    const [campaignSeries, setCampaignSeries] = useState([]);
    const [campaignName, setCampaignName] = useState([]);
    const [filteredSeries, setFilteredSeries] = useState([]);
    const [filteredName, setFilteredName] = useState([]);
    const [selectedCampSeries, setSelectedCampSeries] = useState('Campaign Series');
    const [selectedCampName, setSeletecCampName] = useState('Campaign Name');

    const seriesSearchStr = useRef('');
    const nameSearchStr = useRef('');

    const [startDate, setStartDate] = useState((new Date()).getTime());
    const [endDate, setEndDate] = useState((new Date()).getTime());
    const [sort,setSort] = useState('');

    const [filterArr, setFilterArr] = useState([{
        id: uuid4(),heading: 'campaign_series', operation: 'contains', value: ''
      }]);
    const [filterList, setFilterList] = useState({
        'campaign_series' : 'Campaign Series',
        'campaign_name': 'Campaign Name',
        'total_sent': 'Total Sent',
        'Total unique subscriber open': 'Total unique subscriber open',
        'Total unique subscriber click': 'Total unique subscriber click',
        'Total bounce': 'Total bounce',
        'Total conv': 'Total conv' 
    })
    
    const [lastUpdated, setLastUpdated] = useState('');
    const [importStat, setImportStat] = useState('');
    const [bounceEmails, setBounceEmails] = useState([]);
    const [unsubEmails, setUnsubEmails] = useState([]);

    //modal
    const [engagementDetail, setEngagementDetail] = useState([]);
    const [filteredEngagementDetail, setFilteredEngagementDetail] = useState([]);
    const [totalConv, setTotalConv] = useState([]);
    const [displayMode, setDisplayMode] = useState('email_list');
    const [emailHistory, setEmailHistory] = useState([]);
    const [emailInfo, setEmailInfo] = useState([]);

    const [emailSentFailed, setEmailSentFailed] = useState([]);


    useEffect(() => {
        apiClient.get('/api/mailchimp-campaign', {headers: {'X-User': userName}})
        .then(
                  ({response}) => {
                        return response.json()
                  }
            ).then (
                (data) =>{
                    // logger.log(data);
                    setCampaignSeries(['Select All', ...Object.keys(data)]);
                    setFilteredSeries(['Select All', ...Object.keys(data)]);
                    setTotalCampaign(data);
            })
    }, []);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // logger.log(payload, label)
            return (
            <div className="custom-tooltip p-2">
                <p className="label">{`Date: ${moment(parseInt(label)).format('MM-YYYY')}`}</p>
                <ul className='ps-0' style={{listStyleType: 'none'}}>
                    {
                        payload.map(item => {
                            if (item.dataKey === 'open rate' || item.dataKey === 'click rate'){
                                return <li className="">{`${item.dataKey}: ${item.value}%`}</li>
                            }
                            return <li className="">{`${item.dataKey}: ${item.value.toLocaleString('en-us')}`}</li>
                        })
                    }
                </ul>
            </div>
            );
            }
    };

    const filterHandler = (type, val) => {
        if (type === 'series') {
            const search = campaignSeries.filter(item => {
                return (
                    (val !== '' ? item.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
                )
            });
            setFilteredSeries(search);
        } else {
            const search = campaignName.filter(item => {
                return (
                    (val !== '' ? item.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
                )
            });
            setFilteredName(search);
        }
    };

    const applyDateRangeHandler = (start, end) => {
        let unix_start_time = (new Date(start)).getTime();
        let unix_end_time = (new Date(end)).getTime() + 86400*1000;

        setStartDate(c => unix_start_time);
        setEndDate(c => unix_end_time);
        document.getElementById("startEnd").value = `${start} - ${end}`;
        if(isNaN(unix_start_time) && isNaN(unix_end_time)) {
            unix_start_time = '';
            unix_end_time = '';
        }else if(isNaN(unix_end_time)){
        unix_end_time = '';
        } else if(isNaN(unix_start_time)){
        unix_start_time = '';
        };

        dispatch(showLoading());
        let s = selectedCampSeries;
        let n = selectedCampName;
        if (selectedCampSeries === 'Campaign Series' || selectedCampSeries === 'Select All') s = '';
        if (selectedCampName === 'Campaign Name' || selectedCampName === 'Select All') n = '';
        const input = {
            campaign_series: s,
            campaign_name: n,
            start: unix_start_time,
            end: unix_end_time
        };
        // logger.log(input);
        const inputFailedEmail = {
            start: unix_start_time,
            end: unix_end_time
        };
        logger.log(inputFailedEmail);
        Promise.all([
            apiClient.post('/api/mailchimp-report', input, {headers: {'X-User': userName}})
            .then (
                ({data}) =>{
                    logger.log(data);
                    // bounce metrics
                    let b = {
                        'Hard bounce': 0,
                        'Soft bounce': 0
                    }
                    data['bounce'].map(item => {
                        b['Hard bounce'] += item['Hard bounce']
                        b['Soft bounce'] += item['Soft bounce']
                    });
                    b = Object.entries(b).map(([k, v]) => {
                        return {
                            name: k,
                            value: v
                        }
                    });
                    setBounceReasons(b);
                    let u = {
                        'None given': 0,
                        'No longer interested': 0,
                        'Did not sign up for list': 0,
                        'Spam': 0,
                        'Other': 0,
                        'Inapproriate content': 0
                    };
                    // unsub metrics
                    let unsub_emails = [];
                    data['unsub'].map(item => {
                        u['None given'] += item['None given'] || 0
                        u['No longer interested'] += item['No longer interested'] || 0
                        u['Did not sign up for list'] += item['Did not sign up for list'] || 0
                        u['Spam'] += item['Spam'] || 0
                        u['Other'] += item['Other'] || 0
                        u['Inapproriate content'] += item['Inapproriate content'] || 0
                        if(item['email_address'].length > 0){
                            item['email_address'].map(it => {
                                unsub_emails.push({
                                    campaign_series: item['campaign series'],
                                    campaign_name: item['campaign name'],
                                    date: new Date(item['date'].split('-')[0], item['date'].split('-')[1] - 1, 1).getTime(),
                                    email: it.split(' - ')[0],
                                    reason: it.split(' - ')[1]
                                })
                            })
                        }
                    });
                    setUnsubEmails(unsub_emails);
                    // logger.log('unsub emails: ', unsub_emails)
                    u = Object.entries(u).map(([k, v]) => {
                        return {
                            name: k,
                            value: v
                        }
                    });
                    // logger.log(u)
                    setUnsub(u);
                    // performance metrics: sent, open, click, conv grouped by month
                    let date_range = Array.from(new Set(data['performance'].map(item => item['date'].substring(0, 7)))).sort();
                    // logger.log(date_range);
                    let r = {};
                    let l = {};
                    date_range.map(item => {
                        r[new Date(item.split('-')[0], item.split('-')[1] - 1, 1).getTime()] = {
                            sent: 0,
                            open: 0,
                            click: 0,
                            conv: 0
                        };
                        l[new Date(item.split('-')[0], item.split('-')[1] - 1, 1).getTime()] = {
                            sent: 0
                        };
                    })
                    // logger.log(r);
                    let bounce_emails = []
                    let raw = {};
                    data['performance'].map((item, index) => {
                        Object.keys(r).map(it => {
                            let uT = new Date(item['date'].split('-')[0], item['date'].split('-')[1] - 1, 1).getTime();
                            // logger.log(uT);
                            if (uT  === parseInt(it)){
                                // logger.log('true')
                                r[[it]]['sent'] += item['total_sent'];
                                r[[it]]['open'] += item['Total unique subscriber open'];
                                r[[it]]['click'] += item['Total unique subscriber click'];
                                r[[it]]['conv'] += item['Total conv'];
                            }
                        });
                        Object.keys(l).map(it => {
                            let uT = new Date(item['date'].split('-')[0], item['date'].split('-')[1] - 1, 1).getTime();
                            // logger.log(uT);
                            if (uT  === parseInt(it) && item['Is 1st email'] === 'y'){
                                // logger.log('true')
                                l[[it]]['sent'] += item['total_sent'];
                            }
                        });
                        if (item['total_bounce_email'] && item['total_bounce_email'].length >= 1 && item['total_bounce_email'] !== null){
                            item['total_bounce_email'].map(it => {
                                if (it !== null){
                                    bounce_emails.push({
                                        campaign_series: item['campaign_series'],
                                        campaign_name: item['campaign_name'],
                                        date: new Date(item['date'].split('-')[0], item['date'].split('-')[1] - 1, 1).getTime(),
                                        email: it.split('-')[1],
                                        reason: it.split('-')[0]
                                    })
                                }
                            }) 
                        };
                        // raw performance
                        if (!Object.keys(raw).includes(item['campaign_id'])){
                            let c = 0;
                            if (item['Freq'] !== '') {
                                c = item['Freq']
                            }
                            raw[item['campaign_id']] = {
                                ...item,
                                Freq: c
                            }
                        }else {
                            let c = 0;
                            if (item['Freq'] !== '') {
                                c = item['Freq']
                            }
                            raw[item['campaign_id']]['Freq'] += c
                            raw[item['campaign_id']]['Hard bounce'] += item['Hard bounce']
                            raw[item['campaign_id']]['Soft bounce'] += item['Soft bounce']
                            raw[item['campaign_id']]['Total bounce'] += item['Total bounce']
                            raw[item['campaign_id']]['Total conv'] += item['Total conv']
                            raw[item['campaign_id']]['Total unique subscriber click'] += item['Total unique subscriber click']
                            raw[item['campaign_id']]['Total unique subscriber open'] += item['Total unique subscriber open']
                            raw[item['campaign_id']]['total_sent'] +=  item['total_sent']
                        }
                    });
                    // raw performance
                    setRawPerformance(Object.values(raw));
                    setFilteredRawPerformance(Object.values(raw));

                    setBounceEmails(bounce_emails)
                    
                    data['conv'].map(item => {
                        let u = new Date(item['activity_time'].split('-')[0], item['activity_time'].split('-')[1] - 1, 1).getTime();
                        if (Object.keys(r).includes(String(u))){
                            if (Object.keys(r[u]).includes('conv')){
                                r[u]['conv'] += 1
                            } else {
                                r[u]['conv'] = 1
                            }
                        }
                    });
                    // logger.log(r);
                    let perf = [];
                    Object.entries(r).map(([key, value]) => {
                        perf.push({
                            date: key,
                            'open rate': (value['open']*100/value['sent']).toFixed(1),
                            'click rate': (value['click']*100/value['open']).toFixed(1),
                            ...value
                        })
                    });
                    let list_growth = [];
                    Object.entries(l).map(([key, value]) => {
                        list_growth.push({
                            date: key,
                            ...value
                        })
                    });
                    // logger.log(list_growth);
                    setPerformance(perf);
                    setListGrowth(list_growth);
                    //last update time
                    setLastUpdated(data['last_updated']);
                    // total conv
                    setTotalConv(data['conv']);
            }),
            apiClient.post('/api/failed-email-sent', inputFailedEmail, {headers: {'X-User': userName}})
            .then (({data}) => {
                logger.log('failed email sent: ', data);
                // let d = [];
                // data.map(item =>{
                //     let goal_id = Object.keys(item).filter(it => {
                //         let regex = new RegExp(/^goal_.*/)
                //         return regex.test(it);
                //     })[0].split('_')[1];
                //     d.push({
                //         ...item,
                //         goal_id: goal_id
                //     })
                // })
                setEmailSentFailed(data);
            })
        ]).then(
            () => {return dispatch(hideLoading())}
        ) 
    };

    const datePicker = (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"1000px", 'border': 'none'}}>
            <DatePicker 
                applyDateRangeHandler = {applyDateRangeHandler}
                start = {startDate}
                end = {endDate}
                hideControl=''
            />
        </Popover>
      );
    
    const changeCampaignNameHandler = (item) => {
        setSelectedCampSeries(item);
        let d = []
        Object.entries(totalCampaign).map(([key, value]) => {
            if (key === item){
                d.push(...Object.keys(value));
            }
        });
        // logger.log(d);
        setCampaignName(['Select All', ...d]);
        setFilteredName(['Select All', ...d]);
    };
    const sortHandler = (data, mode) => {
        if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
            setSort(`${mode}_inc`);
            data.sort((a,b) => (b[mode] > a[mode]) ? -1 : (b[mode] < a[mode]) ? 1 : 0)
        } else if(sort === `${mode}_dec`){
            setSort(`${mode}_inc`);
            data.sort((a,b) => (b[mode] > a[mode]) ? -1 : (b[mode] < a[mode]) ? 1 : 0)
        } else if(sort === `${mode}_inc`){
            setSort(`${mode}_dec`);
            data.sort((a,b) => (b[mode] > a[mode]) ? 1 : (b[mode] < a[mode]) ? -1 : 0)
        };
    };

    const updateFilterHandler = (filterID, type, value) => {
        const newArr = filterArr.filter(item => {
            if(item.id === filterID){
                const d = item;
                item[type] = value;
                return d
            }else {
                return item
            }
        });
        setFilterArr(newArr);
        // logger.log(filterArr);
    };
    const deleteFilterHandler = (filterID) => {
    let arr = []
    if (filterArr.length === 1){
        arr = [{
        id: uuid4(),heading: 'keyword', operation: 'contains', value: ''
    }]
    } else {
        arr = filterArr.filter(item => item.id !== filterID)
    }
    setFilterArr(arr);
    // logger.log(filterArr);
    };
    const incFilterHandler = (filterID) => {
    if(filterArr.length === 3 ) return;
    setFilterArr( c => {
        const d = [...c];
        d.push({id: filterID ,heading: 'campaign_series', operation: 'contains', value: ''});
        return d
    });
    // logger.log(filterArr);
    };
    const applyFilterHandler = (d) => {
    //filterArr[{id, heading, operation, value}]
        logger.log('re render');
        let arr = [];
        d.map(item => {
            let count = 0;
            filterArr.map(condition => {
                if (condition.operation === 'contains'){
                    count += eval(`"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                }else if (condition.operation === 'does not contain'){
                    count += eval(`!"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                }else if (condition.operation === 'is'){
                    // logger.log(`"${item[condition.heading]}" === "${condition.value}"`);
                    count += eval(`"${item[condition.heading]}" === "${condition.value}"`)
                }else if (condition.operation === 'is not'){
                    count += eval(`"${item[condition.heading]}" !== "${condition.value}"`)
                }else if(condition.operation === 'greater than') {
                    count += eval(`${item[condition.heading]} > ${condition.value}`)
                }else if (condition.operation === 'greater than or equal'){
                    count += eval(`${item[condition.heading]} >= ${condition.value}`)
                }else if (condition.operation === 'less than'){
                    count += eval(`${item[condition.heading]} < ${condition.value}`)
                }else if (condition.operation === 'less than or equal'){
                    count += eval(`${item[condition.heading]} <= ${condition.value}`)
                }
            })
            if (count === filterArr.length){
                arr.push(item)
            }
        });
        if (Object.keys(d[0]).includes('Hard bounce')){
            setFilteredRawPerformance(arr);
        } else {
            setFilteredEngagementDetail(arr);
        }
        // logger.log(arr)
    };

    const urlFilter =  (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"500px", 'border': 'none'}}>
            <div className='d-flex flex-column p-4 bg-white shadow'>
                <p>Filter URLs</p>
                {
                    filterArr.map(item => {
                        // logger.log(item);
                        return (
                            <div className='d-flex align-items-center' key={item}>
                                where
                                <select class="form-select form-select-sm" aria-label="Default select example" className='ms-3 me-2' style={{maxWidth: '100px'}}
                                    onChange={e => updateFilterHandler(item.id, 'heading', e.target.value)}
                                >
                                  {/* <Form.Select aria-label="Default select example"> */}
                                  {
                                    Object.entries(filterList).map(([k, v]) => (
                                        <option value={k} selected={item['heading'] === k}>{v}</option>
                                    ))
                                  }
                                  {/* </Form.Select> */}
                                </select>
                                <select class="form-select form-select-sm" aria-label="Default select example" className='me-2'
                                    onChange={e => updateFilterHandler(item.id, 'operation', e.target.value)}
                                >
                                    <option value='contains' selected={item.operation === '' ? true : item.operation === 'contains' ? true : false}>contains</option>
                                    <option value="does not contain" selected={item.operation === 'does not contain'}>does not contain</option>
                                    <option value="is" selected={item.operation === 'is'}>is</option>
                                    <option value="is not" selected={item.operation === 'is not'}>is not</option>
                                    <option value="greater than" selected={item.operation === 'greater than'}>greater than</option>
                                    <option value="greater than or equal" selected={item.operation === 'greater than or equal'}>greater than or equal</option>
                                    <option value="less than" selected={item.operation === 'less than'}>less than</option>
                                    <option value="less than or equal" selected={item.operation === 'less than or equal'}>less than or equal</option>
                                </select>
    
                                <input type="text" className="form-control me-2" style={{width: '120px'}} placeholder="Enter a value"
                                    defaultValue={item.value}
                                    onChange={e => updateFilterHandler(item.id, 'value', e.target.value)}
                                />
                                <button className='btn'
                                    onClick={e => deleteFilterHandler(item.id)}
                                ><FontAwesomeIcon icon={faTrash} /></button>
                            </div>
                        )
                    })
                }
                <div className='d-flex mt-4'>
                    <button type="button" class={`btn btn-light me-auto ${filterArr.length === 3 && 'disabled'}`} onClick={e => incFilterHandler(uuid4())}>+ Add condition</button>
                    <button type="button" class="btn btn-primary" onClick={ e => {
                        if (document.getElementById("ModalEngagement").style.display === 'block') {
                            applyFilterHandler(engagementDetail)
                        } else {
                            applyFilterHandler(rawPerformance)
                        }
                    }}>Apply</button>
                </div>
                
            </div>
        </Popover>
    );
    
    const downloadReportHandler = () => {
        if(filteredRawPerformance.length === 0) return;
        const csv = Papa.unparse({
            // "fields": ['URL', "Keyword", 'Volume', 'KD', 'CPC'],
            "data": filteredRawPerformance
        });
        const blob = new Blob([csv]);

        const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
        // logger.log(blob);
        const link = document.createElement("a");
        link.download = 'email_campaign_performance.csv';
        link.href = url;
        link.click();
    };

    const toggleBounceHandler = (modal) => {
        if(rawPerformance.length === 0) return;
        setDisplayMode('email_list');
        setEmailHistory([]);
        setImportStat('');
        const m = new bootstrap.Modal(document.getElementById(modal));
        m.toggle();
    };

    const fetchTotalSentHandler = () => {
        if(rawPerformance.length === 0) return;
        setEngagementDetail([]);
        setFilteredEngagementDetail([]);
        dispatch(showLoading());
        let s = selectedCampSeries;
        let n = selectedCampName;
        if (selectedCampSeries === 'Campaign Series' || selectedCampSeries === 'Select All') s = '';
        if (selectedCampName === 'Campaign Name' || selectedCampName === 'Select All') n = '';
        const input = {
            campaign_series: s,
            campaign_name: n,
            start: startDate,
            end: endDate
        };
        logger.log(input);
        apiClient.post('/api/mailchimp-sent-report', input, {headers: {'X-User': userName}})
        .then (
            ({data}) =>{
                dispatch(hideLoading());
                // logger.log(data);
                setEngagementDetail(data);
                setFilteredEngagementDetail(data);
                setEmailHistory([]);
                setDisplayMode('email_list');
            });
        const m = new bootstrap.Modal(document.getElementById('ModalEngagement'), {focus: false});
        m.toggle();
    };

    const fetchEngagementHandler= (engagement_type) => {
        if(rawPerformance.length === 0) return;
        dispatch(showLoading());
        let s = selectedCampSeries;
        let n = selectedCampName;
        if (selectedCampSeries === 'Campaign Series' || selectedCampSeries === 'Select All') s = '';
        if (selectedCampName === 'Campaign Name' || selectedCampName === 'Select All') n = '';
        let input = {
            campaign_series: s,
            campaign_name: n,
            start: startDate,
            end: endDate,
        };
        setDisplayMode('email_list');
        setEngagementDetail([]);
        setFilteredEngagementDetail([]);
        if (engagement_type === 'conv') {
            logger.log(input);
            apiClient.post('/api/mailchimp-conv-report', input, {headers: {'X-User': userName}})
            .then (
                    ({data}) =>{
                        dispatch(hideLoading());
                        logger.log(data);
                        setEngagementDetail(data);
                        setFilteredEngagementDetail(data);
                    });
        } else {
            input['engagement_type'] = engagement_type;
            // logger.log(input);
            apiClient.post('/api/mailchimp-engagement-report', input, {headers: {'X-User': userName}})
            .then (
                    ({data}) =>{
                        dispatch(hideLoading());
                        // logger.log(data);
                        setEngagementDetail(data);
                        setFilteredEngagementDetail(data);
                    });
        }
        const m = new bootstrap.Modal(document.getElementById('ModalEngagement'), {focus: false});
        m.toggle();
    };

    const fetchEmailActivityHandler = (email_id) => {
        setDisplayMode('email_detail');
        // logger.log('email id: ', email_id);
        dispatch(showLoading());
        apiClient.post('/api/email-activity-history', {email_id: email_id}, {headers: {'X-User': userName}})
        .then (
                ({data}) =>{
                    dispatch(hideLoading());
                    logger.log(data);
                    setEmailHistory(data['activity']);
                    if (data['info'].length > 0){
                        setEmailInfo(data['info'][0]);
                    }
                });
    }
    
    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">MailChimp report</h1>
            <div className='d-flex my-4'>
                <div className='d-flex me-auto align-self-center'>
                    <span className={`${rawPerformance.length === 0 && 'd-none'}`}>
                        Last updated: {`${new Date(lastUpdated).getDate()}/${new Date(lastUpdated).getMonth() + 1}/${new Date(lastUpdated).getFullYear()}`}
                    </span>
                </div>
                <div class="dropdown me-3 align-self-center" >
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faGlobe} className='me-2' />
                        {selectedCampSeries}
                    </button>
                    <ul class="dropdown-menu px-2 py-0" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'250px', 'overflow': 'auto', width: '250px'}}>
                        <div className='d-flex bg-white p-2 sticky-top'>
                            <input type="text" class="form-control" placeholder="Search country..." aria-label="Search country..." aria-describedby="basic-addon1"
                            onChange = {e => filterHandler('series',e.target.value)}
                            ref={seriesSearchStr}
                        />
                        </div>
                        {filteredSeries.map((item, index) => {
                            return (
                                <li key={index} onClick={e => changeCampaignNameHandler(item)}><a class="dropdown-item" href="#">{item}</a></li>
                            )
                        })}
                    </ul>
                </div>
                <div class="dropdown me-3 align-self-center">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faGlobe} className='me-2' />
                        {selectedCampName}
                    </button>
                    <ul class="dropdown-menu px-2 py-0" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'250px', 'overflow': 'auto', width: '250px'}}>
                        <div className='d-flex bg-white p-2 sticky-top'>
                            <input type="text" class="form-control" placeholder="Search country..." aria-label="Search country..." aria-describedby="basic-addon1"
                            onChange = {e => filterHandler('name',e.target.value)}
                            ref={nameSearchStr}
                            />
                        </div>
                        { filteredName.length > 0 && filteredName.map((item, index) => {
                            return (
                                <li key={index} onClick={e => setSeletecCampName(item)}><a class="dropdown-item" href="#">{item}</a></li>
                            )
                        })}
                    </ul>
                </div>
                <div class="input-group ps-3 w-auto">
                  <span class="input-group-text" id="basic-addon1">Date range</span>
                  <OverlayTrigger trigger={["click"]} placement="bottom" overlay={datePicker} rootClose container={this} containerPadding={20}>
                      <input type="text" className="form-control" placeholder="from - to" id="startEnd"/>
                  </OverlayTrigger>
                </div>
            </div>
            <div className='d-flex my-4'>
                {/* Modal Bounce*/}
                <div class="modal fade" id="ModalEngagement" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div class="modal-content">
                        <div className="modal-header">
                            <h5 class="modal-title me-2" id="ModalLongTitle">Engagement Details</h5>
                            <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                <span className='ms-1'>Success</span>
                            </div>
                            <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                <span className='ms-1'>Failed. Pls Retry!</span>
                            </div>
                            <div className='d-flex me-auto'>
                                <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
                                    <button type="button" class="btn btn-light"
                                        onClick={e => {setFilterList(
                                            {
                                                'campaign_series' : 'Campaign Series',
                                                'campaign_name': 'Campaign Name',
                                                'email_address': 'Email',
                                            }
                                        );
                                        setFilterArr([{
                                            id: uuid4(),heading: 'campaign_series', operation: 'contains', value: ''
                                          }])
                                    }}
                                    >
                                        <FontAwesomeIcon className='me-2' icon={faFilter} />
                                        Filter
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div class="modal-body">
                            {
                                displayMode === 'email_list' ?
                                <div class="d-flex flex-column mb-4"  style={{'height':'500px', 'overflow':'auto'}}>
                                    <table class="table">
                                        <thead className="table-primary sticky-top">
                                            <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">
                                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(engagementDetail, 'campaign_series')}>
                                                    <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Campaign Series</span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_series_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_series_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                </button>
                                            </th>
                                            <th scope="col">
                                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(engagementDetail, 'campaign_name')}>
                                                    <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Campaign Name</span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_name_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_name_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                </button>
                                            </th>
                                            <th scope="col">
                                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(engagementDetail, 'activity_time')}>
                                                    <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Date</span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'activity_time_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'activity_time_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                </button>
                                            </th>
                                            <th scope="col">
                                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(engagementDetail, 'email_address')}>
                                                    <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Email</span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'email_address_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'email_address_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                </button>
                                            </th>
                                            {
                                                engagementDetail.length > 0 && Object.keys(engagementDetail[0]).includes('tags') &&
                                                <th scope="col">
                                                    Tags
                                                </th>
                                            }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {   
                                                filteredEngagementDetail.map((item, idx) => (
                                                    <tr>
                                                        <th scope="row">{idx+1}</th>
                                                        <td>{item.campaign_series}</td>
                                                        <td>{item.campaign_name}</td>
                                                        <td>{`${new Date(item.activity_time).getDate()}/${new Date(item.activity_time).getMonth()+1}/${new Date(item.activity_time).getFullYear()}`}</td>
                                                        <td onClick={e => {setDisplayMode('email_detail'); fetchEmailActivityHandler(item.email_id)}}>
                                                            <a href='#'>{item.email_address}</a>
                                                        </td>
                                                        {
                                                            Object.keys(item).includes('tags') && item['tags'].length > 0 &&
                                                            <td>
                                                                {item['tags'].map(a => a.name).join(', ')}
                                                            </td>
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div> :
                                <div class="d-flex" style={{'height':'500px'}}>
                                    <div className='d-flex flex-column col-md-3'>
                                        <h5 className='sticky-top p-2 bg-light'>Contact Info</h5>
                                        <p>Full Name: {emailInfo['full_name']}</p>
                                        <p>Email: {emailInfo['email_address']}</p>
                                        <p>Status: {emailInfo['status']}</p>
                                        <p>Optin time: {new Date(emailInfo['timestamp_opt']).toLocaleDateString()}</p>
                                        <div>
                                            <p>Tags: </p>
                                            <ul className='list-group list-group-flush'>
                                                {   Object.keys(emailInfo).length > 0 &&
                                                    emailInfo['tags'].map(item => (
                                                        <li className='list-group-item border-0'>
                                                            {item['name']}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column col-md-9' style={{'height':'500px', 'overflow':'auto'}}>
                                        <h5 className='sticky-top p-2 bg-light'>Activity History</h5>
                                        <ul className='list-group list-group-flush'>
                                            {
                                                emailHistory.map(item => (
                                                    <li className='list-group-item'>
                                                        <small>{`${new Date(item.created_at_timestamp).toLocaleDateString()} - ${new Date(item.created_at_timestamp).toLocaleTimeString()}`}</small>
                                                        <p class="my-2">{`${item.activity_type}`}
                                                            {   item.campaign_title &&
                                                                <b>{` - ${item.campaign_title}`}</b>
                                                            }
                                                        </p>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                            
                        </div>
                        <div class="modal-footer">
                            {
                                displayMode === 'email_detail' &&
                                <button type="button" className="btn btn-primary align-middle" 
                                    onClick={e => {setDisplayMode('email_list'); setEmailHistory([])}}
                                >
                                    <FontAwesomeIcon icon={faArrowCircleLeft} size='1x' className='me-2'/>
                                    Go Back
                                </button>
                            }
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                        </div>
                        </div>
                    </div>
                </div>
                {/* Modal Bounce*/}
                <div className='d-flex flex-column col-md-6'>
                    <div className='d-flex'>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 ${performance.length > 0 && 'metric-hover'}`}
                            role='button'
                            onClick={fetchTotalSentHandler}
                        >
                            <b>Total sent</b>
                            <h2 className='align-self-center my-3'>
                            { (() => {  
                                        if (performance.length > 0){
                                            let sum = 0;
                                            for (let i = 0; i < performance.length; i++) {
                                                sum += performance[i]['sent'];
                                            };
                                            return sum.toLocaleString('en-US')
                                        }
                                        return '-'
                                    })()
                                }
                            </h2>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 ${performance.length > 0 && 'metric-hover'}`}
                            role="button"
                            onClick={e => fetchEngagementHandler("open")}
                        >
                            <b>Total unique open</b>
                            <h2 className='align-self-center my-3'>
                            { (() => {  
                                        if (performance.length > 0){
                                            let sum = 0;
                                            for (let i = 0; i < performance.length; i++) {
                                                sum += performance[i]['open'];
                                            };
                                            return sum.toLocaleString('en-US')
                                        }
                                        return '-'
                                    })()
                                }
                            </h2>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 ${performance.length > 0 && 'metric-hover'}`}
                            role="button"
                            onClick = {e => fetchEngagementHandler("click")}
                        >
                            <b>Total unique click</b>
                            <h2 className='align-self-center my-3'>
                            { (() => {  
                                        if (performance.length > 0){
                                            let sum = 0;
                                            for (let i = 0; i < performance.length; i++) {
                                                sum += performance[i]['click'];
                                            };
                                            return sum.toLocaleString('en-US')
                                        }
                                        return '-'
                                    })()
                                }
                            </h2>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                    </div>
                    <div className='d-flex mt-4'>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 ${performance.length > 0 && 'metric-hover'}`}
                            role="button"
                            onClick = {e => fetchEngagementHandler("conv")}
                        >
                            <b>Total conversion</b>
                            <h2 className='align-self-center my-3'>
                            { totalConv.length === 0 ? '-' : totalConv.length.toLocaleString('en-US')
                                }
                            </h2>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                        <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                            <b>Open rate</b>
                            <h2 className='align-self-center my-3'>
                            { (() => {  
                                        if (performance.length > 0){
                                            let sent = 0;
                                            let open = 0;
                                            for (let i = 0; i < performance.length; i++) {
                                                sent += performance[i]['sent'];
                                                open += performance[i]['open'];
                                            };
                                            let open_rate = `${(open*100/sent).toFixed(1)}%`
                                            return open_rate
                                        }
                                        return '-'
                                    })()
                                }
                            </h2>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                        <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                            <b>Click rate</b>
                            <h2 className='align-self-center my-3'>
                            { (() => {  
                                        if (performance.length > 0){
                                            let click = 0;
                                            let open = 0;
                                            for (let i = 0; i < performance.length; i++) {
                                                click += performance[i]['click'];
                                                open += performance[i]['open'];
                                            };
                                            let open_rate = `${(click*100/open).toFixed(1)}%`
                                            return open_rate
                                        }
                                        return '-'
                                    })()
                                }
                            </h2>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                    </div>    
                </div>
                <div className='d-flex col-md-6'>
                    <div className='d-flex col-md-8'>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart
                                margin={{
                                    top: 0,
                                    right: 10,
                                    left: 10,
                                    bottom: -10,
                                }}
                            >
                            <Pie
                                dataKey="value"
                                // startAngle={180}
                                // endAngle={0}
                                data={bounceReasons}
                                // cx="50%"
                                // cy="50%"
                                innerRadius={0}
                                // outerRadius={80}
                                paddingAngle={2}
                                fill="#8884d8"
                            >
                                {bounceReasons.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='col-md-4'>
                        <div role='button' class={`d-flex flex-column col border shadow-sm rounded py-2 px-3 ${performance.length > 0 && 'metric-hover'}`}
                            onClick={e => toggleBounceHandler('ModalBounce')}
                        >
                            <b>Total bounce</b>
                            <h2 className='align-self-center my-3'>
                                {   (() => {
                                        let sum = 0;
                                        for (let i = 0; i < bounceReasons.length; i++) {
                                            sum += bounceReasons[i].value;
                                        };
                                        return sum
                                    })()
                                }
                            </h2>
                            <div className='d-flex'>
                                <ul class="list-group list-group-flush">
                                    {
                                        bounceReasons.map((item, idx) => {
                                            return (
                                                <li class="list-group-item border-0 p-0">
                                                    <FontAwesomeIcon icon={faSquareCheck} className='me-2' style={{color: COLORS[idx]}}/>
                                                    {`${item.name}: ${item.value.toLocaleString('en-US')}`}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Bounce*/}
                <div class="modal fade" id="ModalBounce" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div class="modal-content">
                        <div className="modal-header">
                            <h5 class="modal-title me-auto" id="ModalLongTitle">Bounce Details</h5>
                            <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                <span className='ms-1'>Success</span>
                            </div>
                            <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                <span className='ms-1'>Failed. Pls Retry!</span>
                            </div>
                        </div>
                        <div class="modal-body">
                            {
                                displayMode === 'email_list' ?
                                <div class="d-flex flex-column mb-4"  style={{'max-height':'500px', 'overflow':'auto'}}>
                                    <table class="table">
                                        <thead className="table-primary sticky-top">
                                            <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">
                                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(bounceEmails, 'campaign_series')}>
                                                    <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Campaign Series</span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_series_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_series_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                </button>
                                            </th>
                                            <th scope="col">
                                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(bounceEmails, 'campaign_name')}>
                                                    <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Campaign Name</span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_name_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_name_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                </button>
                                            </th>
                                            <th scope="col">
                                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(bounceEmails, 'date')}>
                                                    <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Date</span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'date_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'date_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                </button>
                                            </th>
                                            <th scope="col">
                                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(bounceEmails, 'email')}>
                                                    <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Email</span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'email_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'email_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                </button>
                                            </th>
                                            <th scope="col">
                                                <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(bounceEmails, 'reason')}>
                                                    <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Reason</span>
                                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'reason_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'reason_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                </button>
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bounceEmails.map((item, idx) => (
                                                    <tr>
                                                        <th scope="row">{idx+1}</th>
                                                        <td>{item.campaign_series}</td>
                                                        <td>{item.campaign_name}</td>
                                                        <td>{`${new Date(item.date).getDate()}/${new Date(item.date).getMonth()+1}/${new Date(item.date).getFullYear()}`}</td>
                                                        <td
                                                            onClick={e => {setDisplayMode('email_detail'); fetchEmailActivityHandler(item.email)}}
                                                        >
                                                            <a href='#'>{item.email}</a>
                                                        </td>
                                                        <td>{item.reason}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                
                                </div> :
                                <div class="d-flex" style={{'height':'500px'}}>
                                    <div className='d-flex flex-column col-md-3'>
                                        <h5 className='sticky-top p-2 bg-light'>Contact Info</h5>
                                        <p>Full Name: {emailInfo['full_name']}</p>
                                        <p>Email: {emailInfo['email_address']}</p>
                                        <p>Status: {emailInfo['status']}</p>
                                        <p>Optin time: {new Date(emailInfo['timestamp_opt']).toLocaleDateString()}</p>
                                        <div>
                                            <p>Tags: </p>
                                            <ul className='list-group list-group-flush'>
                                                {   Object.keys(emailInfo).length > 0 &&
                                                    emailInfo['tags'].map(item => (
                                                        <li className='list-group-item border-0'>
                                                            {item['name']}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column col-md-9' style={{'height':'500px', 'overflow':'auto'}}>
                                        <h5 className='sticky-top p-2 bg-light'>Activity History</h5>
                                        <ul className='list-group list-group-flush'>
                                            {
                                                emailHistory.map(item => (
                                                    <li className='list-group-item'>
                                                        <small>{`${new Date(item.created_at_timestamp).toLocaleDateString()} - ${new Date(item.created_at_timestamp).toLocaleTimeString()}`}</small>
                                                        <p class="my-2">{`${item.activity_type}`}
                                                            {   item.campaign_title &&
                                                                <b>{` - ${item.campaign_title}`}</b>
                                                            }
                                                        </p>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="modal-footer">
                            {
                                displayMode === 'email_detail' &&
                                <button type="button" className="btn btn-primary align-middle" 
                                        onClick={e => {setDisplayMode('email_list'); setEmailHistory([])}}
                                    >
                                        <FontAwesomeIcon icon={faArrowCircleLeft} size='1x' className='me-2'/>
                                        Go Back
                                </button>
                            }
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                        </div>
                        </div>
                    </div>
                </div>
                {/* Modal Bounce*/}
            </div>
            <hr />
            <div className='d-flex flex-column my-4'>
                <h5 className='mb-2'>Failed sending emails</h5>
                <div className='d-flex flex-column overflow-auto mb-4 my-4' style={{"height":"500px"}}>
                    <table class="table">
                        <thead className='table-primary sticky-top'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(emailSentFailed, 'campaign_series')}>
                                        <span className='me-2 fw-bold'>Campaign series</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_series_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_series_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(emailSentFailed, 'campaign_name')}>
                                            <span className='me-2 fw-bold'>Campaign name</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_name_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_name_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(emailSentFailed, 'converted_page')}>
                                        <span className='me-2 fw-bold'>Converted URL</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'total_sent_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'total_sent_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(emailSentFailed, 'converted_time')}>
                                            <span className='me-2 fw-bold'>Converted Time</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Total unique subscriber open_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Total unique subscriber open_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(emailSentFailed, 'visitor_id')}>
                                        <span className='me-2 fw-bold'>UserId</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Total unique subscriber click_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Total unique subscriber click_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                {/* <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(emailSentFailed, 'goal_id')}>
                                            <span className='me-2 fw-bold'>GoalId</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Total bounce_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Total bounce_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th> */}
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(emailSentFailed, 'reason')}>
                                            <span className='me-2 fw-bold'>Reason</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Total bounce_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Total bounce_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                emailSentFailed.map((item, idx) => {
                                    if(item.campaign_series !== ''){
                                        return (
                                            <tr>
                                                <th scope="row">{idx+1}</th>
                                                <td>{item.campaign_series}</td>
                                                <td>{item.campaign_name}</td>
                                                <td>{item.converted_page}</td>
                                                <td>{new Date(item.converted_time*1000).toLocaleDateString()}</td>
                                                <td>{item.visitor_id}</td>
                                                {/* <td>{item.goal_id}</td> */}
                                                <td>{item.reason}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <hr />
            <div className='d-flex my-4'>
                <div className='d-flex col-md-6' style={{minHeight: '300px'}}>
                    <ResponsiveContainer width='100%' height='100%'>
                        <AreaChart
                        width={500}
                        height={300}
                        data={listGrowth}
                        margin={{
                            top: 5,
                            right: 10,
                            left: 10,
                            bottom: 5,
                        }}
                        >
                            <defs>
                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                name = "date"
                                tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                            />
                            <YAxis 
                                domain={[0, 'auto']}
                            />
                            <Tooltip 
                                content={<CustomTooltip />}
                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                            />
                            <Legend legendType='none'/>
                            <Area type="monotone" dataKey="sent" name="list growth" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <div className='d-flex col-md-6'>
                    <div className='d-flex col-md-6'>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart
                                margin={{
                                    top: 0,
                                    right: 10,
                                    left: 10,
                                    bottom: -10,
                                }}
                            >
                            <Pie
                                dataKey="value"
                                // startAngle={180}
                                // endAngle={0}
                                data={unsub}
                                // cx="50%"
                                // cy="50%"
                                innerRadius={0}
                                // outerRadius={80}
                                paddingAngle={2}
                                fill="#8884d8"
                            >
                                {unsub.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='col-md-6'>
                        <div role='button' class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 ${performance.length > 0 && 'metric-hover'}`}
                            onClick={e => toggleBounceHandler('ModalUnsub')}
                        >
                            <b>Total unsub</b>
                            <h2 className='align-self-center my-3'>
                                {   (() => {
                                            let sum = 0;
                                            for (let i = 0; i < unsub.length; i++) {
                                                sum += unsub[i].value;
                                            };
                                            return sum
                                        })()
                                    }
                            </h2>
                            <div className='d-flex'>
                                <ul class="list-group list-group-flush">
                                    {
                                        unsub.map((item, idx) => {
                                            let count = -1;
                                            if (item.value > 0){
                                                return (
                                                    <li class="list-group-item border-0 p-0">
                                                        <FontAwesomeIcon icon={faSquareCheck} className='me-2' style={{color: COLORS[idx]}}/>
                                                        {`${item.name}: ${item.value.toLocaleString('en-US')}`}
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Modal Unsub*/}
                    <div class="modal fade" id="ModalUnsub" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                            <div class="modal-content">
                            <div className="modal-header">
                                <h5 class="modal-title me-auto" id="ModalLongTitle">Unsub Details</h5>
                                <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                    <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                    <span className='ms-1'>Success</span>
                                </div>
                                <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                    <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                    <span className='ms-1'>Failed. Pls Retry!</span>
                                </div>
                            </div>
                            <div class="modal-body">
                                {
                                    displayMode === 'email_list' ?
                                    <div class="d-flex flex-column mb-4"  style={{'max-height':'500px', 'overflow':'auto'}}>
                                        <table class="table">
                                            <thead className="table-primary sticky-top">
                                                <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(unsubEmails, 'campaign_series')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Campaign Series</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_series_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_series_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col">
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(unsubEmails, 'campaign_name')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Campaign Name</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_name_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_name_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col">
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(unsubEmails, 'date')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Date</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'date_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'date_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col">
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(unsubEmails, 'email')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Email</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'email_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'email_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col">
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(unsubEmails, 'reason')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Reason</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'reason_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'reason_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {   
                                                    unsubEmails.map((item, idx) => (
                                                        <tr>
                                                            <th scope="row">{idx+1}</th>
                                                            <td>{item.campaign_series}</td>
                                                            <td>{item.campaign_name}</td>
                                                            <td>{`${new Date(item.date).getDate()}/${new Date(item.date).getMonth()+1}/${new Date(item.date).getFullYear()}`}</td>
                                                            <td
                                                                onClick={e => {setDisplayMode('email_detail'); fetchEmailActivityHandler(item.email)}}
                                                            >
                                                                <a href='#'>{item.email}</a>
                                                            </td>
                                                            <td>{item.reason}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    
                                    </div> :
                                    <div class="d-flex" style={{'height':'500px'}}>
                                        <div className='d-flex flex-column col-md-3'>
                                            <h5 className='sticky-top p-2 bg-light'>Contact Info</h5>
                                            <p>Full Name: {emailInfo['full_name']}</p>
                                            <p>Email: {emailInfo['email_address']}</p>
                                            <p>Status: {emailInfo['status']}</p>
                                            <p>Optin time: {new Date(emailInfo['timestamp_opt']).toLocaleDateString()}</p>
                                            <div>
                                                <p>Tags: </p>
                                                <ul className='list-group list-group-flush'>
                                                    {   Object.keys(emailInfo).length > 0 &&
                                                        emailInfo['tags'].map(item => (
                                                            <li className='list-group-item border-0'>
                                                                {item['name']}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column col-md-9' style={{'height':'500px', 'overflow':'auto'}}>
                                            <h5 className='sticky-top p-2 bg-light'>Activity History</h5>
                                            <ul className='list-group list-group-flush'>
                                                {
                                                    emailHistory.map(item => (
                                                        <li className='list-group-item'>
                                                            <small>{`${new Date(item.created_at_timestamp).toLocaleDateString()} - ${new Date(item.created_at_timestamp).toLocaleTimeString()}`}</small>
                                                            <p class="my-2">{`${item.activity_type}`}
                                                                {   item.campaign_title &&
                                                                    <b>{` - ${item.campaign_title}`}</b>
                                                                }
                                                            </p>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                            <div class="modal-footer">
                                {
                                    displayMode === 'email_detail' &&
                                    <button type="button" className="btn btn-primary align-middle" 
                                        onClick={e => {setDisplayMode('email_list'); setEmailHistory([])}}
                                    >
                                        <FontAwesomeIcon icon={faArrowCircleLeft} size='1x' className='me-2'/>
                                        Go Back
                                    </button>
                                }
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal Unsub*/}
                </div>
            </div>
            <hr />
            <div className='d-flex my-4 w-100' style={{height: '300px'}}>
                <ResponsiveContainer width='100%' height='100%' >
                    <ComposedChart
                    // width={1000}
                    height={300}
                    data={performance}
                    margin={{
                        top: 5,
                        right: 10,
                        left: 10,
                        bottom: 5,
                    }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            name = "date"
                            tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                        />
                        <YAxis 
                            yAxisId="left"
                            domain={[0, 'auto']}
                        />
                        <YAxis 
                            yAxisId="right"
                            orientation="right"
                            domain={[0, 100]}
                        />
                        <Tooltip 
                            content={<CustomTooltip />}
                            wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '5px' }}
                        />
                        <Legend legendType='none'/>
                        <Bar yAxisId="left" type="monotone" dataKey="sent" name="list growth" stroke="#8884d8" fill="#8884d8"/>
                        <Bar yAxisId="left" type="monotone" dataKey="conv" stroke="#00C49F" fill="#00C49F"/>
                        <Line yAxisId="right" type="monotone" dataKey="open rate" stroke="#FFBB28" strokeWidth={1.5}/>
                        <Line yAxisId="right" type="monotone" dataKey="click rate" stroke="#FF8042" strokeWidth={1.5}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
            <hr />
            <div className='d-flex flex-column'>
                <div className='d-flex my-3'>
                    <div className='d-flex me-auto'>
                        <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
                            <button type="button" class="btn btn-light"
                                onClick={e => {setFilterList(
                                    {
                                        'campaign_series' : 'Campaign Series',
                                        'campaign_name': 'Campaign Name',
                                        'total_sent': 'Total Sent',
                                        'Total unique subscriber open': 'Total unique subscriber open',
                                        'Total unique subscriber click': 'Total unique subscriber click',
                                        'Total bounce': 'Total bounce',
                                        'Total conv': 'Total conv' 
                                    }
                                );
                                setFilterArr([{
                                    id: uuid4(),heading: 'campaign_series', operation: 'contains', value: ''
                                  }])
                            }}
                            >
                                <FontAwesomeIcon className='me-2' icon={faFilter} />
                                Filter
                            </button>
                        </OverlayTrigger>
                    </div>
                    <button class="btn btn-primary ms-4" type="button" aria-expanded="false" onClick={downloadReportHandler}>
                        <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                        Download
                    </button>
                </div>
                <div className='d-flex overflow-auto mb-4' style={{"height":"500px"}}>
                    <table class="table">
                        <thead className='table-primary sticky-top'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredRawPerformance, 'campaign_series')}>
                                        <span className='me-2 fw-bold'>Campaign series</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_series_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_series_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredRawPerformance, 'campaign_name')}>
                                            <span className='me-2 fw-bold'>Campaign name</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'campaign_name_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'campaign_name_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredRawPerformance, 'total_sent')}>
                                        <span className='me-2 fw-bold'>Sent</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'total_sent_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'total_sent_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredRawPerformance, 'Total unique subscriber open')}>
                                            <span className='me-2 fw-bold'>Open</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Total unique subscriber open_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Total unique subscriber open_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredRawPerformance, 'Total unique subscriber click')}>
                                        <span className='me-2 fw-bold'>Click</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Total unique subscriber click_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Total unique subscriber click_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredRawPerformance, 'Total bounce')}>
                                            <span className='me-2 fw-bold'>Bounce</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Total bounce_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Total bounce_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredRawPerformance.map((item, idx) => {
                                    if(item.campaign_series !== ''){
                                        return (
                                            <tr>
                                                <th scope="row">{idx+1}</th>
                                                <td>{item.campaign_series}</td>
                                                <td>{item.campaign_name}</td>
                                                <td>{item.total_sent}</td>
                                                <td>{item['Total unique subscriber open'] === 0 ? '-' : item['Total unique subscriber open']}</td>
                                                <td>{item['Total unique subscriber click'] === 0 ? '-' : item['Total unique subscriber click']}</td>
                                                <td>{item['Total bounce'] === 0 ? '-' : item['Total bounce']}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    )
};

export default MailChimp;