import { getAuthToken } from './token';  // Import the token validator
import { getDeviceId } from './deviceManager';  // Import the device ID generator
import logger from './logger';

class WebSocketService {
    static instance = null;
    socket = null;
    messageHandlers = new Set();
  
    static getInstance() {
      if (!WebSocketService.instance) {
        WebSocketService.instance = new WebSocketService();
      }
      return WebSocketService.instance;
    }
  
    connect() {
        const token = getAuthToken();

        if (!token || token === 'EXPIRED') {
          logger.log('No valid token found');
          return null;
        }
    
        // logger.log('token:', token);
        const encodedToken = encodeURIComponent(token);
        
        if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
          this.socket = new WebSocket(`${process.env.REACT_APP_WS_PROTOCOL}://${process.env.REACT_APP_ORIGIN}/ws/notifications?token=${encodedToken}&deviceId=${getDeviceId()}`);
          
          this.socket.onopen = () => {
            logger.log('WebSocket Connected');
          };
    
          this.socket.onmessage = (event) => {
            try {
                const messageData = JSON.parse(event.data);
                const messageType = messageData['type'];
                logger.log('Received message:', messageData['content']);
                if (messageType === 'session_invalidation') {
                  logger.log('Session invalidated');
                  localStorage.removeItem('token');
                  localStorage.removeItem('device_id');
                  localStorage.removeItem('role');
                  localStorage.removeItem('expiration');
                  localStorage.removeItem('username');
                };
                
                // Notify all registered handlers
                this.messageHandlers.forEach(handler => handler(messageData));
            } catch (error) {
              console.error('Error handling message:', error);
            }
          };
    
          this.socket.onclose = () => {
            logger.log('WebSocket Disconnected');
            // Optional: implement reconnection logic here
          };
    
          this.socket.onerror = (error) => {
            console.error('WebSocket Error:', error);
          };
        }
        
        return this.socket;
      }
    
      getAuthToken() {
        return getAuthToken();
      }
    
      disconnect() {
        if (this.socket) {
          this.socket.close();
          this.socket = null;
        }
      }
    
      // Add message handler
      addMessageHandler(handler) {
        this.messageHandlers.add(handler);
      }
    
      // Remove message handler
      removeMessageHandler(handler) {
        this.messageHandlers.delete(handler);
      }
  }
  
  export const wsService = WebSocketService.getInstance();