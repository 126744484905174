import * as d3 from "d3";
import * as venn from 'venn.js';
import { useEffect, useRef } from "react";
import logger from "../util/logger";


const VennChart = (props) => {
    const ref = useRef();
    // logger.log(props.data)
    const data = props.data;
    useEffect(() => {
        const width = 350;
        const height = 350;

        // add a tooltip
        const tooltip = d3.select("#tooltipV");

        const colors = ["#1f77b4", "#ff7f0e", "#2ca02c"];

        // draw venn diagram
        const chart = venn.VennDiagram()
                        .width(width)
                        .height(height)
        const div = d3.select(ref.current)
                    .attr("width", width)
                    .attr("height", height)
        div.datum(data).call(chart);

        div.selectAll('.venn-circle path')
            .style('fill', function(d, i) {return colors[d.sets]})
        
        div.selectAll('.venn-circle text')
            .style('fill', function(d, i) {return colors[d.sets]})


        div.selectAll("path")
            .style("stroke-opacity", 0)
            .style("stroke", "#fff")
            .style("stroke-width", 3)


        // add listeners to all the groups to display tooltip on mouseover
        div.selectAll("g")
            .on("mouseenter", function(i, d) {
                // sort all the areas relative to the current item
                venn.sortAreas(div, d);

                // Display a tooltip with the current size
                tooltip.style("display", 'block');
                tooltip.style("position", "absolute")
                        .text(d.size + " keywords");
                
                // highlight the current path
                let selection = d3.select(this).transition("tooltip").duration(400);
                selection.select("path")
                    .style("stroke-width", 3)
                    .style("fill-opacity", d.sets.length == 1 ? .4 : .1)
                    .style("stroke-opacity", 1);
            })

            .on("mousemove", function(event) {
                tooltip.style("left", event.pageX + 30 + "px")
                    .style("top", event.pageY - 28 + "px");
            })

            .on("mouseleave", function(i, d) {
                tooltip.style("display", 'none');
                let selection = d3.select(this).transition("tooltip").duration(400);
                selection.select("path")
                    .style("stroke-width", 0)
                    .style("fill-opacity", d.sets.length == 1 ? .25 : .0)
                    .style("stroke-opacity", 0);
            });

    },[data]);
    return (
        <div id='venn' ref={ref}>
          <div id='tooltipV'>
            
          </div>
        </div>
      )
};

export default VennChart;
